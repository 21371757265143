import React, { Suspense, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { TextInput } from 'components/common/Input/TextInput';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { HormoneWrapper } from 'components/prescriptions/HormoneWrapper/HormoneWrapper';
import { DeliveryMethods } from 'constants/delivery-methods';
import { femaleHormoneRxTypes } from 'constants/hormone-details';
import { useProgesteroneController } from 'hooks/hormones/useProgesteroneController';
import {
  HormoneNames,
  PrescribedObjectTypes,
  PrescriptionKeys,
} from 'types/prescriptionTypes';

import { CustomSlider } from '../../common/Slider/Slider';

type ProgesteroneProps = {
  addNewPrescription: (prescriptionObject: PrescribedObjectTypes) => void;
};

export function Progesterone({ addNewPrescription }: ProgesteroneProps) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const [infoContent, setInfoContent] = useState('');

  const navigate = useNavigate();

  const ProgesteroneDetails = femaleHormoneRxTypes[rx || ''];
  const {
    mgPerMl,
    DTDInWords,
    deliveryMethod,
    setDeliveryMethod,
    DTD,
    setMlPerDay,
    mlPerDay,
    setDTD,
    drops,
    sig,
    setSig,
    refills,
    setRefils,
    setDrops,
  } = useProgesteroneController();

  const isDeliveryOralSyringe =
    deliveryMethod === DeliveryMethods.OralSyringe.name;
  const eyeDropperMaxMessage =
    'Maximum drops/day reached, Do you want us to switch to Oral syringe method?';
  const oralSyringeMaxMessage =
    'Maximum ml/day reached, Do you want us to switch to Oral Micronized Progesterone?';

  const handleConfirmation = (confirmation: boolean) => {
    if (confirmation && isDeliveryOralSyringe && mlPerDay > 2) {
      navigate({
        search: 'rx=oral-micronized-progesterone',
      });
    } else if (confirmation && !isDeliveryOralSyringe && drops === 14) {
      setDeliveryMethod(DeliveryMethods.OralSyringe.name);
      setOpenWarningPopup(false);
    }
  };

  const handleSigSlider = (val: number | number[]) => {
    if (isDeliveryOralSyringe) {
      setMlPerDay(Number(val));
      if (val > 0.5) {
        setDTD(60);
      } else {
        setDTD(30);
      }

      if (val > 2) {
        setInfoContent(oralSyringeMaxMessage);
        setOpenWarningPopup(true);
      }
    } else {
      if (val > 13) {
        setInfoContent(eyeDropperMaxMessage);
        setOpenWarningPopup(true);
      }
      setDrops(Number(val));
    }
  };

  const prescriptionObject: PrescriptionKeys = {
    name: HormoneNames.PROGESTERONE,
    linkedHormone: ProgesteroneDetails?.linkedHormone,
    displayName: ProgesteroneDetails?.displayName,
    mgPerMl: `${mgPerMl} mg/ml`,
    dtd: DTD,
    mlPerDay: isDeliveryOralSyringe ? `${mlPerDay} ml` : undefined,
    drops: isDeliveryOralSyringe
      ? undefined
      : `${drops} drop${drops > 1 ? 's' : ''}/day`,
    refills,
    sig,
    family: ProgesteroneDetails?.family,
    deliveryMethod,
  };

  return (
    <HormoneWrapper
      title={ProgesteroneDetails?.displayName}
      onClick={() => {
        addNewPrescription(prescriptionObject);
      }}>
      <div className="py-2  pb-4 text-background-contrastText">
        <div className="grid grid-cols-12 gap-x-10">
          <div className="col-span-9  flex flex-col justify-center text-start">
            <Typography variant="h5">Delivery Method</Typography>
            <div className="my-3 flex items-center">
              <RadioButton
                label={DeliveryMethods.EyeDropper.name}
                checked={deliveryMethod === DeliveryMethods.EyeDropper.name}
                id="eyeDropper"
                onChange={() => {
                  setDeliveryMethod(DeliveryMethods.EyeDropper.name);
                  setDTD(30);
                }}
              />
              <span className="mx-4" />
              <RadioButton
                label={DeliveryMethods.OralSyringe.name}
                checked={deliveryMethod === DeliveryMethods.OralSyringe.name}
                id="eyeDropper"
                onChange={() => {
                  setDeliveryMethod(DeliveryMethods.OralSyringe.name);
                  if (ProgesteroneDetails?.mlPerDay[mlPerDay] > 0.5) {
                    setDTD(60);
                  } else {
                    setDTD(30);
                  }
                }}
              />
            </div>
          </div>

          <div className="col-span-1 text-left  text-sm font-semibold   ">
            <div className="flex">
              <Typography variant="h5" className="mr-1">
                Strength
              </Typography>
              <Typography variant="h5" className="mr-1">
                :
              </Typography>
            </div>
          </div>

          <div className="col-span-2">
            <Typography variant="h5" className="mr-1" color="primary">
              {mgPerMl} mg/ml
            </Typography>
          </div>
        </div>

        {/* drops per day slider */}

        <div className="mt-7 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold">
            <Typography variant="h5" className="mr-1">
              Dose
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={
                isDeliveryOralSyringe
                  ? ProgesteroneDetails?.mlPerDay[0]
                  : ProgesteroneDetails?.dropsPerDay[0]
              }
              max={
                isDeliveryOralSyringe
                  ? 2.06
                  : (ProgesteroneDetails?.dropsPerDay.at(-1) as number) + 1
              }
              step={isDeliveryOralSyringe ? 0.05 : 1}
              customStartRangeValue={
                isDeliveryOralSyringe
                  ? ProgesteroneDetails?.mlPerDay[0]
                  : ProgesteroneDetails?.dropsPerDay[0]
              }
              customEndRangeValue={
                isDeliveryOralSyringe
                  ? `> ${ProgesteroneDetails?.mlPerDay.at(-1)}`
                  : `> ${ProgesteroneDetails?.dropsPerDay.at(-1)}`
              }
              toolTipValue={
                isDeliveryOralSyringe
                  ? `${mlPerDay > 2 ? '2+' : mlPerDay} ml`
                  : `${drops > 13 ? '13+' : drops} drop${
                      drops > 1 ? 's' : ''
                    }/day`
              }
              sliderValue={isDeliveryOralSyringe ? mlPerDay : drops}
              onChange={(val) => {
                handleSigSlider(val);
              }}
            />
          </div>
          <div className="col-span-3 text-left  text-sm font-semibold  ">
            <div className="flex">
              <Typography variant="h5"> D.T.D: </Typography>{' '}
              <Typography variant="h5" className="ml-1" color="primary">
                {DTDInWords}
              </Typography>
            </div>
          </div>
        </div>

        {/* Refills slider */}
        <div className="mt-8 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold   ">
            <Typography variant="h5" className="mr-1">
              Refills
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={ProgesteroneDetails?.refills[0]}
              max={ProgesteroneDetails?.refills.at(-1)}
              toolTipValue={`${refills} Refills`}
              sliderValue={refills}
              onChange={(val) => {
                setRefils(Number(val));
              }}
            />
          </div>
        </div>

        <div className="mt-4 grid grid-cols-12">
          <div className="col-span-1 text-left">
            <Typography variant="h5" className="mt-4">
              SIG
            </Typography>
          </div>
          <div className="col-span-8">
            <div className="pr-2">
              <TextInput
                value={sig}
                onChange={setSig}
                fullWidth
                className="my-1 rounded bg-background-light py-2 px-4"
              />
            </div>
          </div>
        </div>

        <Suspense>
          <ConfirmationModal
            isModalOpen={openWarningPopup}
            title={infoContent}
            confirmText="Confirm"
            handleConfirm={handleConfirmation}
            closeModal={(close) => {
              setOpenWarningPopup(!close);
              setMlPerDay(2);
              setDrops(13);
            }}
          />
        </Suspense>
      </div>
    </HormoneWrapper>
  );
}
