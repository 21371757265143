import React, { PropsWithChildren } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

type OverlayPros = {
  className?: string;
  open: boolean;
  onClose: () => void;
};

export function Overlay({
  className,
  children,
  open,
  onClose,
}: PropsWithChildren<OverlayPros>) {
  return (
    <div
      id="overlay"
      className={classNames(
        'fixed top-0 bottom-0 left-0 right-0 z-40  h-full w-full bg-background-dark bg-opacity-90',
        {
          '!block': !!open,
        },
        {
          '!hidden': !open,
        },
        className
      )}>
      <XMarkIcon
        onClick={onClose}
        className="absolute top-7 right-10 z-50 h-16 w-16 cursor-pointer font-extrabold text-background-contrastText  "
      />
      {children}
    </div>
  );
}
