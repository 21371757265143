import React from 'react';

import { RadioGroup } from '@headlessui/react';
import { useSearchParams } from 'react-router-dom';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { HormoneWrapper } from 'components/prescriptions/HormoneWrapper/HormoneWrapper';
import { DeliveryMethods } from 'constants/delivery-methods';
import { femaleHormoneRxTypes } from 'constants/hormone-details';
import { useOralProgesteroneController } from 'hooks/hormones/useOralProgesteroneController';
import {
  HormoneNames,
  PrescribedObjectTypes,
  PrescriptionKeys,
} from 'types/prescriptionTypes';

import { CustomSlider } from '../../common/Slider/Slider';

type OralProgesteroneProps = {
  addNewPrescription: (prescriptionObject: PrescribedObjectTypes) => void;
};

export function OralProgesterone({
  addNewPrescription,
}: OralProgesteroneProps) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const OralProgesteroneDetails = femaleHormoneRxTypes?.[rx || ''];

  const {
    refills,
    DTD,
    mg,
    setDTD,
    setRefils,
    setMg,
    sig,
    setSig,
    setMgml,
    mgml,
  } = useOralProgesteroneController();

  const prescriptionObject: PrescriptionKeys = {
    displayName: OralProgesteroneDetails.displayName,
    name: HormoneNames.ORAL_PROGESTERONE,
    linkedHormone: OralProgesteroneDetails.linkedHormone,
    mg: `${OralProgesteroneDetails.mg[mgml]} mg`,
    mlPerDay: `${OralProgesteroneDetails.mg[mg]} mg`,
    sig,
    dtd: DTD,
    refills,
    family: OralProgesteroneDetails?.family,
    deliveryMethod: DeliveryMethods.Capsules.name,
  };

  const addPrescribedDetails = () => {
    addNewPrescription(prescriptionObject);
  };

  return (
    <HormoneWrapper
      title={OralProgesteroneDetails.displayName}
      onClick={() => {
        addPrescribedDetails();
      }}>
      <div className="  py-2 pb-4">
        <div className="grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold   ">
            <Typography variant="h5" className="mr-1">
              Strength
            </Typography>
          </div>
          <div className="col-span-8 ">
            <CustomSlider
              min={0}
              max={12}
              customStartRangeValue={OralProgesteroneDetails.mg[0]}
              customEndRangeValue={OralProgesteroneDetails.mg.at(-1)}
              toolTipValue={`${OralProgesteroneDetails.mg[mgml]} mg`}
              sliderValue={mgml}
              onChange={(val) => {
                setMgml(Number(val));
              }}
            />
          </div>
          <div className="col-span-3 ">
            <Typography variant="h5" align="left">
              {' '}
              Delivery Method:
            </Typography>{' '}
            <Typography variant="h5" className="ml-1" color="primary">
              {DeliveryMethods.Capsules.name}
            </Typography>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold   ">
            <Typography variant="h5" className="mr-1">
              Dose
            </Typography>
          </div>
          <div className="col-span-8 ">
            <CustomSlider
              min={0}
              max={12}
              customStartRangeValue={OralProgesteroneDetails.mg[0]}
              customEndRangeValue={OralProgesteroneDetails.mg.at(-1)}
              toolTipValue={`${OralProgesteroneDetails.mg[mg]} mg`}
              sliderValue={mg}
              onChange={(val) => {
                setMg(Number(val));
              }}
            />
          </div>
        </div>

        {/* Refills slider */}
        <div className="mt-6 grid grid-cols-12 gap-x-10 ">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold   ">
            <Typography variant="h5" className="mr-1">
              Refills
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={OralProgesteroneDetails.refills[0]}
              max={OralProgesteroneDetails.refills.at(-1)}
              toolTipValue={`${refills} Refills`}
              sliderValue={refills}
              onChange={(val) => {
                setRefils(Number(val));
              }}
            />
          </div>
        </div>
        <div className="mt-6 grid grid-cols-12">
          <div className="col-span-1 text-left">
            <Typography variant="h5" className="mt-4">
              SIG
            </Typography>
          </div>
          <div className="col-span-8">
            <div className="pr-2">
              <TextInput
                value={sig}
                onChange={setSig}
                fullWidth
                className="my-1 rounded bg-background-light py-2 px-4"
              />
            </div>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-12 gap-x-14">
          <div className="col-span-1 mt-2  text-left text-sm font-semibold   ">
            <div className="flex">
              <Typography variant="h5" className="mr-1">
                DTD
              </Typography>
            </div>
          </div>
          <div className="col-span-4">
            <RadioGroup value={DTD} onChange={setDTD}>
              <div className="inline-flex cursor-pointer  shadow-md hover:shadow-lg focus:shadow-lg">
                {OralProgesteroneDetails.dtd.map(
                  (item: number, index: number) => (
                    <RadioGroup.Option value={item}>
                      {({ checked }) => (
                        <span
                          className={`${index === 0 && 'rounded-l'} ${
                            item === OralProgesteroneDetails.dtd.at(-1) &&
                            'rounded-r'
                          } flex px-5 py-2 
                  ${
                    checked
                      ? 'bg-primary-dark hover:bg-primary-dark'
                      : 'bg-background-light'
                  }  dark:text-primary-dark_contrastText focus:bg-primary-700 active:bg-primary-800 text-xs !text-base font-normal uppercase leading-tight text-primary-contrastText transition duration-150 ease-in-out hover:bg-primary-main focus:outline-none focus:ring-0`}>
                          {`${item}`}
                        </span>
                      )}
                    </RadioGroup.Option>
                  )
                )}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
    </HormoneWrapper>
  );
}
