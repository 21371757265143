import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useFloating } from '@floating-ui/react-dom';
import { Portal } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';

import { ThemeName } from 'theme/types';
import { InfiniteScrollProps } from 'types/common';

import { CheckBox } from '../CheckBox/CheckBox';
import { LoadingSpinner } from '../Loading/LoadingSpinner';
import { SearchInput } from '../SearchInput/SearchInput';
import { Typography } from '../Typography/Typography';

export interface Option {
  // eslint-disable-next-line
  title: string;
  // eslint-disable-next-line
  value: string | number;
}

export type ControllerOnChangeType = (event: { target: Option[] }) => void;

type ControlProps =
  | {
      // eslint-disable-next-line
      control?: Control<any, any>;
      name: string;
    }
  | {
      control?: false | undefined;
      name?: never;
    };

type MultiSelectDropDownProps = {
  options: Option[] | undefined;
  label: string;
  placeholder?: string;
  defaultValue?: Option[] | undefined;
  searchable?: boolean;
  onChange?: (value: Option[]) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  helperText?: string;
  disableHelperText?: boolean;
  error?: boolean;
  onSearchChange?: (text: string) => void;
  theme?: ThemeName;
} & ControlProps &
  InfiniteScrollProps;
export function MultiSelectDropDown({
  label,
  options,
  defaultValue,
  searchable,
  onChange,
  fullWidth,
  disabled,
  className,
  control,
  name,
  helperText,
  disableHelperText,
  error,
  withInfiniteScroll,
  isFetchingNextPage,
  onNextPage,
  onSearchChange,
  placeholder,
  isOptionsLoading,
  theme = ThemeName.Dark,
}: MultiSelectDropDownProps) {
  const [value, setValue] = useState<Option[]>([]);
  const [open, setOpen] = useState(false);
  const checkboxRefs = useRef<HTMLInputElement[]>([]);
  const { x, y, refs } = useFloating();
  const onDropdownScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (!withInfiniteScroll || isFetchingNextPage) return;
      const target = event.currentTarget;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
        if (isFetchingNextPage) return;
        onNextPage?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetchingNextPage]
  );

  const closeMenu = useCallback((event: MouseEvent) => {
    if ((event?.target as SVGElement).id === 'menu') {
      return;
    }
    if (
      (event?.target as SVGElement).id === 'DropDownbutton' ||
      (event?.target as SVGElement).id === 'searchInput'
    ) {
      return;
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', (e) => closeMenu(e));

    return () => document.body.removeEventListener('click', closeMenu);
  }, [closeMenu]);

  useEffect(() => {
    if (defaultValue) {
      setValue(() => [
        ...defaultValue.map((v) => ({ title: v.title, value: v.value })),
      ]);
    }
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  console.log(helperText, error);
  const getError = useCallback(() => {
    if (control) {
      if (control._formState.errors) {
        if (name.includes('.')) {
          const splittedArray = name.split('.');

          return splittedArray.reduce(
            // eslint-disable-next-line
            (acc: any, i) => acc?.[i],
            control._formState.errors
          );
        }

        return control._formState.errors?.[name];
      }
    }

    return undefined;
  }, [control, name]);

  const fieldError = getError();

  const dropDownValue = useMemo(
    () =>
      value[0]
        ? // eslint-disable-next-line
          value?.reduce(
            (acc, option) => `${acc ? `${acc},` : ''} ${option.title}`,
            ''
          )
        : undefined,
    [value]
  );

  const getDropdown = useCallback(
    (
      controllerOnChange?: ControllerOnChangeType,
      controllerValue: Option[] = []
    ) => (
      <div id="DropDownbutton">
        {/*  eslint-disable-next-line */}
        <div
          id="DropDownbutton"
          onClick={() => setOpen(!open)}
          className={classNames(
            'flex w-full cursor-pointer items-center  justify-between rounded border border-transparent bg-background-light px-2 py-3 text-base text-zinc-400 ',
            {
              '!text-background-contrastText ': !!dropDownValue || !!value[0],
            },
            open ? 'rounded-b-none' : 'rounded',
            disabled
              ? 'pointer-events-none cursor-no-drop opacity-80'
              : 'cursor-pointer',
            {
              'border !border-error-main': !!fieldError,
            },
            {
              'border !border-primary-main': open,
            }
          )}>
          <span className="max-w-[90%] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {dropDownValue ? dropDownValue.toString() : placeholder}
          </span>
          <ChevronDownIcon
            id="DropDownbutton"
            height={20}
            className={`${open && 'rotate-180'}`}
          />
        </div>
        {open && (
          <Portal>
            <div
              onScroll={onDropdownScroll}
              ref={refs.setFloating}
              style={{
                position: 'absolute',
                top: y ?? 0,
                left: x ?? 0,
                width: refs.reference.current?.getBoundingClientRect().width,
              }}
              className={classNames(
                theme,
                ' z-[999] -mt-5 max-h-48   overflow-y-auto rounded-b bg-background-light shadow-md ',
                {
                  '!mt-1': disableHelperText,
                },
                {
                  '!max-h-52': searchable,
                }
              )}>
              {searchable && (
                <div className="sticky top-0 bg-background-dark p-2">
                  <SearchInput
                    fullWidth
                    id="searchInput"
                    onSearch={(v) =>
                      onSearchChange ? onSearchChange(v) : undefined
                    }
                  />{' '}
                </div>
              )}
              <ul
                className={classNames('p-2 pt-1  ', {
                  'border-t border-t-zinc-200 ': searchable,
                })}>
                {options?.map((item: Option, index) => (
                  // eslint-disable-next-line
                  <li
                    onClick={() => {
                      checkboxRefs.current[index].click();
                    }}
                    id="menu"
                    key={item.title}
                    className="flex cursor-pointer   items-center p-2 text-base text-background-contrastText hover:rounded-sm  hover:bg-primary-main hover:text-primary-contrastText">
                    <CheckBox
                      setRef={(ref) => {
                        checkboxRefs.current[index] = ref as HTMLInputElement;
                      }}
                      className="!pointer-events-none"
                      id="menu"
                      onChange={
                        control
                          ? (checked: boolean) => {
                              if (!checked) {
                                const newValue = controllerValue.filter(
                                  (i) => i.value !== item.value
                                );
                                setValue([...newValue]);
                                if (controllerOnChange) {
                                  controllerOnChange({
                                    target: newValue,
                                  });
                                }
                              } else {
                                const newValue = [...value, item];
                                setValue([...newValue]);
                                if (controllerOnChange) {
                                  controllerOnChange({
                                    target: newValue,
                                  });
                                }
                              }
                            }
                          : (checked: boolean) => {
                              if (!checked) {
                                setValue(
                                  value.filter((i) => i?.value !== item?.value)
                                );
                              } else if (onChange) {
                                setValue([...value, item]);
                                onChange([...value, item]);
                              }
                            }
                      }
                      label=""
                      checked={value
                        .map((i) => i.title)
                        ?.toString()
                        .toLowerCase()
                        .includes(item.title.toLowerCase())}
                    />
                    {item.title}
                  </li>
                ))}
                {!options?.[0] && !isOptionsLoading && (
                  <li className="flex items-center space-x-3 p-2 py-1 pl-3.5 pt-2 text-md text-background-contrastText">
                    <Typography color="textMuted" variant="subtitle2">
                      Nothing found
                    </Typography>
                  </li>
                )}
                {(isFetchingNextPage || isOptionsLoading) && (
                  <li className="flex items-center space-x-3 p-2 py-1 pl-3.5 pt-2 text-md text-background-contrastText">
                    <LoadingSpinner className="h-[1.5rem] w-[1.5rem]" />{' '}
                    <Typography variant="subtitle2">loading...</Typography>
                  </li>
                )}
              </ul>
            </div>
          </Portal>
        )}
      </div>
    ),
    [
      value,
      searchable,

      options,
      open,
      onChange,

      isOptionsLoading,
      placeholder,
      disabled,

      control,
      onSearchChange,
      dropDownValue,
      fieldError,
      onDropdownScroll,
      refs,
      x,
      y,
      disableHelperText,
      theme,
      isFetchingNextPage,
    ]
  );

  return (
    <div
      ref={refs.setReference}
      className={classNames(
        ' relative',
        fullWidth ? 'w-full' : 'w-60',
        className
      )}>
      {label && (
        <label htmlFor={name}>
          <Typography className="mb-1" variant="h5">
            {label}
          </Typography>
        </label>
      )}
      {control ? (
        <Controller
          control={control as Control}
          name={name as string}
          render={({
            field: {
              onChange: controllerOnChange,
              value: controllerValue = [],
            },
          }) => getDropdown(controllerOnChange, controllerValue)}
        />
      ) : (
        getDropdown()
      )}
      {!disableHelperText && (
        <p
          style={{ minHeight: '1rem' }}
          className={classNames(
            'm-1 text-left text-sm text-background-contrastText ',
            {
              '!text-error-main': !!fieldError?.message,
            }
          )}>
          {(fieldError?.message as string) || ''}
        </p>
      )}
    </div>
  );
}
