import { useEffect, useState } from 'react';

import { formatDeliveryUnit } from 'utils/common';

type SigGeneratorHookProp = {
  dose: number;
  maximumDose: number;
  unit?: string;
  sigUnit?: string;
  doseOptions: (string | number)[];
  marginValue?: number;
};

export function useSigGenerator({
  sigUnit,
  dose,
  doseOptions,
  maximumDose,
  unit,
  marginValue = 0,
}: SigGeneratorHookProp) {
  const [generatedSIG, setGeneratedSIG] = useState('');

  useEffect(() => {
    if (maximumDose === 0 && marginValue === 0) {
      setGeneratedSIG(
        `${unit ? 'Take' : 'Apply'} ${doseOptions?.[dose]} ${
          unit
            ? `${formatDeliveryUnit(Number(doseOptions?.[dose]), unit)?.replace(
                '/',
                ' per '
              )} by mouth`
            : 'ml per day topically'
        }`
      );
    } else {
      setGeneratedSIG(
        `${unit ? 'Take' : 'Apply'} ${doseOptions?.[dose]} ${
          unit
            ? formatDeliveryUnit(Number(doseOptions?.[dose]), unit)?.replace(
                '/',
                ' per '
              )
            : `ml ${sigUnit ? 'per day ' : ''}topically`
        } and increase as directed up to ${
          doseOptions[
            marginValue ? maximumDose + dose + marginValue : maximumDose + dose
          ]
        } ${
          unit
            ? `${unit?.replace('/', ' per ')} by mouth`
            : 'ml per day topically'
        }`
      );
    }
    // eslint-disable-next-line
  }, [dose, maximumDose]);

  return { generatedSIG, setGeneratedSIG };
}
