/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { ScrollableTabBar } from 'components/common/ScrollableTabBar/ScrollableTabBar';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { QuestionnaireService } from 'services/questionnaire';
import { PatientDetailsContextType } from 'types/patient';
import {
  QuestionnaireResponseType,
  SummaryQuestionType,
  Tab,
} from 'types/questionnaire';

import { getSectionWiseQuestions } from '../questionnaireHelper';
import { QuestionnaireSectionDetails } from '../QuestionnaireSectionDetails';
import { QuestionnaireSkeleton } from '../QuestionnaireSkeleton';

const getGender = {
  M: 'male',
  F: 'female',
};

export function FollowUpQuestionnaire() {
  const [searchParams, setSearchParams] = useSearchParams();
  const responseId = searchParams.get('responseId') || '';
  const section = searchParams.get('section') || '';
  const { gender, id } = useOutletContext<
    PatientDetailsContextType & { followUpId: string }
  >();
  const getFollowUpQuestionnaireResponsesQuery = useQuery(
    QueryKeys.PatientFollowUpQuestionnaire.item(responseId),
    () =>
      QuestionnaireService.getFollowupQuestionnaireResponsesWithId({
        gender: getGender[gender],
        id,
        followUpId: responseId,
      }),

    {
      enabled: responseId !== 'hide' && responseId !== 'undefined',
    }
  );

  const {
    sectionWiseQuestions,
    filteredTabs,
  }: {
    sectionWiseQuestions: QuestionnaireResponseType;
    filteredTabs: Tab[];
  } = useMemo(() => {
    if (getFollowUpQuestionnaireResponsesQuery?.data?.data) {
      return getSectionWiseQuestions(
        getFollowUpQuestionnaireResponsesQuery?.data.data,
        gender
      );
    }

    return {
      sectionWiseQuestions: {} as QuestionnaireResponseType,
      filteredTabs: [] as Tab[],
      filteredSummaryQuestions: {} as SummaryQuestionType,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFollowUpQuestionnaireResponsesQuery?.data, gender, responseId]);

  useEffect(() => {
    if (filteredTabs?.[0]) {
      const params = new URLSearchParams(window.location.search);
      params.set('section', filteredTabs[0].link);
      setSearchParams(params, { replace: true });
    }
  }, [filteredTabs, responseId]);

  if (
    (!section && !responseId) ||
    responseId === 'hide' ||
    responseId === 'undefined'
  ) {
    return (
      <div className=" flex h-[7rem] items-center  justify-center rounded bg-background-light ">
        <Typography variant="h4" color="textMuted">
          {responseId === 'hide'
            ? 'Please select questionnaire'
            : 'No follow up questionnaire found'}
        </Typography>
      </div>
    );
  }

  return (
    <div className=" grid grid-cols-12">
      {getFollowUpQuestionnaireResponsesQuery?.isLoading ? (
        <QuestionnaireSkeleton />
      ) : (
        <div className="  col-span-12    ">
          <ScrollableTabBar tabs={[...filteredTabs]} />
          <div className="p-1" />

          {sectionWiseQuestions?.[section] && (
            <QuestionnaireSectionDetails
              section={sectionWiseQuestions[section]}
            />
          )}
        </div>
      )}
    </div>
  );
}
