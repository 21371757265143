import { APIService } from 'services/api-service';

import { QuestionnaireServiceNS, SchedulerBody } from './types';

export class QuestionnaireService extends APIService {
  static GET_QUESTIONNAIRE_RESPONSES = `${this.API_PREFIX_URL}/patient`;
  static getQuestionnaireResponses = ({
    gender,
    id,
  }: QuestionnaireServiceNS.QuestionnaireResponseBody) => {
    const url = new URL(
      `${QuestionnaireService.GET_QUESTIONNAIRE_RESPONSES}/${gender}/questions/responses`
    );
    url.searchParams.append('patient_id', id);

    return this.get<QuestionnaireServiceNS.QuestionnaireResponse>(
      url.toString(),
      {
        withAuthToken: true,
      }
    );
  };

  static GET_FOLLOW_UP_QUESTIONNAIRE_RESPONSES = `${this.API_PREFIX_URL}/patient`;
  static getFollowupQuestionnaireResponses = ({
    gender,
    id,
  }: QuestionnaireServiceNS.QuestionnaireResponseBody) => {
    const url = new URL(
      `${QuestionnaireService.GET_QUESTIONNAIRE_RESPONSES}/${gender}/follow-up/responses`
    );
    url.searchParams.append('patient_id', id);

    return this.get<QuestionnaireServiceNS.GetFollowUpQuestionnaireResponse>(
      url.toString(),
      {
        withAuthToken: true,
      }
    );
  };

  static getFollowupQuestionnaireResponsesWithId = ({
    gender,
    id,
    followUpId,
  }: QuestionnaireServiceNS.QuestionnaireResponseBody) => {
    const url = new URL(
      `${QuestionnaireService.GET_QUESTIONNAIRE_RESPONSES}/${gender}/follow-up/responses/${followUpId}`
    );
    url.searchParams.append('patient_id', id);

    return this.get<QuestionnaireServiceNS.QuestionnaireResponse>(
      url.toString(),
      {
        withAuthToken: true,
      }
    );
  };

  static FOLLOW_UP_QUESTIONNAIRE_SCHEDULER = `${this.API_PREFIX_URL}/provider/patients/follow-up`;
  static updateFollowUpScheduling = async ({
    followUpInterval,
    patientId,
    followUpDate,
  }: SchedulerBody) => {
    const url = new URL(
      `${this.FOLLOW_UP_QUESTIONNAIRE_SCHEDULER}/${patientId}`
    );

    return this.put(
      url.toString(),
      {
        followUpInterval,
        ...(followUpDate && { followUpDate }),
      },
      {
        withAuthToken: true,
      }
    );
  };
}
