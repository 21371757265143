import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { SkeletonTheme } from 'react-loading-skeleton';

import { AppRouter } from 'router/AppRouter';

import { ThemeProvider } from './theme/ThemeProvider';

import 'react-loading-skeleton/dist/skeleton.css';

export function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SkeletonTheme baseColor="#262626" highlightColor="#444">
          <AppRouter />
          <Toaster position="bottom-right" />
        </SkeletonTheme>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
