import React from 'react';

import classNames from 'classnames';
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom';

import { PatientDetailsContextType } from 'types/patient';

const StartingRxs = [
  { title: 'Lighter Starting Rx', link: 'lighter' },
  { title: 'Common Starting Rx', link: 'common' },
  { title: 'Enhanced Starting Rx', link: 'enhanced' },
  // { title: 'Custom Starting Rx', link: 'custom' },
  { title: 'Create Custom Rx(s)', link: 'e-prescribev2' },
];

export function PrescribeLayout() {
  const { pathname } = useLocation();
  const { gender, id, address, firstName, lastName, hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();
  const isOptionsOpen =
    pathname.endsWith('/prescribe') || pathname.endsWith('/prescribe/');

  return (
    <div className="mt-2 flex flex-col ">
      <div className="flex">
        {isOptionsOpen &&
          StartingRxs.map((rx, index) => (
            <div className="grow">
              <Link to={{ pathname: rx.link }}>
                <div
                  className={classNames(
                    'mr-2 flex h-[250px]  items-center justify-center rounded bg-background-main text-background-contrastText',
                    {
                      '!mr-0': index === StartingRxs.length - 1,
                    }
                  )}>
                  {rx.title}
                </div>
              </Link>
            </div>
          ))}
      </div>
      <div className="w-full">
        <Outlet
          context={{
            firstName,
            lastName,
            gender,
            id,
            address,
            hasDeaForShippingState,
          }}
        />
      </div>
    </div>
  );
}
