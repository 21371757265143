import React from 'react';

import { Typography } from 'components/common/Typography/Typography';
import { estTimestampCalculator, getReadableDate } from 'utils/common';

interface NoteProps {
  text: string;
  date: string;
  className?: string;
}
export function Note({ text, date, className }: NoteProps) {
  return (
    <div className={`flex flex-col ${className} `}>
      <Typography className="mr-4" variant="subtitle2">
        {text}
      </Typography>
      <div className="mt-2 self-end">
        <Typography variant="subtitle2" color="textMuted">
          {getReadableDate(String(estTimestampCalculator(date)), true)}
        </Typography>
      </div>
    </div>
  );
}
