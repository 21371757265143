import React, { ReactNode, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Controller } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { DayPickerWithInput } from 'components/common/DatePicker/DatePicker';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { QueryKeys } from 'constants/query-keys';
import { updateManagerDetailsSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { Option } from 'types/inputTypes';
import { Gender } from 'types/patient';
import { gender, isOlderThan18Years } from 'utils/common';

type UpdateEmailProps = {
  type: string;
  modal: boolean;
  closeModal: (state: boolean) => void;
  preValue?: string;
  onSuccess: () => void;
  defaultProviderTitle?: string;
};
type EmailFormType = {
  email?: string;
  name?: string;
  dob?: string;
  gender?: string;
};

type ModalData = {
  title: string;
  field?: ReactNode;
};
export function UpdateManagerDetailsModal({
  type,
  modal,
  closeModal,
  preValue,
  onSuccess,
  defaultProviderTitle,
}: UpdateEmailProps) {
  const [dobDatePicker, setDobDatePicker] = useState(false);

  const [providerTitle, setProviderTitle] = useState<Option>({
    title: defaultProviderTitle ?? '',
    value: defaultProviderTitle ?? '',
  });
  const updateManagerDetailsMutation = useMutationWithToast(
    UserService.updateProfile
  );

  const { handleSubmit, control, reset, setError, clearErrors } =
    useFormWithErrors<EmailFormType>({
      mutation: updateManagerDetailsMutation,
      schema: updateManagerDetailsSchema(type),
    });

  const getProviderSuffixesQuery = useQuery(
    [QueryKeys.ProviderSuffixes],
    () => UserService.getProviderSuffixes(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const onSubmit = handleSubmit((data) => {
    updateManagerDetailsMutation.mutate(
      {
        ...(type === 'Email' && { officeManagerEmail: data?.email || '' }),
        ...(type === 'Name' && { officeManagerName: data?.name || '' }),
        ...(type === 'Title' && { providerSuffix: providerTitle?.title || '' }),
        ...(type === 'DOB' && {
          dob: format(new Date(data.dob || ''), 'yyyy-MM-dd'),
        }),
        ...(type === 'Gender' && { gender: data?.gender || '' }),
      },
      {
        onSuccess: () => {
          onSuccess();
          reset();
          closeModal(false);
        },
      }
    );
  });
  const updateProviderTitle = () => {
    updateManagerDetailsMutation.mutate(
      {
        providerSuffix: providerTitle?.title || '',
      },
      {
        onSuccess: () => {
          onSuccess();
          reset();
          closeModal(false);
        },
      }
    );
  };
  const modalData: { [x: string]: ModalData } = {
    Name: {
      title: 'Office Manager Name',
      field: (
        <TextInput
          className="pl-2 "
          type="text"
          name="name"
          control={control}
          placeholder="Enter name"
          defaultValue={preValue}
        />
      ),
    },
    Email: {
      title: 'Office Manager Email',
      field: (
        <TextInput
          className="pl-2 "
          type="text"
          name="email"
          control={control}
          placeholder="Enter email"
          defaultValue={preValue}
        />
      ),
    },
    Title: {
      title: 'Provider Title',
      field: (
        <DropDown
          theme={ThemeName.Light}
          options={getProviderSuffixesQuery.data?.data.suffixes.map((s) => ({
            title: s,
            value: s,
          }))}
          defaultValue={providerTitle}
          onChange={(d) => setProviderTitle(d)}
        />
      ),
    },
    Gender: {
      title: 'Gender',
      field: (
        <DropDown
          theme={ThemeName.Light}
          className="w-full "
          control={control}
          name="gender"
          fullWidth
          placeholder="Select gender"
          defaultValue={{
            title: gender[(preValue as Gender) ?? 'blank'],
            value: preValue ?? '',
          }}
          options={[
            { title: 'Male', value: 'M' },
            { title: 'Female', value: 'F' },
          ]}
        />
      ),
    },
    DOB: {
      title: 'DOB',
      field: (
        <Controller
          defaultValue={preValue}
          control={control}
          name="dob"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DayPickerWithInput
              selectedDate={value as unknown as Date}
              open={dobDatePicker}
              placeholder="Select DOB"
              toggleDayPicker={(s) => {
                setDobDatePicker(s);
              }}
              label=""
              withInput
              theme={ThemeName.Dark}
              helperText={error?.message}
              error={!!error?.message}
              onDateSelect={(date) => {
                if (isOlderThan18Years(date as Date)) {
                  onChange({
                    target: { value: date },
                  });
                  clearErrors('dob');
                } else {
                  onChange({
                    target: { value: date },
                  });
                  setError('dob', {
                    message: 'Age must be between 18 and 100 years',
                  });
                }
                setDobDatePicker(false);
              }}
            />
          )}
        />
      ),
    },
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors, type]);

  return (
    <div>
      <Modal
        title={modalData?.[type]?.title}
        open={modal}
        className="w-2/5 overflow-visible"
        closeModal={closeModal}>
        <div className="mt-4">
          {modalData?.[type]?.field}
          <div>
            <div className="mt-2 mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => closeModal(false)}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updateManagerDetailsMutation.isLoading}
                onClick={() => {
                  if (type === 'Title') {
                    updateProviderTitle();
                  } else {
                    onSubmit();
                  }
                }}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
