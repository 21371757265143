import React from 'react';

import classNames from 'classnames';

type ChangeButtonProps = {
  onClick?: () => void;
  title?: string;
  className?: string;
  disabled?: boolean;
};

export function ChangeButton({
  onClick,
  title = 'Change',
  className,
  disabled,
}: ChangeButtonProps) {
  return (
    <button
      type="submit"
      onClick={onClick}
      className={classNames(
        'ml-2 cursor-pointer text-base font-semibold text-primary-main',
        className
      )}
      disabled={disabled}>
      {title}
    </button>
  );
}
