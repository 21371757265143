import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useTestosteroneMenController } from 'hooks/hormones-new/useTestosteroneMenController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type TestosteroneMenProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function TestosteroneMen({ addNewPrescription }: TestosteroneMenProps) {
  const [searchParams] = useSearchParams();

  const medicationId = searchParams.get('id');
  const {
    TestosteroneMenDetails,

    sig,
    setSig,
    dtd,
    setDTD,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useTestosteroneMenController();

  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || undefined,
    displayName: TestosteroneMenDetails.displayName,
    name: HormoneNames.TESTOSTERONE_MEN,
    strength: TestosteroneMenDetails.strength,
    strengthUnit: TestosteroneMenDetails.strengthUnit,
    dose: TestosteroneMenDetails.dose[dose + maximumDose],
    minimumDose: TestosteroneMenDetails.dose[dose],
    doseUnit: TestosteroneMenDetails.doseUnit,
    refills,
    dtd,
    sig,
    deliveryMethod: TestosteroneMenDetails.deliveryMethod,
    family: TestosteroneMenDetails.family,
    note,
  };

  return (
    <HormoneDetails
      prescriptionObject={prescriptionObject}
      statusUnit={TestosteroneMenDetails.statusUnit}
      title={TestosteroneMenDetails.displayName}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Text,
          label: 'Strength',
          value: `${TestosteroneMenDetails.strength} ${TestosteroneMenDetails.strengthUnit}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: TestosteroneMenDetails.dose,
          getTooltip: (val) =>
            `${`${TestosteroneMenDetails.dose[val].toFixed(2)} ${
              TestosteroneMenDetails.doseUnit
            }`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: [0],
          getTooltip: (val) => `${TestosteroneMenDetails.refills[val]} refills`,
          disable: true,
        },

        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: TestosteroneMenDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
            if (TestosteroneMenDetails.dose[val + dose] < 0.35) {
              setDTD(18);

              return;
            }
            if (TestosteroneMenDetails.dose[val + dose] >= 0.7) {
              setDTD(60);
            } else {
              setDTD(30);
            }
          },
        },
        { type: 'Container' },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: TestosteroneMenDetails.deliveryMethod,
        },

        {
          label: 'D.T.D',
          value: `${dtd.toString()} ml`,
        },
      ]}
    />
  );
}
