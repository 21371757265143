import React, { useState } from 'react';

import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { Modal } from 'components/common/Modal/Modal';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { PrescribedObjectType } from 'types/prescriptionTypes';

type PrescriptionReviewModalProps = {
  isOpen: boolean;
  item?: PrescribedObjectType;
  onCancel: () => void;
  onConfirm: () => void;
};

export function PrescriptionReviewModal({
  isOpen,
  item,
  onCancel,
  onConfirm,
}: PrescriptionReviewModalProps) {
  const [agreeToPrescribeHighPotency, setAgreeToPrescribeHighPotency] =
    useState(false);
  //

  const isTopicalHormone =
    !!item?.standardizedName?.includes('Topical') ||
    !!item?.standardizedName?.includes('topically');

  function getDaysOfSupply() {
    if (!item?.daysOfSupply?.withMinDose) {
      return '';
    }

    if (item?.daysOfSupply?.withMinDose === item?.daysOfSupply?.withMaxDose) {
      return `${item?.daysOfSupply?.withMaxDose} Days`;
    }

    return `${item?.daysOfSupply?.withMaxDose} Days to ${item?.daysOfSupply?.withMinDose} Days`;
  }

  function getFormattedDoseValue() {
    return `${item?.minimumDose} ${item?.doseUnit} to ${item?.dose} ${item?.doseUnit}`;
  }

  function getFormattedPrescriptionCheckValue() {
    if (item?.checkValues?.withMaxDose === item?.checkValues?.withMinDose) {
      return `${item?.checkValues?.withMaxDose} ${item?.checkValues?.unit}`;
    }

    return `${item?.checkValues?.withMinDose} ${item?.checkValues?.unit} to ${item?.checkValues?.withMaxDose} ${item?.checkValues?.unit}`;
  }

  const getAddHormoneDisabled = () => {
    if (
      item?.isHighPotencyRx &&
      !agreeToPrescribeHighPotency &&
      isTopicalHormone
    ) {
      return 'Please check the checkbox to confirm that you agree to add high potency prescription';
    }

    return '';
  };

  function showHighPotencyCheckbox() {
    return (
      item?.isHighPotencyRx &&
      (!!item.standardizedName?.includes('Topical') ||
        !!item?.standardizedName?.includes('topically'))
    );
  }

  if (!item) {
    return <>Prescription not found</>;
  }

  function showDose() {
    if (item?.currentDose?.morning.value && item?.currentDose?.evening.value) {
      return (
        <div
          className={classNames(
            'mt-6 mb-2  grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
          )}>
          <span className="col-span-6  flex flex-col">
            <Typography variant="h4">Morning Dose:</Typography>
            <Typography variant="subtitle1">
              {' '}
              {item?.currentDose?.morning.value}{' '}
              {item?.currentDose?.morning.unit}
            </Typography>
          </span>
          <span className={classNames('visible col-span-6  flex flex-col')}>
            <Typography variant="h4">Evening Dose:</Typography>
            <Typography variant="subtitle1">
              {' '}
              {item?.currentDose?.evening.value}{' '}
              {item?.currentDose?.evening.unit}
            </Typography>
          </span>
        </div>
      );
    }

    if (item?.dose === item?.minimumDose && isTopicalHormone) {
      return (
        <div
          className={classNames(
            'mt-6 mb-2  grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
          )}>
          <span className="col-span-6  flex flex-col">
            <Typography variant="h4">Current Dose:</Typography>
            <Typography variant="subtitle1">
              {item?.dose} {item?.doseUnit}
            </Typography>
          </span>
        </div>
      );
    }

    return null;
  }

  function showPrescriptionCheckValue() {
    if (item?.checkValues?.withMinDose && item?.checkValues?.withMaxDose) {
      return (
        <span className="col-span-6  flex flex-col">
          <Typography variant="h4">{item?.checkValues?.unit}:</Typography>
          <Typography variant="subtitle1">
            {' '}
            {getFormattedPrescriptionCheckValue()}
          </Typography>
        </span>
      );
    }

    return null;
  }

  function getAdjustableDoseRange() {
    if (item?.dose || item?.minimumDose) {
      if (item?.dose && item?.minimumDose && item?.dose !== item?.minimumDose) {
        return (
          <span className="col-span-6  flex flex-col">
            <Typography variant="h4">Adjustable Dose Range:</Typography>
            <Typography variant="subtitle1">
              {' '}
              {getFormattedDoseValue()}
            </Typography>
          </span>
        );
      }

      return (
        <span className="col-span-6  flex flex-col">
          <Typography variant="h4">Dose:</Typography>
          <Typography variant="subtitle1">
            {item.dose} {item.doseUnit}
          </Typography>
        </span>
      );
    }

    if (isTopicalHormone && item?.dose === item?.minimumDose) {
      return (
        <span className="col-span-6  flex flex-col">
          <Typography variant="h4">Current Dose:</Typography>
          <Typography variant="subtitle1">
            {item?.dose} {item?.doseUnit}
          </Typography>
        </span>
      );
    }

    return null;
  }

  function showDaysOfSupply() {
    if (item?.daysOfSupply?.withMaxDose && item?.daysOfSupply?.withMinDose) {
      return (
        <span className="col-span-6  flex flex-col">
          <Typography variant="h4">Days of supply:</Typography>
          <Typography variant="subtitle1"> {getDaysOfSupply()}</Typography>
        </span>
      );
    }

    return null;
  }

  return (
    <Modal
      closeModal={() => {
        //
      }}
      className="w-1/2"
      open={isOpen}>
      <div className="flex flex-col">
        <Typography variant="h4">
          Please review the prescription before adding it to the cart.
        </Typography>

        <Typography
          className="mt-8 rounded bg-error-light p-2 pl-4"
          variant="h5"
          color="textSecondary">
          Please double-check the {item.checkValues?.unit} before proceeding
        </Typography>
        <div className="relative col-span-3 mt-2 rounded bg-background-main  ">
          <div
            className=" w-full rounded-md bg-background-main p-4 pb-2 pl-4 pr-2 "
            key={item.name}>
            <div className="relative col-span-6 mt-3 rounded bg-background-main  ">
              <div
                className=" w-full rounded-md bg-background-main pl-6 pr-2  "
                key={item.name}>
                <div className=" grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                  <span className="col-span-8  flex">
                    <Typography color="primary" variant="h4">
                      {item.standardizedName ?? item.name}, {item.dtd}{' '}
                      {item.dtdUnit || 'ml'}
                    </Typography>
                  </span>
                </div>
                {showDose()}
                <div
                  className={classNames(
                    ' mt-6 mb-2 hidden grid-cols-12 !items-start justify-around gap-x-4 text-start',
                    {
                      '!grid':
                        !!showPrescriptionCheckValue() ||
                        !!getAdjustableDoseRange(),
                    }
                  )}>
                  {showPrescriptionCheckValue()}
                  {getAdjustableDoseRange()}
                </div>
                <div
                  className={classNames(
                    'mt-6 mb-2  grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
                  )}>
                  {showDaysOfSupply()}
                  <span
                    className={classNames('visible col-span-6  flex flex-col')}>
                    <Typography variant="h4">Refills:</Typography>
                    <Typography variant="subtitle1"> {item.refills}</Typography>
                  </span>
                </div>
                <div
                  className={classNames(
                    ' mt-6 mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
                  )}>
                  <span className="col-span-12  flex flex-col">
                    <Typography variant="h4">SIG:</Typography>
                    <Typography
                      ellipsis
                      className="!max-w[100%] max-h-[10rem] !overflow-auto whitespace-pre-wrap"
                      variant="subtitle1">
                      {' '}
                      {item.sig}
                    </Typography>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!showHighPotencyCheckbox() && (
          <div className="mt-2 flex items-start gap-1 rounded bg-error-light p-2 pl-4">
            <CheckBox
              checked={agreeToPrescribeHighPotency}
              id=""
              label=""
              onChange={(d) => {
                setAgreeToPrescribeHighPotency(d);
              }}
            />
            <Typography color="textSecondary" variant="h5">
              {`I am aware of the ${item?.isHighPotencyRx ? 'high' : ''}${
                item?.isLowPotencyRx ? 'low' : ''
              } potency of this
              prescription. The results of the 24-hour urine hormone test show
              that this strength is required to treat this patient properly.`}
            </Typography>
          </div>
        )}
        {isTopicalHormone && Number(item?.dtd) < 30 && (
          <div className="mt-2 flex items-start gap-1 rounded bg-error-light p-2 pl-4">
            <Typography color="textSecondary" variant="h5">
              {`Please note that the dispense quantity of this prescription is ${
                item.dtd
              } ${
                item.dtdUnit ?? 'ml'
              }. Please inform the patient that the bottle will intentionally arrive less than completely full. `}
            </Typography>
          </div>
        )}

        <div className="mt-6 flex justify-end gap-6">
          <Button
            onClick={() => {
              setAgreeToPrescribeHighPotency(false);
              onCancel();
            }}
            color="secondary">
            Cancel
          </Button>
          <Button
            data-tooltip-id="confirmButton"
            disabled={!!getAddHormoneDisabled()}
            onClick={() => {
              setAgreeToPrescribeHighPotency(false);
              onConfirm();
            }}>
            Confirm
          </Button>
          {!!getAddHormoneDisabled() && (
            <Tooltip theme="dark" id="confirmButton">
              {getAddHormoneDisabled()}
            </Tooltip>
          )}
        </div>
      </div>
    </Modal>
  );
}
