import { useEffect } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import {
  diagnosisMen,
  diagnosisWomen,
} from 'components/prescriptions/hormones/Diagnosis/diagnosisHelper';
import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';
import { TitrationConfigType } from 'types/patient/prescription';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
} from 'types/prescriptionTypes';

type PrescribedTypes = {
  prescribedHormones: (PrescribedObjectType &
    PrescriptionCheckValuesForReview)[];
  removePrescribedItem: (index: number, id: string) => void;

  onAddMore: () => void;
  titrationType?: TitrationConfigType;

  buttonLoading: boolean;
  excludedHormones: string[];
};

export function CartView({
  prescribedHormones,
  removePrescribedItem,

  onAddMore,
  titrationType,
  excludedHormones,

  buttonLoading,
}: PrescribedTypes) {
  const { profile } = useProfile();
  const {
    id: patientId,
    gender,
    address,
  } = useOutletContext<PatientDetailsContextType>();

  const navigate = useNavigate();

  const getPatientDiagnosisCodesQuery = useQuery(
    QueryKeys.PatientDiagnosisCode.listing({ id: patientId }),
    () => PatientService.getPatientDiagnosisCodes(patientId as string),
    {
      refetchOnMount: false,
    }
  );

  const checkShowPrescribingOptions = () => {
    const hormoneCodeState = getPatientDiagnosisCodesQuery.data?.data.find(
      (i) => i.diagnosisCode.type === 'hormone'
    );

    const thyroidCodeState = getPatientDiagnosisCodesQuery.data?.data.find(
      (i) => i.diagnosisCode.type === 'thyroid'
    );

    let hormoneList: (HormoneNames | undefined)[] = [];
    let thyroidList: (HormoneNames | undefined)[] = [];
    if (!hormoneCodeState || !thyroidCodeState) {
      const diagnosisList = gender === 'F' ? diagnosisWomen : diagnosisMen;

      if (!hormoneCodeState) {
        hormoneList = prescribedHormones
          .filter((i) =>
            diagnosisList.hormone.includes(i.displayName as HormoneNames)
          )
          .map((i) => i.displayName);
      }
      if (!thyroidCodeState) {
        thyroidList = prescribedHormones
          .filter((i) =>
            diagnosisList.thyroid.includes(i.displayName as HormoneNames)
          )
          .map((i) => i.displayName);
      }
    }
    navigate(
      {
        pathname: '../../../checkout',
      },
      {
        state: {
          prescription: { prescribedHormones, excludedHormones, titrationType },
          diagnosisHormoneList: {
            hormone: hormoneList as HormoneNames[],
            thyroid: thyroidList as HormoneNames[],
          },
          shippingAddress: address,
          licenseAndDea: {
            dea: profile?.providerDetails.providerDea,
            license: profile?.providerDetails.providerLicence,
          },
          isConcierge: profile?.practice.isConciergePractice,
        },
      }
    );
  };

  function getDaysOfSupply(item: PrescribedObjectType) {
    if (!item?.daysOfSupply?.withMinDose) {
      return '';
    }

    if (item?.daysOfSupply?.withMinDose === item?.daysOfSupply?.withMaxDose) {
      return `${item?.daysOfSupply?.withMaxDose} Days`;
    }

    return `${item?.daysOfSupply?.withMaxDose} Days to ${item?.daysOfSupply?.withMinDose} Days`;
  }

  function getFormattedDoseValue(item: PrescribedObjectType) {
    return `${item?.minimumDose} ${item?.doseUnit} to ${item?.dose} ${item?.doseUnit}`;
  }

  function getFormattedPrescriptionCheckValue(item: PrescribedObjectType) {
    if (item?.checkValues?.withMaxDose === item?.checkValues?.withMinDose) {
      return `${item?.checkValues?.withMaxDose} ${item?.checkValues?.unit}`;
    }

    return `${item?.checkValues?.withMinDose} ${item?.checkValues?.unit} to ${item?.checkValues?.withMaxDose} ${item?.checkValues?.unit}`;
  }

  function showDose(item: PrescribedObjectType) {
    const isTopicalHormone =
      !!item?.standardizedName?.includes('Topical') ||
      !!item?.standardizedName?.includes('topically');

    if (item?.currentDose?.morning.value && item?.currentDose?.evening.value) {
      return (
        <div
          className={classNames(
            'mt-6 mb-2  grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
          )}>
          <span className="col-span-6  flex flex-col">
            <Typography variant="h4">Morning Dose:</Typography>
            <Typography variant="subtitle1">
              {' '}
              {item?.currentDose?.morning.value}{' '}
              {item?.currentDose?.morning.unit}
            </Typography>
          </span>
          <span className={classNames('visible col-span-6  flex flex-col')}>
            <Typography variant="h4">Evening Dose:</Typography>
            <Typography variant="subtitle1">
              {' '}
              {item?.currentDose?.evening.value}{' '}
              {item?.currentDose?.evening.unit}
            </Typography>
          </span>
        </div>
      );
    }

    if (item?.dose === item?.minimumDose && isTopicalHormone) {
      return (
        <div
          className={classNames(
            'mt-6 mb-2  grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
          )}>
          <span className="col-span-6  flex flex-col">
            <Typography variant="h4">Current Dose:</Typography>
            <Typography variant="subtitle1">
              {item?.dose} {item?.doseUnit}
            </Typography>
          </span>
        </div>
      );
    }

    return null;
  }

  function showPrescriptionCheckValue(item: PrescribedObjectType) {
    if (item?.checkValues?.withMinDose && item?.checkValues?.withMaxDose) {
      return (
        <span className="col-span-6  flex flex-col">
          <Typography variant="h4">{item?.checkValues?.unit}:</Typography>
          <Typography variant="subtitle1">
            {' '}
            {getFormattedPrescriptionCheckValue(item)}
          </Typography>
        </span>
      );
    }

    return null;
  }

  function getAdjustableDoseRange(item: PrescribedObjectType) {
    if (item?.dose || item?.minimumDose) {
      if (item?.dose && item?.minimumDose && item?.dose !== item?.minimumDose) {
        return (
          <span className="col-span-6  flex flex-col">
            <Typography variant="h4">Adjustable Dose Range:</Typography>
            <Typography variant="subtitle1">
              {' '}
              {getFormattedDoseValue(item)}
            </Typography>
          </span>
        );
      }

      return (
        <span className="col-span-6  flex flex-col">
          <Typography variant="h4">Dose:</Typography>
          <Typography variant="subtitle1">
            {item.dose} {item.doseUnit}
          </Typography>
        </span>
      );
    }

    return null;
  }

  function showDaysOfSupply(item: PrescribedObjectType) {
    if (item?.daysOfSupply?.withMaxDose && item?.daysOfSupply?.withMinDose) {
      return (
        <span className="col-span-6  flex flex-col">
          <Typography variant="h4">Days of supply:</Typography>
          <Typography variant="subtitle1"> {getDaysOfSupply(item)}</Typography>
        </span>
      );
    }

    return null;
  }

  useEffect(() => {
    if (prescribedHormones.length < 1) {
      onAddMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescribedHormones.length]);

  return (
    <div className="flex flex-col text-start">
      <div className="mt-6 font-bold">
        <Typography variant="h3">
          Your Cart ({prescribedHormones.length}{' '}
          {prescribedHormones.length === 1 ? 'items' : 'items'}){' '}
        </Typography>
      </div>
      <SkeletonContent
        layout={[
          {
            className: 'mt-10 grid grid-cols-12 gap-x-10',
            children: new Array(prescribedHormones.length).fill(0).map(() => ({
              className:
                'h-96 relative col-span-6 mt-3 rounded bg-background-main pb-4 pt-10 pl-6 ',
              children: [
                {
                  className: 'w-60 h-6',
                },
              ],
            })),
          },
        ]}
        isLoading={false}>
        {() => (
          <div className=" mt-10 grid grid-cols-12 gap-x-10">
            {prescribedHormones.map(
              (item: PrescribedObjectType, index: number) => (
                <div className="relative col-span-6 mt-3 rounded bg-background-main pb-4 ">
                  <div
                    className=" w-full rounded-md bg-background-main pl-6 pr-2 pb-10 shadow-sm"
                    key={item.name}>
                    <span className="col-span-12  flex cursor-pointer justify-end">
                      {/* eslint-disable-next-line */}
                      <XMarkIcon
                        height={30}
                        className="mt-2 mr-2 text-background-contrastText"
                        onClick={() => {
                          removePrescribedItem(index, item.id as string);
                        }}
                      />
                    </span>
                    <div className="relative col-span-3 mt-3 rounded bg-background-main  ">
                      <div
                        className=" w-full rounded-md bg-background-main  shadow-sm"
                        key={item.name}>
                        <div className="relative col-span-6 mt-3 rounded bg-background-main  ">
                          <div
                            className=" w-full rounded-md bg-background-main   shadow-sm"
                            key={item.name}>
                            <div className=" grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                              <span className="col-span-8  flex">
                                <Typography color="primary" variant="h4">
                                  {item.standardizedName}, {item.dtd}{' '}
                                  {item.dtdUnit || 'ml'}
                                </Typography>
                              </span>
                            </div>
                            {showDose(item)}
                            <div
                              className={classNames(
                                ' mt-6 mb-2 hidden grid-cols-12 !items-start justify-around gap-x-4 text-start',
                                {
                                  '!grid':
                                    !!showPrescriptionCheckValue(item) ||
                                    !!getAdjustableDoseRange(item),
                                }
                              )}>
                              {showPrescriptionCheckValue(item)}
                              {getAdjustableDoseRange(item)}
                            </div>
                            <div
                              className={classNames(
                                'mt-6 mb-2  grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
                              )}>
                              {showDaysOfSupply(item)}
                              <span
                                className={classNames(
                                  'visible col-span-6  flex flex-col'
                                )}>
                                <Typography variant="h4">Refills:</Typography>
                                <Typography variant="subtitle1">
                                  {' '}
                                  {item.refills}
                                </Typography>
                              </span>
                            </div>
                            <div
                              className={classNames(
                                ' mt-6 mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start'
                              )}>
                              <span className="col-span-12  flex flex-col">
                                <Typography variant="h4">SIG:</Typography>
                                <Typography
                                  className="!max-w[100%] max-h-[10rem] !items-start !overflow-auto whitespace-pre-wrap"
                                  variant="subtitle1">
                                  {' '}
                                  {item.sig}
                                </Typography>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.isHighPotencyRx && (
                    <div className="absolute bottom-0 flex w-full justify-center rounded-b bg-error-main p-2">
                      <Typography variant="h5">High Potency Rx</Typography>
                    </div>
                  )}
                  {item.isLowPotencyRx && (
                    <div className="absolute bottom-0 flex w-full justify-center rounded-b bg-error-main p-2">
                      <Typography variant="h5">Low Potency Rx</Typography>
                    </div>
                  )}
                  {item.isFromTitration && (
                    <div className="absolute bottom-0 flex w-full justify-center rounded-b bg-primary-main p-2">
                      <Typography variant="h5">{titrationType}</Typography>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        )}
      </SkeletonContent>

      <div className="my-6 flex gap-6">
        <Button size="large" color="secondary" onClick={onAddMore}>
          Add more
        </Button>
        <Button
          size="large"
          disabled={prescribedHormones.length === 0}
          loading={buttonLoading}
          onClick={checkShowPrescribingOptions}>
          Checkout
        </Button>
      </div>
    </div>
  );
}
