import React, { LegacyRef, useRef, useState } from 'react';

import { PlusIcon } from '@heroicons/react/24/outline';
import { CameraIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Control, FieldValues } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';

interface BasicInformationProps<OnboardProviderFormType extends FieldValues> {
  control: Control<OnboardProviderFormType>;
}

export function BasicInformation<OnboardProviderFormType extends FieldValues>({
  control,
}: BasicInformationProps<OnboardProviderFormType>) {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [preview, setPreview] = useState<string>();
  const addProfilePictureMutation = useMutationWithToast(
    UserService.addProfilePicture
  );
  const hiddenFileInput = useRef<HTMLInputElement>();

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!!file && file?.size > 2000000) {
      toast.error('Picture size should be less than 2MB');

      return;
    }
    if (file) {
      addProfilePicture(file);
    }
  };
  const addProfilePicture = (file: File) => {
    if (!!file && file?.size > 2000000) {
      toast.error('Picture size should be less than 2MB');

      return;
    }

    if (file) {
      addProfilePictureMutation.mutate(
        { picture: file, token: token || '' },
        {
          onSuccess: () => {
            setPreview(URL.createObjectURL(file));
          },
        }
      );
    }
  };

  return (
    <div className=" ">
      <div className="flex justify-between">
        <Typography variant="h5">Basic Information</Typography>
        <Typography variant="h5" className="mr-8">
          Profile Picture
        </Typography>
      </div>
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-6 flex flex-col">
          <div className=" mt-3 flex justify-between">
            <TextInput
              name="firstName"
              type="text"
              control={control}
              placeholder="First Name"
              className=" "
              fullWidth
            />
            <span className="mx-2" />
            <TextInput
              name="lastName"
              type="text"
              control={control}
              placeholder="Last Name"
              className=" "
              fullWidth
            />
          </div>
          <div className=" grid grid-cols-12 gap-x-4">
            <div className=" col-span-6">
              <TextInput
                className="w-full"
                name="phone"
                type="tel"
                control={control}
                placeholder="Phone"
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="relative col-span-6 flex items-start justify-end  ">
          {/* eslint-disable */}
          <div
            onClick={handleClick}
            className={classNames(
              'absolute top-5 flex h-40 w-40 cursor-pointer items-center justify-center rounded-full bg-background-light hover:opacity-90',
              {
                '!rounded-none !bg-transparent': !!preview,
              }
            )}>
            {addProfilePictureMutation?.isLoading && (
              <LoadingSpinner className="absolute inset-auto  h-10 w-10 text-primary-light " />
            )}
            {preview ? (
              <div
                className={classNames('', {
                  '  ': !!preview,
                })}>
                {/* <Typography
                  className={classNames(
                    'absolute left-8 h-40 w-40 hover:text-background-contrastText opacity-1 '
                  )}>
                  Edit file{' '}
                </Typography> */}
                <img src={preview} className=" w-40 rounded hover:opacity-80" />
                {preview && (
                  <Button
                    variant="text"
                    className={classNames('absolute right-8 ')}>
                    Change
                  </Button>
                )}
              </div>
            ) : (
              <div
                className={classNames('relative ', {
                  'opacity-10': addProfilePictureMutation?.isLoading,
                })}>
                <CameraIcon className="h-16 w-16 text-background-contrastText" />

                <PlusIcon className=" absolute -right-3 -bottom-1 h-8 w-8 rounded-full border-4 border-background-light bg-primary-light text-background-contrastText" />
              </div>
            )}
          </div>

          <input
            type="file"
            ref={hiddenFileInput as LegacyRef<HTMLInputElement>}
            onChange={handleChange}
            className="hidden"
            accept="image/*"
          />
        </div>
      </div>
    </div>
  );
}
