import React from 'react';

import classNames from 'classnames';

import { Typography } from 'components/common/Typography/Typography';

interface CustomLegendProps {
  payload?: { color: string; value: string }[];
  customPayload?: { color: string; value: string }[];
  className?: string;
}

export function CustomLegend({
  payload,
  className,
  customPayload,
}: CustomLegendProps) {
  const data = customPayload?.length ? customPayload : payload;

  return (
    <div className={classNames('flex justify-center', className)}>
      {data?.map((entry) => (
        <div
          key={`item-${entry.value}`}
          style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <div
            className="rounded-sm"
            style={{
              width: 20,
              height: 20,
              backgroundColor: entry.color,
              marginRight: 5,
            }}
          />
          <Typography variant="subtitle2">{entry.value}</Typography>
        </div>
      ))}
    </div>
  );
}
