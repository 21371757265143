import React, { useEffect, useState } from 'react';

import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { useDebounce } from 'hooks/useDebounce';

import { TextInput } from '../Input/TextInput';

type SearchInputProps = {
  onSearch: (value: string) => void;
  id?: string;
  fullWidth?: boolean;
  noIcon?: boolean;
};

export function SearchInput({
  onSearch,
  id,
  fullWidth,
  noIcon,
}: SearchInputProps) {
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 500);

  useEffect(() => {
    onSearch(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div id={id} className=" relative flex items-center">
      {!noIcon && (
        <div
          id={id}
          className="pointer-events-none absolute inset-y-0 left-0  z-20 ml-2 flex  items-center text-background-contrastText">
          <MagnifyingGlassIcon height={16} />
        </div>
      )}
      <TextInput
        fullWidth={fullWidth}
        id={id}
        value={value}
        type="text"
        placeholder="Search"
        onChange={(text) => setValue(text)}
        className=" !border-none !py-2 pl-7 pr-9"
        disableHelperText
      />

      {!!value && (
        //  eslint-disable-next-line
        <div
          onClick={() => setValue('')}
          id={id}
          className="absolute inset-y-0 right-2 z-20 ml-2  flex cursor-pointer items-center  p-2 text-background-contrastText">
          <XMarkIcon height={20} />
        </div>
      )}
    </div>
  );
}
