import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useTestoDHEAControllerController } from 'hooks/hormones-new/useTestoDHEAController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type TestoDHEAProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function TestoDHEA({ addNewPrescription }: TestoDHEAProps) {
  const [searchParams] = useSearchParams();

  const medicationId = searchParams.get('id');
  const {
    TestoDHEADetails,

    sig,
    setSig,
    dtd,
    strength,

    dose,
    setDose,
    refills,
    setRefills,
    setStrength,
    doseValues,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useTestoDHEAControllerController();

  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || undefined,
    displayName: TestoDHEADetails.displayName,
    name: HormoneNames.TESTO_PER_DHEA,
    strength: `${strength}`,
    strengthUnit: TestoDHEADetails.strengthUnit,

    dose: doseValues[dose + maximumDose],
    minimumDose: doseValues?.[dose],
    doseUnit: TestoDHEADetails.doseUnit,
    refills,
    dtd,
    sig,
    deliveryMethod: TestoDHEADetails.deliveryMethod,
    family: TestoDHEADetails.family,
    note,
  };

  return (
    <HormoneDetails
      onPrescriptionValueChange={(value, renderModal) => {
        const a = value.value.split('/');

        if (Number(a[0]) > 4.41) {
          setStrength('24/160');
          if (strength !== '24/160') {
            renderModal(strength.toString(), '24/160', 'mg/day');
          }
        } else {
          setStrength('8/160');
          if (strength !== '8/160') {
            renderModal(strength.toString(), '8/160', 'mg/day');
          }
        }
      }}
      prescriptionObject={prescriptionObject}
      statusUnit={TestoDHEADetails.statusUnit}
      title={TestoDHEADetails.displayName}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Text,
          label: 'Strength',
          value: `${strength} ${TestoDHEADetails.strengthUnit}`,
        },

        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: doseValues,
          getTooltip: (val) =>
            `${`${doseValues[val].toFixed(2)} ${TestoDHEADetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: [0],
          getTooltip: (val) => `${TestoDHEADetails.refills[val]} refills`,
          disable: true,
        },

        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: doseValues,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
          },
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: TestoDHEADetails.deliveryMethod,
        },
        {
          label: 'D.T.D',
          value: `${dtd.toString()} ml`,
        },
      ]}
    />
  );
}
