import React, {
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

/* eslint-disable */
import { PencilIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useProfile } from 'hooks/useProfile';
import { UserService } from 'services/user';

import { UpdateEmail } from '../UpdateEmail/UpdateEmail';
import { UpdatePhone } from '../UpdatePhone/UpdatePhone';
import { ChangeButton } from './ChangeButton';
import classNames from 'classnames';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { toast } from 'react-hot-toast';
import { formatPhoneNumber, gender } from 'utils/common';
import { UpdateManagerDetailsModal } from '../UpdateManagerDetailsModal/UpdateManagerDetailsModal';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Chip } from 'components/common/Chip/Chip';
import { Button } from 'components/common/Button/Button';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SupervisingProviderModal } from '../SupervisingProviderModal/SupervisingProviderModal';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { UpdateNameModal } from '../UpdateNameModal/UpdateNameModal';
import { GMTDate } from 'lib/general/GMT-date';
import { AddLicenseDeaModal } from '../AddLicenseDea/AddLicenseDeaModal';
import { DEALicenseListing } from '../DEALicenseListing/DEALicenseListing';
import { CheckBox } from 'components/common/CheckBox/CheckBox';

type ManagerDetailsUpdateType = {
  state: boolean;
  type?: 'Name' | 'Email' | 'Title' | 'Gender' | 'DOB';
  data: string;
};

export function UserProfileDetails() {
  const { profile } = useProfile();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { state } = location;

  const focus = searchParams.get('focus') ?? '';
  const queryClient = useQueryClient();
  const [updateNameModal, setUpdateNameModal] = useState(false);
  const [phoneModal, setPhoneModal] = useState<boolean>(false);
  const [emailModal, setEmailModal] = useState<boolean>(false);
  const [deleteInfo, setDeleteInfo] = useState({
    state: false,
    id: '',
    type: '',
    data: '',
  });

  const [
    openDeleteSupervisingProviderConfirmDialog,
    setOpenDeleteSupervisingProviderConfirmDialog,
  ] = useState<boolean>(false);
  const editingSupervisingProviderIdRef = useRef<string>('');

  const [isOpenSupervisingProviderModal, setIsOpenSupervisingProviderModal] =
    useState<{ value: boolean; isUpdating?: boolean }>({
      value: false,
      isUpdating: false,
    });
  const getStatesQuery = useQuery(
    [QueryKeys.States],
    () => UserService.getStates(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const [showAddDeaLicenseModal, setShowAddDEALicenseModal] = useState(false);

  const deaLicenseRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (state && state.view === 'dea-license' && deaLicenseRef.current) {
      deaLicenseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state]);

  const [managerDetailsModal, setManagerDetailsModal] =
    useState<ManagerDetailsUpdateType>({ state: false, data: '' });
  const [isPictureMenuHidden, setIsPictureMenuHidden] = useState<boolean>(true);

  const updateProfilePictureMutation = useMutationWithToast(
    UserService.updateProfilePicture
  );
  const deleteSupervisingProviderMutation = useMutationWithToast(
    UserService.deleteSupervisingProvider
  );

  const deleteProfilePictureMutation = useMutationWithToast(
    UserService.deleteProfilePicture
  );

  const updateProfileDetailsMutation = useMutationWithToast(
    UserService.updateProfile
  );

  const deleteLicenseMutation = useMutationWithToast(
    UserService.deleteLicense,
    {
      onSuccess() {
        queryClient.refetchQueries([QueryKeys.UserProfile]);
      },
    }
  );
  const deleteDeaMutation = useMutationWithToast(UserService.deleteDEA, {
    onSuccess() {
      queryClient.refetchQueries([QueryKeys.UserProfile]);
    },
  });

  const hiddenFileInput = useRef<HTMLInputElement>();

  const closePhoneModal = (state: boolean) => {
    setPhoneModal(state);
  };

  const closePictureMenu = useCallback((event: MouseEvent) => {
    if ((event?.target as SVGElement).id === 'pencilIcon') return;
    setIsPictureMenuHidden(true);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', (e) => closePictureMenu(e));
    return () => document.body.removeEventListener('click', closePictureMenu);
  }, []);

  useEffect(() => {
    if (focus) {
      const element = document.getElementById(focus);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
  }, [focus]);

  const openPhoneModal = () => {
    setPhoneModal(true);
  };

  const closeEmailModal = (state: boolean) => {
    setEmailModal(state);
  };

  const openEmailModal = () => {
    setEmailModal(true);
  };

  const showPictureMenu = () => {
    setIsPictureMenuHidden(false);
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (!!file && file?.size > 2000000) {
      toast.error('Picture size should be less than 2MB');
      return;
    }
    if (file)
      updateProfilePictureMutation.mutate(
        { picture: file },
        {
          onSuccess: () => {
            queryClient.refetchQueries([QueryKeys.UserProfile]);
          },
        }
      );
  };

  function handleDeleteProfilePicture() {
    deleteProfilePictureMutation.mutate(undefined, {
      onSuccess: () => {
        queryClient.refetchQueries([QueryKeys.UserProfile]);
      },
    });
  }

  const onDeleteSupervisingProvider = () => {
    deleteSupervisingProviderMutation.mutate(
      editingSupervisingProviderIdRef.current,
      {
        onSuccess: () => {
          queryClient.refetchQueries([QueryKeys.UserProfile]).then(() => {
            setOpenDeleteSupervisingProviderConfirmDialog(false);
            editingSupervisingProviderIdRef.current = '';
          });
        },
      }
    );
  };

  const onUpdateOffersTelemedicines = (checked: boolean) => {
    updateProfileDetailsMutation.mutate(
      { offersTelemedicine: checked },
      {
        onSuccess: () => {
          queryClient.refetchQueries([QueryKeys.UserProfile]);
        },
      }
    );
  };

  const onDeleteLicense = (id: string) => {
    deleteLicenseMutation.mutate(id);
  };

  const onDeleteDEA = (id: string) => {
    deleteDeaMutation.mutate(id);
  };

  const supervisingDoctorsStateIds =
    profile?.providerDetails.supervisingProviders?.map((sp) => sp.licenseState);

  return (
    <div className="mt-4 flex  rounded-md p-1 pb-8">
      <div className="grow py-2 px-8">
        <div className="relative flex items-center  rounded bg-background-light p-4 py-8">
          {(updateProfilePictureMutation?.isLoading ||
            deleteProfilePictureMutation?.isLoading) && (
            <LoadingSpinner className="top-15  absolute left-11  z-50 text-primary-dark " />
          )}
          <Avatar
            imgUrl={profile?.pictureUrl}
            userName={profile?.firstName}
            sizeClass="h-20 w-20"
          />
          <div className="relative h-5">
            {!updateProfilePictureMutation?.isLoading && (
              <PencilIcon
                id="pencilIcon"
                onClick={showPictureMenu}
                className="absolute top-8 right-1 z-40 cursor-pointer rounded bg-white p-[4px]"
                height={20}
              />
            )}

            <div
              className={classNames(
                'absolute  top-8 -left-6 z-50  w-32 flex-col rounded bg-background-dark text-base font-semibold  text-background-contrastText',
                {
                  hidden: isPictureMenuHidden,
                }
              )}>
              <div
                onClick={handleClick}
                className="cursor-pointer rounded-t p-3 pl-3 pt-3 hover:bg-background-light">
                {!!profile?.pictureUrl ? 'Change' : 'Upload'}
              </div>
              {!!profile?.pictureUrl && (
                <div
                  onClick={handleDeleteProfilePicture}
                  className="cursor-pointer rounded-b p-3 pl-3 pb-3 text-error-main hover:bg-background-light ">
                  Delete
                </div>
              )}
            </div>
            <input
              type="file"
              ref={hiddenFileInput as LegacyRef<HTMLInputElement>}
              onChange={handleChange}
              className="hidden"
              accept="image/png, image/jpeg"
            />
          </div>
          <div className="ml-6 flex flex-col gap-1">
            <div className="flex">
              <Typography className="min-w-[21.5rem] grow" variant="h4">
                {`${profile?.firstName} ${profile?.middleName ?? ''} ${
                  profile?.lastName
                }`}{' '}
              </Typography>
              <ChangeButton onClick={() => setUpdateNameModal(true)} />
            </div>
            <div className="flex">
              <Typography className="grow" variant="subtitle1">
                {`${formatPhoneNumber(profile?.phone)} `} {'  '}{' '}
              </Typography>
              <ChangeButton onClick={openPhoneModal} />
            </div>
            <div className="flex">
              <Typography className="grow" variant="subtitle1">
                {`${profile?.email} `}
                {'  '}
              </Typography>
              <ChangeButton onClick={openEmailModal} />
            </div>
          </div>
        </div>
        {/* <div className="mt-2 h-1 w-full bg-background-light" /> */}
        <div className=" mt-2 flex flex-col gap-2 rounded bg-background-light p-4">
          <div className="flex">
            <div className="flex">
              <Typography
                className="min-w-[12rem]"
                variant="subtitle1"
                ellipsis>
                Provider title
              </Typography>{' '}
              <Typography variant="subtitle1" ellipsis className="min-w-[1rem]">
                :
              </Typography>{' '}
              <Typography
                variant="subtitle1"
                ellipsis
                className="min-w-[15rem] max-w-[15rem]">
                {profile?.providerDetails?.providerSuffix ?? 'Not Available'}
              </Typography>
            </div>

            {/* <ChangeButton
              onClick={() =>
                setManagerDetailsModal({
                  type: 'Title',
                  state: true,
                  data: profile?.providerDetails?.providerSuffix ?? '',
                })
              }
            /> */}
          </div>
          <div className="flex">
            <div className="flex">
              <Typography
                className="min-w-[12rem]"
                variant="subtitle1"
                ellipsis>
                Gender
              </Typography>{' '}
              <Typography variant="subtitle1" ellipsis className="min-w-[1rem]">
                :
              </Typography>{' '}
              <Typography
                variant="subtitle1"
                ellipsis
                className="min-w-[15rem] max-w-[15rem]">
                {gender[profile?.providerDetails?.gender ?? 'blank']}
              </Typography>
            </div>

            <ChangeButton
              onClick={() =>
                setManagerDetailsModal({
                  type: 'Gender',
                  state: true,
                  data: profile?.providerDetails?.gender ?? '',
                })
              }
            />
          </div>
          <div className="flex">
            <div className="flex">
              <Typography
                className="min-w-[12rem]"
                variant="subtitle1"
                ellipsis>
                DOB
              </Typography>{' '}
              <Typography variant="subtitle1" ellipsis className="min-w-[1rem]">
                :
              </Typography>{' '}
              <Typography
                variant="subtitle1"
                ellipsis
                className="min-w-[15rem] max-w-[15rem]">
                {profile?.providerDetails?.dob
                  ? new GMTDate(
                      profile?.providerDetails?.dob
                    ).getReadableDateInGMT()
                  : 'Not Available'}
              </Typography>
            </div>

            <ChangeButton
              onClick={() =>
                setManagerDetailsModal({
                  type: 'DOB',
                  state: true,
                  data: profile?.providerDetails?.dob ?? '',
                })
              }
            />
          </div>

          <div className="flex">
            <div className="flex">
              <Typography
                className="min-w-[12rem]"
                variant="subtitle1"
                ellipsis>
                Office manager name
              </Typography>{' '}
              <Typography variant="subtitle1" ellipsis className="min-w-[1rem]">
                :
              </Typography>{' '}
              <Typography
                data-tooltip-id="managerName"
                variant="subtitle1"
                ellipsis
                className="min-w-[15rem] max-w-[15rem]">
                {profile?.providerDetails.officeManagerName}
              </Typography>
            </div>

            <Tooltip
              style={{
                color: 'black',
                fontFamily: 'Verdana',
                zIndex: 10,
              }}
              className="bg-background-contrastText text-md text-background-dark"
              id="managerName">
              {profile?.providerDetails.officeManagerName}
            </Tooltip>
            <ChangeButton
              onClick={() =>
                setManagerDetailsModal({
                  type: 'Name',
                  state: true,
                  data: profile?.providerDetails.officeManagerName ?? '',
                })
              }
            />
          </div>
          <div className="flex">
            <div className="flex">
              <Typography
                className="min-w-[12rem]"
                variant="subtitle1"
                ellipsis>
                Office manager contact
              </Typography>{' '}
              <Typography variant="subtitle1" ellipsis className="min-w-[1rem]">
                :
              </Typography>{' '}
              <Typography
                data-tooltip-id="managerContact"
                variant="subtitle1"
                ellipsis
                className="min-w-[15rem] max-w-[15rem]">
                {profile?.providerDetails.officeManagerEmail}
              </Typography>
            </div>
            <Tooltip
              style={{
                color: 'black',
                fontFamily: 'Verdana',
                zIndex: 10,
              }}
              className="bg-background-contrastText text-md text-background-dark"
              id="managerContact">
              {profile?.providerDetails.officeManagerEmail}
            </Tooltip>
            <ChangeButton
              onClick={() =>
                setManagerDetailsModal({
                  type: 'Email',
                  state: true,
                  data: profile?.providerDetails.officeManagerEmail ?? '',
                })
              }
            />
          </div>
        </div>
        <div className=" mt-2 flex flex-col gap-2 rounded bg-background-light p-4">
          <div className="flex">
            <CheckBox
              checked={profile?.providerDetails.offersTelemedicine}
              onChange={(checked) => onUpdateOffersTelemedicines(checked)}
              id="offers-telemedicine"
              label="Do you offer telemedicine for your hormone services?"
              isLoading={updateProfileDetailsMutation.isLoading}
            />
          </div>
        </div>

        <div
          className="mt-6 flex w-full items-center justify-between "
          ref={deaLicenseRef}>
          <Typography variant="h4">License(s) & DEA(s) </Typography>
          <Button
            onClick={() => setShowAddDEALicenseModal(true)}
            variant="outlined">
            Add DEA & License
          </Button>
        </div>
        <div className="mt-6 ">
          <DEALicenseListing />
        </div>

        <div className="mt-6 h-1 w-full bg-background-light" />

        <div className="mt-6 flex flex-col gap-4">
          <div className="flex flex-col">
            <Typography variant="h4">Specialties</Typography>
            <div className="my-2 flex flex-wrap gap-2">
              {profile?.providerSpecialties.map((s) => (
                <Chip key={s.id} label={s.name}></Chip>
              ))}{' '}
            </div>
          </div>{' '}
          <div className=" h-1 w-full bg-background-light" />
          <div>
            <Typography variant="h4">NPI</Typography>
            <div className="my-2 flex flex-wrap gap-2">
              <Chip label={profile?.providerDetails.npi}></Chip>
            </div>
          </div>
          <div className=" h-1 w-full bg-background-light" />
        </div>
      </div>
      <UpdateNameModal
        name={{
          firstName: profile?.firstName ?? '',
          lastName: profile?.lastName ?? '',
          middleName: profile?.middleName ?? '',
        }}
        modal={updateNameModal}
        closeModal={() => {
          setUpdateNameModal(false);
        }}
      />
      <UpdatePhone
        phone={profile?.phone || ''}
        modal={phoneModal}
        closeModal={closePhoneModal}
      />
      <UpdateEmail
        email={profile?.email || ''}
        modal={emailModal}
        closeModal={closeEmailModal}
      />
      <SupervisingProviderModal
        isUpdating={isOpenSupervisingProviderModal.isUpdating}
        defaultValues={profile?.providerDetails.supervisingProviders?.find(
          (sd) => sd.id === editingSupervisingProviderIdRef.current
        )}
        isModalOpen={isOpenSupervisingProviderModal.value}
        onModalClose={() =>
          setIsOpenSupervisingProviderModal((prev) => ({
            ...prev,
            value: false,
          }))
        }
        supervisingProviderId={editingSupervisingProviderIdRef.current}
        states={
          isOpenSupervisingProviderModal.isUpdating
            ? getStatesQuery?.data?.data ?? []
            : getStatesQuery.data?.data?.filter(
                (state) => !supervisingDoctorsStateIds?.includes(state.id)
              ) ?? []
        }
        visibleStates={profile?.providerDetails.providerDea.map(
          (d) => d.stateId
        )}
      />

      <UpdateManagerDetailsModal
        type={managerDetailsModal.type ?? ''}
        defaultProviderTitle={profile?.providerDetails?.providerSuffix}
        preValue={managerDetailsModal.data}
        modal={managerDetailsModal?.state}
        closeModal={() => {
          setManagerDetailsModal({ ...managerDetailsModal, state: false });
        }}
        onSuccess={() => queryClient.refetchQueries([QueryKeys.UserProfile])}
      />

      <AddLicenseDeaModal
        showAddModal={showAddDeaLicenseModal}
        handleClose={() => setShowAddDEALicenseModal(false)}
      />
      <ConfirmationModal
        closeModal={() => {
          if (
            deleteSupervisingProviderMutation.isLoading ||
            !!queryClient.isFetching([QueryKeys.UserProfile])
          ) {
            return;
          }
          setOpenDeleteSupervisingProviderConfirmDialog(false);
        }}
        classes={{
          confirmBtn: '!bg-error-main',
        }}
        title="Are you sure to delete this supervising provider?"
        confirmText="Delete"
        handleConfirm={onDeleteSupervisingProvider}
        confirmLoading={
          deleteSupervisingProviderMutation.isLoading ||
          !!queryClient.isFetching([QueryKeys.UserProfile])
        }
        isModalOpen={openDeleteSupervisingProviderConfirmDialog}
      />
      <ConfirmationModal
        confirmLoading={
          deleteDeaMutation.isLoading || deleteLicenseMutation.isLoading
        }
        confirmText="Delete"
        buttonColor={{
          confirmBtn: 'error',
        }}
        closeModal={() => {
          setDeleteInfo({
            state: false,
            id: '',
            type: '',
            data: '',
          });
        }}
        handleConfirm={() => {
          if (deleteInfo.type === 'DEA') {
            onDeleteDEA(deleteInfo.id);
          } else {
            onDeleteLicense(deleteInfo.id);
          }
          setDeleteInfo((prev) => ({ ...prev, state: false }));
        }}
        isModalOpen={deleteInfo.state}>
        <Typography variant="h4">
          {' '}
          Are you sure to delete{' '}
          <span className="mx-1 text-primary-main">{deleteInfo.data}</span>{' '}
          {deleteInfo.type} number ?
        </Typography>
      </ConfirmationModal>
    </div>
  );
}
