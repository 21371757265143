import React from 'react';

export function TitrationDetailsSkeleton() {
  return (
    <div className="mt-4 grid animate-pulse   grid-cols-12 bg-background-main ">
      <div className="col-span-2 ">
        {[...Array(6)].map(() => (
          <div className="mt-1 mr-1   h-12 bg-background-light " />
        ))}
      </div>
      <div className="col-span-10  ">
        {[...Array(6)].map(() => (
          <div className="flex overflow-auto ">
            {[...Array(28)].map(() => (
              <div className="mt-1 mr-1 h-12 w-10  bg-background-light " />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
