import React, { useMemo } from 'react';

import { QuestionObject } from 'types/questionnaire';

import { QuestionCategory } from './QuestionCategory';
import { getCategoryWiseQuestion } from './questionnaireHelper';

interface QuestionnaireSectionDetailsProps {
  section: QuestionObject[];
}
export function QuestionnaireSectionDetails({
  section,
}: QuestionnaireSectionDetailsProps) {
  const categoryWiseQuestion = useMemo(
    () => getCategoryWiseQuestion(section),
    [section]
  );

  return (
    <div className="scrollbar-hide h-[30rem]   overflow-y-auto rounded-b text-md text-background-contrastText ">
      {categoryWiseQuestion.map((category, index) => (
        <QuestionCategory category={category} index={index} />
      ))}
    </div>
  );
}
