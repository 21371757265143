import React, { ReactNode } from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { Tooltip } from '../tooltip/ToolTip';

type InfoTooltipProps = {
  label?: ReactNode;
  id: string;
  className?: string;
  fullWidth?: boolean;
};
export function InfoTooltip({
  label,
  id,
  className,
  fullWidth,
}: InfoTooltipProps) {
  return (
    <div className={classNames(' px-1', className)}>
      <InformationCircleIcon
        data-tooltip-id={id}
        className="h-5 w-5 font-extrabold text-[#efb034] "
      />
      <Tooltip
        className={classNames('max-w-[24rem]  text-lg !font-thin', {
          'max-w-full': fullWidth,
        })}
        id={id}>
        {label}
      </Tooltip>
    </div>
  );
}
