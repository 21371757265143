import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useLiothyronineExtController } from 'hooks/hormones-new/useLiothyronineExtController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type LiothyronineExtProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function LiothyronineExt({ addNewPrescription }: LiothyronineExtProps) {
  const [searchParams] = useSearchParams();
  const medicationId = searchParams.get('id');
  const {
    LiothyronineExtDetails,
    strength,
    setStrength,
    sig,
    setSig,
    dtd,
    setDTD,

    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useLiothyronineExtController();

  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || '',
    displayName: LiothyronineExtDetails.displayName,
    name: HormoneNames.LIOTHYRONINE_EXT,
    strength: LiothyronineExtDetails.strength[strength],
    strengthUnit: LiothyronineExtDetails.strengthUnit,

    dose: LiothyronineExtDetails.dose[dose + maximumDose],
    minimumDose: LiothyronineExtDetails.dose[dose],
    doseUnit: LiothyronineExtDetails.doseUnit,
    refills,
    dtd,
    dtdUnit: LiothyronineExtDetails.dtdUnit,

    sig,
    deliveryMethod: LiothyronineExtDetails.deliveryMethod,
    family: LiothyronineExtDetails.family,
    note,
  };

  return (
    <HormoneDetails
      showStatus={false}
      prescriptionObject={prescriptionObject}
      title={LiothyronineExtDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Strength',
          value: strength,
          onChange(value) {
            setStrength(value);
          },
          options: LiothyronineExtDetails.strength,
          getTooltip: (val) =>
            `${`${LiothyronineExtDetails.strength[val]} ${LiothyronineExtDetails.strengthUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: LiothyronineExtDetails.dose,
          getTooltip: (val) =>
            `${`${LiothyronineExtDetails.dose[val]} ${LiothyronineExtDetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: LiothyronineExtDetails.refills,
          getTooltip: (val) => `${LiothyronineExtDetails.refills[val]} refills`,
        },
        {
          type: PrescriptionInputTypes.DTD,
          value: dtd,
          unit: LiothyronineExtDetails.dtdUnit,
          onChange(val) {
            setDTD(val);
          },
          DTDValues: LiothyronineExtDetails.dtd,
        },
        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: LiothyronineExtDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
          },
          unit: LiothyronineExtDetails.doseUnit,
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: LiothyronineExtDetails.deliveryMethod,
        },
      ]}
    />
  );
}
