import { APIListingResponse } from 'services/api-service';
import { LicenseNumber } from 'types/common';
import { Option } from 'types/inputTypes';
import { AddressType } from 'types/onboard';
import { PrescribedObjectType } from 'types/prescriptionTypes';

export enum Gender {
  Male = 'M',
  Female = 'F',
}

export type PatientDetailsContextType = {
  id: string;

  firstName: string;
  lastName: string;
  middleName: string;

  email: string;
  phone: string;
  gender: Gender;
  dob: string;
  completedVisits: number;
  allergies: string;
  picture?: string;
  diagnosisHormone?: PatientDiagnosisCodes;
  diagnosisThyroid?: PatientDiagnosisCodes;
  patientKnownAllergiesByPatient: string;
  patientOtherMedicationsByPatient: string;
  patientKnownAllergiesByProvider: AllergyType[] | undefined;
  patientOtherMedicationsByProvider: Option[] | undefined;
  address?: AddressType & { id: string };
  patientSsnNumber?: string;
  patientDriversLicense?: LicenseNumber;
  isArchivedPatient: boolean;
  hasDeaForShippingState?: boolean;
};

export type PatientDiagnosisCodes = {
  diagnosisCode: {
    id: string;
    code: string;
    description: string;
    type: string;
  };
};

export type DiagnosisFormType = {
  hormoneCode: string;
  thyroidCode: string;
};

export type PrescribeOptionsType = {
  thyroidCode?: string;
  hormoneCode?: string;
  deliveryMethod?: string;
  billingCycle?: string;
  pharmacyNotes: string;
};

export interface PatientAllergyType {
  compositeAllergyId: string;
  conceptID: string;
  conceptType: string;
  description: string;
  source: string;
  status: string;
  touchDate: string;
  created_at: string;
  id: string;
  updated_at: string;
}

export interface PatientOtherMedicationType {
  medMedidDesc: string;
  medName: string;
  formularyDrugId: number;
}

export type PatientAllergyListResponse = APIListingResponse<PatientAllergyType>;

export type PatientOtherMedicationResponse =
  APIListingResponse<PatientOtherMedicationType>;

export type AllergyType = {
  title: string;
  severity: string;
  id: string;
};

export interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  payload?: { value?: string | number };
}

export type StoredHormoneDetails = {
  id?: string;
  name?: string;
  coveredUnderSubscription?: boolean;
  isControlledSubstance?: boolean;
};

export type HormoneSelectionProps = {
  gender: Gender;
  RxDetails?: StoredHormoneDetails;
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
  onBack: () => void;
};
