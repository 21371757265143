import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useT4PerLAT3Controller } from 'hooks/hormones-new/useT4PerLAT3Controller';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type T4PerLAT3Props = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function T4PerLAT3({ addNewPrescription }: T4PerLAT3Props) {
  const [searchParams] = useSearchParams();
  const medicationId = searchParams.get('id');
  const {
    T4PerLATDetails,
    dtd,
    setStrength,
    strength,
    setDTD,
    sig,
    setSig,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useT4PerLAT3Controller();

  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || '',
    displayName: T4PerLATDetails.displayName,
    name: HormoneNames.T4_PER_LAT3,
    strength: T4PerLATDetails.strength[strength],
    strengthUnit: T4PerLATDetails.strengthUnit,
    dose: T4PerLATDetails.dose[dose + maximumDose],
    minimumDose: T4PerLATDetails.dose[dose],
    doseUnit: T4PerLATDetails.doseUnit,
    refills,
    dtd,
    dtdUnit: T4PerLATDetails.dtdUnit,
    sig,
    deliveryMethod: T4PerLATDetails.deliveryMethod,
    family: T4PerLATDetails.family,
    note,
  };

  return (
    <HormoneDetails
      showStatus={false}
      prescriptionObject={prescriptionObject}
      title={T4PerLATDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Strength',
          value: strength,
          onChange(value) {
            setStrength(value);
          },
          options: T4PerLATDetails.strength,
          getTooltip: (val) =>
            `${`${T4PerLATDetails.strength[val]} ${T4PerLATDetails.strengthUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
            setSig(
              `Take ${T4PerLATDetails.dose[value]} capsule(s) per morning, prior to breakfast, by mouth as directed.`
            );
          },
          options: T4PerLATDetails.dose,
          getTooltip: (val) =>
            `${`${T4PerLATDetails.dose[val]} ${T4PerLATDetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: T4PerLATDetails.refills,
          getTooltip: (val) => `${T4PerLATDetails.refills[val]} refills`,
        },
        {
          type: PrescriptionInputTypes.DTD,
          value: dtd,
          unit: T4PerLATDetails.dtdUnit,
          onChange(val) {
            setDTD(val);
          },
          DTDValues: T4PerLATDetails.dtd,
        },
        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: T4PerLATDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
          },
          unit: T4PerLATDetails.doseUnit,
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: T4PerLATDetails.deliveryMethod,
        },
      ]}
    />
  );
}
