import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

interface UseSearchParamsPagination {
  defaultPage?: number;
  key?: string;
}

export function useSearchParamsPagination({
  defaultPage = 1,
  key = 'page',
}: UseSearchParamsPagination = {}) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get(key)) return;

    const params = new URLSearchParams(window.location.search);
    params.set(key, String(defaultPage));
    setSearchParams(params, { replace: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    page: Number(searchParams.get(key) || defaultPage),
    setPage: (page: number) =>
      setSearchParams(
        () => {
          const params = new URLSearchParams(window.location.search);
          params.set(key, String(page));

          return params;
        },
        { replace: true }
      ),
  };
}
