import React from 'react';

import { Control, FieldValues } from 'react-hook-form';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';

interface OfficeDetailsProps<OnboardProviderFormType extends FieldValues> {
  control: Control<OnboardProviderFormType>;
}

export function OfficeDetails<OnboardProviderFormType extends FieldValues>({
  control,
}: OfficeDetailsProps<OnboardProviderFormType>) {
  return (
    <div className="grid grid-cols-12 ">
      <div className="col-span-12 flex justify-between">
        <Typography variant="h5">Office Details</Typography>
      </div>
      <div className=" col-span-12 mt-3 grid grid-cols-12 gap-x-4">
        <div className="col-span-3">
          <div className="flex items-center">
            <TextInput
              type="text"
              placeholder="Office Manager Name"
              className="  "
              fullWidth
              name="officeManagerName"
              control={control}
            />
          </div>
        </div>

        <div className="col-span-3">
          <div className="flex items-center">
            <TextInput
              type="text"
              placeholder="Manager Email "
              className="  "
              fullWidth
              name="officeManagerEmail"
              control={control}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
