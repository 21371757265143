import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from 'constants/query-keys';
import { PatientService } from 'services/patient';
import { PatientPrescription } from 'types/patient/prescription';

type CurrentPrescriptionProp = {
  medicationId: string;
  onSuccess: (prescription: PatientPrescription | undefined) => void;
};

export function useCurrentActivePrescription({
  medicationId,
  onSuccess,
}: CurrentPrescriptionProp) {
  const { id: patientId } = useParams();

  const getPatientPrescriptionQuery = useQuery(
    QueryKeys.PatientPrescriptions.listing({
      category: 'current',
      id: patientId ?? '',
    }),
    () =>
      PatientService.getPatientPrescription({
        category: 'current' ?? '',
        id: patientId ?? '',
      }),
    {
      enabled: !!patientId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess(data) {
        onSuccess(
          data?.data.find((p) => p.prescriptionToMedication.id === medicationId)
        );
      },
    }
  );

  const currentActivePrescriptions = useMemo(
    () =>
      getPatientPrescriptionQuery.data?.data.find(
        (p) => p.prescriptionToMedication.id === medicationId
      ),
    [getPatientPrescriptionQuery.data, medicationId]
  );

  //   useEffect(() => {
  //     if (currentActivePrescriptions?.id) {
  //       getPrescriptionCheckMutation.mutateAsync(currentActivePrescriptions?.id);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [currentActivePrescriptions?.id]);

  return {
    currentActivePrescriptions,
    isLoading: getPatientPrescriptionQuery.isLoading,
  };
}
