import React from 'react';

import { Typography } from '../Typography/Typography';

export function UnderConstruction() {
  return (
    <div className="flex h-96  flex-col items-center justify-center rounded bg-background-main">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="block h-20  w-20 fill-primary-main">
        <path d="M 12 2.03125 C 11.506 2.03125 11.0575 2.2585 10.8125 2.6875 L 1.1875 19.75 C 0.9245 20.222 0.9205 20.81125 1.1875 21.28125 C 1.4445 21.73325 1.90525 22 2.40625 22 C 2.43625 22 2.46775 22.001 2.46875 22 L 21.46875 22 C 22.01975 22 22.50825 21.70075 22.78125 21.21875 C 23.05325 20.73975 23.04925 20.1675 22.78125 19.6875 L 13.15625 2.6875 C 12.91225 2.2605 12.494 2.03125 12 2.03125 z M 12.78125 9 C 13.45725 9.006 14.006 9.573 14 10.25 C 13.994 10.925 13.45725 11.4425 12.78125 11.4375 C 12.10525 11.4315 11.5565 10.89475 11.5625 10.21875 C 11.5675 9.54175 12.10525 8.994 12.78125 9 z M 10.65625 11.375 C 10.869672 11.369234 11.0495 11.38425 11.15625 11.40625 L 11.25 11.4375 L 12.9375 12.125 C 13.2795 12.259 13.54525 12.52 13.65625 12.875 L 14.3125 15.96875 L 16.9375 17.0625 L 18.28125 15.71875 L 20.6875 19.96875 L 15 19.96875 L 16 18 L 16.375 17.625 L 7 13.5 L 7.25 12.9375 L 7.84375 13.1875 L 8.53125 11.84375 L 8.65625 11.75 C 9.02975 11.50925 10.015984 11.392297 10.65625 11.375 z M 10.5 12.59375 C 10.153 12.61975 9.6825 12.681 9.4375 12.75 L 8.96875 13.6875 L 9.8125 14.03125 L 10.5 12.59375 z M 12.5625 13.5625 L 11.96875 14.96875 L 12.96875 15.40625 L 12.5625 13.5625 z M 9.21875 15.375 L 12.5 16.8125 L 13 17.5 L 13 20 L 11.71875 20 L 11.78125 17.84375 L 10.4375 16 L 9.28125 19.96875 L 8 19.96875 L 9.21875 15.375 z" />
      </svg>
      <Typography variant="h4">Under construction.</Typography>
    </div>
  );
}
