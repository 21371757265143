import React from 'react';

import classNames from 'classnames';

import { Typography } from 'components/common/Typography/Typography';
import { PrescriptionCheckType } from 'types/patient/prescription';

type PrescriptionStatusIndicatorProps = {
  data?: PrescriptionCheckType;
  isLoading?: boolean;
  statusUnit?: string;
  supply?: boolean;
  className?: string;
  secondaryData?: PrescriptionCheckType;
};

type ColorType = {
  [key: string]: string;
};

const colorObj: ColorType = {
  Good: 'bg-[#00D9AF]',
  Warning: 'bg-[#E3DB00]',
  Bad: 'bg-[#EC6761]',
};

const colorDaysObj: ColorType = {
  Good: 'bg-[#00D9AF]',
  Warning: 'bg-[#00D9AF]',
  Bad: 'bg-[#EC6761]',
};

export function PrescriptionStatusIndicator({
  data,
  isLoading,
  statusUnit,
  supply,
  className,
  secondaryData,
}: PrescriptionStatusIndicatorProps) {
  const isMaximumMGEEQ = statusUnit?.includes('Maximum');

  return (
    <div
      className={classNames(
        'col-span-3   text-left text-sm font-semibold',
        className
      )}>
      <Typography className=" whitespace-nowrap" variant="h5" align="left">
        {supply ? 'Days of supply' : statusUnit}:
      </Typography>
      {isLoading === true ? (
        <div className=" mt-1 h-[2rem]  animate-pulse rounded bg-background-light" />
      ) : (
        <div>
          <div
            className={classNames(
              'mt-1 flex rounded py-2 px-4 text-background-light ',
              `${
                supply
                  ? colorDaysObj[data?.validDaysOfSupply ? 'Good' : 'Bad']
                  : colorObj[
                      isMaximumMGEEQ
                        ? (data?.rxQuality as string)
                        : (secondaryData?.rxQuality as string)
                    ]
              }`
            )}>
            {supply
              ? `${
                  secondaryData?.daysOfSupply &&
                  data?.daysOfSupply &&
                  data?.daysOfSupply < secondaryData?.daysOfSupply
                    ? `${secondaryData.daysOfSupply}/`
                    : ''
                }${data?.daysOfSupply} Days`
              : `${(isMaximumMGEEQ ? data : secondaryData)?.value} ${
                  (isMaximumMGEEQ ? data : secondaryData)?.unit
                }`}
          </div>
        </div>
      )}
    </div>
  );
}
