import React, { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { SwitchPracticeModal } from 'components/practice/SwitchPracticeModal/SwitchPracticeModal';
import { useProfile } from 'hooks/useProfile';
import { UserService } from 'services/user';

export function SwitchLocationButtonWithModal() {
  const { profile } = useProfile();
  const [showPracticeSwitchModal, setShowPracticeSwitchModal] = useState(false);

  const [showPracticeLocation, setShowPracticeLocation] = useState(false);

  const getProviderPracticeAddressesMutation = useMutation((id: string) =>
    UserService.getProviderPracticeAddresses(id)
  );

  const getProviderPracticeMutation = useMutation(() =>
    UserService.getProviderPractices({ page: 1, size: 100 })
  );

  function checkIfUserHasMultiplePracticesAndLocation() {
    getProviderPracticeMutation.mutate(undefined, {
      onSuccess(data) {
        if (data.data.items.length > 1) {
          setShowPracticeLocation(true);
        }
      },
    });

    getProviderPracticeAddressesMutation.mutate(profile?.practice.id ?? '', {
      onSuccess(data) {
        if (data.data.length > 1) {
          setShowPracticeLocation(true);
        }
      },
    });
  }

  useEffect(() => {
    if (profile?.practice.id) {
      checkIfUserHasMultiplePracticesAndLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.practice?.id]);

  return (
    <div
      className={classNames(
        'flex h-0 flex-col gap-y-2 overflow-hidden transition-all duration-300 ',
        {
          'mt-6 !h-24': showPracticeLocation,
        }
      )}>
      <Typography color="textPrimary" variant="h5">
        Your current Practice & Location
      </Typography>
      <div className=" flex items-center justify-between rounded bg-background-light p-2 px-4 pr-6 ">
        <div className="flex flex-col gap-y-2">
          <Typography color="white" variant="h5">
            {profile?.practice?.name}
          </Typography>

          <Typography color="white" className="italic" variant="subtitle2">
            {`${`${profile?.practiceAddress?.addressLine1}, ${`${
              profile?.practiceAddress?.addressLine2
                ? `${profile?.practiceAddress?.addressLine2},`
                : ''
            }`} ${profile?.practiceAddress?.city}, ${
              profile?.practiceAddress?.state?.name
            } ${profile?.practiceAddress?.zipCode}, ${
              profile?.practiceAddress?.country
            }.`}`}
          </Typography>
        </div>
        <Button
          onClick={() => {
            setShowPracticeSwitchModal(true);
          }}>
          Switch
        </Button>
      </div>
      <SwitchPracticeModal
        open={showPracticeSwitchModal}
        onClose={() => {
          setShowPracticeSwitchModal(false);
        }}
      />
    </div>
  );
}
