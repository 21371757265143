import { useEffect, useMemo, useState } from 'react';

type MaximumDoseHandlerProps = {
  dose: number;
  incrementalValue?: number;
  doses: number[];
  defaultValue?: number;
};

export function useMaximumDoseHandler({
  dose,
  incrementalValue = 0,
  doses,
  defaultValue,
}: MaximumDoseHandlerProps) {
  const [maxDosePreValue, setMaxDosePreValue] = useState<number>();
  const [maximumDose, setMaximumDose] = useState(defaultValue ?? 0);

  const maxDoseOptions = useMemo(
    () => [...doses.slice(dose + incrementalValue, doses.length)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dose, incrementalValue]
  );

  const currentMaxDoseIndex = useMemo(() => {
    if (maximumDose === 0) {
      return 0;
    }

    if (maxDosePreValue) {
      if (maxDoseOptions.length === 1) {
        setMaxDosePreValue(undefined);
        setMaximumDose(0);
      }
      const index = maxDoseOptions.indexOf(maxDosePreValue);
      if (index > 0) {
        return index;
      }

      return 0;
    }

    return maximumDose;
  }, [maxDoseOptions, maxDosePreValue, maximumDose, setMaximumDose]);

  useEffect(() => {
    setMaximumDose(currentMaxDoseIndex);

    // eslint-disable-next-line
  }, [dose]);

  return { maximumDose, setMaximumDose, setMaxDosePreValue, maxDoseOptions };
}
