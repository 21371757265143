import React from 'react';

export function QuestionnaireSkeleton() {
  return (
    <div className=" col-span-12 animate-pulse">
      <div className="  flex justify-between">
        {[...Array(6)].map(() => (
          <div className=" h-12 w-40 rounded bg-background-light" />
        ))}
      </div>
      <div className="mt-2  h-[30rem] rounded bg-background-light " />
    </div>
  );
}
