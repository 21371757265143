import { Suspense, useReducer, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Typography } from 'components/common/Typography/Typography';
import { PrescribedHormones } from 'components/prescriptions/PrescribedHormones/PrescribedHormones';
import { PrescriptionDetails } from 'components/prescriptions/PrescriptionDetails/PrescriptionDetails';
import {
  femaleHormoneRxTypes,
  maleHormoneRxTypes,
} from 'constants/hormone-details';
import { QueryKeys } from 'constants/query-keys';
import { hormoneReducer } from 'reducers/prescription-reducer';
import { PatientService } from 'services/patient';
import { Gender, PatientDetailsContextType } from 'types/patient';
import {
  PrescribedObjectTypes,
  ReducerActionType,
} from 'types/prescriptionTypes';
import './RxTypes.css';
import { normalizeString } from 'utils/common';

type OpenWarningPopupType = {
  state: boolean;
  details?: {
    name?: string;
    family: string;
    newHormoneName: string;
  };
};

export function EPrescribe() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const { id: patientId, gender } =
    useOutletContext<PatientDetailsContextType>();
  const getHormonesQuery = useQuery(
    [QueryKeys.Hormones],
    () => PatientService.getPatientMedication(gender || ''),
    { enabled: !!gender && !!patientId }
  );

  const [openWarningPopup, setOpenWarningPopup] =
    useState<OpenWarningPopupType>({
      state: false,
    });
  const initialState: PrescribedObjectTypes[] = JSON.parse(
    (sessionStorage.getItem(`hormones${patientId}`) as string) ?? '[]'
  );
  const [isPrescribeActive, setIsPrescribeActive] = useState(
    !initialState.length
  );

  const [prescribedHormones, dispatch] = useReducer(
    hormoneReducer,
    initialState
  );
  const checkAlreadyPrescribedItem = ({
    name,
    family,
  }: PrescribedObjectTypes) => {
    let index = -1;
    let isRepeated = false;
    const existingHormoneDetails = {
      name: '',
      family: family ?? '',
      newHormoneName: name ?? '',
    };
    // eslint-disable-next-line no-plusplus
    for (let i = prescribedHormones.length - 1; i >= 0; i--) {
      if (prescribedHormones[i].name === name) {
        index = i;
        break;
      }
    }
    if (family && index === -1) {
      if (
        prescribedHormones?.some((item) => {
          if (item?.family === family) {
            existingHormoneDetails.name = item.name || '';
          }

          return item?.family === family;
        })
      ) {
        isRepeated = true;
      }
    }

    return { index, isRepeated, existingHormoneDetails };
  };

  function getHormoneId() {
    let id;
    getHormonesQuery?.data?.data.some((item) => {
      if (normalizeString(item.name) === rx) {
        id = item.id;
      }

      return normalizeString(item.name) === rx;
    });

    return id;
  }
  const addNewPrescription = async (prescribedItem: PrescribedObjectTypes) => {
    const { index, isRepeated, existingHormoneDetails } =
      checkAlreadyPrescribedItem(prescribedItem);

    if (isRepeated && existingHormoneDetails?.name) {
      setOpenWarningPopup({
        state: true,
        details: existingHormoneDetails,
      });

      return;
    }
    setIsPrescribeActive(false);

    const id = getHormoneId() || prescribedItem?.id;

    if (!id) {
      return;
    }

    if (index > -1) {
      dispatch({
        type: ReducerActionType.UPDATE_HORMONE,
        payload: { hormoneObject: { ...prescribedItem, id }, index },
      });
      sessionStorage.setItem(
        `hormones${patientId}`,
        JSON.stringify([
          ...prescribedHormones.map((hormone) => {
            if (id === hormone.id) {
              return { ...prescribedItem, id };
            }

            return hormone;
          }),
        ])
      );
    } else {
      dispatch({
        type: ReducerActionType.ADD_HORMONE,
        payload: { hormoneObject: { ...prescribedItem, id }, index: -1 },
      });
      sessionStorage.setItem(
        `hormones${patientId}`,
        JSON.stringify([...prescribedHormones, { ...prescribedItem, id }])
      );
    }
  };

  const removePrescribedItem = (index: number, id: string) => {
    dispatch({
      type: ReducerActionType.DELETE_HORMONE,
      payload: { hormoneObject: { refills: 0 }, index },
    });
    sessionStorage.setItem(
      `hormones${patientId}`,
      JSON.stringify([
        ...prescribedHormones.filter((hormone) => id !== hormone.id),
      ])
    );
  };

  const rxTypeClickHandler = (item: string) => {
    if (gender === Gender.Male) {
      navigate({
        search: `rx=${maleHormoneRxTypes[item]?.link}`,
      });
    } else {
      navigate({
        search: `rx=${femaleHormoneRxTypes[item]?.link}`,
      });
    }
  };

  function getColor(item: string) {
    if (gender === Gender.Male) {
      return maleHormoneRxTypes[normalizeString(item)]?.link === rx
        ? 'primary'
        : 'secondary';
    }

    return femaleHormoneRxTypes[normalizeString(item)]?.link === rx
      ? 'primary'
      : 'secondary';
  }

  return (
    <div className="w-full ">
      {isPrescribeActive && (
        <div className="  grid grid-cols-10 gap-4 pt-0 ">
          <div className="  col-span-3  grid">
            <div className="">
              <Typography
                variant="h3"
                color="textMuted"
                className="justify-center bg-background-main py-3 ">
                Select Rx to Prescribe
              </Typography>

              <div className="scrollbar-hide mt-1 grid h-[25rem]  cursor-pointer overflow-auto rounded-b">
                {getHormonesQuery?.data?.data.map((item) => (
                  <Button
                    key={item.name}
                    color={getColor(item.name)}
                    className="mb-[1px] justify-start rounded-none py-2   pl-6 text-md shadow-sm"
                    onClick={() =>
                      rxTypeClickHandler(normalizeString(item.name))
                    }>
                    {item.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-7 h-[28rem] rounded-b  bg-background-main pt-6 pl-12">
            {rx ? (
              <PrescriptionDetails addNewPrescription={addNewPrescription} />
            ) : (
              <div className="-mt-6 flex h-full items-center justify-center">
                <Typography variant="h4" color="textMuted">
                  Please select a hormone
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="col-span-12 text-black dark:text-white">
        <PrescribedHormones
          prescribedHormones={prescribedHormones}
          removePrescribedItem={removePrescribedItem}
          isPrescribeActive={isPrescribeActive}
          setIsPrescribeActive={setIsPrescribeActive}
        />
      </div>
      <Suspense>
        <ConfirmationModal
          isModalOpen={openWarningPopup.state}
          infoContent={
            <div>
              <Typography variant="h4" color="primary" className="!inline">
                {openWarningPopup?.details?.newHormoneName}{' '}
              </Typography>
              <Typography variant="h4" className="!inline">
                could not be added because{' '}
              </Typography>
              <Typography variant="h4" color="primary" className="!inline">
                {openWarningPopup?.details?.name}
              </Typography>{' '}
              <Typography variant="h4" className="!inline">
                is already there from{' '}
              </Typography>
              <Typography variant="h4" color="primary" className="!inline">
                {openWarningPopup?.details?.family}
              </Typography>{' '}
              <Typography variant="h4" className="!inline">
                family. Please remove it to add this one.{' '}
              </Typography>
            </div>
          }
          closeModal={() => {
            setOpenWarningPopup({ ...openWarningPopup, state: false });
            setTimeout(
              () => setOpenWarningPopup({ state: false, details: undefined }),
              300
            );
          }}
        />
      </Suspense>
    </div>
  );
}
