import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';

import { PrescriptionSlider } from './PrescriptionSlider';

export type SigGeneratorProps = {
  value: string;
  onChange: (val: string) => void;
  error?: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  doseOptions: (number | string)[];
  doseValue: number;
  maximumDoseValue: number;
  maximumDoseOnChange: (val: number) => void;
  unit?: string;
  sigUnit?: string;
};

export function SigGenerator({
  value,
  onChange,
  unit,
  error,
  setError,
  doseOptions,
  doseValue,
  maximumDoseValue,
  maximumDoseOnChange,
  sigUnit,
}: SigGeneratorProps) {
  const [showSigAlterPopup, setShowSigAlterPopup] = useState({
    state: false,
    shown: false,
  });
  const [dosePreValue, setDosePreValue] = useState(0);
  const getToolTip = useCallback(
    (val: number) => `${`${doseOptions[val]} ${unit ?? 'ml/day'}`}`,

    [doseOptions, unit]
  );

  useEffect(() => {
    if (maximumDoseValue === 0) {
      onChange(
        `${unit ? 'Take' : 'Apply'} ${doseOptions[0]} ${
          unit
            ? `${unit?.replace('/', ' per ')} by mouth`
            : 'ml per day topically'
        }`
      );
    } else {
      onChange(
        `${unit ? 'Take' : 'Apply'} ${doseOptions[0]} ${
          unit?.replace('/', ' per ') ??
          `ml ${sigUnit ? 'per day ' : ''}topically`
        } and increase as directed up to ${doseOptions[maximumDoseValue]} ${
          unit
            ? `${unit?.replace('/', ' per ')} by mouth`
            : 'ml per day topically'
        }`
      );
    }
    // eslint-disable-next-line
  }, [maximumDoseValue, doseValue]);

  useEffect(() => {
    if (error) {
      if (value) {
        setError(false);
      }
    }
  }, [value, setError, error]);

  useEffect(() => {
    setDosePreValue(doseValue);
    if (maximumDoseValue === 0) {
      maximumDoseOnChange(0);

      return;
    }

    if (dosePreValue < doseValue) {
      maximumDoseOnChange(maximumDoseValue - 1);

      return;
    }

    if (dosePreValue > doseValue) {
      maximumDoseOnChange(maximumDoseValue + 1);
    }

    // eslint-disable-next-line
  }, [doseValue]);

  return (
    <div className=" col-span-9 flex flex-col gap-2">
      <Typography className="mb-1 " variant="h5">
        Generate the SIG for patient and pharmacy
      </Typography>
      <div
        className={classNames(
          'mt-10 grid h-8 grid-cols-9 items-center gap-x-8'
        )}>
        {doseOptions.length === 1 ? (
          <div className="col-span-9 -mt-4 grid grid-cols-9">
            {' '}
            <div className="col-span-2 text-left xl:col-span-1">
              <Typography className="">
                <div className="!text-sm">
                  Maximum <span className="whitespace-nowrap">Daily Dose</span>
                </div>
              </Typography>
            </div>
            <div className="col-span-7 xl:col-span-8">
              <Typography
                variant="h5"
                color="white"
                className="ml-2 max-w-min whitespace-nowrap rounded bg-primary-dark  p-2">
                {`${doseOptions[0]} ${unit ?? 'ml/day'}`}
              </Typography>
            </div>
          </div>
        ) : (
          <PrescriptionSlider
            label={
              <div className="!text-sm">
                Maximum <span className="whitespace-nowrap">Daily Dose</span>
              </div>
            }
            value={maximumDoseValue}
            onChange={maximumDoseOnChange}
            getTooltip={getToolTip}
            options={[...doseOptions]}
          />
        )}
      </div>
      <div
        className={classNames(
          ' mt-4 grid h-8 grid-cols-9 items-center gap-x-8'
        )}>
        <div className="col-span-9  grid grid-cols-9 items-center gap-x-12">
          <div className="col-span-2 -mt-1 text-left  text-sm font-semibold xl:col-span-1  ">
            <Typography className="mb-6" variant="h5">
              SIG
            </Typography>
          </div>
          <div className="col-span-7 xl:col-span-8">
            <TextInput
              value={value}
              onChange={(val) => {
                if (showSigAlterPopup?.shown) {
                  onChange(val);
                } else {
                  setShowSigAlterPopup({
                    state: true,
                    shown: true,
                  });
                }
              }}
              fullWidth
              className=""
              error={error}
              helperText={error ? 'SIG is required' : ''}
              placeholder="Please enter sig"
            />
          </div>
        </div>
      </div>
      <Modal
        closeModal={() => {
          //
        }}
        open={showSigAlterPopup.state}>
        <div
          className={classNames(
            ' m-2 flex  w-full  flex-col items-center overflow-y-hidden '
          )}>
          Altering the SIG is likely to lead to prescription rejection. Please
          communicate specific instructions with your patient directly.
          <Button
            onClick={() => {
              setShowSigAlterPopup({
                state: false,
                shown: true,
              });
            }}
            className="mt-4 w-20">
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}
