/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import { Control, UseFormGetValues } from 'react-hook-form';

import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';

type SupervisingProviderDetailsProps = {
  control: Control<any, any>;
  getValidationErrors: (fieldName: string) => any;
  getValues: UseFormGetValues<any>;
  states: { value: string; title: string }[];
};

export function SupervisingProviderDetails({
  control,
  getValidationErrors,
  getValues,
  states,
}: SupervisingProviderDetailsProps) {
  const getProviderSuffixesQuery = useQuery(
    [QueryKeys.ProviderSuffixes],
    () => UserService.getProviderSuffixes(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const suffixesOptions = getProviderSuffixesQuery.data?.data.suffixes.map(
    (s) => ({
      title: s,
      value: s,
    })
  );

  return (
    <div className="flex flex-col gap-1">
      <div className="w-100 mt-4 mb-2 flex h-10 items-center rounded bg-primary-main p-3">
        <Typography color="white" variant="body1">
          Please provide the details of your supervising provider
        </Typography>
      </div>
      <DropDown
        required
        label="Title"
        placeholder="Select title"
        fullWidth
        theme={ThemeName.Light}
        options={suffixesOptions}
        defaultValue={suffixesOptions?.find(
          (suffix) => suffix.value === getValues('suffix')
        )}
        control={control}
        name="suffix"
        helperText={getValidationErrors('suffix').msg}
        error={getValidationErrors('suffix').hasError}
      />

      <div className="flex gap-4">
        <TextInput
          required
          label="First Name"
          className=" pl-2"
          type="text"
          name="firstName"
          control={control}
          fullWidth
          placeholder="First Name"
          helperText={getValidationErrors('firstName').msg}
          error={getValidationErrors('firstName').hasError}
        />
        <TextInput
          label="Middle Name"
          className=" pl-2"
          type="text"
          name="middleName"
          control={control}
          fullWidth
          placeholder="Middle Name"
          helperText={getValidationErrors('middleName').msg}
          error={getValidationErrors('middleName').hasError}
        />
        <TextInput
          required
          label="Last Name"
          className=" pl-2"
          type="text"
          name="lastName"
          control={control}
          fullWidth
          placeholder="Last Name"
          helperText={getValidationErrors('lastName').msg}
          error={getValidationErrors('lastName').hasError}
        />
      </div>
      <TextInput
        required
        label="NPI Number"
        name="npi"
        control={control}
        fullWidth
        className=" "
        placeholder="Enter NPI number"
        helperText={getValidationErrors('npi').msg}
        error={getValidationErrors('npi').hasError}
      />
      {getValues('licenseState') ? (
        <Tooltip id="licenseState">
          Your supervising provider’s DEA & License must be of state{' '}
          {states.find((s) => s.value === getValues('licenseState'))?.title}
        </Tooltip>
      ) : null}

      <div data-tooltip-id="licenseState">
        <DropDown
          theme={ThemeName.Light}
          searchable
          label="Select State"
          name="licenseState"
          placeholder="Select State"
          fullWidth
          defaultValue={states.find(
            (s) => s.value === getValues('licenseState')
          )}
          disabled
          control={control}
          options={states || []}
        />
      </div>

      <div className="flex gap-4">
        <TextInput
          required
          label="DEA number"
          control={control}
          fullWidth
          placeholder="Enter DEA number"
          name="supervisingDea"
          helperText={getValidationErrors('supervisingDea').msg}
          error={getValidationErrors('supervisingDea').hasError}
        />
        <TextInput
          required
          label="License Number"
          name="licenseNumber"
          control={control}
          fullWidth
          className=" "
          placeholder="Enter license number"
          helperText={getValidationErrors('licenseNumber').msg}
          error={getValidationErrors('licenseNumber').hasError}
        />
      </div>
    </div>
  );
}
