import React, { useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';

import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { useTestosteroneCypeController } from 'hooks/hormones-new/useTestosteroneCypeController';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function TestosteroneCyp({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();

  const {
    TestosteroneCypeDetails,

    dtd,

    refills,
    setRefills,

    setSig,
    sig,
    dose,
    doseUnit,
  } = useTestosteroneCypeController();

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: TestosteroneCypeDetails.displayName,
      name: HormoneNames.TestosteroneCyp,
      strength: TestosteroneCypeDetails.strength,
      strengthUnit: TestosteroneCypeDetails.strengthUnit,
      dose,
      doseUnit,
      refills,
      dtd,
      sig,
      deliveryMethod: TestosteroneCypeDetails.deliveryMethod,
      family: TestosteroneCypeDetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: TestosteroneCypeDetails.dtdUnit,
    }),
    [RxDetails, dtd, sig, refills, TestosteroneCypeDetails, dose, doseUnit]
  );

  const stadardizedNamePrescriptionObject = useMemo(
    () => ({
      prescription: {
        ...prescriptionObject,
        dose: TestosteroneCypeDetails.dose,
      },
    }),
    [prescriptionObject, TestosteroneCypeDetails]
  );

  const { getStandardizedNameQuery } = useHormoneStandardizedName(
    stadardizedNamePrescriptionObject
  );

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),

    [getStandardizedNameQuery.data?.data.standardRepresentation]
  );
  //

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
        })
      }
      validations={{
        [HormoneValidations.Loading]: {
          isFailed: getStandardizedNameQuery.isLoading,
        },
        [HormoneValidations.MissingDEA]: {
          isFailed:
            !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: false,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(sig.length) > 140,
        },
      }}
      prescriptionObject={{ ...prescriptionObject, ...prescriptionCheckValues }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex justify-between',
          title: '',
          children: [
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${TestosteroneCypeDetails.strength} ${TestosteroneCypeDetails.strengthUnit}`,
              label: 'Strength',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ml`,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dose} ${doseUnit}`,
              label: 'Dose',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: TestosteroneCypeDetails.deliveryMethod,
              label: 'Delivery Method',
            },
          ],
        },

        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${TestosteroneCypeDetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: TestosteroneCypeDetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setSig(s);
              },
              value: sig,
            },
          ],
        },
      ]}
    />
  );
}
