import { Dispatch, SetStateAction } from 'react';

import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { SupervisingProvider } from 'types/provider';

type SupervisingProviderListingModalProps = {
  showSupervisingProviderListingModal: boolean;
  setShowSupervisingProviderListingModal: Dispatch<SetStateAction<boolean>>;
  supervisingProvider: SupervisingProvider | undefined;
  supervisingProviderState: string | undefined;
};

export function SupervisingProviderListingModal({
  showSupervisingProviderListingModal,
  setShowSupervisingProviderListingModal,
  supervisingProvider,
  supervisingProviderState,
}: SupervisingProviderListingModalProps) {
  if (!supervisingProvider) {
    return null;
  }

  return (
    <Modal
      className="w-[70%] overflow-visible rounded"
      open={showSupervisingProviderListingModal}
      closeModal={() => {
        //
      }}>
      <div>
        <Typography className="mb-3" variant="subtitle2">
          {' '}
          Your supervising provider details for the state of&nbsp;
          <Typography color="primary" variant="subtitle2">
            {`${supervisingProviderState}`}.
          </Typography>
        </Typography>
        <Table
          rowsData={[supervisingProvider] || []}
          columns={[
            {
              title: 'Name',
              render: (rowData) =>
                `${rowData?.licensedPrescriberFirstName} ${
                  rowData?.licensedPrescriberMiddleName || ''
                } ${rowData?.licensedPrescriberLastName} (${
                  rowData.licensedPrescriberSuffix
                })`,
              classNames: 'text-center',
            },
            {
              title: 'NPI',
              render: (rowData) => rowData.npi,
              classNames: 'text-center',
            },
            {
              title: 'DEA',
              render: (rowData) => rowData.dea,
              classNames: 'text-center',
            },

            {
              title: 'License',
              render: (rowData) => rowData.licenseNumber,
              classNames: 'text-center',
            },

            {
              title: 'State',
              render: () => supervisingProviderState || '',
              classNames: 'text-center',
            },
          ]}
          noDataMessage=" You currently have no records"
        />

        <Typography className="my-8">
          Note - Please ensure that your name matches the one registered in the
          National Provider Identifier (NPI) database.
        </Typography>

        <div className="mt-6 flex justify-end">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setShowSupervisingProviderListingModal(false)}>
            {' '}
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}
