import { useEffect, useState } from 'react';

import { DeliveryMethods } from 'constants/delivery-methods';
import { femaleHormoneRxTypes } from 'constants/hormone-details';

export const useProgesteroneController = () => {
  const [mgPerMl, setMgPerMl] = useState(200);
  const [mlPerDay, setMlPerDay] = useState(0.05);
  const [deliveryMethod, setDeliveryMethod] = useState(
    DeliveryMethods.EyeDropper.name
  );
  const [drops, setDrops] = useState(2);
  const [refills, setRefils] = useState(0);
  const [DTD, setDTD] = useState(30);
  const [sig, setSig] = useState(
    deliveryMethod === DeliveryMethods.EyeDropper.name
      ? 'Take 1-20 drops at sleep time as directed'
      : 'Take 0.05 - 2ml at sleep time as directed '
  );

  const dropsPerML = 40;

  useEffect(() => {
    setSig(
      deliveryMethod === DeliveryMethods.EyeDropper.name
        ? 'Take 1-20 drops at sleep time as directed'
        : 'Take 0.05 - 2ml at sleep time as directed '
    );
  }, [deliveryMethod]);

  const getCourseDuration = () => {
    let eachBottleDuration = 1;
    let refillsDuration = 1;
    if (deliveryMethod === DeliveryMethods.EyeDropper.name) {
      const totalDrops = dropsPerML * DTD;
      eachBottleDuration = totalDrops / 30 / drops;
    } else {
      const totalMlInOneMonth =
        femaleHormoneRxTypes.progesterone.mlPerDay[mlPerDay] * 30;
      eachBottleDuration = DTD / totalMlInOneMonth;
    }
    refillsDuration = eachBottleDuration * (refills + 1);

    return {
      eachBottleDuration: eachBottleDuration.toFixed(1),
      durationWithRefills: refillsDuration.toFixed(1),
    };
  };

  return {
    mgPerMl,
    getCourseDuration,
    deliveryMethod,
    sig,
    setSig,
    drops,
    refills,
    setMlPerDay,
    mlPerDay,
    DTD,
    setDTD,
    DTDInWords: `${DTD} ml`,
    setDeliveryMethod,
    setMgPerMl,
    setRefils,
    setDrops,
  };
};
