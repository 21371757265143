import { HormoneNames } from 'types/prescriptionTypes';

export const diagnosticHormone = [];

export const diagnosisWomen = {
  hormone: [
    HormoneNames.BI_EST,
    HormoneNames.PROGESTERONE,
    HormoneNames.DHEA,
    HormoneNames.TESTOSTERONE_WOMEN,
    HormoneNames.DHEA_ORAL,
    HormoneNames.ORAL_PROGESTERONE,
    HormoneNames.TESTO_PER_DHEA,
    HormoneNames.TADALAFIL,
    HormoneNames.SILDENAFIL,
    HormoneNames.Danazol,
  ],

  thyroid: [
    HormoneNames.LEVOTHYROXINE,
    HormoneNames.LIOTHYRONINE_EXT,
    HormoneNames.T4_PER_LAT3,
  ],
};

export const diagnosisMen = {
  thyroid: [
    HormoneNames.LEVOTHYROXINE,
    HormoneNames.LIOTHYRONINE_EXT,
    HormoneNames.T4_PER_LAT3,
  ],

  hormone: [
    HormoneNames.TESTOSTERONE_MEN,
    HormoneNames.TADALAFIL,
    HormoneNames.SILDENAFIL,
    HormoneNames.Danazol,
    HormoneNames.TestosteroneCyp,
    HormoneNames.Pregnyl,
  ],
};
