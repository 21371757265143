import { APIService } from 'services/api-service';

import { HarsNS } from './types';

export class HarsService extends APIService {
  static GET_HARS_STATS = `${this.API_PREFIX_URL}/hars/`;
  static getHarsStats = async (patientId: string) => {
    const url = new URL(this.GET_HARS_STATS);
    url.searchParams.append('patient_id', patientId);

    return this.get<HarsNS.PatientHarsStats>(url.toString(), {
      withAuthToken: true,
    });
  };
}
