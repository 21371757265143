import { useState } from 'react';

import { maleHormoneRxTypes } from 'constants/hormone-details-new';
import { useSIG } from 'hooks/hormones/useSIG';

export const usePregnylController = () => {
  const PregnylDetails = maleHormoneRxTypes?.['pregnyl-(injectable)(hcg)'];

  const [refills, setRefills] = useState(0);
  const [dtd, setDTD] = useState(10);
  const { sig, setSig } = useSIG(
    'Inject 0.5ml (500lU) subcutaneously twice weekly as directed by prescriber'
  );
  const [note, setNote] = useState('');

  const [dose] = useState('0.5');
  const dtdUnit = 'ml';
  const doseUnit = 'cc';

  return {
    PregnylDetails,
    dose,
    dtdUnit,
    doseUnit,
    sig,
    setSig,
    setDTD,

    dtd,
    refills,
    setRefills,
    note,
    setNote,
  };
};
