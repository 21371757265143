import { useState } from 'react';

import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';

export const useOralProgesteroneController = () => {
  const OralProgesteroneDetails =
    femaleHormoneRxTypes?.['oral-micronized-progesterone'];
  const [strength, setStrength] = useState(0);
  const [maximumDose, setMaximumDose] = useState(0);

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(60, OralProgesteroneDetails.dtd);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');

  return {
    OralProgesteroneDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,

    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  };
};
