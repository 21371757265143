import React from 'react';

import classNames from 'classnames';

import { ThemeName } from 'theme/types';

import { DropDown } from '../DropDown/DropDown';
import { Pagination } from '../Pagination/Pagination';
import { Typography } from '../Typography/Typography';
import { TableHeadItem } from './TableHeadItem';
import { TableRow } from './TableRow';
import { TableSkeleton } from './TableSkeleton';
import { ColumnObject } from './types';
// eslint-disable-next-line import/extensions
import './TableStyle.css';

type PaginationProps =
  | {
      pagination?: true;
      page: number;
      totalRecords: number;
      onPageChange: (page: number) => void;
    }
  | {
      pagination?: false;
      page?: never;
      totalRecords?: never;
      onPageChange?: never;
    };

type TableProps<TItem> = {
  columns: ColumnObject<TItem>[];
  rowsData: TItem[];
  loading?: boolean;
  onRowClick?: (item: TItem) => void;
  recordsPerPage?: number;
  noDataMessage?: string;
  isSticky?: boolean;
  expandedAccordions?: number[];
  onAccordionExpandChange?: (index: number[]) => void;
  theme?: ThemeName;
  getAccordionContent?: (item: TItem) => JSX.Element;
  tableClassName?: string;
  setRecordsPerPage?: React.Dispatch<number>;
  isPageSizeSelectionAvailable?: boolean;
} & PaginationProps;

export function Table<TItem>({
  rowsData,
  columns,
  pagination,
  loading = false,
  onRowClick,
  page,
  totalRecords,
  onPageChange,
  recordsPerPage = 4,
  noDataMessage,
  isSticky = false,
  expandedAccordions,
  onAccordionExpandChange,
  getAccordionContent,
  theme = ThemeName.Dark,
  tableClassName,
  setRecordsPerPage,
  isPageSizeSelectionAvailable = false,
}: TableProps<TItem>) {
  const availablePageSize = [
    {
      value: '10',
      title: '10',
    },
    {
      value: '25',
      title: '25',
    },
  ];

  return (
    <div className="relative overflow-auto rounded">
      <div
        className={classNames(
          'table-scrollbar custom-scrollbar relative overflow-auto',
          tableClassName
        )}>
        <table
          className={classNames(
            'relative w-full !border-separate !border-spacing-0 text-left text-sm',
            {
              'rounded-md': !pagination,
            }
          )}>
          <thead className=" sticky top-0 z-[20] rounded bg-primary-main font-normal text-primary-contrastText">
            <tr className="">
              {columns.map((column: ColumnObject<TItem>, index) => (
                <TableHeadItem<TItem>
                  isSticky={
                    isSticky && [columns.length - 1, 0, 1].includes(index)
                  }
                  columnIndex={index}
                  columnsLength={columns.length}
                  key={(column.title, toString())}
                  column={column}
                />
              ))}
            </tr>
          </thead>
          <tbody className=" z-[10]">
            {loading ? (
              <TableSkeleton<TItem>
                theme={theme}
                recordsPerPage={recordsPerPage}
                columns={columns}
              />
            ) : (
              rowsData?.map((item: TItem, index: number) => (
                <TableRow<TItem>
                  // eslint-disable-next-line
                  key={index}
                  item={item}
                  onRowClick={onRowClick}
                  columns={columns}
                  isSticky={isSticky && !loading}
                  expandedAccordions={expandedAccordions}
                  onAccordionExpandChange={onAccordionExpandChange}
                  getAccordionContent={getAccordionContent}
                  rowIndex={index}
                />
              ))
            )}
          </tbody>
        </table>
        {rowsData[0] || loading ? null : (
          <div className="flex h-10 w-full justify-center bg-background-main">
            <Typography variant="subtitle1" color="textMuted">
              {noDataMessage}
            </Typography>
          </div>
        )}
      </div>
      {pagination && rowsData[0] && (
        <div className="flex w-full items-center justify-end gap-4 rounded-b border-t border-background-light bg-background-main p-3">
          {isPageSizeSelectionAvailable ? (
            <DropDown
              defaultValue={{
                title: recordsPerPage.toString(),
                value: recordsPerPage,
              }}
              onChange={(s) => {
                setRecordsPerPage?.(s.value as number);
                onPageChange(1);
              }}
              disableHelperText
              options={availablePageSize}
              placeholder="Rows per page"
              className="!w-20"
              placement="top"
              listClassName="!pl-1"
            />
          ) : null}
          <Pagination
            page={page}
            recordsPerPage={recordsPerPage}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}
