import React, { PropsWithChildren } from 'react';

import {
  FallbackRender,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react';

import { ErrorFallbackComponent } from 'components/general/ErrorFallbackComponent/ErrorFallbackComponent';

interface ErrorBoundaryProps {
  renderFallback?: FallbackRender | React.ReactElement;
}

export function ErrorBoundary({
  children,
  renderFallback = () => <ErrorFallbackComponent />,
}: PropsWithChildren<ErrorBoundaryProps>) {
  return (
    <SentryErrorBoundary
      key={window.location.pathname}
      fallback={renderFallback}>
      {children}
    </SentryErrorBoundary>
  );
}
