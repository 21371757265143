import React, { useCallback, useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useDebounce } from 'hooks/useDebounce';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { PatientService } from 'services/patient';
import { Option } from 'types/inputTypes';
import { AllergyType } from 'types/patient';

type KnownAllergiesProps = {
  allergiesByPatient?: string;
  allergiesByProvider?: AllergyType[] | null;
  patientId: string;
};

export function KnownAllergies({
  allergiesByPatient,
  allergiesByProvider,
  patientId,
}: KnownAllergiesProps) {
  const queryClient = useQueryClient();

  const [allergy, setAllergy] = useState<Option>();
  const [allergies, setAllergies] = useState<AllergyType[]>(
    allergiesByProvider ?? []
  );
  const [clearState, setClearState] = useState(false);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [severity, setSeverity] = useState<string>();
  const debouncedValue = useDebounce<string>(searchValue, 300);
  const [noAllergies, setNoAllergies] = useState(false);

  const updateAllergiesMedicationMutation = useMutationWithToast(
    PatientService.updatePatientAllergiesMedication
  );
  const getAllergiesQuery = usePaginatedQuery(
    QueryKeys.Allergies.listing({ search: debouncedValue }),
    ({ pageParam = 1 }) =>
      PatientService.getPatientAllergiesList({
        search: debouncedValue,
        page: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const severityType = [
    { title: 'Unspecified', value: 'Unspecified' },
    { title: 'Mild', value: 'Mild' },
    { title: 'Moderate', value: 'Moderate' },
    { title: 'Severe', value: 'Severe' },
  ];

  function deleteAllergy(index: number) {
    updateAllergies(allergies.filter((i, indx) => indx !== index));
    setAllergies(allergies.filter((i, indx) => indx !== index));
    if (allergies?.[0]?.id) {
      setNoAllergies(true);
    }
  }

  const updateAllergies = useCallback(
    (newAllergies: AllergyType[]) =>
      updateAllergiesMedicationMutation.mutate(
        {
          id: patientId,
          patientKnownAllergiesNc: [...newAllergies],
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(
              QueryKeys.PatientAllergiesMedication.item(patientId)
            );
            setSearchValue('');
            setError(false);
            setSeverity(undefined);
            setAllergy(undefined);
          },
        }
      ),
    [queryClient, updateAllergiesMedicationMutation, patientId]
  );

  useEffect(() => {
    if (allergiesByProvider === null || allergiesByProvider === undefined) {
      setNoAllergies(false);
    } else if (!allergiesByProvider?.[0]?.id) {
      setNoAllergies(true);
    }
  }, [allergiesByProvider]);

  return (
    <div className=" flex w-full  grow flex-col  rounded bg-background-main p-6 ">
      {' '}
      <Typography variant="h4">Known Allergies</Typography>
      <div className="mt-6">
        <Typography variant="h5">
          Patient provided following as known allergies.
        </Typography>
        <div data-tooltip-id="knownAllergiesByPatient">
          <TextInput
            disabled
            id="knownAllergy"
            value={allergiesByPatient}
            className="mt-1 pl-2"
            type="text"
            name="patientKnownAllergies"
            fullWidth
            placeholder="Enter known allergies"
          />
        </div>
        <Tooltip id="knownAllergiesByPatient">{allergiesByPatient}</Tooltip>
      </div>
      <CheckBox
        onChange={(v) => {
          if (v) {
            updateAllergies([]);
            setAllergies([]);
          }
          setNoAllergies(v);
        }}
        checked={noAllergies}
        id="knownLAllergies"
        label="No Known Allergies"
      />
      {!noAllergies && (
        <div className="mt-4">
          <Typography variant="h5">
            Please select relevant known allergies from the list below.
          </Typography>
          <div className="mt-1 flex items-start gap-x-2 ">
            <DropDown
              clearDropdownState={clearState}
              fullWidth
              className="grow-[2]"
              onChange={(v) => {
                setAllergy(v);
                setClearState(false);
                setError(false);
              }}
              withInfiniteScroll
              searchable
              placeholder="Select known allergy"
              label=""
              options={getAllergiesQuery.data?.pages.map((i) => ({
                title: i.description,
                value: i?.compositeAllergyId,
              }))}
              onSearchChange={(text) => {
                setSearchValue(text);
              }}
              onNextPage={() => {
                getAllergiesQuery.fetchNextPage();
              }}
              isOptionsLoading={getAllergiesQuery.isLoading}
              isFetchingNextPage={getAllergiesQuery.isFetchingNextPage}
              error={error}
              helperText={error ? 'Allergy already exists' : ''}
            />
            <DropDown
              clearDropdownState={clearState}
              onChange={(v) => setSeverity(v.value as string)}
              placeholder="Select severity"
              className="grow-[1] "
              disableHelperText
              options={severityType}
            />
            <Button
              disabled={!allergy && !severity}
              onClick={() => {
                if (allergy && severity) {
                  if (allergies.some((a) => a.id === allergy.value)) {
                    setError(true);

                    return;
                  }
                  updateAllergies([
                    ...allergies,
                    {
                      title: allergy.title,
                      severity,
                      id: allergy.value as string,
                    },
                  ]);
                  setAllergies([
                    ...allergies,
                    {
                      title: allergy.title,
                      severity,
                      id: allergy.value as string,
                    },
                  ]);
                  setClearState(true);
                  setAllergy(undefined);
                  setSeverity(undefined);
                }
              }}
              className="!py-3"
              size="large">
              ADD
            </Button>
          </div>
        </div>
      )}
      <div className="flex  flex-col overflow-auto ">
        <div className="">
          {allergies?.[0]?.id && (
            <div className=" flex h-40 flex-col gap-y-1  overflow-auto rounded-b">
              {allergies?.map((a, index) => (
                <div className=" grid grid-cols-12 rounded border border-dashed  border-primary-main bg-background-main p-2 ">
                  <Typography
                    variant="subtitle2"
                    className="col-span-8 pl-5 pr-1"
                    ellipsis>
                    {a.title}
                  </Typography>{' '}
                  <Typography className="col-span-3" variant="subtitle2">
                    {a.severity}
                  </Typography>{' '}
                  <TrashIcon
                    onClick={() => deleteAllergy(index)}
                    id={a.id}
                    className="col-span-1 h-5 w-5 cursor-pointer font-extrabold text-error-main "
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
