import { useMemo } from 'react';

import { DoseSplittingMethods, HormoneNames } from 'types/prescriptionTypes';

type DoseSplitterProps = {
  dose: string;
  type: DoseSplittingMethods | undefined;
  hormoneName: HormoneNames;
};

export function useDoseSplitter({
  dose: doseInString,
  type,
  hormoneName,
}: DoseSplitterProps) {
  function splitDoseForMorningandEvening(dose: number) {
    // Convert the dose to a number with precision to avoid floating-point issues
    const preciseDose = parseFloat(dose.toFixed(2));

    // Calculate half of the dose
    const halfDose = parseFloat((preciseDose / 2).toFixed(2));

    // Check if the dose is even by checking if the dose * 100 is divisible by 2
    const isEven = (preciseDose * 100) % 2 === 0;

    if (isEven) {
      // If the dose is even, split it equally
      return {
        morning: halfDose.toFixed(2),
        evening: halfDose.toFixed(2),
      };
    }

    // If the dose is odd, adjust the split
    // Morning dose is the smaller portion (floor to 1 decimal place)
    const baseDose = Math.floor(dose * 10) / 10;
    // Calculate the remaining dose
    const extraRemainingDose = dose - baseDose;
    // Add the base dose and remaining dose

    const splittedDose = baseDose / 2;

    return {
      morning: splittedDose.toFixed(2).toString(),
      evening: (splittedDose + extraRemainingDose).toFixed(2).toString(),
    };
  }

  function splitDoseAsHormoneName() {
    const dose = Number(doseInString);
    const dosage = splitDoseForMorningandEvening(dose);
    switch (hormoneName) {
      case HormoneNames.BI_EST:
        return {
          morning: Number(dosage.morning).toFixed(2).toString(),
          evening: Number(dosage.evening).toFixed(2).toString(),
        };

      case HormoneNames.PROGESTERONE:
        return { morning: '0', evening: dose.toFixed(2) };

      case HormoneNames.TESTOSTERONE_WOMEN:
      case HormoneNames.TESTO_PER_DHEA:
      case HormoneNames.DHEA:
        return { morning: dose.toFixed(2), evening: '0' };

      default:
        return {
          morning: (dose / 2).toFixed(2),
          evening: (dose / 2).toFixed(2),
        };
    }
  }
  //

  function splitDose() {
    const dose = Number(doseInString);

    switch (type) {
      case DoseSplittingMethods.MorningAndEvening:
        return splitDoseForMorningandEvening(dose);

      case DoseSplittingMethods.Morning:
        return {
          morning: dose.toFixed(2).toString(),
          evening: '0',
        };

      case DoseSplittingMethods.Evening:
        return {
          morning: '0',
          evening: dose.toFixed(2).toString(),
        };

      default:
        return splitDoseAsHormoneName();
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const splittedDose = useMemo(() => splitDose(), [doseInString, type]);

  return { splittedDose };
}
