import React, { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { QueryKeys } from 'constants/query-keys';
import { supervisingDoctorSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useProfile } from 'hooks/useProfile';
import { extractValidationErrors } from 'lib/general/extractors';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { State, SupervisingProvider } from 'types/provider';

type SupervisingProviderModalProps = {
  states: State[];
  isModalOpen: boolean;
  onModalClose: (success?: boolean) => void;
  defaultValues?: SupervisingProvider;
  isUpdating?: boolean;
  supervisingProviderId?: string;
  state?: string;
  visibleStates?: string[];
};

export function SupervisingProviderModal({
  isModalOpen,
  onModalClose,
  states,
  state: disabledState,
  isUpdating,
  defaultValues,
  supervisingProviderId,
  visibleStates,
}: SupervisingProviderModalProps) {
  const { userProfileQuery } = useProfile();

  const getProviderSuffixesQuery = useQuery(
    [QueryKeys.ProviderSuffixes],
    () => UserService.getProviderSuffixes(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const addSupervisingProviderMutation = useMutationWithToast(
    UserService.addSupervisingDoctor
  );
  const updateSupervisingProviderMutation = useMutationWithToast(
    UserService.updateSupervisingDoctor
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>({
    resolver: yupResolver(supervisingDoctorSchema),
    defaultValues: {
      licenseState: disabledState,
    },
  });

  useEffect(() => {
    if (!disabledState) return;
    setTimeout(() => {
      setValue('licenseState', disabledState);
    }, 100);
  }, [disabledState, setValue]);

  useEffect(() => {
    if (isUpdating && defaultValues) {
      reset({
        dea: defaultValues?.dea,
        firstName: defaultValues?.licensedPrescriberFirstName,
        lastName: defaultValues?.licensedPrescriberLastName,
        middleName: defaultValues?.licensedPrescriberMiddleName,
        suffix: defaultValues?.licensedPrescriberSuffix,
        licenseNumber: defaultValues?.licenseNumber,
        licenseState: defaultValues?.licenseState,
        npi: defaultValues?.npi,
      });

      return;
    }
    reset(
      {
        dea: '',
        firstName: '',
        lastName: '',
        middleName: '',
        suffix: '',
        licenseNumber: '',
        npi: '',
        licenseState: disabledState ?? '',
      },
      {
        keepValues: false,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating, defaultValues]);

  const onSubmit = handleSubmit((data) => {
    const formData = {
      dea: data.dea,
      firstName: data.firstName,
      lastName: data.lastName,
      licenseNumber: data.licenseNumber,
      licenseState: data.licenseState,

      middleName: data.middleName,
      suffix: data.suffix,
      npi: data.npi,
    };
    if (!isUpdating) {
      addSupervisingProviderMutation.mutate(
        {
          supervisingProvider: { ...formData },
          require: true,
        },
        {
          onSuccess: () => {
            userProfileQuery.refetch().then(() => {
              onCloseClick(true);
            });
          },
        }
      );

      return;
    }
    updateSupervisingProviderMutation.mutate(
      {
        supervisingProvider: { ...formData },
        supervisingProviderId: supervisingProviderId || '',
      },
      {
        onSuccess: () => {
          userProfileQuery.refetch().then(() => {
            onCloseClick(!!isUpdating);
          });
        },
      }
    );
  });

  const getValidationErrors = (fieldName: string) =>
    extractValidationErrors(
      fieldName,
      isUpdating
        ? updateSupervisingProviderMutation
        : addSupervisingProviderMutation,
      errors
    );

  const suffixesOptions = getProviderSuffixesQuery.data?.data.suffixes.map(
    (s) => ({
      title: s,
      value: s,
    })
  );
  const statesOptions = states.map((s) => ({ title: s.name, value: s.id }));

  const isLoading =
    updateSupervisingProviderMutation.isLoading ||
    addSupervisingProviderMutation.isLoading ||
    userProfileQuery.isLoading;

  const onCloseClick = (success: boolean) => {
    reset();

    updateSupervisingProviderMutation.reset();
    addSupervisingProviderMutation.reset();
    onModalClose(success);
  };

  const StateDefault = useMemo(
    () =>
      statesOptions.find((state) => state.value === getValues('licenseState')),
    [statesOptions, getValues]
  );

  return (
    <Modal
      title="Supervising Provider Details"
      open={isModalOpen}
      className="w-[55%] overflow-visible"
      closeModal={() => {
        if (isLoading) return;
        onModalClose();
      }}>
      <div className="flex flex-col gap-1">
        <div className="mt-4 flex gap-4">
          <DropDown
            required
            label="Title"
            placeholder="Select title"
            className="w-[55%]"
            theme={ThemeName.Light}
            options={suffixesOptions}
            defaultValue={suffixesOptions?.find(
              (suffix) => suffix.value === getValues('suffix')
            )}
            control={control}
            name="suffix"
            helperText={getValidationErrors('suffix').msg}
            error={getValidationErrors('suffix').hasError}
          />
          <TextInput
            required
            label="First Name"
            className=" pl-2"
            type="text"
            name="firstName"
            control={control}
            fullWidth
            placeholder="First Name"
            helperText={getValidationErrors('firstName').msg}
            error={getValidationErrors('firstName').hasError}
          />
          <TextInput
            label="Middle Name"
            className=" pl-2"
            type="text"
            name="middleName"
            control={control}
            fullWidth
            placeholder="Middle Name"
            helperText={getValidationErrors('middleName').msg}
            error={getValidationErrors('middleName').hasError}
          />
          <TextInput
            required
            label="Last Name"
            className=" pl-2"
            type="text"
            name="lastName"
            control={control}
            fullWidth
            placeholder="Last Name"
            helperText={getValidationErrors('lastName').msg}
            error={getValidationErrors('lastName').hasError}
          />{' '}
        </div>
        <div className="flex gap-4">
          <DropDown
            required
            label="License State"
            placeholder="Select license state"
            theme={ThemeName.Light}
            searchable
            fullWidth
            name="licenseState"
            control={control}
            options={statesOptions?.filter((s) =>
              visibleStates?.includes(s.value)
            )}
            disabled={!!disabledState}
            defaultValue={StateDefault}
            helperText={getValidationErrors('licenseState').msg}
            error={getValidationErrors('licenseState').hasError}
          />
          <TextInput
            required
            label="License Number"
            name="licenseNumber"
            control={control}
            fullWidth
            className=" "
            placeholder="Enter license number"
            helperText={getValidationErrors('licenseNumber').msg}
            error={getValidationErrors('licenseNumber').hasError}
          />{' '}
        </div>
        <div className="flex gap-4">
          <TextInput
            required
            label="DEA number"
            control={control}
            fullWidth
            placeholder="Enter DEA number"
            name="dea"
            helperText={getValidationErrors('dea').msg}
            error={getValidationErrors('dea').hasError}
          />
          <TextInput
            required
            label="NPI Number"
            name="npi"
            control={control}
            fullWidth
            className=" "
            placeholder="Enter NPI number"
            helperText={getValidationErrors('npi').msg}
            error={getValidationErrors('npi').hasError}
          />
        </div>
        <div className="flex gap-4 self-end">
          <Button
            disabled={isLoading}
            color="secondary"
            onClick={() => onCloseClick(!!isUpdating)}>
            Cancel
          </Button>
          <Button disabled={isLoading} loading={isLoading} onClick={onSubmit}>
            {isUpdating ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
