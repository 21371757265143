import React, { ReactNode } from 'react';

import classNames from 'classnames';

type ChipProps = {
  label: ReactNode;
  className?: string;
};

export function Chip({ label, className }: ChipProps) {
  return (
    <span
      className={classNames(
        'rounded-3xl border border-primary-main p-1 px-4 text-md text-background-contrastText',
        className
      )}>
      {' '}
      {label}
    </span>
  );
}
