import React, { PropsWithChildren } from 'react';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';

interface HormoneWrapperProps {
  title: 'string';
  onClick: () => void;
}

export function HormoneWrapper({
  children,
  title,
  onClick,
}: PropsWithChildren<HormoneWrapperProps>) {
  return (
    <div className="relative flex h-full w-full flex-col">
      <div className="mb-6">
        <Typography variant="h3" color="textPrimary">
          Prescribing{' '}
          <Typography
            variant="h3"
            color="white"
            className="mx-2 rounded-sm bg-primary-main px-2">
            {title}
          </Typography>
        </Typography>
      </div>
      <div>{children}</div>
      <div className="absolute bottom-8 right-6">
        <Button className="px-12" onClick={onClick}>
          Add
        </Button>
      </div>
    </div>
  );
}
