import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useTestosteroneWomenController } from 'hooks/hormones-new/useTestosteroneWomenController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type TestosteroneWomenProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function TestosteroneWomen({
  addNewPrescription,
}: TestosteroneWomenProps) {
  const [searchParams] = useSearchParams();

  const medicationId = searchParams.get('id');
  const {
    TestosteroneWomenDetails,

    sig,
    setSig,
    dtd,
    strength,
    setDTD,
    dose,
    setDose,
    refills,
    setRefills,
    setStrength,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useTestosteroneWomenController();
  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || undefined,
    displayName: TestosteroneWomenDetails.displayName,
    name: HormoneNames.TESTOSTERONE_WOMEN,
    strength: `${strength}`,
    strengthUnit: TestosteroneWomenDetails.strengthUnit,

    dose: TestosteroneWomenDetails.dose[dose + maximumDose],
    minimumDose: TestosteroneWomenDetails.dose[dose],
    doseUnit: TestosteroneWomenDetails.doseUnit,
    refills,
    dtd,
    sig,
    deliveryMethod: TestosteroneWomenDetails.deliveryMethod,
    family: TestosteroneWomenDetails.family,
    note,
  };

  return (
    <HormoneDetails
      onPrescriptionValueChange={(value, renderModal) => {
        const a = value.value;

        if (Number(a) > 4.0) {
          setStrength(24);
          if (strength !== 24) {
            setDose(2);
            renderModal(strength.toString(), '24', 'mg/day');
          }
        } else {
          setStrength(8);
          if (strength !== 8) {
            renderModal(strength.toString(), '8', 'mg/day');
          }
        }
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      prescriptionObject={prescriptionObject}
      statusUnit={TestosteroneWomenDetails.statusUnit}
      title={TestosteroneWomenDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Text,
          label: 'Strength',
          value: `${strength} ${TestosteroneWomenDetails.strengthUnit}`,
        },

        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
            if (TestosteroneWomenDetails.dose?.[value] > 0.3) {
              setDTD(30);
            } else {
              setDTD(18);
            }
          },
          options: TestosteroneWomenDetails.dose,
          getTooltip: (val) =>
            `${`${TestosteroneWomenDetails.dose[val].toFixed(2)} ${
              TestosteroneWomenDetails.doseUnit
            }`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: [0],
          getTooltip: (val) =>
            `${TestosteroneWomenDetails.refills[val]} refills`,
          disable: true,
        },

        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: TestosteroneWomenDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
            if (TestosteroneWomenDetails.dose?.[dose + val] > 0.3) {
              setDTD(30);
            } else {
              setDTD(18);
            }
          },
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: TestosteroneWomenDetails.deliveryMethod,
        },
        {
          label: 'D.T.D',
          value: `${dtd.toString()} ml`,
        },
      ]}
    />
  );
}
