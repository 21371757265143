export const DeliveryMethods = {
  Syringe: {
    name: 'Syringe',
  },
  OrificeReducer: {
    name: 'Orifice Reducer',
    variants: { 0.85: 0.85, 1.2: 1.2, 1.8: 1.8 },
  },
  EyeDropper: {
    name: 'Eye Dropper',
  },
  OralSyringe: {
    name: 'Dose applicator (oral syringe)',
  },
  Tablets: {
    name: 'Tablets',
  },
  Capsules: {
    name: 'Capsules',
  },
  Vial: {
    name: 'Vial',
  },
};

export enum DeliveryMethodsEnum {
  OralSyringe = 'Dose applicator (oral syringe)',
  Tablets = 'Tablets',
  Capsules = 'Capsules',
  Vial = 'Vial',
  Syringe = 'Syringe',
}
