import React, { useCallback, useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, isPast, isSameDay } from 'date-fns';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { DayPickerWithInput } from 'components/common/DatePicker/DatePicker';
import { RoundRadioButton } from 'components/common/RoundRadioButton/RoundRadioButton';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { GMTDate } from 'lib/general/GMT-date';
import { PatientService } from 'services/patient';
import { QuestionnaireService } from 'services/questionnaire';
import { SchedulerBody } from 'services/questionnaire/types';
import { ThemeName } from 'theme/types';
import { getReadableDate } from 'utils/common';

type FollowUpSchedulerProps = {
  showErrorMessage?: boolean;
};

export function FollowUpScheduler({
  showErrorMessage,
}: FollowUpSchedulerProps) {
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [followUpInterval, setFollowUpInterval] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState('');
  const [modifyInterval, setModifyInterval] = useState(false);
  const [modifyDate, setModifyDate] = useState(false);

  const { id } = useParams();
  const queryClient = useQueryClient();

  const updateFollowUpScheduleMutation = useMutationWithToast(
    async (data: SchedulerBody) => {
      const scheduleData = await QuestionnaireService.updateFollowUpScheduling(
        data as SchedulerBody
      );
      await queryClient.refetchQueries(
        QueryKeys.PatientDetails.item(id as string)
      );

      return scheduleData;
    }
  );
  const getPatientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(id as string),
    () => PatientService.getPatientDetails(id || ''),
    {
      enabled: !!id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const handleSave = (loadingType: string) => {
    setLoading(loadingType);
    updateFollowUpScheduleMutation.mutate(
      {
        patientId: id ?? '',

        ...(loadingType === 'interval' && {
          followUpInterval,
        }),
        ...(loadingType === 'date' && {
          followUpDate: format(new Date(selectedDate ?? ''), 'yyyy-MM-dd'),
        }),
      },
      {
        onSuccess() {
          setLoading('');

          if (loadingType === 'interval') {
            setModifyInterval(false);
          }
          if (loadingType === 'date') {
            setModifyDate(false);
          }
        },
        onError() {
          setLoading('');
        },
      }
    );
  };

  const getFollowUpIntervalDescription = () => {
    if (modifyInterval) {
      return null;
    }

    switch (
      getPatientDetailsQuery.data?.data?.patientFollowUpSchedule
        ?.followUpInterval
    ) {
      case 'monthly':
        return 'We will remind the patient to fill out the follow-up questionnaire on the 1st of every month.';

      case 'weekly':
        return 'We will remind the patient to fill out the follow-up questionnaire every Sunday.';

      default:
        return null;
    }
  };

  const getFollowUpDate = () => {
    if (modifyDate) {
      return null;
    }
    if (
      getPatientDetailsQuery.data?.data?.patientFollowUpSchedule?.followUpDate
    ) {
      return `We will remind patient to fill up followup questionnaire on ${getReadableDate(
        getPatientDetailsQuery.data?.data?.patientFollowUpSchedule
          ?.followUpDate ?? ''
      )}.`;
    }

    return null;
  };

  const setDateAndInterval = useCallback(() => {
    setFollowUpInterval(
      getPatientDetailsQuery.data?.data?.patientFollowUpSchedule
        ?.followUpInterval ?? ''
    );
    setSelectedDate(
      getPatientDetailsQuery.data?.data?.patientFollowUpSchedule?.followUpDate
        ? new GMTDate(
            getPatientDetailsQuery.data?.data?.patientFollowUpSchedule
              ?.followUpDate ?? ''
          )
        : undefined
    );
  }, [getPatientDetailsQuery.data?.data?.patientFollowUpSchedule]);

  useEffect(() => {
    setDateAndInterval();
  }, [
    getPatientDetailsQuery.data?.data?.patientFollowUpSchedule,
    setDateAndInterval,
  ]);

  return (
    <SkeletonContent
      isLoading={getPatientDetailsQuery.isLoading}
      isError={getPatientDetailsQuery.isError}
      layout={[
        {
          className: 'grid gap-1 rounded w-full max-w-[50%]',
          children: new Array(1).fill(0).map((_, index) => ({
            className:
              'flex flex-col  gap-4 bg-background-main p-4 py-6 rounded-md',
            children: [
              {
                className: 'flex justify-between items-center',
                children: [
                  {
                    className: 'flex flex-col gap-4',
                    children: [
                      {
                        className: 'h-4 w-[700px]',
                      },
                      {
                        className: ' mt-4 flex flex-col space-y-2',
                        children: [
                          {
                            className: 'flex items-center space-x-4',
                            children: Array(2)
                              .fill(0)
                              .map(() => ({
                                className: 'h-6 w-[120px]',
                              })),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    className: 'h-8 w-[100px]',
                  },
                ],
              },

              {
                className: classNames('h-[1px] bg-background-light w-full', {
                  ' hidden ': index > 2,
                }),
              },
              {
                className: 'flex justify-between items-center',
                children: [
                  {
                    className: 'flex flex-col gap-4',
                    children: [
                      {
                        className: 'h-4 w-[700px]',
                      },
                      {
                        className: ' mt-4 flex flex-col space-y-2',
                        children: [
                          {
                            className: 'flex items-center space-x-4',
                            children: Array(2)
                              .fill(0)
                              .map(() => ({
                                className: 'h-6 w-[120px]',
                              })),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    className: 'h-8 w-[100px]',
                  },
                ],
              },
            ],
          })),
        },
      ]}>
      {() => (
        <div className="flex w-full max-w-[50%] flex-col rounded  ">
          <Typography variant="h4" className=" rounded-t  px-2 py-2 ">
            Schedule follow-up questionnaire
          </Typography>
          <div
            // key={item.id}
            className=" flex h-[26rem] flex-col space-y-2 rounded bg-background-main p-4 py-0">
            {showErrorMessage ? (
              <div className="flex h-full w-full items-center justify-center">
                <Typography className="px-12" variant="subtitle1">
                  The scheduler for follow-up questionnaires is only available
                  to patients who have completed the initial questionnaire.
                </Typography>
              </div>
            ) : (
              <div className="flex h-full flex-col gap-2">
                <div
                  className={classNames(
                    'flex  h-[49%] max-h-[49%] min-h-[49%] grow  !items-center justify-between p-2  px-4 py-0 ',
                    {
                      ' ': !!getFollowUpIntervalDescription(),
                    }
                  )}>
                  <div className="flex flex-col gap-2 ">
                    <Typography variant="subtitle1" className="">
                      {getFollowUpIntervalDescription() ??
                        'How frequently would you want this patient to fill the follow-up questionnaire?'}
                    </Typography>

                    <div className="flex flex-col">
                      {!getFollowUpIntervalDescription() && (
                        <div className=" mt-2 mb-2 flex gap-8">
                          <RoundRadioButton
                            checked={followUpInterval === 'monthly'}
                            label="Monthly"
                            onChange={(c) => {
                              if (c) {
                                setFollowUpInterval('monthly');
                              } else {
                                setFollowUpInterval('');
                              }
                            }}
                          />{' '}
                          <RoundRadioButton
                            checked={followUpInterval === 'weekly'}
                            label="Weekly"
                            onChange={(c) => {
                              if (c) {
                                setFollowUpInterval('weekly');
                              } else {
                                setFollowUpInterval('');
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="mt-2 flex ">
                      <Button
                        data-tooltip-id="saveButtonInterval"
                        disabled={!followUpInterval}
                        loading={loading === 'interval'}
                        onClick={
                          getFollowUpIntervalDescription()
                            ? () => setModifyInterval(true)
                            : () => handleSave('interval')
                        }>
                        {getFollowUpIntervalDescription() ? 'Modify' : 'Save'}
                      </Button>
                      {!followUpInterval && (
                        <Tooltip theme="dark" id="saveButtonInterval">
                          Please select interval for followup
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={classNames(
                    'h-[1px] max-h-[1px] min-h-[1px] w-full bg-background-contrastText px-2'
                  )}
                />
                <div
                  className={classNames(
                    ' flex h-[49%] max-h-[49%] min-h-[49%] w-full grow items-center justify-between p-2 px-4 py-4 pt-0 ',
                    {
                      ' ': !!getFollowUpDate(),
                    }
                  )}>
                  <div className="flex flex-col gap-2 ">
                    <Typography variant="subtitle1">
                      {getFollowUpDate() ??
                        'Would you like this patient to fill the follow up questionnaire on a specific day?'}
                    </Typography>

                    <div className="flex flex-col">
                      {!getFollowUpDate() && (
                        <div id="daySelector" className=" mt-2   ">
                          <div className="w-48">
                            <DayPickerWithInput
                              fullWidth
                              position="ltr"
                              toggleDayPicker={(t) => setShowDateSelector(t)}
                              placeholder="Select Date"
                              label=""
                              withInput
                              selectedDate={selectedDate}
                              open={showDateSelector}
                              theme={ThemeName.Dark}
                              disableHelperText
                              onDateSelect={(d) => {
                                updateFollowUpScheduleMutation.reset();
                                if (isPast(d) && !isSameDay(d, new Date())) {
                                  setError(true);
                                } else {
                                  setError(false);
                                }
                                setSelectedDate(d);
                                setShowDateSelector(false);
                              }}
                            />
                          </div>

                          <p
                            style={{ minHeight: '1rem' }}
                            className={classNames(
                              'invisible m-1 text-left text-sm text-error-main',
                              {
                                '!visible':
                                  error ||
                                  extractValidationErrors(
                                    'followUpDate',
                                    updateFollowUpScheduleMutation
                                  ).hasError,
                              }
                            )}>
                            {extractValidationErrors(
                              'followUpDate',
                              updateFollowUpScheduleMutation
                            ).hasError
                              ? extractValidationErrors(
                                  'followUpDate',
                                  updateFollowUpScheduleMutation
                                ).msg
                              : ' Please ensure that the selected date is valid.'}
                          </p>
                        </div>
                      )}
                      <div>
                        <Button
                          loading={loading === 'date'}
                          data-tooltip-id="saveDateButton"
                          disabled={error || !selectedDate}
                          className=""
                          onClick={
                            getFollowUpDate()
                              ? () => setModifyDate(true)
                              : () => handleSave('date')
                          }>
                          {getFollowUpDate() ? 'Modify' : 'Save'}
                        </Button>
                      </div>
                    </div>
                  </div>
                  {(error || !selectedDate) && (
                    <Tooltip theme="dark" id="saveDateButton">
                      {!selectedDate
                        ? 'Please select a date'
                        : 'Please select valid date'}
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </SkeletonContent>
  );
}
