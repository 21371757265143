import { useState } from 'react';

import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';
import { CurrentDoseType } from 'types/patient/prescription';

export const useDHEAController = () => {
  const DHEADetails = femaleHormoneRxTypes?.dhea;
  const [strength, setStrength] = useState(0);
  const [maximumDose, setMaximumDose] = useState(0);

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(30);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');
  const [currentDose, setCurrentDose] = useState<CurrentDoseType>();

  return {
    DHEADetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,

    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
    currentDose,
    setCurrentDose,
  };
};
