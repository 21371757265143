import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { HormoneWrapper } from 'components/prescriptions/HormoneWrapper/HormoneWrapper';
import { DeliveryMethods } from 'constants/delivery-methods';
import { femaleHormoneRxTypes } from 'constants/hormone-details';
import { useEstradiolController } from 'hooks/hormones/useEstradiolController';
import {
  HormoneNames,
  PrescribedObjectTypes,
  PrescriptionKeys,
} from 'types/prescriptionTypes';

import { CustomSlider } from '../../common/Slider/Slider';

type EstradiolProps = {
  addNewPrescription: (prescriptionObject: PrescribedObjectTypes) => void;
};

export function Estradiol({ addNewPrescription }: EstradiolProps) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const EstradiolDetails = femaleHormoneRxTypes?.[rx || ''];
  const {
    mgPerMl,
    setMgPerMl,
    DTDInWords,
    DTD,
    drops,
    sig,
    setSig,
    refills,
    setRefils,
    setDrops,
  } = useEstradiolController();

  const prescriptionObject: PrescriptionKeys = {
    displayName: EstradiolDetails.displayName,
    name: HormoneNames.ESTRADIOL,
    linkedHormone: EstradiolDetails.linkedHormone,
    mgPerMl: `${mgPerMl} mg/ml`,
    dtd: DTD,
    drops: `${drops} drop${drops > 1 ? 's' : ''}/day`,
    refills,
    sig,
    family: EstradiolDetails?.family,
    deliveryMethod: DeliveryMethods.OrificeReducer.name,
  };

  return (
    <HormoneWrapper
      title={EstradiolDetails.displayName}
      onClick={() => {
        addNewPrescription(prescriptionObject);
      }}>
      <div className=" py-2 dark:text-white">
        <div className="-mb-4 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1 h-24 text-left text-sm font-semibold  ">
            <Typography variant="h5" className="mr-1">
              Strength
            </Typography>
          </div>
          <div className="col-span-8 ">
            <CustomSlider
              min={EstradiolDetails.mgPerMl[0]}
              max={EstradiolDetails.mgPerMl.at(-1)}
              sliderValue={mgPerMl}
              toolTipValue={`${mgPerMl} mg/ml`}
              onChange={(val) => {
                setMgPerMl(Number(val));
              }}
            />
          </div>

          <div className="col-span-3">
            <Typography variant="h5" align="left">
              {' '}
              Delivery Method:{' '}
            </Typography>
            <Typography variant="h5" className="ml-1" color="primary">
              {DeliveryMethods.OrificeReducer.name}
            </Typography>
          </div>
        </div>

        {/* drops per day slider */}

        <div className="grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1 h-24 text-left text-sm font-semibold">
            <Typography variant="h5" className="mr-1">
              Dose
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={EstradiolDetails.dropsPerDay[0]}
              max={EstradiolDetails.dropsPerDay.at(-1)}
              toolTipValue={`${drops} drop${drops > 1 ? 's' : ''}/day`}
              sliderValue={drops}
              onChange={(val) => {
                setDrops(Number(val));
              }}
            />
          </div>

          <div className="col-span-3 h-24 text-left text-sm font-semibold  ">
            <div className="flex">
              <Typography variant="h5"> D.T.D: </Typography>{' '}
              <Typography variant="h5" className="ml-1" color="primary">
                {DTDInWords}
              </Typography>
            </div>
          </div>
        </div>

        {/* Refills slider */}
        <div className="-mt-4 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1 text-left text-sm font-semibold">
            <Typography variant="h5" className="mr-1">
              Refills
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={EstradiolDetails.refills[0]}
              max={EstradiolDetails.refills.at(-1)}
              toolTipValue={`${refills} Refills`}
              sliderValue={refills}
              onChange={(val) => {
                setRefils(Number(val));
              }}
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12">
          <div className="col-span-1 text-left">
            <Typography variant="h5" className="mt-4">
              SIG
            </Typography>
          </div>
          <div className="col-span-8">
            <div className="pr-2">
              <TextInput
                value={sig}
                onChange={setSig}
                fullWidth
                className="my-1 rounded bg-background-light py-2 px-4"
              />
            </div>
          </div>
        </div>
      </div>
    </HormoneWrapper>
  );
}
