import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { NavLink, Navigate, Outlet, useLocation } from 'react-router-dom';

import { SupervisingProviderModal } from 'components/profile/SupervisingProviderModal/SupervisingProviderModal';
import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';

export function PatientsListingLayout() {
  const location = useLocation();

  const [showAddSupervisingDoctor, setShowAddSupervisingDoctor] =
    useState(false);
  const getStatesQuery = useQuery(
    [QueryKeys.States],
    () => UserService.getStates(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const tabs = [
    { title: 'Active', link: 'active' },
    { title: 'In progress', link: 'inprogress' },
    { title: 'Invitations', link: 'invited' },
    { title: 'Archived', link: 'archived' },
  ];

  if (location?.pathname === '/app/patients') {
    return <Navigate to="./active" />;
  }

  return (
    <div className="ml-4 mt-6">
      <div className="mt-8 flex ">
        <div>
          <div className="mt-2 flex cursor-pointer flex-col text-base font-semibold text-background-contrastText">
            {tabs.map((tab) => (
              <NavLink
                key={tab.title}
                to={tab.link}
                className={({ isActive }) =>
                  `inline-block py-4  pl-1 text-lg  ${
                    isActive
                      ? 'text-primary-main '
                      : 'text-background-contrastText '
                  }`
                }>
                {tab.title}
              </NavLink>
            ))}
          </div>
        </div>
        <span className="mx-8 mt-3 h-[10rem] w-[2px] bg-background-light " />
        <SupervisingProviderModal
          isUpdating={false}
          isModalOpen={showAddSupervisingDoctor}
          onModalClose={() => {
            setShowAddSupervisingDoctor(false);
          }}
          states={getStatesQuery?.data?.data ?? []}
        />

        <div className="-mt-2 grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
