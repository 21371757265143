import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Table } from 'components/common/Table/Table';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { NewCropFrame } from 'components/patient/NewcropFrame/NewCropFrame';
import { PrescriptionNCStatusModal } from 'components/patient/Order/PrescriptionNCStatusModal';
import { providerTitlesNotRequiringSupervisorDoctor } from 'constants/provider-titles';
import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { OTCItem, OrderInfo, OrderItem } from 'services/patient/types';
import { UserService } from 'services/user';
import { AddressType } from 'types/onboard';
import { PatientDetailsContextType } from 'types/patient';
import { dateToYYYYMMDD } from 'utils/common';

type PatientOrderDetailsCardProps = {
  rowData: OrderInfo;
  isAccordianSelected?: boolean;
  openNewcrop?: boolean;
};

export function PatientOrderDetailsCard({
  rowData,
  isAccordianSelected,
  openNewcrop,
}: PatientOrderDetailsCardProps) {
  const { profile, userProfileQuery } = useProfile();
  const patientDetails = useOutletContext<PatientDetailsContextType>();
  const [showNCstatusModal, setShowNCstatusModal] = useState(false);
  const [transmitFailedDataToNewCrop, setTransmitDataToNewCrop] =
    useState(false);

  const [failedPrescription, setFailedPrescription] = useState<string[]>([]);

  const getStatesQuery = useQuery(
    [QueryKeys.States],
    () => UserService.getStates(),
    {
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const getDEALicenseQuery = useQuery([QueryKeys.ProviderDEALicenses], () =>
    UserService.getLicensesAndDEA()
  );

  function getHeight() {
    if (!isAccordianSelected) {
      return 'h-0';
    }
    const itemsNumber = [
      ...(rowData?.orderSubscription?.orderItems ?? []),
      ...(rowData?.orderNonSubscriptionItems ?? []),
      ...(rowData?.orderItems ?? []),
    ].length;

    if (rowData?.orderOtcItems?.length > 6 && itemsNumber > 6) {
      return '!h-[55rem]';
    }

    if (rowData?.orderOtcItems?.length > 4 && itemsNumber > 4) {
      return '!h-[50rem]';
    }
    if (rowData?.orderOtcItems?.length > 4) {
      return '!h-[45rem]';
    }

    if (rowData?.orderOtcItems?.[0]?.name) {
      return '!h-[35rem]';
    }

    if (itemsNumber > 4) {
      return '!h-[30rem]';
    }

    return '!h-[20rem]';
  }
  const showSubmitToNewCrop = useMemo(
    () =>
      [
        ...(rowData?.orderSubscription?.orderItems ?? []),
        ...(rowData?.orderNonSubscriptionItems ?? []),
        ...(rowData?.orderItems ?? []),
      ].some((i) => i.prescription.category === 'future'),
    [
      rowData?.orderItems,
      rowData?.orderNonSubscriptionItems,
      rowData?.orderSubscription?.orderItems,
    ]
  );
  function showNewcrop() {
    if (
      showSubmitToNewCrop &&
      (process.env?.REACT_APP_SHOW_NEWCROP === 'Yes' ||
        [
          'VdFaTq8P', // Doctor Fake
          '92eUpHbr', // Anat Sapan
          'deHF7k6Q', // Daved Rosensweet
          'M6P9jWbx', // Alexis Foster-Horton
          '2JTU7tNv', // Amanda Hill
          'XedxNoEU', // Rebecca Powers
          'VHQbTmcL', // Christina Hinson
        ].includes(profile?.id as string)) &&
      !patientDetails.isArchivedPatient
    ) {
      return true;
    }

    return false;
  }

  const getMatchingLicenseState = useCallback(() => {
    const matchingLicense = profile?.providerDetails.providerLicence?.find(
      (d) => d.stateId === patientDetails?.address?.state?.id
    );

    if (matchingLicense?.id) {
      return getStatesQuery.data?.data?.find(
        (s) => s.id === matchingLicense.stateId
      )?.abbreviation;
    }

    return '';
  }, [
    getStatesQuery.data?.data,
    patientDetails.address?.state?.id,
    profile?.providerDetails.providerLicence,
  ]);

  const getMatchingDeaState = useCallback(() => {
    const matchingDea = profile?.providerDetails.providerDea?.find(
      (d) => d.stateId === patientDetails?.address?.state?.id
    );

    if (matchingDea?.id) {
      return getStatesQuery.data?.data?.find(
        (s) => s.id === matchingDea.stateId
      )?.abbreviation;
    }

    return '';
  }, [
    getStatesQuery.data?.data,
    patientDetails.address?.state?.id,
    profile?.providerDetails.providerDea,
  ]);

  const getMatchingSupervisorProvider = useCallback(() => {
    const matchingSP = profile?.providerDetails.supervisingProviders.find(
      (sp) => sp.licenseState === patientDetails.address?.state?.id
    );

    if (matchingSP?.id) {
      return {
        id: matchingSP?.id ?? '',
        firstName: matchingSP?.licensedPrescriberFirstName ?? '',
        lastName: matchingSP?.licensedPrescriberLastName ?? '',
        middle: matchingSP?.licensedPrescriberMiddleName ?? '',
        suffix: matchingSP?.licensedPrescriberSuffix ?? '',
        dea: matchingSP?.dea ?? '',
        licenseNumber: matchingSP?.licenseNumber ?? '',
        npi: matchingSP?.npi ?? '',
        licenseState:
          getStatesQuery.data?.data?.find(
            (s) => s?.id === matchingSP?.licenseState
          )?.abbreviation || '',
      };
    }

    return undefined;
  }, [
    getStatesQuery.data?.data,
    patientDetails.address?.state?.id,
    profile?.providerDetails.supervisingProviders,
  ]);

  function getBaseDea() {
    const hasMidlevel = getDEALicenseQuery.data?.data?.find(
      (d) => d.stateId === patientDetails?.address?.state?.id
    );

    if (hasMidlevel?.supervisingProvider?.dea) {
      return (
        getDEALicenseQuery.data?.data?.find((d) => d?.isMidlevelBase === true)
          ?.dea ?? ''
      );
    }

    if (hasMidlevel?.license) {
      return (
        getDEALicenseQuery.data?.data?.find((d) => d?.isBaseDea === true)
          ?.dea ?? ''
      );
    }

    return '';
  }

  const getOtcItems = () => {
    const otcItems = rowData?.orderOtcItems.reduce(
      (a, i) => ({
        ...a,
        [i.orderItemId]: a?.[i.orderItemId]?.[0]
          ? [...a[i.orderItemId], i]
          : [i],
      }),
      {} as { [x: string]: OTCItem[] }
    );

    return otcItems;
  };

  const getProviderId = () => {
    if (
      providerTitlesNotRequiringSupervisorDoctor.includes(
        profile?.providerDetails.providerSuffix || ''
      )
    ) {
      return profile?.providerNewCropIdentifiers?.ncLicensePrescriberId;
    }
    if (getMatchingSupervisorProvider()?.id) {
      return profile?.providerNewCropIdentifiers?.ncMidLevelPrescriberId;
    }

    return profile?.providerNewCropIdentifiers?.ncLicensePrescriberId;
  };

  function getPrescriptionForNewcrop() {
    const orderItems = [
      ...(rowData?.orderSubscription?.orderItems ?? []),
      ...(rowData?.orderNonSubscriptionItems ?? []),
      ...(rowData?.orderItems ?? []),
    ];

    if (failedPrescription?.[0]) {
      orderItems
        .filter((o) => failedPrescription.includes(o.prescription.id))
        .map((i) => ({ ...i.prescription, orderItemId: i.id }));
    }

    return orderItems.map((i) => ({ ...i.prescription, orderItemId: i.id }));
  }

  return (
    <div
      className={classNames(
        'h-0 overflow-hidden border-background-main  bg-background-light transition-[height_border]  duration-[0.3s] ease-out',
        {
          'border-y border-y-[#464646] px-8 ': isAccordianSelected,
        },
        getHeight()
      )}>
      <div className="mt-4 flex w-11/12 flex-col gap-y-2">
        <div className="flex justify-between">
          <Typography className="col-span-12  pl-1  " variant="h5">
            Order Items
          </Typography>

          {showNewcrop() && (
            <>
              <Button onClick={() => setShowNCstatusModal(true)}>
                Resubmit Prescription(s)
              </Button>
              <NewCropFrame
                showButton={false}
                open={
                  (openNewcrop || transmitFailedDataToNewCrop) &&
                  !getStatesQuery.isLoading
                }
                isDataLoaded={
                  !userProfileQuery.isLoading && !getStatesQuery.isLoading
                }
                onCancel={() => {
                  setTransmitDataToNewCrop(false);
                }}
                data={{
                  pharmacyCode: rowData.pharmacy?.code,
                  practice: {
                    id: profile?.practice.id || '',
                    name: profile?.practice.name || '',
                    phoneNumber:
                      profile?.practiceAddress.contactDetails.phone ?? '',
                    faxNumber:
                      profile?.practiceAddress.contactDetails.faxNumber ?? '',
                    address: {
                      ...profile?.practiceAddress,
                      state: getStatesQuery.data?.data?.find(
                        (s) => s?.id === profile?.practiceAddress?.state?.id
                      ),
                      country: 'US',
                    } as AddressType,
                    location: {
                      id: profile?.practiceAddress.id || '',
                      name: profile?.practice.name ?? '',
                      // This needs to be changed to location contact details and address.
                      phoneNumber:
                        profile?.practiceAddress.contactDetails.phone ?? '',
                      faxNumber:
                        profile?.practiceAddress.contactDetails.faxNumber ?? '',
                      address: {
                        ...profile?.practiceAddress,
                        state: getStatesQuery.data?.data?.find(
                          (s) => s?.id === profile?.practiceAddress?.state?.id
                        ),
                        country: 'US',
                      } as AddressType,
                    },
                  },
                  provider: {
                    id: getProviderId() ?? '',
                    firstName: profile?.firstName || '',
                    lastName: profile?.lastName || '',
                    suffix: profile?.providerDetails.providerSuffix || '',
                    baseDea: getBaseDea(),
                    // This Provider's DEA needs to be captured based on the state of patient.
                    dea:
                      getDEALicenseQuery.data?.data?.find(
                        (d) => d.stateId === patientDetails?.address?.state?.id
                      )?.dea ?? '',
                    deaState: getMatchingDeaState() ?? '',
                    npi: profile?.providerDetails.npi || '',
                    // This Provider's License needs to be captured based on the state of patient.
                    licenseState: getMatchingLicenseState() ?? '',
                    licenseNumber:
                      getDEALicenseQuery.data?.data?.find(
                        (l) => l.stateId === patientDetails?.address?.state?.id
                      )?.license ?? '',
                    phone: profile?.phone.substring(1) || '',
                    requireSupervisingProvider:
                      providerTitlesNotRequiringSupervisorDoctor.includes(
                        profile?.providerDetails.providerSuffix || ''
                      )
                        ? false
                        : !!getMatchingSupervisorProvider()?.id,
                    supervisingProvider: getMatchingSupervisorProvider(),
                  },
                  patient: {
                    id: patientDetails?.id,
                    firstName: patientDetails?.firstName,
                    lastName: patientDetails?.lastName,
                    address: {
                      ...patientDetails?.address,
                      state: getStatesQuery.data?.data?.find(
                        (s) => s?.id === patientDetails?.address?.state?.id
                      ),
                      country: 'US',
                    } as AddressType,
                    email: patientDetails?.email,
                    phoneNumber: patientDetails?.phone.substring(1) || '',
                    DOB: dateToYYYYMMDD(patientDetails.dob),
                    gender: patientDetails?.gender,
                    allergies:
                      typeof patientDetails.patientKnownAllergiesByProvider ===
                      'string'
                        ? undefined
                        : patientDetails.patientKnownAllergiesByProvider,
                    otherMedications:
                      typeof patientDetails.patientOtherMedicationsByProvider ===
                      'string'
                        ? undefined
                        : patientDetails.patientOtherMedicationsByProvider,
                    diagnosis: {
                      hormone: patientDetails.diagnosisHormone?.diagnosisCode,
                      thyroid: patientDetails.diagnosisThyroid?.diagnosisCode,
                    },
                    SSNNumber: patientDetails.patientSsnNumber,
                    drivingLicense: patientDetails.patientDriversLicense,
                  },
                  prescription: getPrescriptionForNewcrop(),
                  otcItems: getOtcItems(),
                }}
              />
            </>
          )}
        </div>
        <Table
          rowsData={[
            ...(rowData?.orderSubscription?.orderItems ?? []),
            ...(rowData?.orderNonSubscriptionItems ?? []),
            ...(rowData?.orderItems ?? []),
          ]}
          columns={[
            {
              title: 'Name',
              render: (data: OrderItem) =>
                data?.prescription?.prescriptionToMedication?.name ?? 'NA',
            },
            {
              title: 'Strength',
              render: (data: OrderItem) =>
                `${data?.prescription?.strength?.value} ${data?.prescription?.strength?.unit}` ??
                'NA',
            },
            {
              title: 'Ratio',
              render: (data: OrderItem) =>
                data?.prescription?.ratio?.e3
                  ? `E3:E2(${data?.prescription?.ratio?.e3}:${data?.prescription?.ratio?.e2})`
                  : 'NA',
            },
            {
              title: 'Quantity',
              render: (data: OrderItem) =>
                `${data?.prescription?.dtd?.value} ${data?.prescription?.dtd?.unit}` ??
                'NA',
            },

            {
              title: 'Dose',
              render: (data: OrderItem) =>
                `${data?.prescription?.deliveryMethodAndSig?.dose?.value} ${data?.prescription?.deliveryMethodAndSig?.dose?.unit}` ??
                'NA',
            },
            {
              title: 'DTD',
              render: (data: OrderItem) =>
                `${data?.prescription?.dtd?.value} ${data?.prescription?.dtd?.unit}` ??
                'NA',
            },

            {
              title: 'SIG',
              render: (data: OrderItem) => (
                <>
                  <Typography
                    ellipsis
                    className="max-w-[20rem]"
                    variant="subtitle2"
                    data-tooltip-id={`orderItemsSig${data.id}`}>
                    {data?.prescription?.deliveryMethodAndSig?.sig ?? 'NA'}
                  </Typography>
                  <Tooltip id={`orderItemsSig${data.id}`}>
                    {' '}
                    {data?.prescription?.deliveryMethodAndSig?.sig ?? 'NA'}
                  </Tooltip>
                </>
              ),
            },
          ]}
          noDataMessage="No items found."
        />
      </div>
      <div className="mt-6">
        {rowData?.orderOtcItems[0] && (
          <div className="flex w-11/12 flex-col gap-y-2">
            <Typography className="col-span-12  pl-1   " variant="h5">
              OTC Items
            </Typography>
            <Table
              recordsPerPage={4}
              rowsData={[...(rowData?.orderOtcItems ?? [])]}
              columns={[
                {
                  title: 'Name',
                  render: (data: OTCItem) => data?.name ?? 'NA',
                },
                {
                  title: 'Quantity',
                  render: (data: OTCItem) => data?.quantity ?? 'NA',
                },
                {
                  title: 'Is replacement',
                  render: (data: OTCItem) =>
                    data?.isReplacement === true ? 'Yes' : 'No',
                },
                {
                  title: 'Replace order Id',
                  render: (data: OTCItem) => data?.replacedOrderId ?? 'NA',
                },
                {
                  title: 'Replacement reason',
                  render: (data: OTCItem) => (
                    <>
                      <Typography
                        ellipsis
                        className="max-w-[20rem]"
                        variant="subtitle2"
                        data-tooltip-id="replacementReason">
                        {data?.replacementReason ?? 'NA'}
                      </Typography>
                      <Tooltip id="replacementReason">
                        {data?.replacementReason ?? 'NA'}
                      </Tooltip>
                    </>
                  ),
                },
              ]}
              noDataMessage="No items found."
            />
          </div>
        )}
      </div>
      <PrescriptionNCStatusModal
        open={showNCstatusModal}
        onCancel={() => setShowNCstatusModal(false)}
        onTransmit={(failedPrescriptions) => {
          setFailedPrescription(failedPrescriptions);
          setTransmitDataToNewCrop(true);
        }}
        disabled={!showSubmitToNewCrop}
        orderItems={[
          ...(rowData?.orderSubscription?.orderItems ?? []),
          ...(rowData?.orderNonSubscriptionItems ?? []),
          ...(rowData?.orderItems ?? []),
        ]}
      />
    </div>
  );
}
