/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Slider.css';

type SliderProps = {
  min: number;
  max: number;
  onChange: (val: number | number[]) => void;
  step?: number;
  sliderValue?: number;
  defaultValue?: number;
  toolTipValue?: string;
  customStartRangeValue?: string;
  customEndRangeValue?: string;
  className?: string;
};
type handleProps = {
  prefixCls?: string;
  vertical?: boolean;
  offset: number;
  value: number;
  dragging?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  reverse?: boolean;
  index: number;
  tabIndex?: number;
  ariaLabel: string;
  ariaLabelledBy: string;
};

export function CustomSlider({
  min,
  max,
  onChange,
  defaultValue,
  sliderValue,
  toolTipValue,
  customStartRangeValue,
  step,
  customEndRangeValue,
  className,
}: SliderProps) {
  const { Handle } = Slider;

  const handle = (props: handleProps) => {
    const { value, index, ...restProps } = props;

    return (
      <div className={classNames(className)}>
        <SliderTooltip
          prefixCls="rc-slider-tooltip"
          overlay={
            <span className="mt-3 flex text-center text-sm text-white">
              {toolTipValue}
            </span>
          }
          visible
          placement="top"
          key={index}>
          <Handle value={value} {...restProps} />
        </SliderTooltip>
      </div>
    );
  };

  return (
    <div className="my-1 text-background-contrastText">
      <Slider
        className=" h-6"
        min={min}
        max={max}
        handle={handle}
        step={step}
        value={sliderValue}
        defaultValue={defaultValue}
        onChange={(val) => onChange(val)}
        railStyle={{ backgroundColor: '#ebf4f3', height: 10 }}
        trackStyle={{
          backgroundColor: '#359583',
          height: 10,
        }}
        handleStyle={{
          borderColor: '#45BDA6',
          height: 24,
          width: 24,
          marginTop: '-8px',
          marginLeft: '5px',
          backgroundColor: '#45BDA6',
          opacity: 1,
          zIndex: 8,
        }}
      />
      <div className=" flex justify-between ">
        <span className="mt text-sm">{customStartRangeValue || min}</span>
        <span className="text-sm ">{customEndRangeValue || max}</span>
      </div>
    </div>
  );
}
