import { HormoneNames } from 'types/prescriptionTypes';

// eslint-disable-next-line
export const femaleHormoneRxTypes: any = {
  'bi-est': {
    displayName: 'Bi-Est',
    name: HormoneNames.BI_EST,
    link: 'bi-est',
    linkedHormone: HormoneNames.ESTRADIOL,
    mgPerMl: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
      46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
    ],
    E3ToE2: [
      '05:95',
      '10:90',
      '15:85',
      '20:80',
      '25:75',
      '30:70',
      '35:65',
      '40:60',
      '45:55',
      '50:50',
      '55:45',
      '60:40',
      '65:35',
      '70:30',
      '75:25',
      '80:20',
    ],
    dtd: 30,
    dropsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'Estradiol',
  },
  estradiol: {
    displayName: 'Estradiol',
    name: HormoneNames.ESTRADIOL,
    link: 'estradiol',
    linkedHormone: HormoneNames.BI_EST,
    mgPerMl: [
      10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
      46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
    ],
    dtd: 30,
    dropsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'Estradiol',
  },
  progesterone: {
    displayName: 'Progesterone',
    name: HormoneNames.PROGESTERONE,
    link: 'progesterone',
    linkedHormone: HormoneNames.ORAL_PROGESTERONE,
    dropsPerDay: [2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    mlPerDay: [
      0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
      0.7, 0.75, 0.8, 0.85, 0.9, 1, 1.5, 2.0,
    ],
    family: 'Progesterone',
  },
  'oral-micronized-progesterone': {
    name: HormoneNames.ORAL_PROGESTERONE,
    displayName: 'Oral Micronised Progesterone',
    link: 'oral-micronized-progesterone',
    linkedHormone: HormoneNames.PROGESTERONE,
    mg: [25, 50, 75, 115, 125, 150, 175, 225, 250, 275, 300, 325, 350],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'Progesterone',
  },
  'testosterone/dhea': {
    name: HormoneNames.TESTO_PER_DHEA,
    displayName: 'Testosterone/DHEA',
    link: 'testosterone/dhea',
    mgPerMl: [
      '5 /105 ',
      '10 /110 ',
      '15 /115 ',
      '20 /120 ',
      '25 /125 ',
      '30 /130 ',
      '35 /135 ',
      '40 /140 ',
    ],
    dropsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'Testosterone/DHEA',
  },
  testosterone: {
    name: HormoneNames.TESTOSTERONE_WOMEN,
    displayName: 'Testosterone',
    link: 'testosterone',
    mgPerMl: [10, 20, 30, 40, 50, 60],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    dropsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    family: 'Testosterone/DHEA',
  },
  dhea: {
    name: HormoneNames.DHEA,
    displayName: 'DHEA',
    link: 'dhea',
    mgPerMl: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    dropsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    family: 'Testosterone/DHEA',
  },
  'dhea-oral': {
    displayName: 'DHEA Oral',
    name: HormoneNames.DHEA_ORAL,
    link: 'dhea-oral',
    mg: [5, 7.5, 10, 12.5, 15],
    dtd: [30, 60, 90],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'Testosterone/DHEA',
  },
  'levothyroxine-(t4)': {
    displayName: 'Levothyroxine (T4)',
    name: HormoneNames.LEVOTHYROXINE,
    link: 'levothyroxine-(t4)',
    mg: [25, 50, 75, 100, 125, 150],
    dtd: [30, 60, 90],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'T4/LAT3',
  },
  'liothyronine-extended-release-(lat3)': {
    displayName: 'Liothyronine Extended Release (LAT3)',
    name: HormoneNames.LIOTHYRONINE_EXT,
    link: 'liothyronine-extended-release-(lat3)',
    mg: [3, 5, 10, 15, 20, 25],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'T4/LAT3',
  },
  't4/lat3': {
    displayName: 'T4/LAT3',
    name: HormoneNames.T4_PER_LAT3,
    link: 't4/lat3',
    mg: [
      '50/5',
      '50/10',
      '50/15',
      '50/20',
      '75/5',
      '75/10',
      '75/15',
      '75/20',
      '75/25',
      '100/5',
      '100/10',
      '100/15',
      '100/25',
    ],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'T4/LAT3',
  },
  sildenafil: {
    displayName: 'Sildenafil',
    name: HormoneNames.SILDENAFIL,
    link: 'sildenafil',
    mg: [20, 50, 100],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  tadalafil: {
    displayName: 'Tadalafil',
    name: HormoneNames.TADALAFIL,
    link: 'tadalafil',
    mg: [2.5, 5, 10, 20],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  danazol: {
    displayName: 'Danazol',
    name: HormoneNames.Danazol,
    link: 'danazol',
    mg: [6.25, 12.5, 18.75, 25],
    dtd: [30, 60, 90],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
};
// eslint-disable-next-line
export const maleHormoneRxTypes: any = {
  'levothyroxine-(t4)': {
    displayName: 'Levothyroxine',
    name: HormoneNames.LEVOTHYROXINE,
    link: 'levothyroxine-(t4)',
    mg: [25, 50, 75, 100, 125, 150],
    dtd: [30, 60, 90],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'T4/LAT3',
  },
  'liothyronine-extended-release-(lat3)': {
    displayName: 'Liothyronine Extended Release (LAT3)',
    name: HormoneNames.LIOTHYRONINE_EXT,
    link: 'liothyronine-extended-release-(lat3)',
    mg: [3, 5, 10, 15, 20, 25],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'T4/LAT3',
  },
  't4/lat3': {
    displayName: 'T4/LAT3',
    name: HormoneNames.T4_PER_LAT3,
    link: 't4/lat3',
    mg: [
      '50/5',
      '50/10',
      '50/15',
      '50/20',
      '75/5',
      '75/10',
      '75/15',
      '75/20',
      '75/25',
      '100/5',
      '100/10',
      '100/15',
      '100/25',
    ],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'T4/LAT3',
  },
  sildenafil: {
    displayName: 'Sildenafil',
    name: HormoneNames.SILDENAFIL,
    link: 'sildenafil',
    mg: [20, 50, 100],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  tadalafil: {
    displayName: 'Tadalafil',
    name: HormoneNames.TADALAFIL,
    link: 'tadalafil',
    mg: [2.5, 5, 10, 20],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  danazol: {
    displayName: 'Danazol',
    name: HormoneNames.Danazol,
    link: 'danazol',
    mg: [6.25, 12.5, 18.75, 25],
    dtd: [30, 60, 90, 120],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  testosterone: {
    name: HormoneNames.TESTOSTERONE_MEN,
    displayName: 'Testosterone',
    link: 'testosterone',
    dtd: [30, 60],
    ml: [
      0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5,
      1.6, 1.7, 1.8, 1.9, 2.0,
    ],
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    dropsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    family: 'Testosterone',
  },
  'testosterone-cyp-inj-200mg/ml': {
    displayName: 'Testosterone Cyp Inj 200mg/ml',
    name: HormoneNames.TestosteroneCyp,
    link: 'testosterone-cyp-inj-200mg/ml',
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    family: 'Testosterone',
  },
  'pregnyl-(injectable)(hcg)': {
    displayName: 'Pregnyl (injectable)(HCG)',
    name: HormoneNames.Pregnyl,
    link: 'pregnyl-(injectable)(hcg)',
    refills: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
};
