import React from 'react';

import { Typography } from '../Typography/Typography';

type RoundRadioButtonProps = {
  label?: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
};

export function RoundRadioButton({
  label,
  checked,
  onChange,
}: RoundRadioButtonProps) {
  return (
    <div className="inline-flex cursor-pointer items-center">
      <input
        type="radio"
        className="form-radio h-5 w-5 !accent-primary-main"
        checked={checked}
        onChange={(e) => {
          onChange(e?.target.checked);
        }}
      />
      <Typography variant="subtitle2" className="ml-2">
        {label ?? ''}
      </Typography>
    </div>
  );
}
