import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useSildenafilController } from 'hooks/hormones-new/useSildenafilController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type SildenafilProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function Sildenafil({ addNewPrescription }: SildenafilProps) {
  const [searchParams] = useSearchParams();
  const medicationId = searchParams.get('id');
  const {
    SildenafilDetails,
    strength,
    setStrength,
    sig,
    setSig,
    dtd,
    setDTD,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useSildenafilController();
  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || '',
    displayName: SildenafilDetails.displayName,
    name: HormoneNames.SILDENAFIL,
    strength: `${strength}`,
    strengthUnit: SildenafilDetails.strengthUnit,
    dose: SildenafilDetails.dose[dose + maximumDose],
    minimumDose: SildenafilDetails.dose[dose],
    doseUnit: SildenafilDetails.doseUnit,
    refills,
    dtd,
    dtdUnit: SildenafilDetails.dtdUnit,
    sig,
    deliveryMethod: SildenafilDetails.deliveryMethod,
    family: SildenafilDetails.family,
    note,
  };

  return (
    <HormoneDetails
      showStatus={false}
      prescriptionObject={prescriptionObject}
      title={SildenafilDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.DTD,
          label: 'Strength',
          value: strength,
          unit: SildenafilDetails.strengthUnit,
          onChange(val) {
            setStrength(val);
          },
          DTDValues: SildenafilDetails.strength,
        },

        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: SildenafilDetails.dose,
          getTooltip: (val) =>
            `${`${SildenafilDetails.dose[val]} ${SildenafilDetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: SildenafilDetails.refills,
          getTooltip: (val) => `${SildenafilDetails.refills[val]} refills`,
        },
        {
          type: PrescriptionInputTypes.DTD,
          value: dtd,
          unit: SildenafilDetails.dtdUnit,
          onChange(val) {
            setDTD(val);
          },
          DTDValues: SildenafilDetails.dtd,
        },
        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: SildenafilDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
          },
          unit: SildenafilDetails.doseUnit,
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: SildenafilDetails.deliveryMethod,
        },
      ]}
    />
  );
}
