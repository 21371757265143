import React from 'react';

export function HormoneContainerSkeleton() {
  return (
    <>
      {[...Array(3)].map(() => (
        <div className="col-span-3 mt-3 min-h-[300px] animate-pulse rounded bg-background-main " />
      ))}
    </>
  );
}
