import React, { useState } from 'react';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { UpdatePassword } from 'components/profile/UpdatePassword/UpdatePassword';

export function UserSetting() {
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = (state: boolean) => {
    setModal(state);
  };

  const openModal = () => {
    setModal(true);
  };

  return (
    <div className="ml-4">
      <div className="grid grid-cols-10">
        <div className="col-span-6 mt-6">
          <div className=" flex justify-between ">
            <Typography variant="subtitle1">
              Do you want to Change Password?
            </Typography>
            <Button onClick={openModal}>Change Password</Button>
          </div>

          <div className=" mt-4 flex justify-between " />
        </div>
      </div>
      <UpdatePassword modal={modal} closeModal={closeModal} />
    </div>
  );
}
