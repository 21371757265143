import { Dispatch, SetStateAction } from 'react';

import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';
import { PrescribedObjectTypes } from 'types/prescriptionTypes';

type PrescribedTypes = {
  prescribedHormones: PrescribedObjectTypes[];
  removePrescribedItem: (index: number, id: string) => void;
  isPrescribeActive: boolean;
  setIsPrescribeActive: Dispatch<SetStateAction<boolean>>;
};

export function PrescribedHormones({
  prescribedHormones,
  removePrescribedItem,
  isPrescribeActive,
  setIsPrescribeActive,
}: PrescribedTypes) {
  const { id } = useOutletContext<PatientDetailsContextType>();
  const navigate = useNavigate();

  const prescribeMedicationMutation = useMutationWithToast(
    PatientService.prescribeMedication,
    {
      onSuccess() {
        sessionStorage.removeItem('hormones');
      },
    }
  );

  if (isPrescribeActive && !prescribedHormones.length) {
    return null;
  }

  const prescribeHormones = () => {
    const medications = prescribedHormones?.map((item) => {
      const dose = item?.drops?.split(' ') || item?.mlPerDay?.split(' ');
      const strength = item.mgPerMl?.split(' ') || item.mg?.split(' ');
      const e3e2 = item?.e3Toe2?.split(':');

      return {
        medicationId: item.id || '',
        refills: item.refills,
        deliveryMethodAndSig: {
          deliveryMethod: item.deliveryMethod || '',
          sig: item.sig,
          dose: dose
            ? {
                unit: dose?.[1],
                value: dose?.[0],
              }
            : null,
        },
        strength: {
          unit: strength?.[1],
          value: strength?.[0],
        },
        ratio: {
          e3: e3e2?.[0],
          e2: e3e2?.[1],
        },
        dtd: item.dtd
          ? {
              unit: 'ml',
              value: Number(item.dtd),
            }
          : null,
      };
    });

    prescribeMedicationMutation.mutate(
      { patientId: id, medications },
      {
        onSuccess: () => {
          navigate({
            pathname: '../../../overview',
          });
        },
      }
    );
  };

  return (
    <div className="flex flex-col text-start">
      <div className="mt-3 font-bold">
        <Typography variant="h3">Queued prescriptions</Typography>
      </div>
      <div className="grid grid-cols-12 gap-x-10">
        {prescribedHormones.map(
          (item: PrescribedObjectTypes, index: number) => (
            <div
              className="min-h-82 col-span-3 mt-3 rounded-md bg-background-main pl-4 pr-2 pb-4 shadow-sm"
              key={item.name}>
              <span className="col-span-12 mb-2 flex cursor-pointer justify-end">
                {/* eslint-disable-next-line */}
                <XMarkIcon
                  height={24}
                  className="mt-2 mr-2"
                  onClick={() => {
                    removePrescribedItem(index, item.id as string);
                  }}
                />
              </span>
              <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                <Typography
                  variant="subtitle2"
                  align="left"
                  className="col-span-3">
                  Rx
                </Typography>
                <Typography variant="subtitle2">:</Typography>
                <span className="col-span-8  flex">
                  <Typography variant="subtitle2" align="left">
                    {item?.displayName}
                  </Typography>
                  {item.e3Toe2 && (
                    <Typography
                      variant="subtitle2"
                      className="ml-3"
                      align="left">
                      {item.e3Toe2}
                    </Typography>
                  )}
                </span>
              </div>
              {(!!item.mgPerMl || !!item.mg) && (
                <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className="col-span-3">
                    Strength
                  </Typography>
                  <Typography variant="subtitle2">:</Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className="col-span-8">
                    {item.mgPerMl || item.mg}
                  </Typography>
                </div>
              )}
              {(!!item.drops || !!item.mlPerDay) && (
                <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className="col-span-3">
                    Dose
                  </Typography>
                  <Typography variant="subtitle2">:</Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    className="col-span-8">
                    {item?.drops ?? item?.mlPerDay}
                  </Typography>
                </div>
              )}
              {!!item.dtd && (
                <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                  <Typography
                    variant="subtitle2"
                    className="col-span-3"
                    align="left">
                    D.T.D
                  </Typography>
                  <Typography variant="subtitle2">:</Typography>
                  <Typography
                    variant="subtitle2"
                    className="col-span-8"
                    align="left">
                    {item.dtd} ml
                  </Typography>
                </div>
              )}
              <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                <Typography
                  variant="subtitle2"
                  className="col-span-3"
                  align="left">
                  SIG
                </Typography>
                <Typography variant="subtitle2">:</Typography>
                <Typography
                  variant="subtitle2"
                  className="col-span-8"
                  align="left">
                  {item.sig}
                </Typography>
              </div>
              <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                <Typography
                  variant="subtitle2"
                  className="col-span-3"
                  align="left">
                  Delivery Method
                </Typography>
                <Typography variant="subtitle2">:</Typography>
                <Typography
                  variant="subtitle2"
                  className="col-span-8"
                  align="left">
                  {item.deliveryMethod}
                </Typography>
              </div>
              <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                <Typography
                  variant="subtitle2"
                  className="col-span-3"
                  align="left">
                  Refills
                </Typography>
                <Typography variant="subtitle2">:</Typography>
                <Typography
                  variant="subtitle2"
                  className="col-span-8"
                  align="left">
                  {item.refills}
                </Typography>
              </div>
            </div>
          )
        )}
        {!isPrescribeActive && (
          <div className="min-h-82 col-span-3 mt-3 min-h-[220px] rounded-md bg-background-main p-2 pb-2 shadow-sm">
            {/* eslint-disable-next-line */}
            <div
              className="flex h-full cursor-pointer items-center justify-center"
              onClick={() => setIsPrescribeActive(true)}>
              <Typography variant="h5">
                <PlusIcon height={24} className=" mr-1" /> Add new Rx
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className="my-4 flex justify-end">
        <Button
          loading={prescribeMedicationMutation?.isLoading}
          onClick={prescribeHormones}>
          Confirm
        </Button>
      </div>
    </div>
  );
}
