import { useState } from 'react';

export const useLiothyronineExtController = () => {
  const [mg, setMg] = useState(0);
  const [sig, setSig] = useState('Take 0.25 - 25 mcg daily as directed');
  const [DTD, setDTD] = useState(30);
  const [refills, setRefils] = useState(0);
  const mcg = 50;

  return {
    refills,
    DTD,
    DTDInWords: `${DTD} ml`,
    mg,
    setDTD,
    setRefils,
    sig,
    setSig,
    setMg,
    mcg,
  };
};
