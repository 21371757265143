import React from 'react';

import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

import { Typography } from 'components/common/Typography/Typography';
import { formatDeliveryUnit } from 'utils/common';

interface SelectorCheckboxProps {
  label?: string;
  options: (number | string)[];
  value: number | string;
  onChange: (value: number | string) => void;
  unit: string;
  selectorClassName?: string;
  containerClassName?: string;
}

export function SelectorCheckbox({
  label,
  options,
  value,
  onChange,
  unit,
  selectorClassName,
  containerClassName,
}: SelectorCheckboxProps) {
  return (
    <div className={classNames('flex flex-col ', containerClassName)}>
      {label && (
        <Typography
          className="  whitespace-nowrap"
          variant="subtitle2"
          align="left">
          {label}
        </Typography>
      )}
      <RadioGroup className="mt-1" value={value} onChange={onChange}>
        <div className="inline-flex cursor-pointer  shadow-md hover:shadow-lg focus:shadow-lg">
          {options.map((item: number | string, index: number, arr) => (
            <RadioGroup.Option value={item}>
              {/* eslint-disable-next-line */}
              {({ checked }: any) => (
                <div
                  className={classNames(
                    `lowercase ${index === 0 && 'rounded-l'} ${
                      index === arr.length - 1 && 'rounded-r'
                    }  flex px-5 py-2 
                  ${
                    checked
                      ? 'bg-primary-dark hover:bg-primary-dark'
                      : 'bg-background-light'
                  }    whitespace-nowrap  uppercase  text-primary-contrastText transition duration-150 ease-in-out hover:bg-primary-main focus:outline-none focus:ring-0`,
                    selectorClassName
                  )}>
                  <Typography variant="h5" color="white">
                    {`${item} ${formatDeliveryUnit(Number(item), unit)}`}
                  </Typography>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
