import { useState } from 'react';

import { maleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';
import { CurrentDoseType } from 'types/patient/prescription';

export const useTestosteroneMenController = () => {
  const TestosteroneMenDetails = maleHormoneRxTypes?.testosterone;
  const [maximumDose, setMaximumDose] = useState(0);

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(18);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');
  const [currentDose, setCurrentDose] = useState<CurrentDoseType>();
  // the maximum daily dose slider should automatically be 0.2 higher than the dose slider
  const marginValue = 6;

  return {
    TestosteroneMenDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
    currentDose,
    setCurrentDose,
    marginValue,
  };
};
