import React, { PropsWithChildren } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useLocation, useSearchParams } from 'react-router-dom';

import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { UserService } from 'services/user';

import LOGO from '../../../assets/images/LOGO.png';

type PageProps = {
  title?: string;
};
export function Page({ children, title }: PropsWithChildren<PageProps>) {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const token = searchParams.get('token') ?? '';
  const { profile } = useProfile();

  const getProviderPracticeDetailsQuery = useQuery(
    [QueryKeys.ProviderPracticeDetails],
    () => UserService.getProviderPracticeDetails({ token }),
    {
      retry: false,
      enabled: !!token,
    }
  );
  function getFavicon() {
    if (getProviderPracticeDetailsQuery.data?.data.inviteToPractice.logoUrl) {
      return getProviderPracticeDetailsQuery.data?.data.inviteToPractice
        .logoUrl;
    }
    if (profile?.practice?.logoUrl) {
      return profile?.practice?.logoUrl;
    }

    return LOGO;
  }

  if (location?.pathname.includes('/auth/login')) {
    window.location.replace(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/login`);
  }

  return (
    <div>
      <Helmet>
        <title>
          {title
            ? `${title}: ${profile?.practice?.name ?? 'Brite live'}`
            : 'Brite live'}
        </title>
        <link rel="icon" type="image/png" href={getFavicon()} />
      </Helmet>
      <div>{children}</div>
    </div>
  );
}
