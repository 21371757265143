import { useState } from 'react';

export const useDHEAOralController = () => {
  const [mg, setMg] = useState(5);
  const [sig, setSig] = useState('Take 5-15 mg daily as directed');
  const [DTD, setDTD] = useState(60);
  const [refills, setRefils] = useState(0);

  return {
    refills,
    DTD,
    DTDInWords: `${DTD} ml`,
    mg,
    setDTD,
    setRefils,
    sig,
    setSig,
    setMg,
  };
};
