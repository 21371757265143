import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';

import { SummaryQuestionType } from 'types/questionnaire';
import { getAge, getReadableDate } from 'utils/common';

import { getAnswer, getAverage, summaryQuestions } from './questionnaireHelper';
import { headFont, styles, textStyle } from './summaryStyles';

type MaleProps = {
  filteredSummaryQuestions: SummaryQuestionType;
  generalInfo: { [key: string]: string };
  gender: string;
};
const shortSection: {
  [key: string]: string;
} = {
  'M&T': 'mouthAndThroat',
  'Liv/Toxic': 'liverAndToxicity',
  Immune: 'immuneSystem',
  'G-I': 'intestine',
  'Mind/Emot': 'mindAndEmotions',
  Hormones: 'hormonal',
  Aller: 'allergy',
  'C-V': 'cardiovascular',
  'G-U': 'urinaryTract',
};
const ROSsections = [
  'Head',
  'Nose',
  'Sleep',
  'Lungs',
  'Skin',
  'Hormones',
  'Liv/Toxic',
  'Eyes',
  'M&T',
  'Immune',
  'G-I',
  'Weight',
  'Mind/Emot',
  'Ears',
  'Aller',
  'C-V',
  'G-U',
  'Energy',
  'Dental',
  'other',
];

export function MalePDF({
  filteredSummaryQuestions,
  generalInfo,
  gender,
}: MaleProps) {
  const section3 = [
    {
      label: 'Sexual Function',
      propName: 'sexualFunction',
    },
    {
      label: 'Physical Changes',
      propName: 'physicalChanges',
    },
    {
      label: 'Urologic Changes',
      propName: 'urologicChanges',
    },
    {
      label: 'Mental/Emotional',
      propName: 'mentalAndEmotional',
    },
    {
      label: 'General Physical Changes',
      propName: 'generalPhysicalChanges',
    },
    {
      label: 'Metabolic Changes',
      propName: 'metabolicChanges',
    },
  ];

  return (
    <Document title={`${generalInfo.name} Summary`}>
      <Page size="A4" style={styles.page} wrap={false}>
        <View
          style={[
            styles.Header,
            {
              marginBottom: '-1px',
              display: 'flex',
              justifyContent: 'center',
              fontSize: 1,
            },
          ]}>
          <Text
            style={[
              ...headFont,
              { color: '#349583', fontSize: 15, height: 'auto', padding: 4 },
            ]}>
            Male Health Questionnaire
          </Text>
        </View>{' '}
        <View
          style={[
            styles.Header,
            {
              marginBottom: '5px',
              display: 'flex',
              justifyContent: 'center',
              padding: 1,
              height: 'auto',
              fontSize: 11,
            },
          ]}>
          <Text style={headFont}>Summary</Text>
        </View>
        <View style={styles.Header}>
          <Text style={styles.headerFields}>
            {summaryQuestions[gender].header[0].label}:{' '}
            {
              generalInfo[
                summaryQuestions[gender].header[0].label.toLowerCase()
              ]
            }
          </Text>{' '}
          <Text style={styles.headerFields}>
            {summaryQuestions[gender].header[1].label}:{' '}
            {getReadableDate(
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ],
              false,
              'numeric'
            )
              .split('/')
              .join('-')}
          </Text>{' '}
          <Text style={styles.headerFields}>
            {summaryQuestions[gender].header[2].label}:{' '}
            {getAge(
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ].split('-')[0],
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ].split('-')[1],
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ].split('-')[2]
            )}
            {
              generalInfo[
                summaryQuestions[gender].header[2].label.toLowerCase()
              ]
            }
          </Text>{' '}
          <Text style={styles.headerFields}>
            {summaryQuestions[gender].header[3].label}:{' '}
            {
              generalInfo[
                summaryQuestions[gender].header[3].label.toLowerCase()
              ]
            }
          </Text>
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.section}>
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[0].label}:{' '}
              {getAnswer(filteredSummaryQuestions.section1?.[0])
                .toString()
                .replace(',', ' , ')}
            </Text>{' '}
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[1].label}:{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[0]
              }{' '}
              ,{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[1]
              }
            </Text>{' '}
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[2].label}:{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[2]
              }
            </Text>{' '}
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[3].label}:{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[3]
              }
            </Text>
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[4].label}:{' '}
              {getAnswer(filteredSummaryQuestions.section1?.[4])}
            </Text>
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[5].label}:{' '}
              {getAnswer(filteredSummaryQuestions.section1?.[5])}
            </Text>
          </View>
          <View style={styles.section}>
            {summaryQuestions[gender].section2.map((i, index) => (
              <Text style={textStyle}>
                {i.label}:{' '}
                {getAnswer(filteredSummaryQuestions.section2?.[index])}
              </Text>
            ))}
            <Text style={textStyle}>
              Grand Average of Above :{' '}
              {getAverage(filteredSummaryQuestions.section2)}
            </Text>
          </View>

          <View style={styles.section}>
            {section3.map(({ label, propName }) => (
              <Text style={textStyle}>
                {label}: {getAverage(filteredSummaryQuestions[propName])}
              </Text>
            ))}
            <Text style={textStyle}>
              Grand Average of Above :{' '}
              {(
                section3
                  .map(({ propName }) =>
                    getAverage(filteredSummaryQuestions[propName])
                  )
                  .reduce((a, t) => Number(a) + Number(t), 0) / section3.length
              ).toFixed(1)}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={textStyle}>
              Grand Total R of S :{' '}
              {ROSsections.map((i) =>
                getAverage(filteredSummaryQuestions[i.toLowerCase()]) === ''
                  ? getAverage(filteredSummaryQuestions[shortSection[i]])
                  : getAverage(filteredSummaryQuestions[i.toLowerCase()])
              )
                .reduce((a, t) => (Number(a) + t ? Number(t) : 0), 0)
                .toFixed(1)}
            </Text>
            {summaryQuestions[gender].section4.map((i, index) => (
              <Text style={textStyle}>
                {i.label}:{' '}
                {getAnswer(filteredSummaryQuestions.section4?.[index])}
              </Text>
            ))}
          </View>
        </View>
        <View style={styles.mainSectionContainer}>
          {summaryQuestions[gender].mainSection.map((i, index) => (
            <View style={[styles.mainSection, { marginTop: '-1px' }]}>
              <Text
                style={[
                  ...textStyle,
                  { width: '100px', borderRight: '1px solid #a1a1a1' },
                ]}>
                {i.label}:
              </Text>{' '}
              <Text style={[...textStyle, { flexGrow: 1 }]}>
                {' '}
                {getAnswer(filteredSummaryQuestions.mainSection?.[index])}
              </Text>{' '}
            </View>
          ))}
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid #a1a1a1',
            marginTop: '-2px',
          }}>
          {summaryQuestions[gender].mainSectionFooter.map((i, index) => (
            <Text
              style={[
                ...textStyle,
                { flexBasis: '20%', borderRight: "1px solid #a1a1a1'" },
              ]}>
              {i.label}:{' '}
              {getAnswer(filteredSummaryQuestions.mainSectionFooter?.[index])}
            </Text>
          ))}
        </View>
        <View style={[styles.footer, { marginTop: '-2px' }]}>
          <View style={styles.footerSectionMen}>
            <Text style={[...textStyle, { textAlign: 'center' }]}>
              Sexual Function
            </Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].sexualFunction.map((i, index) => (
                <Text style={textStyle}>
                  {i.label}{' '}
                  {getAnswer(filteredSummaryQuestions.sexualFunction?.[index])}
                </Text>
              ))}
              <Text style={[...textStyle, { borderLeft: ' ' }]}>
                Average of above{'   '}
                {getAverage(filteredSummaryQuestions.sexualFunction)}
              </Text>
            </View>
          </View>{' '}
          <View style={styles.footerSectionMen}>
            <Text style={[...textStyle, { textAlign: 'center' }]}>
              Physical Changes
            </Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].physicalChanges.map((i, index) => (
                <Text style={textStyle}>
                  {i.label}{' '}
                  {getAnswer(filteredSummaryQuestions.physicalChanges?.[index])}
                </Text>
              ))}
              <Text style={[...textStyle, { borderLeft: ' ' }]}>
                Average of above{'   '}
                {getAverage(filteredSummaryQuestions.physicalChanges)}
              </Text>
            </View>
          </View>{' '}
          <View style={styles.footerSectionMen}>
            <Text style={[...textStyle, { textAlign: 'center' }]}>
              Urologic Changes
            </Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].urologicChanges.map((i, index) => (
                <Text style={textStyle}>
                  {i.label}{' '}
                  {getAnswer(filteredSummaryQuestions.urologicChanges?.[index])}
                </Text>
              ))}
              <Text style={[...textStyle, { borderLeft: ' ' }]}>
                Average of above{'   '}
                {getAverage(filteredSummaryQuestions.urologicChanges)}
              </Text>
            </View>
          </View>{' '}
          <View style={{ flexBasis: '100%', padding: 5 }} />
          <View style={styles.footerSectionMen}>
            <Text style={[...textStyle, { textAlign: 'center' }]}>
              Mental/Emotional
            </Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].mentalAndEmotional.map((i, index) => (
                <Text style={[...textStyle]}>
                  {i.label}{' '}
                  {getAnswer(
                    filteredSummaryQuestions.mentalAndEmotional?.[index]
                  )}
                </Text>
              ))}
              <Text style={[...textStyle, { borderLeft: ' ' }]}>
                Average of above{'   '}
                {getAverage(filteredSummaryQuestions.mentalAndEmotional)}
              </Text>
            </View>
          </View>
          <View style={styles.footerSectionMen}>
            <Text style={[...textStyle, { textAlign: 'center' }]}>
              General Physical Changes
            </Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].generalPhysicalChanges.map(
                (i, index) => (
                  <Text style={[...textStyle]}>
                    {i.label}{' '}
                    {getAnswer(
                      filteredSummaryQuestions.generalPhysicalChanges?.[index]
                    )}
                  </Text>
                )
              )}
              <Text style={[...textStyle, { borderLeft: ' ' }]}>
                Average of above{'   '}
                {getAverage(filteredSummaryQuestions.generalPhysicalChanges)}
              </Text>
            </View>
          </View>
          <View style={styles.footerSectionMen}>
            <Text style={[...textStyle, { textAlign: 'center' }]}>
              Metabolic Changes
            </Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].metabolicChanges.map((i, index) => (
                <Text style={[...textStyle]}>
                  {i.label}{' '}
                  {getAnswer(
                    filteredSummaryQuestions.metabolicChanges?.[index]
                  )}
                </Text>
              ))}
              <Text style={[...textStyle, { borderLeft: ' ' }]}>
                Average of above{'   '}
                {getAverage(filteredSummaryQuestions.metabolicChanges)}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            border: '1px solid #a1a1a1',
            paddingHorizontal: 10,
            marginTop: '-2px',
          }}>
          <Text style={[...textStyle, { border: 'none' }]}>
            Grand Total R of S :{' '}
            {ROSsections.map((i) =>
              getAverage(filteredSummaryQuestions[i.toLowerCase()]) === ''
                ? getAverage(filteredSummaryQuestions[shortSection[i]])
                : getAverage(filteredSummaryQuestions[i.toLowerCase()])
            )
              .reduce((a, t) => (Number(a) + t ? Number(t) : 0), 0)
              .toFixed(1)}
          </Text>
          <Text style={[...textStyle, { border: 'none' }]}>
            {/* We need to fix this  */}
            Grand Average of R of S{' :  '}{' '}
            {(
              ROSsections.map((i) =>
                getAverage(filteredSummaryQuestions[i.toLowerCase()]) === ''
                  ? getAverage(filteredSummaryQuestions[shortSection[i]])
                  : getAverage(filteredSummaryQuestions[i.toLowerCase()])
              ).reduce((a, t) => (Number(a) + t ? Number(t) : 0), 0) /
              ROSsections.length
            ).toFixed(1)}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            border: '1px solid #a1a1a1',
            marginTop: '-2px',
          }}>
          {ROSsections.map((i) => (
            <Text
              style={[
                ...textStyle,
                { flexBasis: '20%', borderRight: '1px solid #a1a1a1' },
              ]}>
              {i}
              {' : '}{' '}
              {getAverage(filteredSummaryQuestions[i.toLowerCase()]) === ''
                ? getAverage(filteredSummaryQuestions[shortSection[i]])
                : getAverage(filteredSummaryQuestions[i.toLowerCase()])}
            </Text>
          ))}
        </View>
      </Page>
    </Document>
  );
}
