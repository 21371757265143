import React, { useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useDebounce } from 'hooks/useDebounce';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { PatientService } from 'services/patient';
import { ThemeName } from 'theme/types';
import { Option } from 'types/inputTypes';
import { AllergyType } from 'types/patient';

type AllergiesMedicationsModalProps = {
  patientId: string;
  open: boolean;
  onClose: () => void;

  defaultValue: {
    patientKnownAllergiesByPatient: string;
    patientOtherMedicationsByPatient: string;
    patientKnownAllergiesByProvider?: AllergyType[];
    patientOtherMedicationsByProvider?: Option[];
  };
  type: string;
};
export function AllergiesMedicationsModal({
  onClose,
  patientId,
  open,
  defaultValue,
  type,
}: AllergiesMedicationsModalProps) {
  const queryClient = useQueryClient();
  const [allergy, setAllergy] = useState<Option>();
  const [allergies, setAllergies] = useState<AllergyType[]>(
    defaultValue?.patientKnownAllergiesByProvider ?? []
  );
  const [clearState, setClearState] = useState(false);
  const [error, setError] = useState(false);
  const [medication, setMedication] = useState<Option>();
  const [medications, setMedications] = useState<Option[]>([]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [severity, setSeverity] = useState<string>();
  const debouncedValue = useDebounce<string>(searchValue, 300);

  const updateAllergiesMedicationMutation = useMutationWithToast(
    PatientService.updatePatientAllergiesMedication
  );
  const getAllergiesQuery = usePaginatedQuery(
    QueryKeys.Allergies.listing({ search: debouncedValue }),
    ({ pageParam = 1 }) =>
      PatientService.getPatientAllergiesList({
        search: debouncedValue,
        page: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getOtherMedicationsQuery = usePaginatedQuery(
    QueryKeys.OtherMedication.listing({ search: debouncedValue }),
    ({ pageParam = 1 }) =>
      PatientService.getPatientOtherMedicationList({
        search: debouncedValue,
        page: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  // eslint-disable-next-line
  function getDefaultValues(defaultValue: Option[] | undefined) {
    const value = defaultValue?.map((i) => {
      if (i?.title && i?.value) {
        return { title: i?.title, value: i?.value };
      }

      return undefined;
    });

    return typeof value?.[0] === undefined || value === undefined
      ? undefined
      : (value as Option[]);
  }
  const severityType = [
    { title: 'Unspecified', value: 'Unspecified' },
    { title: 'Mild', value: 'Mild' },
    { title: 'Moderate', value: 'Moderate' },
    { title: 'Severe', value: 'Severe' },
  ];

  function deleteAllergy(index: number) {
    setAllergies(allergies.filter((i, indx) => indx !== index));
  }
  function deleteMedication(index: number) {
    setMedications(medications.filter((i, indx) => indx !== index));
  }
  useEffect(() => {
    if (defaultValue?.patientKnownAllergiesByProvider?.[0]?.id) {
      setAllergies(defaultValue?.patientKnownAllergiesByProvider);
    }
    if (defaultValue?.patientOtherMedicationsByProvider?.[0]?.value) {
      setMedications(defaultValue?.patientOtherMedicationsByProvider);
    }
  }, [defaultValue]);

  return (
    <Modal
      className=" w-2/5 overflow-visible "
      open={open}
      closeModal={() => {
        //
      }}>
      <div>
        {type === 'Allergies' ? (
          <div className="flex flex-col">
            <Typography color="gray" variant="h3">
              Known Allergies
            </Typography>
            <div className="mt-6">
              <Typography color="gray" variant="h5">
                By Patient
              </Typography>
              <div data-tooltip-id="knownAllergiesByPatient">
                <TextInput
                  disabled
                  value={defaultValue?.patientKnownAllergiesByPatient}
                  className="mt-1 pl-2"
                  type="text"
                  name="patientKnownAllergies"
                  fullWidth
                  placeholder="Enter known allergies"
                />
              </div>
              <Tooltip theme="dark" id="knownAllergiesByPatient">
                {defaultValue?.patientKnownAllergiesByPatient}
              </Tooltip>
            </div>
            <div className="flex items-center gap-x-2 ">
              <DropDown
                clearDropdownState={clearState}
                fullWidth
                className="grow-[2]"
                onChange={(v) => {
                  setAllergy(v);
                  setClearState(false);
                  setError(false);
                }}
                withInfiniteScroll
                searchable
                placeholder="Select known allergy"
                theme={ThemeName.Light}
                label="By Provider"
                options={getAllergiesQuery.data?.pages.map((i) => ({
                  title: i.description,
                  value: i?.compositeAllergyId,
                }))}
                onSearchChange={(text) => {
                  setSearchValue(text);
                }}
                onNextPage={() => {
                  getAllergiesQuery.fetchNextPage();
                }}
                isOptionsLoading={getAllergiesQuery.isLoading}
                isFetchingNextPage={getAllergiesQuery.isFetchingNextPage}
                error={error}
                helperText={error ? 'Allergy already exists' : ''}
              />
              <DropDown
                clearDropdownState={clearState}
                onChange={(v) => setSeverity(v.value as string)}
                placeholder="Select severity"
                theme={ThemeName.Light}
                className="grow-[1] "
                disableHelperText
                options={severityType}
              />
              <Button
                disabled={!allergy && !severity}
                onClick={() => {
                  if (allergy && severity) {
                    if (allergies.some((a) => a.id === allergy.value)) {
                      setError(true);

                      return;
                    }
                    setAllergies([
                      ...allergies,
                      {
                        title: allergy.title,
                        severity,
                        id: allergy.value as string,
                      },
                    ]);
                    setClearState(true);
                    setAllergy(undefined);
                    setSeverity(undefined);
                  }
                }}
                className="!py-3"
                size="large">
                ADD
              </Button>
            </div>
            <div className="flex h-60 flex-col overflow-auto ">
              <div className="">
                <div className="flex justify-between rounded rounded-b-none bg-primary-main p-2">
                  <Typography variant="h5" className="pl-6" color="white">
                    Name
                  </Typography>{' '}
                  <Typography variant="h5" color="white">
                    Severity
                  </Typography>{' '}
                  <span />
                </div>
                <div className=" h-40  overflow-auto rounded-b">
                  {allergies?.map((a, index) => (
                    <div className=" grid grid-cols-12  rounded rounded-b-none bg-background-main p-3">
                      <Typography
                        variant="subtitle2"
                        className="col-span-6 pl-5 pr-1"
                        ellipsis
                        color="gray">
                        {a.title}
                      </Typography>{' '}
                      <Typography
                        className="col-span-4"
                        variant="subtitle2"
                        color="gray">
                        {a.severity}
                      </Typography>{' '}
                      <TrashIcon
                        onClick={() => deleteAllergy(index)}
                        id={a.id}
                        className="col-span-2 h-5 w-5 cursor-pointer font-extrabold text-error-main "
                      />
                    </div>
                  ))}
                  {!allergies[0]?.id && (
                    <div className="">
                      <Typography
                        variant="h5"
                        color="textMuted"
                        className=" flex w-full justify-center bg-background-main p-2">
                        No Allergies{' '}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col">
              <Typography color="gray" variant="h3">
                Other Medications
              </Typography>
              <div className="mt-6">
                <Typography color="gray" variant="h5">
                  By Patient
                </Typography>

                <div data-tooltip-id="otherMedicationByPatient">
                  <TextInput
                    disabled
                    value={defaultValue?.patientOtherMedicationsByPatient}
                    className="mt-1 pl-2"
                    type="text"
                    name="patientOtherMedications"
                    fullWidth
                    placeholder="Enter other medications"
                  />
                </div>
                <Tooltip theme="dark" id="otherMedicationByPatient">
                  {defaultValue?.patientOtherMedicationsByPatient}
                </Tooltip>
              </div>
              <div className="flex items-center gap-x-2 ">
                <DropDown
                  clearDropdownState={clearState}
                  onChange={(v) => {
                    setMedication(v);
                    setClearState(false);
                    setError(false);
                  }}
                  withInfiniteScroll
                  searchable
                  fullWidth
                  placeholder="Select other medication"
                  theme={ThemeName.Light}
                  label="By Provider"
                  // defaultValue={getDefaultValues(
                  //   defaultValue?.patientOtherMedicationsByProvider
                  // )}
                  options={getOtherMedicationsQuery.data?.pages.map((i) => ({
                    title: i?.medMedidDesc,
                    value: i?.formularyDrugId,
                  }))}
                  onSearchChange={(text) => {
                    setSearchValue(text);
                  }}
                  onNextPage={() => {
                    getOtherMedicationsQuery.fetchNextPage();
                  }}
                  isOptionsLoading={getOtherMedicationsQuery.isLoading}
                  isFetchingNextPage={
                    getOtherMedicationsQuery.isFetchingNextPage
                  }
                  error={error}
                  helperText={error ? 'Medication already exists' : ''}
                />

                <Button
                  disabled={!medication}
                  onClick={() => {
                    if (medication) {
                      if (
                        medications.some((a) => a.value === medication.value)
                      ) {
                        setError(true);

                        return;
                      }
                      setMedications([
                        ...medications,
                        {
                          title: medication.title,

                          value: medication.value as string,
                        },
                      ]);
                      setClearState(true);
                      setMedication(undefined);
                    }
                  }}
                  className="!py-3"
                  size="large">
                  ADD
                </Button>
              </div>
              <div className="flex h-60 flex-col overflow-auto ">
                <div className="">
                  <div className="flex justify-between rounded rounded-b-none bg-primary-main p-2">
                    <Typography variant="h5" className="pl-6" color="white">
                      Name
                    </Typography>{' '}
                    <span />
                    <span />
                  </div>
                  <div className=" h-40  overflow-auto rounded-b">
                    {medications?.map((a, index) => (
                      <div className=" grid grid-cols-12  rounded rounded-b-none bg-background-main p-3">
                        <Typography
                          variant="subtitle2"
                          className="col-span-6 pl-5 pr-1"
                          ellipsis
                          color="gray">
                          {a.title}
                        </Typography>{' '}
                        <span className="col-span-4 pl-5 pr-1" />
                        <TrashIcon
                          onClick={() => deleteMedication(index)}
                          id={a.value as string}
                          className="col-span-2 h-5 w-5 cursor-pointer font-extrabold text-error-main "
                        />
                      </div>
                    ))}
                    {!medications[0]?.value && (
                      <div className="">
                        <Typography
                          variant="h5"
                          color="textMuted"
                          className=" flex w-full justify-center bg-background-main p-2">
                          No Other Medications{' '}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="mt-6 flex justify-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setSearchValue('');
                setError(false);
                setSeverity(undefined);
                setAllergy(undefined);
                setMedication(undefined);
                // setAllergies([]);
                onClose();
              }}>
              Close
            </Button>
            <span className="mx-2" />
            <Button
              loading={updateAllergiesMedicationMutation.isLoading}
              onClick={() => {
                updateAllergiesMedicationMutation.mutate(
                  {
                    id: patientId,
                    ...(type === 'Allergies' && {
                      patientKnownAllergiesNc: [...allergies],
                    }),

                    ...(type === 'Medication' && {
                      patientOtherMedicationsNc: [...medications],
                    }),
                  },
                  {
                    onSuccess: () => {
                      queryClient.refetchQueries(
                        QueryKeys.PatientAllergiesMedication.item(patientId)
                      );
                      setSearchValue('');
                      setError(false);
                      setSeverity(undefined);
                      setAllergy(undefined);
                      setMedication(undefined);
                      onClose();
                    },
                  }
                );
              }}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
