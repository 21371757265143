import React from 'react';

import { useTestosteroneCypeController } from 'hooks/hormones-new/useTestosteroneCypeController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type TestosteroneCypProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function TestosteroneCyp({ addNewPrescription }: TestosteroneCypProps) {
  const {
    TestosteroneCypeDetails,

    sig,
    setSig,

    refills,
    setRefills,
    dtd,
    setDTD,
    dose,
    doseUnit,
    dtdUnit,
    note,
    setNote,
  } = useTestosteroneCypeController();

  const prescriptionObject: PrescribedObjectType = {
    displayName: TestosteroneCypeDetails.displayName,
    name: HormoneNames.TestosteroneCyp,
    strength: TestosteroneCypeDetails.strength,
    strengthUnit: TestosteroneCypeDetails.strengthUnit,
    refills,
    sig,
    dose,
    dtd,
    dtdUnit,
    doseUnit,
    deliveryMethod: TestosteroneCypeDetails.deliveryMethod,
    family: TestosteroneCypeDetails.family,
    note,
  };

  return (
    <HormoneDetails
      showStatus={false}
      prescriptionObject={prescriptionObject}
      title={TestosteroneCypeDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: [0],
          getTooltip: (val) =>
            `${TestosteroneCypeDetails.refills[val]} refills`,
          disable: true,
        },

        {
          type: PrescriptionInputTypes.SIG,
          value: sig,
          onChange(val) {
            setSig(val);
          },
        },
        {
          type: PrescriptionInputTypes.DTD,
          value: dtd,
          onChange(val) {
            setDTD(val);
          },
          DTDValues: [10],
          unit: dtdUnit,
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',
          value: TestosteroneCypeDetails.deliveryMethod,
        },
        {
          label: 'Strength',
          value: `${TestosteroneCypeDetails.strength} ${TestosteroneCypeDetails.strengthUnit}`,
        },
        {
          label: 'Dose',
          value: `${dose} ${doseUnit}`,
        },
      ]}
    />
  );
}
