import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useDHEAController } from 'hooks/hormones-new/useDHEAController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type DHEAProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function DHEA({ addNewPrescription }: DHEAProps) {
  const [searchParams] = useSearchParams();

  const medicationId = searchParams.get('id');
  const {
    DHEADetails,

    sig,
    setSig,
    dtd,

    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useDHEAController();

  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || undefined,
    displayName: DHEADetails.displayName,
    name: HormoneNames.DHEA,
    strength: DHEADetails.strength,
    strengthUnit: DHEADetails.strengthUnit,

    dose: DHEADetails.dose[dose + maximumDose],
    minimumDose: DHEADetails.dose[dose],
    doseUnit: DHEADetails.doseUnit,
    refills,
    dtd,
    sig,
    deliveryMethod: DHEADetails.deliveryMethod,
    family: DHEADetails.family,
    note,
  };

  return (
    <HormoneDetails
      prescriptionObject={prescriptionObject}
      statusUnit={DHEADetails.statusUnit}
      title={DHEADetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Text,
          label: 'Strength',
          value: `${DHEADetails.strength} ${DHEADetails.strengthUnit}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: DHEADetails.dose,
          getTooltip: (val) =>
            `${`${DHEADetails.dose[val]} ${DHEADetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: DHEADetails.refills,
          getTooltip: (val) => `${DHEADetails.refills[val]} refills`,
        },

        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: DHEADetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
          },
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: DHEADetails.deliveryMethod,
        },
        {
          label: 'D.T.D',
          value: `${dtd.toString()} ml`,
        },
      ]}
    />
  );
}
