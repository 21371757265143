import { useState } from 'react';

export const useDanazolController = () => {
  const [mg, setMg] = useState(0);
  const [sig, setSig] = useState('Take 1 daily as directed');
  const [DTD, setDTD] = useState(30);
  const [refills, setRefils] = useState(0);

  return {
    refills,
    DTD,
    DTDInWords: `${DTD} ml`,
    mg,
    setDTD,
    setRefils,
    sig,
    setSig,
    setMg,
  };
};
