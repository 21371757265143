// eslint-disable-next-line import/extensions

// eslint-disable-next-line
import { AvatarDropdown } from 'components/general/AvatarDropdown/AvatarDropdown';
import { useProfile } from 'hooks/useProfile';

import briteLogo from '../../../assets/images/LOGO.png';

export function Header() {
  const { profile } = useProfile();

  return (
    <div className="w-full  rounded bg-background-main  py-2">
      <div className="flex items-center justify-between px-8">
        <figure className="relative h-[2.5rem] w-max max-w-[8rem]  ">
          <img
            className="h-full object-contain"
            src={profile?.practice?.logoUrl || briteLogo}
            alt={profile?.practice?.name}
          />
        </figure>
        <div className="flex items-center space-x-4">
          {/* <SwitchDarkMode /> */}

          <AvatarDropdown />
        </div>
      </div>
    </div>
  );
}
