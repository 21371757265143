import { useState } from 'react';

export const useTestosteroneM = () => {
  const [mg, setMg] = useState(0);
  const [sig, setSig] = useState('0.1 - 2ml daily as directed');
  const [DTD, setDTD] = useState(30);
  const [refills, setRefils] = useState(0);
  const mgml = 186;

  return {
    refills,
    DTD,
    DTDInWords: `${DTD} ml`,
    mg,
    setDTD,
    setRefils,
    sig,
    setSig,
    setMg,
    mgml,
  };
};
