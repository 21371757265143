import React from 'react';

import classNames from 'classnames';

import { Typography } from 'components/common/Typography/Typography';
import { InfoBoxType } from 'types/prescriptionTypes';

export function InfoDisplayBox({
  isLoading,
  value,
  label,
  className,
  colorClass,
  helperText,
}: InfoBoxType) {
  return (
    <div className={classNames('  min-h-[5rem] min-w-[10rem]  ', className)}>
      {isLoading === true ? (
        <>
          {' '}
          <div className="  h-[20px] w-16  animate-pulse rounded bg-background-light" />
          <div className=" mt-1  h-[34px]   animate-pulse rounded bg-background-light" />
        </>
      ) : (
        <>
          <Typography
            className="  whitespace-nowrap"
            variant="subtitle2"
            align="left">
            {label}
          </Typography>
          <Typography
            color="white"
            variant="h5"
            className={classNames(
              'mt-1 !flex !justify-center rounded bg-primary-main py-2 px-4 ',
              colorClass
            )}>
            {value}
          </Typography>
          {!!helperText && (
            <div className="mt-1 flex justify-center">{helperText}</div>
          )}
        </>
      )}
    </div>
  );
}
