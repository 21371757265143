import React, { ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';

type FullHeightModalProps = {
  isOpen: boolean;
  children: ReactNode;
};

export function FullHeightModal({ isOpen, children }: FullHeightModalProps) {
  //   const navigate = useNavigate();

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        //
      }}>
      {' '}
      <Transition show={isOpen}>
        {isOpen && (
          <div className="fixed inset-0 z-50 flex h-screen w-screen    overflow-auto bg-background-dark  ">
            <Transition.Child
              className="w-full"
              enter="transform transition ease-out duration-500"
              enterFrom="translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transform transition ease-in duration-500"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="translate-y-full opacity-0">
              <div className="  container  z-10   bg-background-dark ">
                {children}
              </div>
            </Transition.Child>
          </div>
        )}
      </Transition>
    </Dialog>
  );
}
