import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { useLicenseDeaExpirationDateChecker } from 'hooks/useLicenseDeaExpirationDateChecker';

export function DEALicenseExpirationReminderTile() {
  const { checkIfAllRecordsHasExpirationDate } =
    useLicenseDeaExpirationDateChecker();
  const navigate = useNavigate();

  if (checkIfAllRecordsHasExpirationDate()) {
    return null;
  }

  return (
    <div className="mt-5 w-full rounded py-2">
      <div className="flex  gap-4 rounded bg-error-main bg-opacity-50 px-4 py-3 text-background-contrastText">
        <ExclamationCircleIcon className="h-10 w-10  font-extrabold text-inherit " />
        <div>
          <Typography variant="subtitle2">
            Expiration dates are missing for some or all of your licenses.
            Please complete before submitting your next prescription.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="mt-3"
            onClick={() => {
              navigate('/app/user/profile', {
                state: {
                  view: 'dea-license',
                },
              });
            }}>
            Let&apos;s do it
          </Button>
        </div>
      </div>
    </div>
  );
}
