import React, { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { QueryKeys } from 'constants/query-keys';
import { addressSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { PatientService } from 'services/patient';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { AddressFormType } from 'types/common';
import { AddressType } from 'types/onboard';

type AddressModalProps = {
  open: boolean;
  onModalClose: () => void;
  patientId: string;
  addressDetail?: AddressType;
  updating?: boolean;
};

export function AddPatientShippingAddressModal({
  open,
  onModalClose,
  updating,
  addressDetail,
  patientId,
}: AddressModalProps) {
  const queryClient = useQueryClient();

  const updateAddressMutation = useMutationWithToast(
    PatientService.updatePatientShippingAddress
  );
  const getStatesQuery = useQuery(
    [QueryKeys.States],
    () => UserService.getStates(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { handleSubmit, control, reset, getValues } =
    useFormWithErrors<AddressFormType>({
      mutation: updateAddressMutation,
      schema: addressSchema,
    });

  const States = (getStatesQuery.data?.data || []).map((item) => ({
    title: item.name,
    value: item.id,
  }));

  const onSubmit = handleSubmit((data) => {
    updateAddressMutation.mutate(
      {
        patientId,
        address: {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2 || '',
          city: data.city,
          country: data.country,
          stateId: data.stateId,
          zipCode: data.zipCode,
        },
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries(QueryKeys.PatientDetails.item(patientId));
          onModalClose();
        },
      }
    );
  });

  useEffect(() => {
    if (updating && addressDetail) {
      reset({
        ...addressDetail,
        addressLine2: addressDetail.addressLine2,
        country: 'USA',
        stateId: addressDetail.state?.id,
      });

      return;
    }
    reset(
      {
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateId: '',
        zipCode: '',
        country: 'USA',
      },
      {
        keepValues: false,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);

  return (
    <Modal
      title={`${updating ? 'Update' : 'Add'} Shipping Address`}
      open={open}
      className="w-[55%] overflow-visible"
      closeModal={() => {
        // if (addressMutation.isLoading) return;
        // onModalClose();
        // addressMutation.reset();
        // reset();
      }}>
      <div className="mt-4 flex flex-col ">
        <div>
          <div className="grid grid-cols-2 gap-x-5">
            <TextInput
              className="w-full"
              type="text"
              name="addressLine1"
              control={control}
              fullWidth
              label="Address Line 1"
              placeholder="Address Line 1"
            />
            <TextInput
              className="w-full"
              type="text"
              name="addressLine2"
              control={control}
              fullWidth
              label="Address line 2"
              placeholder="Address line 2"
            />
          </div>
          <div className="grid grid-cols-4 gap-x-5">
            <TextInput
              className="w-full"
              type="text"
              name="city"
              control={control}
              fullWidth
              label="City"
              placeholder="Name of the city"
            />
            <DropDown
              searchable
              fullWidth
              label="State"
              placeholder="Select a state"
              name="stateId"
              control={control}
              theme={ThemeName.Light}
              options={States}
              defaultValue={States.find(
                (s) => s.value === getValues('stateId')
              )}
              //   className="relative"
            />
            <TextInput
              className="w-full"
              name="zipCode"
              control={control}
              fullWidth
              placeholder="ZIP Code"
              label="ZIP Code"
            />
            <TextInput
              disabled
              className="w-full"
              type="text"
              name="country"
              control={control}
              //   helperText={getValidationError('country').msg}
              //   error={getValidationError('country').hasError}
              fullWidth
              placeholder="Name of the country"
              label="Country"
            />
          </div>

          <div className="mt-6 flex justify-end">
            <Button
              color="secondary"
              variant="contained"
              //   disabled={
              //     updating
              //       ? updateAddressMutation.isLoading
              //       : addressMutation.isLoading
              //   }
              onClick={() => {
                reset();
                onModalClose();
              }}>
              Close
            </Button>{' '}
            <span className="mx-2" />
            <Button
              onClick={onSubmit}
              loading={updateAddressMutation.isLoading}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
