import React from 'react';

import { TitrationDetails } from 'components/prescriptions/tiration-details/TitrationDetails';

export function CommonStartingOption() {
  return (
    <div>
      <TitrationDetails titrationType="Common Starting Rx" />
    </div>
  );
}
