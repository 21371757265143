import { useCallback } from 'react';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';

import { Typography } from 'components/common/Typography/Typography';
import { HormoneNames } from 'types/prescriptionTypes';

type CalculateDifferenceProps = {
  hormoneName: HormoneNames;
};

export function useCalculateDifference({
  hormoneName,
}: CalculateDifferenceProps) {
  const getDifferenceUI = useCallback(
    (diff: number | string, increasedFromValue: string) => {
      const difference = Number(diff);

      if (difference === 0) {
        return (
          <Typography className="!flex " variant="subtitle2">
            No change from prior dose
          </Typography>
        );
      }
      if (difference > 0) {
        return (
          <div className="w-full">
            <Typography className="!flex " variant="subtitle2">
              {' '}
              <div>
                <dfn className="items-center whitespace-nowrap  pr-1 not-italic text-primary-main">
                  <ArrowUpIcon className="!inline h-4 w-4 font-extrabold " />
                  {Number(difference).toFixed(0)}%
                </dfn>
                increase from {increasedFromValue}
              </div>
            </Typography>
          </div>
        );
      }

      return (
        <div className="w-full">
          <Typography className="!flex " variant="subtitle2">
            {' '}
            <div>
              <dfn className=" items-center whitespace-nowrap  pr-1 not-italic text-error-main">
                <ArrowDownIcon className="!inline h-4 w-4 font-extrabold " />{' '}
                {Number(difference).toFixed(0).replace('-', '')}%
              </dfn>{' '}
              decrease from {increasedFromValue}
            </div>
          </Typography>
        </div>
      );
    },
    []
  );

  const calculatePercentageDifference = useCallback(
    (oldValue = '', newValue = '') => {
      let difference = 0;
      let percentageDifference = 0;
      const isTestoDhea = !!(
        HormoneNames.TESTO_PER_DHEA.toLowerCase() ===
        hormoneName.toLocaleLowerCase()
      );
      if (isTestoDhea) {
        difference =
          Number(`${newValue}`.split('/')?.[1]) -
          Number(`${oldValue}`.split('/')?.[1]);
        percentageDifference =
          (difference / Number(`${oldValue}`.split('/')?.[1])) * 100;
      } else {
        difference = Number(newValue) - Number(oldValue);
        percentageDifference = (difference / Number(oldValue)) * 100;
      }

      if (Number.isNaN(percentageDifference)) {
        return 0;
      }

      if (
        percentageDifference === 0 ||
        (Number(oldValue) === 0 && Number(newValue) === 0)
      ) {
        return 0;
      }

      return percentageDifference;
    },
    [hormoneName]
  );

  return { calculatePercentageDifference, getDifferenceUI };
}
