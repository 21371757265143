import React, { useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';

import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { usePregnylController } from 'hooks/hormones-new/usePregnylController';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function Pregnyl({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();

  const {
    PregnylDetails,

    dtd,

    refills,
    setRefills,

    setSig,
    sig,
    dose,
    doseUnit,
  } = usePregnylController();

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: PregnylDetails.displayName,
      name: HormoneNames.Pregnyl,
      strength: PregnylDetails.strength,
      strengthUnit: PregnylDetails.strengthUnit,
      dose: PregnylDetails.dose,
      doseUnit: PregnylDetails.doseUnit,
      refills,
      dtd,
      sig,
      deliveryMethod: PregnylDetails.deliveryMethod,
      family: PregnylDetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: PregnylDetails.dtdUnit,
    }),
    [PregnylDetails, RxDetails, dtd, sig, refills]
  );

  const { getStandardizedNameQuery } = useHormoneStandardizedName({
    prescription: { ...prescriptionObject, dose: PregnylDetails.dose },
  });

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStandardizedNameQuery.data?.data.standardRepresentation]
  );

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
        })
      }
      validations={{
        [HormoneValidations.Loading]: {
          isFailed: !!getStandardizedNameQuery.isLoading,
        },
        [HormoneValidations.MissingDEA]: {
          isFailed:
            !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: false,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(sig.length) > 140,
        },
      }}
      prescriptionObject={{ ...prescriptionObject, ...prescriptionCheckValues }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex justify-between',
          title: '',
          children: [
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${PregnylDetails.strength} ${PregnylDetails.strengthUnit}`,
              label: 'Strength',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ml`,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dose} ${doseUnit}`,
              label: 'Dose',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: PregnylDetails.deliveryMethod,
              label: 'Delivery Method',
            },
          ],
        },

        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) => `${PregnylDetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: PregnylDetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setSig(s);
              },
              value: sig,
            },
          ],
        },
      ]}
    />
  );
}
