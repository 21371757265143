import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useLevothyroxineController } from 'hooks/hormones-new/useLevothyroxineController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type LevothyroxineProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function Levothyroxine({ addNewPrescription }: LevothyroxineProps) {
  const [searchParams] = useSearchParams();
  const medicationId = searchParams.get('id');
  const {
    LevothyroxineDetails,

    sig,
    setSig,
    dtd,
    setDTD,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useLevothyroxineController();

  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || '',
    displayName: LevothyroxineDetails.displayName,
    name: HormoneNames.LEVOTHYROXINE,
    strength: LevothyroxineDetails.strength,
    strengthUnit: LevothyroxineDetails.strengthUnit,
    dose: LevothyroxineDetails.dose[dose + maximumDose],
    minimumDose: LevothyroxineDetails.dose[dose],
    doseUnit: LevothyroxineDetails.doseUnit,
    refills,
    dtd,
    sig,
    dtdUnit: LevothyroxineDetails.dtdUnit,
    deliveryMethod: LevothyroxineDetails.deliveryMethod,
    family: LevothyroxineDetails.family,
    note,
  };

  return (
    <HormoneDetails
      showStatus={false}
      prescriptionObject={prescriptionObject}
      title={LevothyroxineDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: LevothyroxineDetails.dose,
          getTooltip: (val) =>
            `${`${LevothyroxineDetails.dose[val]} ${LevothyroxineDetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: LevothyroxineDetails.refills,
          getTooltip: (val) => `${LevothyroxineDetails.refills[val]} refills`,
        },
        {
          type: PrescriptionInputTypes.DTD,
          value: dtd,
          unit: LevothyroxineDetails.dtdUnit,
          onChange(val) {
            setDTD(val);
          },
          DTDValues: LevothyroxineDetails.dtd,
        },
        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: LevothyroxineDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
          },
          unit: LevothyroxineDetails.doseUnit,
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: LevothyroxineDetails.deliveryMethod,
        },
        {
          label: 'Strength',

          value: `${LevothyroxineDetails.strength} ${LevothyroxineDetails.strengthUnit}`,
        },
      ]}
    />
  );
}
