import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useDebounce } from 'hooks/useDebounce';
import { transformMedication } from 'pages/app/patients/[id]/prescriptions/prescribe/common';
import { PatientPrescriptionService } from 'services/prescription';
import { PrescriptionCheckType } from 'types/patient/prescription';
import { PrescribedObjectType } from 'types/prescriptionTypes';

type CheckPrescriptionHookProp = {
  prescription: PrescribedObjectType;
  onSuccess: (data: PrescriptionCheckType) => void;
};

export function useCheckPrescription({
  prescription,
  onSuccess,
}: CheckPrescriptionHookProp) {
  const { id: patientId } = useParams();
  const debouncedValue = useDebounce<PrescribedObjectType>(
    prescription as PrescribedObjectType,
    500
  );
  const checkPrescriptionValueQuery = useQuery(
    [debouncedValue],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: transformMedication(debouncedValue as PrescribedObjectType),
      }),
    {
      enabled: !!patientId && !!prescription?.id,
      onSuccess: (data) => {
        onSuccess(data?.data);
      },
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return { checkPrescriptionValueQuery };
}
