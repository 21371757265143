import React from 'react';

import { TitrationDetails } from 'components/prescriptions/tiration-details/TitrationDetails';

export function EnhancedStartingOption() {
  return (
    <div>
      <TitrationDetails titrationType="Enhanced Starting Rx" />
    </div>
  );
}
