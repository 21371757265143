import React from 'react';

import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { ThemeName } from 'theme/types';

type ChangeDoseConfirmationModalProps = {
  loading: boolean;
  open: boolean;
  prescriptionName: string;
  prescriptionDifference: {
    UI: JSX.Element | string;
    difference: number;
    currentValue: string | number;
    originalValue: string | number;
  };
  unit: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export function ChangeDoseConfirmationModal({
  open,
  prescriptionName,
  prescriptionDifference,
  unit,
  loading,
  onCancel,
  onConfirm,
}: ChangeDoseConfirmationModalProps) {
  return (
    <Modal
      className="h-60 w-1/2 max-w-[700px] px-4"
      open={open}
      closeModal={() => {
        //
      }}>
      <SkeletonContent
        theme={ThemeName.Light}
        layout={[
          {
            className: 'flex h-60 flex-col w-full gap-6 ',
            children: [
              {
                className: 'w-full h-20',
              },
              {
                className: 'w-60 h-4 mt-2',
              },
              {
                className: ' flex gap-8 self-end',
                children: [
                  {
                    className: 'w-24 h-8',
                  },
                  {
                    className: 'w-24 h-8',
                  },
                ],
              },
            ],
          },
        ]}
        isLoading={loading}>
        {() => (
          <div className="flex flex-col gap-4">
            <Typography variant="h3">
              Do you wish to apply these changes?
            </Typography>
            <Typography variant="subtitle1" className="flex h-20 !items-start">
              {prescriptionDifference?.difference === 0 ? (
                <span>
                  You have reset the dose of{' '}
                  <span className="font-bold text-primary-main">
                    {prescriptionName}.
                  </span>{' '}
                </span>
              ) : (
                <span>
                  You have changed the current dose of{' '}
                  <span className="font-bold text-primary-main">
                    {prescriptionName}
                  </span>{' '}
                  from{' '}
                  <span className="font-bold text-primary-main">
                    {prescriptionDifference?.originalValue} {unit}
                  </span>{' '}
                  to{' '}
                  <span className="font-bold text-primary-main">
                    {prescriptionDifference?.currentValue} {unit}
                  </span>
                  . This is a{' '}
                  <span className="font-bold text-primary-main">
                    {prescriptionDifference?.difference
                      .toFixed(1)
                      .toString()
                      .replace('-', '')}
                    %{' '}
                    {prescriptionDifference?.difference < 0
                      ? 'decrease'
                      : 'increase'}
                  </span>{' '}
                  compared to the existing dose.
                </span>
              )}
            </Typography>

            <div className="mt-4 flex gap-8 self-end ">
              <Button onClick={onCancel} color="secondary">
                Cancel
              </Button>
              <Button onClick={onConfirm}>Confirm</Button>
            </div>
          </div>
        )}
      </SkeletonContent>
    </Modal>
  );
}
