import React, { useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';

import { useCheckPrescription } from 'hooks/e-prescribe/useCheckPrescription';
import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { useMaximumDoseHandler } from 'hooks/e-prescribe/useMaximumDoseHandler';
import { useSigGenerator } from 'hooks/e-prescribe/useSigGenerator';
import { useT4PerLAT3Controller } from 'hooks/hormones-new/useT4PerLAT3Controller';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { colorObjForSupply } from '../common';
import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function T4PerLAT3({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();

  const {
    T4PerLATDetails,

    dtd,
    dose,
    setDose,
    refills,
    setRefills,
    strength,
    setStrength,
  } = useT4PerLAT3Controller();

  const { maximumDose, setMaximumDose, maxDoseOptions, setMaxDosePreValue } =
    useMaximumDoseHandler({
      dose,
      doses: T4PerLATDetails.dose,
    });

  const { generatedSIG, setGeneratedSIG } = useSigGenerator({
    dose,
    maximumDose,
    doseOptions: T4PerLATDetails?.dose,
    unit: T4PerLATDetails.doseUnit,
  });

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: T4PerLATDetails.displayName,
      name: HormoneNames.T4_PER_LAT3,
      strength: T4PerLATDetails.strength[strength],
      strengthUnit: T4PerLATDetails.strengthUnit,
      ratioUnit: T4PerLATDetails.ratioUnit,
      dose: T4PerLATDetails.dose[maximumDose + dose],
      minimumDose: T4PerLATDetails.dose[dose],
      doseUnit: T4PerLATDetails.doseUnit,
      refills,
      dtd,
      sig: generatedSIG,
      deliveryMethod: T4PerLATDetails.deliveryMethod,
      family: T4PerLATDetails.family,
      dtdUnit: T4PerLATDetails.dtdUnit,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
    }),
    [
      T4PerLATDetails,
      RxDetails,
      dose,
      dtd,
      generatedSIG,
      maximumDose,

      refills,
      strength,
    ]
  );

  const { checkPrescriptionValueQuery: maxDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: T4PerLATDetails.dose[dose + maximumDose],
      },
      onSuccess() {
        // if (T4PerLATDetails.ratio[ratio] === '80:20') {
        //   if (Number(data?.value) > 4.05) {
        //     if (strength !== 60) {
        //       // renderModal(strength.toString(), '60', 'mgeeq/day');
        //       setDose(4);
        //       setMaximumDose(0);
        //     }
        //     setStrength(60);
        //   } else {
        //     setStrength(30);
        //     if (strength !== 30) {
        //       // renderModal(strength.toString(), '30', 'mgeeq/day');
        //     }
        //   }
        // }
      },
    });

  const { checkPrescriptionValueQuery: minDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: T4PerLATDetails.dose[dose],
      },
      onSuccess() {
        // console.log(data);
      },
    });

  const { getStandardizedNameQuery } = useHormoneStandardizedName({
    prescription: { ...prescriptionObject, dose: T4PerLATDetails.dose[dose] },
  });

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      daysOfSupply: {
        withMaxDose:
          maxDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
        withMinDose:
          minDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
      },
      isHighPotencyRx: false,
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      maxDosePrescriptionValues,
      minDosePrescriptionValues,
      getStandardizedNameQuery.data?.data.standardRepresentation,
    ]
  );

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
        })
      }
      prescriptionObject={{ ...prescriptionObject, ...prescriptionCheckValues }}
      validations={{
        [HormoneValidations.Loading]: {
          isFailed:
            maxDosePrescriptionValues.isLoading ||
            minDosePrescriptionValues.isLoading ||
            getStandardizedNameQuery.isLoading,
        },
        [HormoneValidations.MissingDEA]: {
          isFailed:
            !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: !maxDosePrescriptionValues.data?.data.validDaysOfSupply,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(generatedSIG.length) > 140,
        },
      }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex justify-between',
          title: '',
          children: [
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ${T4PerLATDetails.dtdUnit}`,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: T4PerLATDetails.deliveryMethod,
              label: 'Delivery Method',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                !!minDosePrescriptionValues.isLoading,
              value:
                maxDosePrescriptionValues.data?.data.daysOfSupply ===
                minDosePrescriptionValues.data?.data?.daysOfSupply
                  ? `${maxDosePrescriptionValues.data?.data.daysOfSupply} Days`
                  : `${maxDosePrescriptionValues.data?.data.daysOfSupply}-${minDosePrescriptionValues.data?.data?.daysOfSupply} Days`,
              label: 'Days of Supply',
              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForSupply[
                      maxDosePrescriptionValues.data?.data.validDaysOfSupply.toString()
                    ]
                  : ''
              }`,
            },
          ],
        },
        {
          title: 'Strength',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-16 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              value: strength,
              onChange(value) {
                setStrength(value);
              },
              options: T4PerLATDetails.strength,
              getTooltip: (val) =>
                `${`${T4PerLATDetails.strength[val]} ${T4PerLATDetails.strengthUnit}`}`,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          title: 'Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-16 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${T4PerLATDetails.dose[val]} ${T4PerLATDetails.doseUnit}`}`,
              onChange: (d) => {
                setDose(d);
              },
              options: T4PerLATDetails.dose,
              value: dose,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          title: 'Maximum Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-16 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${maxDoseOptions[val]?.toFixed(2)} ${
                  T4PerLATDetails.doseUnit
                }`}`,
              onChange: (d) => {
                setMaxDosePreValue(maxDoseOptions[d]);
                setMaximumDose(d);
              },
              options: maxDoseOptions,
              value: maximumDose,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) => `${T4PerLATDetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: T4PerLATDetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setGeneratedSIG(s);
              },
              value: generatedSIG,
            },
          ],
        },
      ]}
    />
  );
}
