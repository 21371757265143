import React, { useCallback, useEffect, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Modal } from 'components/common/Modal/Modal';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { useProfile } from 'hooks/useProfile';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { GetProviderPracticesAddressesResponse } from 'types/provider';

type SwitchPracticeModalProps = {
  open: boolean;
  onClose: () => void;
};
const resultSize = 10;
export function SwitchPracticeModal({
  onClose,
  open,
}: SwitchPracticeModalProps) {
  const { profile, userProfileQuery } = useProfile();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //   const queryClient = useQueryClient();

  const [switchedPractice, setSwitchedPractice] = useState<string>('');

  const [practiceAddresses, setPracticeAddresses] = useState<{
    [x: string]: GetProviderPracticesAddressesResponse[];
  }>({});
  const getProviderPracticesQuery = usePaginatedQuery(
    QueryKeys.ProviderPractices.listing({
      providerId: profile?.id,
      size: resultSize,
    }),
    ({ pageParam = 1 }) =>
      UserService.getProviderPractices({
        size: resultSize,
        page: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const getProviderPracticeAddressesMutation = useMutation(
    (id: string) => UserService.getProviderPracticeAddresses(id),
    {
      onSuccess(data, variables) {
        setPracticeAddresses((prev) => ({
          ...prev,
          [variables]: [...data.data],
        }));
      },
    }
  );

  const onProviderPracticesScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = event.currentTarget;
      if (getProviderPracticesQuery.isFetchingNextPage) return;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
        getProviderPracticesQuery.fetchNextPage();
      }
    },
    [getProviderPracticesQuery]
  );

  const switchPracticeMutation = useMutationWithToast(
    UserService.switchPractice,
    {
      onSuccess() {
        setLoading(true);
        Promise.all([
          userProfileQuery.refetch(),
          queryClient.refetchQueries(
            QueryKeys.Patients.listing({
              page: 1,
              search: '',
              archived: 'no',
            })
          ),
          getProviderPracticesQuery.refetch(),
        ]).then(() => {
          setLoading(false);
          setPracticeAddresses({});
          setSwitchedPractice('');
          onClose();

          navigate('/app/patients/active?active_pt_page=1');
        });
      },
    }
  );

  function getAddresses() {
    getProviderPracticesQuery.data?.pages.forEach((i) =>
      getProviderPracticeAddressesMutation.mutateAsync(i.practice.id)
    );
  }
  useEffect(() => {
    if (getProviderPracticesQuery.data?.pages?.[0]?.id && open) {
      getAddresses();
    }
    // eslint-disable-next-line
  }, [open, getProviderPracticesQuery.data?.pages]);

  return (
    <Modal
      open={open}
      className=" w-2/3"
      closeModal={() => {
        //
      }}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            Select the one you want to switch to.
          </Typography>
          {practiceAddresses[profile?.practiceAddress?.id as string]?.length ===
            1 &&
          practiceAddresses[profile?.practiceAddress?.id as string][0].address
            .id === profile?.practiceAddress?.id ? (
            // eslint-disable-next-line
            <div className="flex h-[70vh] flex-col overflow-auto border p-6">
              <Typography color="textMuted" variant="subtitle1">
                No practices and locations found to switch.
              </Typography>
            </div>
          ) : (
            // eslint-disable-next-line
            <div
              onScroll={onProviderPracticesScroll}
              className="flex h-[70vh] flex-col overflow-auto rounded-lg   p-4 pt-0">
              <SkeletonContent
                theme={ThemeName.Light}
                layout={[
                  {
                    className: 'grid  gap-4  p-4 rounded-lg',
                    children: new Array(3).fill(0).map(() => ({
                      className: 'pb-4 grid gap-4',
                      children: [
                        {
                          className: 'flex items-center justify-between',
                          children: [
                            {
                              className: 'flex space-x-4 items-center',
                              children: [
                                {
                                  className: 'h-[45px] w-[45px] rounded-[90%]',
                                },
                                {
                                  className: 'pl-8 h-4 w-[120px]',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          className: 'ml-[60px] flex flex-col space-y-4',
                          children: new Array(3).fill(0).map(() => ({
                            className: 'h-4 w-[400px]',
                          })),
                        },
                      ],
                    })),
                  },
                ]}
                isLoading={getProviderPracticesQuery.isLoading || loading}>
                {() => (
                  <>
                    {' '}
                    {getProviderPracticesQuery.data?.pages?.map((practice) => (
                      <div className="flex  gap-4 border-b py-6">
                        {' '}
                        <Avatar
                          sizeClass="h-12 w-12 "
                          imgUrl={practice.practice.logoUrl}
                        />
                        <div className="flex w-full flex-col gap-2">
                          <Typography variant="h4">
                            {practice.practice.name}
                          </Typography>
                          <SkeletonContent
                            theme={ThemeName.Light}
                            layout={[
                              {
                                className: 'w-80 mt-2 flex h-4 items-center ',
                              },
                            ]}
                            isLoading={
                              getProviderPracticeAddressesMutation.isLoading
                            }>
                            {() => (
                              <div className="flex w-full flex-col gap-2 ">
                                {practiceAddresses[practice.practice.id]?.map(
                                  (address) => (
                                    // eslint-disable-next-line
                                    <div
                                      onClick={
                                        address.isActive
                                          ? () => {
                                              if (
                                                address.address.id ===
                                                profile?.practiceAddress.id
                                              ) {
                                                return;
                                              }
                                              setSwitchedPractice(address.id);
                                            }
                                          : undefined
                                      }
                                      className={classNames(
                                        'flex w-[90%] cursor-pointer items-center gap-2 rounded-md  border border-white  bg-background-main  p-2 py-3 ',
                                        {
                                          'border-1 !border-primary-main':
                                            address.id === switchedPractice,
                                          'cursor-not-allowed !opacity-50':
                                            !address.isActive,
                                        }
                                      )}>
                                      <Typography
                                        className="w-[90%]"
                                        variant="subtitle2">
                                        {`${`${
                                          address.address.addressLine1
                                        }, ${`${
                                          address.address?.addressLine2
                                            ? `${address.address.addressLine2},`
                                            : ''
                                        }`} ${address.address.city}, ${
                                          address.address.state?.name
                                        } ${address.address.zipCode}, ${
                                          address.address.country
                                        }.`}`}
                                      </Typography>
                                      {address.address.id ===
                                      profile?.practiceAddress.id ? (
                                        <Typography
                                          className="mr-4 rounded bg-primary-main p-2 py-[2px]"
                                          color="white">
                                          Current
                                        </Typography>
                                      ) : (
                                        <CheckBox
                                          disable={!address.isActive}
                                          checked={
                                            address.id === switchedPractice
                                          }
                                          id=""
                                          label=""
                                          onChange={(value) => {
                                            if (value) {
                                              setSwitchedPractice(address.id);
                                            }
                                          }}
                                        />
                                      )}
                                    </div>
                                  )
                                )}{' '}
                              </div>
                            )}
                          </SkeletonContent>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </SkeletonContent>
            </div>
          )}
          <div
            className={classNames('invisible flex justify-center', {
              '!visible': getProviderPracticesQuery.isFetchingNextPage,
            })}>
            <LoadingSpinner className="h-[1.5rem] w-[1.5rem]  text-primary-main" />
          </div>
        </div>
        <div className="flex gap-4 self-end">
          <Button
            disabled={switchPracticeMutation.isLoading || loading}
            onClick={() => {
              setSwitchedPractice('');
              onClose();
            }}
            color="secondary">
            Close
          </Button>
          <Button
            disabled={!switchedPractice}
            loading={switchPracticeMutation.isLoading || loading}
            onClick={() => {
              switchPracticeMutation.mutate(switchedPractice);
            }}>
            Switch
          </Button>
        </div>
      </div>
    </Modal>
  );
}
