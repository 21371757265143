import { useState } from 'react';

export const useLevothyroxineController = () => {
  // const [mgPerMl, setMgP] = useState(10);
  const [mg, setMg] = useState(0);
  const [sig, setSig] = useState('');
  const [DTD, setDTD] = useState(30);
  const [refills, setRefils] = useState(0);

  const mgPerML = 50;

  return {
    refills,
    DTD,
    DTDInWords: `${DTD} ml`,
    mgPerML,
    setDTD,
    setRefils,
    sig,
    setSig,
    mg,
    setMg,
  };
};
