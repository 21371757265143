import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { APIListingResponse } from 'services/api-service';

export function usePaginatedQuery<
  TQueryFnData extends AxiosResponse<
    APIListingResponse<unknown>
  > = AxiosResponse<APIListingResponse<unknown>>,
  TError = unknown,
  TData = TQueryFnData['data']['items'][number],
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey
    >,
    'queryKey' | 'queryFn'
  >
) {
  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    {
      getNextPageParam: ({ data: { page, size, total } }) =>
        size * page < total ? page + 1 : undefined,
      getPreviousPageParam: ({ data: { page } }) =>
        page > 1 ? page - 1 : undefined,

      select: (data) => ({
        pageParams: data.pageParams,
        pages: data.pages.map((item) => item.data.items).flat(1) as TData[],
      }),

      ...options,
    }
  );
}
