import React, { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';

import { ScrollableTabBar } from 'components/common/ScrollableTabBar/ScrollableTabBar';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { QuestionnaireService } from 'services/questionnaire';
import { PatientDetailsContextType } from 'types/patient';
import {
  QuestionnaireResponseType,
  SummaryQuestionType,
  Tab,
} from 'types/questionnaire';

import { getSectionWiseQuestions } from './questionnaireHelper';
import { QuestionnairePDF } from './QuestionnairePDF';
import { QuestionnaireSectionDetails } from './QuestionnaireSectionDetails';
import { QuestionnaireSkeleton } from './QuestionnaireSkeleton';

const getGender = {
  M: 'male',
  F: 'female',
};

export function InitialQuestionnaire() {
  const [searchParams] = useSearchParams();
  // const [viewName, setViewName] = useState('summary');

  const navigate = useNavigate();
  const section = searchParams.get('section') || '';
  const { gender, id, firstName, lastName, dob, address } =
    useOutletContext<PatientDetailsContextType>();
  const getQuestionnaireResponsesQuery = useQuery(
    QueryKeys.PatientQuestionnaire.listing({ gender, id }),
    () =>
      QuestionnaireService.getQuestionnaireResponses({
        gender: getGender[gender],
        id,
      }),

    {
      enabled: !!id,
      retry: 0,
    }
  );

  const {
    sectionWiseQuestions,
    filteredTabs,
    filteredSummaryQuestions,
  }: {
    sectionWiseQuestions: QuestionnaireResponseType;
    filteredTabs: Tab[];
    filteredSummaryQuestions: SummaryQuestionType;
  } = useMemo(() => {
    if (getQuestionnaireResponsesQuery?.data?.data) {
      return getSectionWiseQuestions(
        getQuestionnaireResponsesQuery?.data.data,
        gender
      );
    }

    return {
      sectionWiseQuestions: {} as QuestionnaireResponseType,
      filteredTabs: [] as Tab[],
      filteredSummaryQuestions: {} as SummaryQuestionType,
    };
  }, [getQuestionnaireResponsesQuery?.data, gender]);

  useEffect(() => {
    if (filteredTabs[0] && !section) {
      navigate({ search: `?section=${'summary'}` }, { replace: true });
    }
    // eslint-disable-next-line
  }, [filteredTabs, navigate]);

  return (
    <div className=" grid grid-cols-12">
      {getQuestionnaireResponsesQuery?.isLoading ? (
        <QuestionnaireSkeleton />
      ) : (
        <div className="  col-span-12    ">
          {(!filteredTabs[0] &&
            getQuestionnaireResponsesQuery.status === 'success') ||
          getQuestionnaireResponsesQuery.isError ? (
            <div className=" flex h-[7rem] items-center  justify-center rounded bg-background-light ">
              <Typography variant="h4" color="textMuted">
                Questionnaire not submitted
              </Typography>
            </div>
          ) : (
            <>
              <ScrollableTabBar
                tabs={[{ title: 'Summary', link: 'summary' }, ...filteredTabs]}
              />
              <div className="p-1" />

              {section === 'summary' ? (
                <QuestionnairePDF
                  filteredSummaryQuestions={{
                    ...filteredSummaryQuestions,
                  }}
                  generalInfo={{
                    name: `${firstName} ${lastName}`,
                    dob,
                    state: address?.state?.name ?? ' ',
                  }}
                  gender={gender}
                />
              ) : (
                sectionWiseQuestions?.[section] && (
                  <QuestionnaireSectionDetails
                    section={sectionWiseQuestions[section]}
                  />
                )
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
