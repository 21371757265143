import { MouseEventHandler } from 'react';

import { avatarColors } from './constants';

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

export function Avatar({
  containerClassName = '  ',
  sizeClass = 'h-6 w-6 text-sm',
  radius = 'rounded-full',
  imgUrl,
  userName,
  hasChecked,
  onClick,
  hasCheckedClass = 'w-4 h-4 -top-0.5 -right-0.5',
  ...rest
}: AvatarProps) {
  const url = imgUrl || '';
  const name = userName || 'John Doe';
  const _setBgColor = (color: string) => {
    const backgroundIndex = Math.floor(
      color.charCodeAt(0) % avatarColors.length
    );

    return avatarColors[backgroundIndex];
  };

  return (
    //  eslint-disable-next-line
    <div
      onClick={onClick}
      {...rest}
      className={`wil-avatar relative z-20 inline-flex flex-shrink-0 items-center justify-center font-semibold  uppercase text-white shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
      style={{ backgroundColor: url ? undefined : _setBgColor(name) }}>
      {url ? (
        <img
          className={`absolute inset-0 z-20 h-full w-full object-fill ${radius}`}
          src={url}
          alt={name}
        />
      ) : (
        <span className="wil-avatar__name">{name[0]}</span>
      )}

      {hasChecked && (
        <span
          className={` text-light_bg-contrastText dark:text-dark_bg-contrastText absolute flex items-center justify-center rounded-full bg-teal-500 text-xs  ${hasCheckedClass}`}>
          <i className="las la-check" />
        </span>
      )}
    </div>
  );
}
