import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { HormoneWrapper } from 'components/prescriptions/HormoneWrapper/HormoneWrapper';
import { DeliveryMethods } from 'constants/delivery-methods';
import { maleHormoneRxTypes } from 'constants/hormone-details';
import { usePregnylController } from 'hooks/hormones/usePregnylController';
import {
  HormoneNames,
  PrescribedObjectTypes,
  PrescriptionKeys,
} from 'types/prescriptionTypes';

import { CustomSlider } from '../../common/Slider/Slider';

type PregnylProps = {
  addNewPrescription: (prescriptionObject: PrescribedObjectTypes) => void;
};

export function Pregnyl({ addNewPrescription }: PregnylProps) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const PregnylDetails = maleHormoneRxTypes?.[rx || ''];

  const { refills, setRefils, sig, setSig, mgml } = usePregnylController();

  const prescriptionObject: PrescriptionKeys = {
    displayName: PregnylDetails?.displayName,
    name: HormoneNames.Pregnyl,
    mg: `${mgml} ml vial`,

    refills,
    sig,
    family: PregnylDetails?.family,
    deliveryMethod: DeliveryMethods.Vial.name,
  };

  const addPrescribedDetails = () => {
    addNewPrescription(prescriptionObject);
  };

  return (
    <HormoneWrapper
      title={PregnylDetails.displayName}
      onClick={() => {
        addPrescribedDetails();
      }}>
      <div className=" py-2 pb-4 text-background-contrastText">
        <div className="mt-6 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold   ">
            <Typography variant="h5" className="mr-1">
              Refills
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={PregnylDetails.refills[0]}
              max={PregnylDetails.refills.at(-1)}
              toolTipValue={`${refills} Refills`}
              sliderValue={refills}
              onChange={(val) => {
                setRefils(Number(val));
              }}
            />
          </div>
          <div className="col-span-3  ">
            <Typography variant="h5" align="left">
              {' '}
              Delivery Method:
            </Typography>{' '}
            <Typography variant="h5" className="ml-1" color="primary">
              {DeliveryMethods.Vial.name}
            </Typography>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 text-left">
            <Typography variant="h5" className="mt-4">
              SIG
            </Typography>
          </div>
          <div className="col-span-8">
            <TextInput
              value={sig}
              onChange={setSig}
              fullWidth
              className="my-1 rounded bg-background-light py-2 px-4"
            />
          </div>
          <div className="col-span-1 text-left text-sm font-semibold">
            <div className="flex">
              <Typography variant="h5" className="mr-1">
                Strength
              </Typography>
              <Typography variant="h5" className="mr-1">
                :
              </Typography>
            </div>
          </div>
          <div className="col-span-2">
            <Typography variant="h5" className="mr-1" color="primary">
              {mgml} iu kit
            </Typography>
          </div>
        </div>

        {/* Refills slider */}
      </div>
    </HormoneWrapper>
  );
}
