export const providerTitlesNotRequiringSupervisorDoctor = [
  'DDS',
  'DO',
  'MD',
  'OD',
  'FACC',
  'FACP',
  'RD',
  'VI',
  'DMD',
  'DVM',
];

export const hiddenProviderTitles = [
  'DDS',
  'Jr',
  'Sr',
  'Esq',
  'I',
  'II',
  'III',
  'Jr',
  'Sr',
  'Esq',
  'IV',
  'JR',
  'SR',
  'ESQ',
  'JR.',
  'SR.',
  'ESQ.',
  'Jr.',
  'Sr.',
  'Esq.',
  'PHD',
  'PHARMD',
  'RPH',
  'JR',
  'SR',
  'ESQ',
  'V',
  'VI',
];
