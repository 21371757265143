import React, { Suspense } from 'react';

import { useSearchParams } from 'react-router-dom';

import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { useProgesteroneController } from 'hooks/hormones-new/useProgesteroneController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type ProgesteroneProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function Progesterone({ addNewPrescription }: ProgesteroneProps) {
  const [searchParams] = useSearchParams();

  const medicationId = searchParams.get('id');
  const {
    ProgesteroneDetails,

    sig,
    setSig,
    dtd,

    dose,

    refills,
    setRefills,
    deliveryMethod,
    handleDose,
    openWarningPopup,
    setOpenWarningPopup,
    setDTD,
    handleConfirmation,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useProgesteroneController();
  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || undefined,
    displayName: ProgesteroneDetails.displayName,
    name: HormoneNames.PROGESTERONE,
    strength: ProgesteroneDetails.strength,
    strengthUnit: ProgesteroneDetails.strengthUnit,

    dose: ProgesteroneDetails.dose[dose + maximumDose],
    minimumDose: ProgesteroneDetails.dose[dose],
    doseUnit: ProgesteroneDetails.doseUnit,
    refills,
    dtd,
    sig,
    deliveryMethod,
    family: ProgesteroneDetails.family,
    note,
  };

  return (
    <>
      <HormoneDetails
        prescriptionObject={prescriptionObject}
        statusUnit={ProgesteroneDetails.statusUnit}
        title={ProgesteroneDetails.displayName}
        onAdd={() => {
          addNewPrescription(prescriptionObject);
        }}
        onPharmacyNoteChange={(t) => {
          setNote(t);
        }}
        hormoneInputs={[
          {
            type: PrescriptionInputTypes.Text,
            label: 'Strength',
            value: `${ProgesteroneDetails.strength} ${ProgesteroneDetails.strengthUnit}`,
          },
          {
            type: PrescriptionInputTypes.Slider,
            label: 'Dose',
            value: dose,

            onChange: handleDose,
            options: ProgesteroneDetails.dose,
            getTooltip: (val) =>
              `${`${ProgesteroneDetails.dose[val]} ${ProgesteroneDetails.doseUnit}`}`,
          },
          {
            type: PrescriptionInputTypes.Slider,
            label: 'Refills',
            value: refills,
            onChange(value) {
              setRefills(value);
            },
            options: ProgesteroneDetails.refills,
            getTooltip: (val) => `${ProgesteroneDetails.refills[val]} refills`,
          },

          {
            type: PrescriptionInputTypes.SigGenerator,
            value: sig,
            onChange(val) {
              setSig(val);
            },
            sigUnit: 'at bedtime',
            doseOptions: ProgesteroneDetails.dose,
            doseValue: dose,
            maximumDoseValue: maximumDose,
            maximumDoseOnChange(val) {
              setMaximumDose(val);
              handleDose(val, 'maximumDose');
            },
          },
        ]}
        hormoneInfo={[
          {
            label: 'Delivery Method',

            value: ProgesteroneDetails.deliveryMethod,
          },
          {
            label: 'D.T.D',
            value: `${dtd.toString()}ml`,
          },
        ]}
      />
      <Suspense>
        <ConfirmationModal
          isModalOpen={openWarningPopup}
          title="Maximum ml/day reached, Do you want us to switch to Oral Micronized Progesterone ?"
          confirmText="Confirm"
          handleConfirm={handleConfirmation}
          closeModal={(close) => {
            setOpenWarningPopup(!close);
            if (
              ProgesteroneDetails.dose[dose] ===
              ProgesteroneDetails.dose[dose + maximumDose]
            ) {
              handleDose(dose - 1);
            } else {
              setMaximumDose(maximumDose - 1);
              setDTD(60);
            }
          }}
        />
      </Suspense>
    </>
  );
}
