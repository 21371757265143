import React from 'react';

import { RadioGroup } from '@headlessui/react';
import { useSearchParams } from 'react-router-dom';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { HormoneWrapper } from 'components/prescriptions/HormoneWrapper/HormoneWrapper';
import { DeliveryMethods } from 'constants/delivery-methods';
import { maleHormoneRxTypes } from 'constants/hormone-details';
import { useTestosteroneM } from 'hooks/hormones/useTestosteroneM';
import {
  HormoneNames,
  PrescribedObjectTypes,
  PrescriptionKeys,
} from 'types/prescriptionTypes';

import { CustomSlider } from '../../common/Slider/Slider';

type TestosteroneMenProps = {
  addNewPrescription: (prescriptionObject: PrescribedObjectTypes) => void;
};

export function TestosteroneMen({ addNewPrescription }: TestosteroneMenProps) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const TestosteroneMenDetails = maleHormoneRxTypes?.[`${rx}` || ''];

  const { refills, DTD, mg, setDTD, setRefils, setMg, sig, setSig, mgml } =
    useTestosteroneM();

  const prescriptionObject: PrescriptionKeys = {
    displayName: TestosteroneMenDetails?.displayName,
    name: HormoneNames.TESTOSTERONE_MEN,
    mg: `${mgml} mg/ml`,
    mlPerDay: `${TestosteroneMenDetails.ml[mg]} ml`,
    dtd: DTD,
    refills,
    sig,
    family: TestosteroneMenDetails?.family,
    deliveryMethod: DeliveryMethods.OrificeReducer.name,
  };

  const addPrescribedDetails = () => {
    addNewPrescription(prescriptionObject);
  };

  return (
    <HormoneWrapper
      title={TestosteroneMenDetails.displayName}
      onClick={() => {
        addPrescribedDetails();
      }}>
      <div className=" py-2 pb-4 text-background-contrastText">
        <div className="grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold   ">
            <Typography variant="h5" className="mr-1">
              Dose
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={0}
              max={19}
              customStartRangeValue={TestosteroneMenDetails.ml[0]}
              customEndRangeValue={TestosteroneMenDetails.ml.at(-1)}
              toolTipValue={`${TestosteroneMenDetails.ml[mg]} ml`}
              sliderValue={mg}
              onChange={(val) => {
                setMg(Number(val));
              }}
            />
          </div>
          <div className="col-span-3">
            <Typography variant="h5" align="left">
              {' '}
              Delivery Method:{' '}
            </Typography>
            <Typography variant="h5" className="ml-1" color="primary">
              {DeliveryMethods.OrificeReducer.name}
            </Typography>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold">
            <Typography variant="h5" className="mr-1">
              Refills
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={TestosteroneMenDetails.refills[0]}
              max={TestosteroneMenDetails.refills.at(-1)}
              toolTipValue={`${refills} Refills`}
              sliderValue={refills}
              onChange={(val) => {
                setRefils(Number(val));
              }}
            />
          </div>
          <div className="col-span-1 text-left  text-sm font-semibold">
            <div className="flex">
              <Typography variant="h5" className="mr-1">
                Strength
              </Typography>
              <Typography variant="h5" className="mr-1">
                :
              </Typography>
            </div>
          </div>
          <div className="col-span-2">
            <Typography variant="h5" className="mr-1" color="primary">
              {mgml} mg/ml
            </Typography>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-12">
          <div className="col-span-1 text-left">
            <Typography variant="h5" className="mt-4">
              SIG
            </Typography>
          </div>
          <div className="col-span-8">
            <div className="pr-2">
              <TextInput
                value={sig}
                onChange={setSig}
                fullWidth
                className="my-1 rounded bg-background-light py-2"
              />
            </div>
          </div>
        </div>
        <div className="mt-2 grid grid-cols-12 gap-x-14">
          <div className="col-span-1 mt-2  text-left text-sm font-semibold">
            <div className="flex">
              <Typography variant="h5" className="mr-1">
                DTD
              </Typography>
            </div>
          </div>
          <div className="col-span-4">
            <RadioGroup value={DTD} onChange={setDTD}>
              <div className="inline-flex cursor-pointer  shadow-md hover:shadow-lg focus:shadow-lg">
                {TestosteroneMenDetails.dtd.map(
                  (item: number, index: number) => (
                    <RadioGroup.Option value={item}>
                      {({ checked }) => (
                        <span
                          className={`${index === 0 && 'rounded-l'} ${
                            item === TestosteroneMenDetails.dtd.at(-1) &&
                            'rounded-r'
                          } flex px-5 py-2 
                  ${
                    checked
                      ? 'bg-primary-dark hover:bg-primary-dark'
                      : 'bg-background-light'
                  }  dark:text-primary-dark_contrastText focus:bg-primary-700 active:bg-primary-800 text-xs !text-base font-normal uppercase leading-tight text-primary-contrastText transition duration-150 ease-in-out hover:bg-primary-main focus:outline-none focus:ring-0`}>
                          {`${item}`}
                        </span>
                      )}
                    </RadioGroup.Option>
                  )
                )}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
    </HormoneWrapper>
  );
}
