import { ReactNode, useState } from 'react';

import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';

export type ConfigurationModalProps = {
  oldValue: string;
  newValue: string;
  checkPrescriptionUnit: string;
  changeValueUnit: string;
  onConfirm?: () => void;
  customMessage?: ReactNode;
};

export function useConfigurationChangeModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>();

  const renderModal = ({
    changeValueUnit,
    checkPrescriptionUnit,
    customMessage,
    newValue,
    oldValue,
  }: ConfigurationModalProps) => {
    setModalContent(
      customMessage ?? (
        <>
          Please note that you have changed the strength from
          <span className="font-bold text-primary-main">
            {' '}
            {oldValue} {changeValueUnit}
          </span>{' '}
          to{' '}
          <span className="font-bold text-primary-main">
            {newValue} {changeValueUnit}
          </span>
          , and the minimum and maximum dose sliders have been reset to their
          initial state. Please adjust the dose again to ensure the appropriate
          <span className="font-bold text-background-contrastText ">
            {' '}
            {checkPrescriptionUnit}
          </span>{' '}
        </>
      )
    );
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalContent('');
  };

  function Modal() {
    return (
      <ConfirmationModal
        isModalOpen={isOpen}
        closeModal={() => {
          closeModal();
        }}
        // confirmText="Confirm"
        infoButtonCancelText="Close"
        // handleConfirm={() => {
        //   confirmFunctionRef.current();
        //   closeModal();
        // }}
      >
        <div className="py-4">{modalContent}</div>
      </ConfirmationModal>
    );
  }

  return { renderModal, Modal };
}
