import { Dispatch, SetStateAction } from 'react';

import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';
import { TitrationConfigType } from 'types/patient/prescription';
import { HormoneNames, PrescribedObjectType } from 'types/prescriptionTypes';

import {
  diagnosisMen,
  diagnosisWomen,
} from '../hormones/Diagnosis/diagnosisHelper';
import { HormoneContainerSkeleton } from './HormoneContainerSkeleton';

type PrescribedTypes = {
  prescribedHormones: PrescribedObjectType[];
  removePrescribedItem: (index: number, id: string) => void;
  isPrescribeActive: boolean;
  setIsPrescribeActive: Dispatch<SetStateAction<boolean>>;
  titrationType?: TitrationConfigType;

  isLoading: boolean;

  buttonLoading: boolean;
  excludedHormones: string[];
};

export function PrescribedHormonesNew({
  prescribedHormones,
  removePrescribedItem,
  isPrescribeActive,
  setIsPrescribeActive,
  titrationType,
  excludedHormones,

  isLoading = false,

  buttonLoading,
}: PrescribedTypes) {
  const { profile } = useProfile();
  const { id, gender, address } = useOutletContext<PatientDetailsContextType>();
  const navigate = useNavigate();

  const getPatientDiagnosisCodesQuery = useQuery(
    QueryKeys.PatientDiagnosisCode.listing({ id }),
    () => PatientService.getPatientDiagnosisCodes(id as string),
    {
      refetchOnMount: false,
    }
  );
  if (isPrescribeActive && !prescribedHormones.length) {
    return null;
  }

  const checkShowPrescribingOptions = () => {
    const hormoneCodeState = getPatientDiagnosisCodesQuery.data?.data.find(
      (i) => i.diagnosisCode.type === 'hormone'
    );

    const thyroidCodeState = getPatientDiagnosisCodesQuery.data?.data.find(
      (i) => i.diagnosisCode.type === 'thyroid'
    );

    let hormoneList: (HormoneNames | undefined)[] = [];
    let thyroidList: (HormoneNames | undefined)[] = [];
    if (!hormoneCodeState || !thyroidCodeState) {
      const diagnosisList = gender === 'F' ? diagnosisWomen : diagnosisMen;

      if (!hormoneCodeState) {
        hormoneList = prescribedHormones
          .filter((i) =>
            diagnosisList.hormone.includes(i.displayName as HormoneNames)
          )
          .map((i) => i.displayName);
      }
      if (!thyroidCodeState) {
        thyroidList = prescribedHormones
          .filter((i) =>
            diagnosisList.thyroid.includes(i.displayName as HormoneNames)
          )
          .map((i) => i.displayName);
      }
    }
    navigate(
      {
        pathname: '../../../checkout',
      },
      {
        state: {
          prescription: { prescribedHormones, excludedHormones, titrationType },
          diagnosisHormoneList: {
            hormone: hormoneList as HormoneNames[],
            thyroid: thyroidList as HormoneNames[],
          },
          shippingAddress: address,
          licenseAndDea: {
            dea: profile?.providerDetails.providerDea,
            license: profile?.providerDetails.providerLicence,
          },
          isConcierge: profile?.practice.isConciergePractice,
        },
      }
    );
  };

  return (
    <div className="flex flex-col text-start">
      <div className="mt-3 font-bold">
        <Typography variant="h3">Your Cart</Typography>
      </div>
      <div className="grid grid-cols-12 gap-x-10">
        {isLoading ? (
          <HormoneContainerSkeleton />
        ) : (
          <>
            {prescribedHormones.map(
              (item: PrescribedObjectType, index: number) => (
                <div className="relative col-span-3 mt-3 rounded bg-background-main pb-4 ">
                  <div
                    className=" w-full rounded-md bg-background-main pl-4 pr-2 pb-5 shadow-sm"
                    key={item.name}>
                    <span className="col-span-12 mb-2 flex cursor-pointer justify-end">
                      {/* eslint-disable-next-line */}
                      <XMarkIcon
                        height={24}
                        className="mt-2 mr-2 text-background-contrastText"
                        onClick={() => {
                          removePrescribedItem(index, item.id as string);
                        }}
                      />
                    </span>
                    <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                      <Typography
                        variant="subtitle2"
                        align="left"
                        className="col-span-3">
                        Rx
                      </Typography>
                      <Typography variant="subtitle2">:</Typography>
                      <span className="col-span-8  flex">
                        <Typography variant="subtitle2" align="left">
                          {item.displayName}
                        </Typography>
                        {item.ratio && (
                          <Typography
                            variant="subtitle2"
                            className="ml-3"
                            align="left">
                            {item.ratio}
                          </Typography>
                        )}
                      </span>
                    </div>
                    {!!item.strength && (
                      <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className="col-span-3">
                          Strength
                        </Typography>
                        <Typography variant="subtitle2">:</Typography>
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className="col-span-8">
                          {`${item.strength} ${item.strengthUnit}`}
                        </Typography>
                      </div>
                    )}
                    {!!item?.minimumDose && item.minimumDose !== item.dose && (
                      <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className="col-span-3">
                          Minimum Dose
                        </Typography>
                        <Typography variant="subtitle2">:</Typography>
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className="col-span-8 lowercase">
                          {`${item.minimumDose} ${item.doseUnit}`}
                        </Typography>
                      </div>
                    )}
                    {!!item.dose && (
                      <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className="col-span-3">
                          Dose
                        </Typography>
                        <Typography variant="subtitle2">:</Typography>
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className="col-span-8 lowercase">
                          {`${item.dose} ${item.doseUnit}`}
                        </Typography>
                      </div>
                    )}
                    {!!item.dtd && (
                      <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                        <Typography
                          variant="subtitle2"
                          className="col-span-3"
                          align="left">
                          D.T.D
                        </Typography>
                        <Typography variant="subtitle2">:</Typography>
                        <Typography
                          variant="subtitle2"
                          className="col-span-8 lowercase"
                          align="left">
                          {item.dtd} {item.dtdUnit || 'ml'}
                        </Typography>
                      </div>
                    )}
                    <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                      <Typography
                        variant="subtitle2"
                        className="col-span-3"
                        align="left">
                        SIG
                      </Typography>
                      <Typography variant="subtitle2">:</Typography>
                      <Typography
                        variant="subtitle2"
                        className="col-span-8"
                        align="left">
                        {item.sig}
                      </Typography>
                    </div>
                    <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                      <Typography
                        variant="subtitle2"
                        className="col-span-3"
                        align="left">
                        Delivery Method
                      </Typography>
                      <Typography variant="subtitle2">:</Typography>
                      <Typography
                        variant="subtitle2"
                        className="col-span-8"
                        align="left">
                        {item.deliveryMethod}
                      </Typography>
                    </div>
                    {!!item.refills && (
                      <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                        <Typography
                          variant="subtitle2"
                          className="col-span-3"
                          align="left">
                          Refills
                        </Typography>
                        <Typography variant="subtitle2">:</Typography>
                        <Typography
                          variant="subtitle2"
                          className="col-span-8"
                          align="left">
                          {item.refills}
                        </Typography>
                      </div>
                    )}
                    {!!item.note && (
                      <div className="mb-2 grid grid-cols-12 !items-start justify-around gap-x-4 text-start">
                        <Typography
                          variant="subtitle2"
                          className="col-span-3"
                          align="left">
                          Note
                        </Typography>
                        <Typography variant="subtitle2">:</Typography>
                        <Typography
                          data-tooltip-id="prescription_note"
                          ellipsis
                          variant="subtitle2"
                          className="col-span-8"
                          align="left">
                          {item.note}
                        </Typography>
                        <Tooltip id="prescription_note">{item.note}</Tooltip>
                      </div>
                    )}
                  </div>
                  {item.isFromTitration && (
                    <div className="absolute bottom-0 flex w-full justify-center rounded-b bg-primary-main p-2">
                      <Typography variant="h5">{titrationType}</Typography>
                    </div>
                  )}
                </div>
              )
            )}
          </>
        )}
        {!isPrescribeActive && (
          <div className="min-h-82 col-span-3 mt-3 min-h-[220px] rounded-md bg-background-main p-2 pb-2 shadow-sm">
            {/* eslint-disable-next-line */}
            <div
              className="flex h-full cursor-pointer items-center justify-center"
              onClick={() => setIsPrescribeActive(true)}>
              <Typography variant="h5">
                <PlusIcon height={24} className=" mr-1" /> Add new Rx
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className="my-6 flex justify-end">
        <Button
          disabled={prescribedHormones.length === 0}
          loading={buttonLoading}
          onClick={checkShowPrescribingOptions}>
          Checkout
        </Button>
      </div>
    </div>
  );
}
