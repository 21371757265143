import { useOutletContext, useSearchParams } from 'react-router-dom';

import { BiEst } from 'components/hormone-forms/BiEst/BiEst';
// import { DHEAOral } from 'components/hormone-forms/DHEAOral/DHEAOral';
import { Danazol } from 'components/hormone-forms/Danazol/Danazol';
import { DHEA } from 'components/hormone-forms/DHEA/DHEA';
import { DHEAOral } from 'components/hormone-forms/DHEAOral/DHEAOral';
import { Estradiol } from 'components/hormone-forms/Estradiol/Estradiol';
import { Levothyroxine } from 'components/hormone-forms/Levothyronine/Levothyronine';
import { LiothyronineExt } from 'components/hormone-forms/LiothyronineExt/LiothyronineExt';
import { OralProgesterone } from 'components/hormone-forms/OralProgesterone/OralProgesterone';
import { Pregnyl } from 'components/hormone-forms/Pregnyl/Pregnyl';
import { Progesterone } from 'components/hormone-forms/Progesterone/Progesterone';
import { Sildenafil } from 'components/hormone-forms/Sildenafil/Sildenafil';
import { T4PerLAT3 } from 'components/hormone-forms/T4PerLAT3/T4PerLAT3';
import { Tadalafil } from 'components/hormone-forms/Tadalafil/Tadalafil';
import { TestoDHEA } from 'components/hormone-forms/TestoDHEA/TestoDHEA';
import { TestosteroneCyp } from 'components/hormone-forms/TestosteroneCyp.tsx/TestosteroneCyp';
import { TestosteroneMen } from 'components/hormone-forms/TestosteroneMen/TestosteroneMen';
import { TestosteroneWomen } from 'components/hormone-forms/TestosteroneWomen/TestosteroneWomen';
import { Gender, PatientDetailsContextType } from 'types/patient';
import { PrescribedObjectTypes } from 'types/prescriptionTypes';

type PrescriptionDetailsType = {
  addNewPrescription: (prescribedObject: PrescribedObjectTypes) => void;
};

export function PrescriptionDetails({
  addNewPrescription,
}: PrescriptionDetailsType) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const { gender } = useOutletContext<PatientDetailsContextType>();
  // eslint-disable-next-line
  const femaleRXComponents: any = {
    'bi-est': <BiEst addNewPrescription={addNewPrescription} />,
    estradiol: <Estradiol addNewPrescription={addNewPrescription} />,
    progesterone: <Progesterone addNewPrescription={addNewPrescription} />,
    'oral-micronized-progesterone': (
      <OralProgesterone addNewPrescription={addNewPrescription} />
    ),
    'testosterone/dhea': <TestoDHEA addNewPrescription={addNewPrescription} />,
    testosterone: <TestosteroneWomen addNewPrescription={addNewPrescription} />,
    dhea: <DHEA addNewPrescription={addNewPrescription} />,
    'dhea-oral': <DHEAOral addNewPrescription={addNewPrescription} />,
    'levothyroxine-(t4)': (
      <Levothyroxine addNewPrescription={addNewPrescription} />
    ),
    'liothyronine-extended-release-(lat3)': (
      <LiothyronineExt addNewPrescription={addNewPrescription} />
    ),
    't4/lat3': <T4PerLAT3 addNewPrescription={addNewPrescription} />,
    sildenafil: <Sildenafil addNewPrescription={addNewPrescription} />,
    tadalafil: <Tadalafil addNewPrescription={addNewPrescription} />,
    danazol: <Danazol addNewPrescription={addNewPrescription} />,
  };
  // eslint-disable-next-line
  const maleRXComponents: any = {
    testosterone: <TestosteroneMen addNewPrescription={addNewPrescription} />,

    'levothyroxine-(t4)': (
      <Levothyroxine addNewPrescription={addNewPrescription} />
    ),
    'liothyronine-extended-release-(lat3)': (
      <LiothyronineExt addNewPrescription={addNewPrescription} />
    ),
    't4/lat3': <T4PerLAT3 addNewPrescription={addNewPrescription} />,
    sildenafil: <Sildenafil addNewPrescription={addNewPrescription} />,
    tadalafil: <Tadalafil addNewPrescription={addNewPrescription} />,
    danazol: <Danazol addNewPrescription={addNewPrescription} />,
    'testosterone-cyp-inj-200mg/ml': (
      <TestosteroneCyp addNewPrescription={addNewPrescription} />
    ),
    'pregnyl-(injectable)(hcg)': (
      <Pregnyl addNewPrescription={addNewPrescription} />
    ),
  };
  function getRxHormoneComponent(rxHormone: string) {
    if (gender === Gender.Male) {
      return maleRXComponents[`${rxHormone}`];
    }

    return femaleRXComponents[`${rxHormone}`];
  }

  return getRxHormoneComponent(rx || '');
}
