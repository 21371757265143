import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useDHEAOralController } from 'hooks/hormones-new/useDHEAOralController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type DHEAOralProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function DHEAOral({ addNewPrescription }: DHEAOralProps) {
  const [searchParams] = useSearchParams();
  const medicationId = searchParams.get('id');
  const {
    DHEAOralDetails,
    strength,
    setStrength,
    sig,
    setSig,
    dtd,
    setDTD,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  } = useDHEAOralController();
  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || '',
    displayName: DHEAOralDetails.displayName,
    name: HormoneNames.DHEA_ORAL,
    strength: DHEAOralDetails.strength[strength],
    strengthUnit: DHEAOralDetails.strengthUnit,
    dose: DHEAOralDetails.dose[dose + maximumDose],
    minimumDose: DHEAOralDetails.dose[dose],
    doseUnit: DHEAOralDetails.doseUnit,
    refills,
    dtd,
    dtdUnit: DHEAOralDetails.dtdUnit,
    sig,
    deliveryMethod: DHEAOralDetails.deliveryMethod,
    family: DHEAOralDetails.family,
    note,
  };

  return (
    <HormoneDetails
      showStatus={false}
      prescriptionObject={prescriptionObject}
      title={DHEAOralDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Strength',
          value: strength,
          onChange(value) {
            setStrength(value);
          },
          options: DHEAOralDetails.strength,
          getTooltip: (val) =>
            `${`${DHEAOralDetails.strength[val]} ${DHEAOralDetails.strengthUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: DHEAOralDetails.dose,
          getTooltip: (val) =>
            `${`${DHEAOralDetails.dose[val]} ${DHEAOralDetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: DHEAOralDetails.refills,
          getTooltip: (val) => `${DHEAOralDetails.refills[val]} refills`,
        },
        {
          type: PrescriptionInputTypes.DTD,
          value: dtd,

          unit: DHEAOralDetails.dtdUnit,
          onChange(val) {
            setDTD(val);
          },
          DTDValues: DHEAOralDetails.dtd,
        },
        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: DHEAOralDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
          },
          unit: DHEAOralDetails.doseUnit,
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: DHEAOralDetails.deliveryMethod,
        },
      ]}
    />
  );
}
