import React, { useEffect, useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Table } from 'components/common/Table/Table';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useSearchParamsPagination } from 'hooks/useSearchParamsPagination';
import { PatientService } from 'services/patient';
import { OrderStatusHoverText, OrderStatusLabel } from 'types/order/intext';
import { PatientDetailsContextType } from 'types/patient';
import { getReadableDate, getTitleCaseFromSnakeCase } from 'utils/common';

import { PatientOrderDetailsCard } from './PatientOrderdetailsCard';

type OrderTablesProps = {
  title: string;
  type: string;
};
export function OrderTables({ title, type }: OrderTablesProps) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [currentAcordianIndex, setCurrentAcordianIndex] = useState<number>();
  const { page, setPage } = useSearchParamsPagination({ key: type });
  const [deleteOrderId, setDeleteOrderId] = useState('');
  const patientDetails = useOutletContext<PatientDetailsContextType>();
  const [placedOrderId, setPlacedOrderId] = useState('');
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const { state } = useLocation();
  const getPatientOrdersQuery = useQuery(
    QueryKeys.PatientOrders.listing({ page, id: patientDetails?.id, type }),
    () =>
      PatientService.getPatientOrders({ id: patientDetails?.id, page, type }),
    {
      enabled: !!patientDetails?.id && patientDetails?.id !== 'NA',
      refetchOnWindowFocus: false,
    }
  );

  const cancelOrderMutation = useMutationWithToast(
    PatientService.cancelPatientOrder,
    {
      onSuccess() {
        queryClient.refetchQueries(
          QueryKeys.PatientOrders.listing({
            page,
            id,
            type,
          })
        );
        queryClient.refetchQueries(
          QueryKeys.PatientPrescriptionRefills.listing()
        );
        queryClient.refetchQueries(QueryKeys.PatientDetails.item(id as string));
        setShowConfirmCancel(false);
      },
    }
  );
  function cancelOrder(orderId: string) {
    cancelOrderMutation.mutate(
      {
        orderId,
        patientId: patientDetails?.id,
      },
      {
        onSuccess() {
          queryClient.refetchQueries(
            QueryKeys.PatientDetails.item(id as string)
          );
        },
      }
    );
  }

  useEffect(() => {
    if (
      state?.id &&
      (patientDetails?.diagnosisHormone?.diagnosisCode?.id ||
        patientDetails?.diagnosisThyroid?.diagnosisCode?.id)
    ) {
      setPlacedOrderId(state?.id);
    }
  }, [
    state?.id,
    patientDetails?.diagnosisHormone?.diagnosisCode?.id,
    patientDetails?.diagnosisThyroid?.diagnosisCode?.id,
  ]);

  return (
    <div className="flex flex-col">
      <Typography className="mb-4" variant="h4">
        {title}
      </Typography>
      <Table
        loading={getPatientOrdersQuery.isLoading}
        rowsData={getPatientOrdersQuery?.data?.data.items || []}
        columns={[
          {
            title: 'Order Id',
            render: (rowData) => rowData?.id ?? 'NA',
          },
          {
            title: 'Created At',
            render: (rowData) => (
              <span className="-mt-0.5 text-lg">
                {rowData?.createdAt
                  ? getReadableDate(rowData?.createdAt, true)
                  : 'NA'}
              </span>
            ),
          },
          {
            title: 'Status',
            render: (rowData) => (
              <span data-tooltip-id={`${rowData.id}_order_status`}>
                {OrderStatusLabel?.[rowData.status]}
                <Tooltip id={`${rowData.id}_order_status`}>
                  {OrderStatusHoverText?.[rowData.status] ?? rowData.status}
                </Tooltip>
              </span>
            ),
          },

          {
            title: 'Tracking Id',
            render: (rowData) => rowData?.trackingNumber ?? 'NA',
          },
          {
            title: 'Shipping',
            render: (rowData) =>
              getTitleCaseFromSnakeCase(rowData?.shippingMethod) ?? 'NA',
          },
          {
            title: 'Carrier',
            render: (rowData) => rowData?.carrier ?? 'NA',
          },
          {
            title: 'Delivery Date',
            render: (rowData) =>
              rowData.deliveryDate
                ? getReadableDate(rowData.deliveryDate, true)
                : 'NA',
          },
          {
            title: '',
            render: (rowData, index) => (
              <div className="flex items-center gap-6">
                {type !== 'fulfilled' &&
                  !patientDetails.isArchivedPatient &&
                  rowData?.status !== 'out_for_delivery' && (
                    <Button
                      loading={
                        cancelOrderMutation.isLoading &&
                        rowData?.id === deleteOrderId
                      }
                      onClick={(e) => {
                        setDeleteOrderId(rowData?.id);
                        e.stopPropagation();
                        setShowConfirmCancel(true);
                      }}
                      color="error">
                      Cancel
                    </Button>
                  )}
                <ChevronDownIcon
                  className={classNames(
                    'h-5 w-5 font-extrabold text-background-contrastText ',
                    {
                      '!rotate-180': index === currentAcordianIndex,
                    }
                  )}
                />
              </div>
            ),
          },
        ]}
        expandedAccordions={
          currentAcordianIndex || currentAcordianIndex === 0
            ? [currentAcordianIndex]
            : []
        }
        onAccordionExpandChange={(items) => {
          setCurrentAcordianIndex(items[0]);
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        getAccordionContent={(rowData) => (
          <PatientOrderDetailsCard
            openNewcrop={rowData.id === placedOrderId}
            rowData={rowData}
          />
        )}
        noDataMessage={`You currently have no ${title?.toLowerCase()}`}
        onPageChange={(p) => {
          setPage(p);
          setCurrentAcordianIndex(undefined);
        }}
        page={page}
        totalRecords={getPatientOrdersQuery?.data?.data.total || 0}
        recordsPerPage={10}
        isSticky
      />
      <ConfirmationModal
        confirmLoading={cancelOrderMutation.isLoading}
        confirmText="Cancel"
        buttonColor={{
          confirmBtn: 'error',
        }}
        closeModal={() => {
          setShowConfirmCancel(false);
        }}
        handleConfirm={() => {
          cancelOrder(deleteOrderId);
        }}
        isModalOpen={showConfirmCancel}>
        Are you sure you would like to cancel this order?
      </ConfirmationModal>
    </div>
  );
}
