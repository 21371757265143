import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { nameSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';

type NameFormType = {
  firstName: string;
  middleName: string;
  lastName: string;
};

type UpdatePhoneProps = {
  modal: boolean;
  closeModal: (state: boolean) => void;
  name: NameFormType;
};

export function UpdateNameModal({ modal, closeModal, name }: UpdatePhoneProps) {
  const queryClient = useQueryClient();
  const updateNameMutation = useMutationWithToast(UserService.updateName);

  const { handleSubmit, control, reset } = useFormWithErrors<NameFormType>({
    mutation: updateNameMutation,
    schema: nameSchema,
  });

  const onSubmit = handleSubmit((data) => {
    updateNameMutation.mutate(
      {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data?.middleName,
      },
      {
        onSuccess: () => {
          closeModal(false);
          reset();
          queryClient.refetchQueries([QueryKeys.UserProfile]);
        },
      }
    );
  });

  useEffect(() => {
    reset({
      firstName: name.firstName,
      lastName: name.lastName,
      middleName: name?.middleName,
    });
  }, [name, reset]);

  return (
    <div>
      <Modal
        title="Update Name"
        open={modal}
        className="w-2/5"
        closeModal={closeModal}>
        <div className="mt-4">
          <div className="flex">
            {' '}
            <TextInput
              fullWidth
              label="First Name"
              name="firstName"
              defaultValue={name?.firstName}
              control={control}
              placeholder="First Name"
              className=""
            />
            <span className="mx-2" />
            <TextInput
              fullWidth
              label="Middle Name"
              name="middleName"
              defaultValue={name?.middleName}
              control={control}
              placeholder="Middle Name"
              className=""
            />
            <span className="mx-2" />
            <TextInput
              label="Last Name"
              name="lastName"
              defaultValue={name?.lastName}
              control={control}
              placeholder="Last Name"
              fullWidth
            />
          </div>
          <Typography>
            Please ensure that your name matches the one registered in the
            National Provider Identifier (NPI) database.
          </Typography>

          <div>
            <div className="mt-2 mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => closeModal(false)}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updateNameMutation.isLoading}
                onClick={onSubmit}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
