import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';

import { SummaryQuestionType } from 'types/questionnaire';
import { getAge, getReadableDate } from 'utils/common';

import {
  binaryAnswerToRating,
  getAnswer,
  getAverage,
  summaryQuestions,
} from './questionnaireHelper';
import { headFont, styles, textStyle } from './summaryStyles';

type FemalePDFProps = {
  filteredSummaryQuestions: SummaryQuestionType;
  generalInfo: { [key: string]: string };
  gender: string;
};

const shortSection: {
  [key: string]: string;
} = {
  'M&T': 'mouthAndThroat',
  'Liv/Toxic': 'liverAndToxicity',
  Immune: 'immuneSystem',
  'G-I': 'intestine',
  'Mind/Emot': 'mindAndEmotions',
  Hormones: 'hormonal',
  Aller: 'allergy',
  'C-V': 'cardiovascular',
  'G-U': 'urinaryTract',
};
const ROSsections = [
  'Head',
  'Nose',
  'Sleep',
  'Lungs',
  'Skin',
  'Hormones',
  'Liv/Toxic',
  'Eyes',
  'M&T',
  'Immune',
  'G-I',
  'Weight',
  'Mind/Emot',
  'Ears',
  'Aller',
  'C-V',
  'G-U',
  'Energy',
  'Dental',
  'other',
];

export function FemalePDF({
  filteredSummaryQuestions,
  generalInfo,
  gender,
}: FemalePDFProps) {
  const section6 = [
    {
      label: 'Estrog Def',
      propName: 'estrogenDeficiency',
    },
    {
      label: 'Estrog Xs',
      propName: 'estrogenExcess',
    },
    {
      label: 'Prog Def',
      propName: 'progesteroneDeficiency',
    },
    {
      label: 'Test Def',
      propName: 'testoteroneDeficiency',
    },
  ];

  function getProgesteroneDeficiencyData(label: string, index: number) {
    if (!label) {
      return '';
    }
    const answer = getAnswer(
      filteredSummaryQuestions.progesteroneDeficiency?.[index]
    );

    return filteredSummaryQuestions.progesteroneDeficiency?.[index]?.question
      ?.id
      ? binaryAnswerToRating?.[answer] || binaryAnswerToRating?.[answer] === 0
        ? binaryAnswerToRating?.[answer]
        : answer
      : 0;
  }

  return (
    <Document title={`${generalInfo.name} Summary`}>
      <Page size="A4" style={styles.page} wrap={false}>
        <View
          style={[
            styles.Header,
            {
              marginBottom: '-1px',
              display: 'flex',
              justifyContent: 'center',
              fontSize: 1,
            },
          ]}>
          <Text
            style={[
              ...headFont,
              { color: '#349583', fontSize: 15, height: 'auto', padding: 4 },
            ]}>
            Female Health Questionnaire
          </Text>
        </View>{' '}
        <View
          style={[
            styles.Header,
            {
              marginBottom: '5px',
              display: 'flex',
              justifyContent: 'center',
              padding: 1,
              height: 'auto',
              fontSize: 11,
            },
          ]}>
          <Text style={headFont}>Summary</Text>
        </View>
        <View style={styles.Header}>
          <Text style={[styles.headerFields, { textTransform: 'capitalize' }]}>
            {summaryQuestions[gender].header[0].label}:{' '}
            {
              generalInfo[
                summaryQuestions[gender].header[0].label.toLowerCase()
              ]
            }
          </Text>{' '}
          <Text style={styles.headerFields}>
            {summaryQuestions[gender].header[1].label}:{' '}
            {getReadableDate(
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ],
              false,
              'numeric'
            )
              .split('/')
              .join('-')}
          </Text>{' '}
          <Text style={styles.headerFields}>
            {summaryQuestions[gender].header[2].label}:{' '}
            {getAge(
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ].split('-')[0],
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ].split('-')[1],
              generalInfo[
                summaryQuestions[gender].header[1].label.toLowerCase()
              ].split('-')[2]
            )}
            {
              generalInfo[
                summaryQuestions[gender].header[2].label.toLowerCase()
              ]
            }
          </Text>{' '}
          <Text style={styles.headerFields}>
            {summaryQuestions[gender].header[3].label}:{' '}
            {
              generalInfo[
                summaryQuestions[gender].header[3].label.toLowerCase()
              ]
            }
          </Text>
        </View>
        <View style={styles.sectionContainer}>
          <View style={styles.section}>
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[0].label}:{' '}
              {getAnswer(filteredSummaryQuestions.section1?.[0])
                ?.toString()
                .replace(',', ' , ')}
            </Text>{' '}
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[1].label}:{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[0]
              }{' '}
              ,{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[1]
              }
            </Text>{' '}
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[2].label}:{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[2]
              }
            </Text>{' '}
            <Text style={textStyle}>
              {summaryQuestions[gender].section1[3].label}:{' '}
              {
                getAnswer(filteredSummaryQuestions.section1?.[1])
                  ?.toString()
                  .split(',')?.[3]
              }
            </Text>
          </View>
          <View style={styles.section}>
            {summaryQuestions[gender].section2.map((i, index) => (
              <Text style={textStyle}>
                {i.label}:{' '}
                {getAnswer(filteredSummaryQuestions.section2?.[index])}
              </Text>
            ))}
          </View>
          <View style={styles.section}>
            {summaryQuestions[gender].section3.map((i, index) => (
              <Text style={textStyle}>
                {i.label}:{' '}
                {getAnswer(filteredSummaryQuestions.section3?.[index])}
              </Text>
            ))}
          </View>
          <View style={styles.section}>
            <Text style={textStyle}>Symptoms Summary :</Text>
            {section6.map(({ label, propName }) => (
              <Text style={textStyle}>
                {label}: {getAverage(filteredSummaryQuestions[propName])}
              </Text>
            ))}
          </View>
          <View style={styles.section}>
            {summaryQuestions[gender].section4.map((i, index) => (
              <Text style={textStyle}>
                {i.label}
                {' : '}
                {getAnswer(filteredSummaryQuestions.section4?.[index])}
              </Text>
            ))}
          </View>
          <View style={styles.section}>
            {summaryQuestions[gender].section5.map((i, index) => (
              <Text style={textStyle}>
                {i.label}:{' '}
                {getAnswer(filteredSummaryQuestions.section5?.[index])}
              </Text>
            ))}
            <Text style={textStyle}>
              {/* Need to work here  */}R of S total{' : '}
              {ROSsections.map((i) =>
                getAverage(filteredSummaryQuestions[i.toLowerCase()]) === ''
                  ? getAverage(filteredSummaryQuestions[shortSection[i]])
                  : getAverage(filteredSummaryQuestions[i.toLowerCase()])
              )
                .reduce((a, t) => (Number(a) + t ? Number(t) : 0), 0)
                .toFixed(1)}
            </Text>
          </View>
        </View>
        <View style={[styles.mainSectionContainer, { marginTop: '-2px' }]}>
          {summaryQuestions[gender].mainSection.map((i, index) => (
            <View style={[styles.mainSection]}>
              <Text
                style={[
                  ...textStyle,
                  {
                    width: '100px',
                    borderRight: '1px solid #a1a1a1',
                    minWidth: '100px',
                    maxWidth: '100px',
                  },
                ]}>
                {i.label}:
              </Text>{' '}
              <Text style={[...textStyle, { flex: 1 }]}>
                {' '}
                {getAnswer(filteredSummaryQuestions.mainSection?.[index])}
              </Text>{' '}
            </View>
          ))}
        </View>
        <View style={[styles.footer, { marginTop: '-2px' }]}>
          <View style={styles.footerSection}>
            <Text style={textStyle}>Estrogen deficiency</Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].estrogenDeficiency.map((i, index) => (
                <Text style={textStyle}>
                  {i.label}{' '}
                  {getAnswer(
                    filteredSummaryQuestions.estrogenDeficiency?.[index]
                  )}
                </Text>
              ))}
              <Text style={[...textStyle]}>
                Average of above{' : '}{' '}
                {getAverage(filteredSummaryQuestions.estrogenDeficiency)}
              </Text>
            </View>
          </View>{' '}
          <View style={styles.footerSection}>
            <Text style={textStyle}>Estrogen excess</Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].estrogenExcess.map((i, index) => (
                <Text style={textStyle}>
                  {i.label}{' '}
                  {getAnswer(filteredSummaryQuestions.estrogenExcess?.[index])}
                </Text>
              ))}
              <Text style={[...textStyle]}>
                Average of above{' : '}{' '}
                {getAverage(filteredSummaryQuestions.estrogenExcess)}
              </Text>
            </View>
          </View>{' '}
          <View style={styles.footerSection}>
            <Text style={textStyle}>Progesterone deficiency</Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].progesteroneDeficiency.map(
                (i, index) => (
                  <Text style={textStyle}>
                    {i.label} {getProgesteroneDeficiencyData(i.label, index)}
                  </Text>
                )
              )}
              <Text style={[...textStyle]}>
                Average of above{' : '}{' '}
                {getAverage(filteredSummaryQuestions.progesteroneDeficiency)}
              </Text>
            </View>
          </View>{' '}
          <View style={styles.footerSection}>
            <Text style={textStyle}>Testosterone deficiency</Text>
            <View style={styles.footerFields}>
              {summaryQuestions[gender].testoteroneDeficiency.map(
                (i, index) => (
                  <Text style={[...textStyle]}>
                    {i.label}{' '}
                    {getAnswer(
                      filteredSummaryQuestions.testoteroneDeficiency?.[index]
                    )}
                  </Text>
                )
              )}
              <Text style={[...textStyle]}>
                Average of above{' : '}{' '}
                {getAverage(filteredSummaryQuestions.testoteroneDeficiency)}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            border: '1px solid #a1a1a1',
            paddingHorizontal: 10,
            marginTop: '-2px',
          }}>
          <Text style={[...textStyle, { border: 'none' }]}>
            Review of Systems
          </Text>
          <Text style={[...textStyle, { border: 'none' }]}>
            Grand Average of R of S{' :  '}{' '}
            {(
              ROSsections.map((i) =>
                getAverage(filteredSummaryQuestions[i.toLowerCase()]) === ''
                  ? getAverage(filteredSummaryQuestions[shortSection[i]])
                  : getAverage(filteredSummaryQuestions[i.toLowerCase()])
              ).reduce((a, t) => (Number(a) + t ? Number(t) : 0), 0) /
              ROSsections.length
            ).toFixed(1)}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            border: '1px solid #a1a1a1',
            marginTop: '-2px',
          }}>
          {ROSsections.map((i) => (
            <Text
              style={[
                ...textStyle,
                { flexBasis: '20%', borderRight: '1px solid #a1a1a1' },
              ]}>
              {i}
              {' : '}{' '}
              {getAverage(filteredSummaryQuestions[i.toLowerCase()]) === ''
                ? getAverage(filteredSummaryQuestions[shortSection[i]])
                : getAverage(filteredSummaryQuestions[i.toLowerCase()])}
            </Text>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text style={headFont}>General Health Symptoms</Text>
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Head
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].head.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.head?.[index])}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Eyes
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].eyes.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.eyes?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Ears
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].ears.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.ears?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Nose
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].nose.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.nose?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Allergy
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].allergy.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.allergy?.[index])}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Mouth and Throat
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].mouthAndThroat.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.mouthAndThroat?.[index])}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Sleep
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].sleep.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.sleep?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Immune System
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].immuneSystem.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.immuneSystem?.[index])}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Cardiovascular
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].cardiovascular.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.cardiovascular?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Lungs
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].lungs.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.lungs?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Intestine
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].intestine.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.intestine?.[index])}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Skin
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].skin.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.skin?.[index])}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Weight
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].weight.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.weight?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Energy
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].energy.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.energy?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Hormonal
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].hormonal.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(
                  filteredSummaryQuestions.estrogenDeficiency?.[index]
                )}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Mind and Emotions
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].mindAndEmotions.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.mindAndEmotions?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Dental
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].dental.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.dental?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Liver and Toxicity
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].liverAndToxicity.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.liverAndToxicity?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Other
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].other.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.other?.[index])}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '15px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text style={headFont}>Symptoms</Text>
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Symptoms of Estrogen Deficiency
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].estrogenDeficiency.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(
                  filteredSummaryQuestions.estrogenDeficiency?.[index]
                )}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Symptoms of Estrogen Excess
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].estrogenExcess.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(filteredSummaryQuestions.estrogenExcess?.[index])}
              </Text>
            </View>
          ))}
        </View>{' '}
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Symptoms of Progesterone Deficiency
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].progesteroneDeficiency.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(
                  filteredSummaryQuestions.progesteroneDeficiency?.[index]
                )}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            styles.Header,
            { marginTop: '5px', display: 'flex', justifyContent: 'center' },
          ]}>
          <Text
            style={[...headFont, { fontSize: 10, padding: 1, height: 'auto' }]}>
            Symptoms of Testosterone Deficiency
          </Text>
        </View>
        <View
          style={{
            border: '1px solid #a1a1a1',
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '-1px',
          }}>
          {summaryQuestions[gender].testoteroneDeficiency.map((i, index) => (
            <View style={{ flexBasis: '50%' }}>
              <Text
                style={[...textStyle, { borderRight: '1px solid #a1a1a1' }]}>
                {i.label}
                {'      '}
                {getAnswer(
                  filteredSummaryQuestions.testoteroneDeficiency?.[index]
                )}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
