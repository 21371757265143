import React, { useEffect, useMemo } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';

import { useCalculateDifference } from 'hooks/e-prescribe/useCalculateDifference';
import { useCheckPrescription } from 'hooks/e-prescribe/useCheckPrescription';
import { useCurrentActivePrescription } from 'hooks/e-prescribe/useCurrentPrescription';
import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { useMaximumDoseHandler } from 'hooks/e-prescribe/useMaximumDoseHandler';
import { useSigGenerator } from 'hooks/e-prescribe/useSigGenerator';
import { useTestosteroneMenController } from 'hooks/hormones-new/useTestosteroneMenController';
import { PatientPrescriptionService } from 'services/prescription';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { colorObjForCheckPrescription, colorObjForSupply } from '../common';
import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function TestosteroneMen({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();

  const {
    TestosteroneMenDetails,

    dtd,
    dose,
    setDose,
    refills,
    setRefills,

    setDTD,
    currentDose,
    setCurrentDose,
    marginValue,
  } = useTestosteroneMenController();

  const getCurrentPrescriptionCheckMutation = useMutation(
    (id: string) => PatientPrescriptionService.getPrescriptionCheckValue(id),
    {
      onSuccess() {
        // console.log(data, '******');
      },
    }
  );

  const {
    currentActivePrescriptions,
    isLoading: currentPrescriptionFetchLoading,
  } = useCurrentActivePrescription({
    medicationId: RxDetails?.id ?? '',
    onSuccess(prescription) {
      getCurrentPrescriptionCheckMutation.mutateAsync(prescription?.id ?? '');
    },
  });

  const { calculatePercentageDifference, getDifferenceUI } =
    useCalculateDifference({
      hormoneName: HormoneNames.TESTOSTERONE_MEN,
    });

  const { maximumDose, setMaximumDose, maxDoseOptions, setMaxDosePreValue } =
    useMaximumDoseHandler({
      dose,
      doses: TestosteroneMenDetails.dose,
      incrementalValue: marginValue,
    });

  const { generatedSIG, setGeneratedSIG } = useSigGenerator({
    dose,
    maximumDose,
    doseOptions: TestosteroneMenDetails?.dose,
    marginValue,
  });

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: TestosteroneMenDetails.displayName,
      name: HormoneNames.TESTOSTERONE_WOMEN,
      strength: TestosteroneMenDetails.strength,
      strengthUnit: TestosteroneMenDetails.strengthUnit,
      ratioUnit: TestosteroneMenDetails.ratioUnit,
      dose: TestosteroneMenDetails.dose[maximumDose + dose + marginValue],
      minimumDose: TestosteroneMenDetails.dose[dose],
      doseUnit: TestosteroneMenDetails.doseUnit,
      refills,
      dtd,
      sig: generatedSIG,
      deliveryMethod: TestosteroneMenDetails.deliveryMethod,
      family: TestosteroneMenDetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: TestosteroneMenDetails.dtdUnit,
      currentDose,
    }),
    [
      TestosteroneMenDetails,
      RxDetails,
      dose,
      dtd,
      generatedSIG,
      maximumDose,
      refills,
      currentDose,
      marginValue,
    ]
  );

  const { checkPrescriptionValueQuery: maxDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: TestosteroneMenDetails.dose[dose + maximumDose + marginValue],
      },
      onSuccess() {
        //
      },
    });

  const { checkPrescriptionValueQuery: minDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: TestosteroneMenDetails.dose[dose],
      },
      onSuccess() {
        // console.log(data);
      },
    });

  const stadardizedNamePrescriptionObject = useMemo(
    () => ({
      prescription: {
        ...prescriptionObject,
        dose: TestosteroneMenDetails.dose[dose],
      },
    }),
    [prescriptionObject, TestosteroneMenDetails, dose]
  );

  const { getStandardizedNameQuery } = useHormoneStandardizedName(
    stadardizedNamePrescriptionObject
  );

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      checkValues: {
        withMaxDose: maxDosePrescriptionValues.data?.data.value,
        withMinDose: minDosePrescriptionValues.data?.data.value,
        unit: maxDosePrescriptionValues.data?.data.unit ?? '',
      },
      daysOfSupply: {
        withMaxDose:
          maxDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
        withMinDose:
          minDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
      },
      isHighPotencyRx: false,
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      maxDosePrescriptionValues,
      minDosePrescriptionValues,
      getStandardizedNameQuery.data?.data.standardRepresentation,
    ]
  );

  function getComparisonValueForMinimumDose() {
    if (
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value
    ) {
      const comparableValue =
        getCurrentPrescriptionCheckMutation.data?.data?.currentDoseQuality
          ?.value ??
        getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value;

      return getDifferenceUI(
        calculatePercentageDifference(
          comparableValue,
          minDosePrescriptionValues.data?.data.value
        ),
        `${comparableValue} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
      );
    }

    return '';
  }

  useEffect(() => {
    if (TestosteroneMenDetails.dose[dose + maximumDose + marginValue] < 0.35) {
      setDTD(18);

      return;
    }
    if (TestosteroneMenDetails.dose[dose + maximumDose + marginValue] >= 0.7) {
      setDTD(60);
    } else {
      setDTD(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dose, maximumDose]);

  useEffect(() => {
    if (currentActivePrescriptions?.id) {
      getCurrentPrescriptionCheckMutation.mutateAsync(
        currentActivePrescriptions?.id ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions?.id]);

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
        })
      }
      prescriptionObject={{ ...prescriptionObject, ...prescriptionCheckValues }}
      validations={{
        [HormoneValidations.Loading]: {
          isFailed:
            maxDosePrescriptionValues.isLoading ||
            minDosePrescriptionValues.isLoading ||
            getStandardizedNameQuery.isLoading,
        },
        [HormoneValidations.MissingDEA]: {
          isFailed:
            !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: !maxDosePrescriptionValues.data?.data.validDaysOfSupply,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(generatedSIG.length) > 140,
        },
      }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex justify-between',
          title: '',
          children: [
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${TestosteroneMenDetails.strength} ${TestosteroneMenDetails.strengthUnit}`,
              label: 'Strength',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ml`,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: TestosteroneMenDetails.deliveryMethod,
              label: 'Delivery Method',
            },
            {
              label: 'Days of Supply',
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                !!minDosePrescriptionValues.isLoading,
              value:
                maxDosePrescriptionValues.data?.data.daysOfSupply ===
                minDosePrescriptionValues.data?.data?.daysOfSupply
                  ? `${maxDosePrescriptionValues.data?.data.daysOfSupply} Days`
                  : `${maxDosePrescriptionValues.data?.data.daysOfSupply}-${minDosePrescriptionValues.data?.data?.daysOfSupply} Days`,

              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForSupply[
                      maxDosePrescriptionValues.data?.data.validDaysOfSupply.toString()
                    ]
                  : ''
              }`,
            },
          ],
        },

        {
          title: 'Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${TestosteroneMenDetails.dose[val]?.toFixed(2)} ${
                  TestosteroneMenDetails.doseUnit
                }`}`,
              onChange: (d) => {
                setDose(d);
              },
              options: [
                ...TestosteroneMenDetails.dose.slice(
                  0,
                  TestosteroneMenDetails.dose.length - marginValue
                ),
              ],
              value: dose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!minDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Minimum ${minDosePrescriptionValues?.data?.data.unit}`,
              value:
                `${minDosePrescriptionValues?.data?.data.value} ${minDosePrescriptionValues?.data?.data.unit}` ??
                '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                minDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      minDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText: getComparisonValueForMinimumDose(),
            },
            {
              type: PrescriptionInputTypes.DoseSplitter,
              onChange: (cd) => {
                setCurrentDose({
                  morning: {
                    value: cd.morning,
                    unit: TestosteroneMenDetails.doseUnit,
                  },
                  evening: {
                    value: cd.evening,
                    unit: TestosteroneMenDetails.doseUnit,
                  },
                });
              },
              hormoneName: HormoneNames.TESTOSTERONE_MEN,
              value: TestosteroneMenDetails.dose[dose],
              wrapperClassName: 'col-span-9 pl-4 mt-16 ',
            },
          ],
        },
        {
          title: 'Maximum Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${maxDoseOptions[val]?.toFixed(2)} ${
                  TestosteroneMenDetails.doseUnit
                }`}`,
              onChange: (d) => {
                setMaxDosePreValue(maxDoseOptions[d]);
                setMaximumDose(d);
              },
              options: maxDoseOptions,
              value: maximumDose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Maximum ${maxDosePrescriptionValues?.data?.data.unit}`,
              value:
                `${maxDosePrescriptionValues?.data?.data.value} ${maxDosePrescriptionValues?.data?.data.unit}` ??
                '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      maxDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText:
                currentActivePrescriptions?.id &&
                !!getCurrentPrescriptionCheckMutation.data?.data?.value
                  ? getDifferenceUI(
                      calculatePercentageDifference(
                        getCurrentPrescriptionCheckMutation.data?.data?.value,
                        maxDosePrescriptionValues.data?.data.value
                      ),
                      `${getCurrentPrescriptionCheckMutation.data?.data?.value} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
                    )
                  : '',
            },
          ],
        },
        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${TestosteroneMenDetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: TestosteroneMenDetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setGeneratedSIG(s);
              },
              value: generatedSIG,
            },
          ],
        },
      ]}
    />
  );
}
