import React from 'react';

import { RadioGroup } from '@headlessui/react';

import { Typography } from 'components/common/Typography/Typography';

interface DTDSelectorProps {
  label?: string;
  DTDValues: number[];
  value: number | string;
  onChange: (value: number) => void;
  unit?: string;
  showLabel?: boolean;
}

export function DTDSelector({
  label,
  DTDValues,
  value,
  onChange,
  unit,
  showLabel = true,
}: DTDSelectorProps) {
  return (
    <>
      {showLabel && (
        <div className="col-span-2 text-left  text-sm font-semibold xl:col-span-1   ">
          <div className="flex">
            <Typography variant="h5" className="">
              {label ?? 'DTD'}
            </Typography>
          </div>
        </div>
      )}
      <div className="col-span-7 flex  xl:col-span-8 ">
        <RadioGroup value={value} onChange={onChange}>
          <div className="inline-flex cursor-pointer  shadow-md hover:shadow-lg focus:shadow-lg">
            {DTDValues.map((item: number, index: number, arr) => (
              <RadioGroup.Option value={item}>
                {/* eslint-disable-next-line */}
                {({ checked }: any) => (
                  <span
                    className={`lowercase ${index === 0 && 'rounded-l'} ${
                      index === arr.length - 1 && 'rounded-r'
                    }  flex px-5 py-2 
                  ${
                    checked
                      ? 'bg-primary-dark hover:bg-primary-dark'
                      : 'bg-background-light'
                  }    whitespace-nowrap !text-base font-normal uppercase leading-tight text-primary-contrastText transition duration-150 ease-in-out hover:bg-primary-main focus:outline-none focus:ring-0`}>
                    {`${item} ${unit}`}
                  </span>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
}
