import React, { useEffect, useMemo } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';

import { useTestosteroneWomenController } from 'hooks/e-prescribe/hormone-v2-hooks/useTestosteroneWomenController';
import { useCalculateDifference } from 'hooks/e-prescribe/useCalculateDifference';
import { useCheckPrescription } from 'hooks/e-prescribe/useCheckPrescription';
import { useCurrentActivePrescription } from 'hooks/e-prescribe/useCurrentPrescription';
import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { PatientPrescriptionService } from 'services/prescription';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { colorObjForCheckPrescription, colorObjForSupply } from '../common';
import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function TestosteroneWomen({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();

  const {
    TestosteroneWomenDetails,

    dtd,
    dose,
    setDose,
    refills,
    setRefills,
    strength,
    setStrength,
    setDTD,
    currentDose,
    setCurrentDose,
    renderModal,
    generatedSIG,
    maximumDose,
    setGeneratedSIG,
    setMaximumDose,
    strengthOptions,
    marginValue,
    maxDoseOptions,
    setMaxDosePreValue,
  } = useTestosteroneWomenController();

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: TestosteroneWomenDetails.displayName,
      name: HormoneNames.TESTOSTERONE_WOMEN,
      strength: `${strength}`,
      strengthUnit: TestosteroneWomenDetails.strengthUnit,

      ratioUnit: TestosteroneWomenDetails.ratioUnit,
      dose: TestosteroneWomenDetails.dose[maximumDose + dose + marginValue],
      minimumDose: TestosteroneWomenDetails.dose[dose],
      doseUnit: TestosteroneWomenDetails.doseUnit,
      refills,
      dtd,
      sig: generatedSIG,
      deliveryMethod: TestosteroneWomenDetails.deliveryMethod,
      family: TestosteroneWomenDetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: TestosteroneWomenDetails.dtdUnit,
      currentDose,
    }),
    [
      TestosteroneWomenDetails,
      RxDetails,
      dose,
      dtd,
      generatedSIG,
      maximumDose,
      refills,
      strength,
      currentDose,
      marginValue,
    ]
  );

  const getCurrentPrescriptionCheckMutation = useMutation(
    (id: string) => PatientPrescriptionService.getPrescriptionCheckValue(id),
    {
      onSuccess() {
        // console.log(data, '******');
      },
    }
  );

  const {
    currentActivePrescriptions,
    isLoading: currentPrescriptionFetchLoading,
  } = useCurrentActivePrescription({
    medicationId: RxDetails?.id ?? '',
    onSuccess(prescription) {
      getCurrentPrescriptionCheckMutation.mutateAsync(prescription?.id ?? '');
    },
  });

  const { checkPrescriptionValueQuery: maxDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: TestosteroneWomenDetails.dose[dose + maximumDose + marginValue],
      },
      onSuccess() {
        //
      },
    });

  const { calculatePercentageDifference, getDifferenceUI } =
    useCalculateDifference({
      hormoneName: HormoneNames.TESTOSTERONE_WOMEN,
    });

  const { checkPrescriptionValueQuery: minDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: TestosteroneWomenDetails.dose[dose],
      },
      onSuccess() {
        // console.log(data);
      },
    });

  const { getStandardizedNameQuery } = useHormoneStandardizedName({
    prescription: {
      ...prescriptionObject,
      dose: TestosteroneWomenDetails.dose[dose],
    },
  });

  // console.log(currentActivePrescriptions);

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      checkValues: {
        withMaxDose: maxDosePrescriptionValues.data?.data.value,
        withMinDose: minDosePrescriptionValues.data?.data.value,
        unit: maxDosePrescriptionValues.data?.data.unit ?? '',
      },
      daysOfSupply: {
        withMaxDose:
          maxDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
        withMinDose:
          minDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
      },
      isHighPotencyRx: false,
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      maxDosePrescriptionValues,
      minDosePrescriptionValues,
      getStandardizedNameQuery.data?.data.standardRepresentation,
    ]
  );

  function getComparisonValueForMinimumDose() {
    if (
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value
    ) {
      const comparableValue =
        getCurrentPrescriptionCheckMutation.data?.data?.currentDoseQuality
          ?.value ??
        getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value;

      return getDifferenceUI(
        calculatePercentageDifference(
          comparableValue,
          minDosePrescriptionValues.data?.data.value
        ),
        `${comparableValue} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
      );
    }

    return '';
  }

  useEffect(() => {
    if (
      TestosteroneWomenDetails.dose?.[dose + maximumDose + marginValue] > 0.3
    ) {
      setDTD(30);
    } else {
      setDTD(18);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dose, maximumDose]);

  useEffect(() => {
    if (currentActivePrescriptions?.id) {
      getCurrentPrescriptionCheckMutation.mutateAsync(
        currentActivePrescriptions?.id ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions?.id]);

  return (
    <HormoneDetailsUIV2
      onAdd={() =>
        addNewPrescription({
          ...prescriptionObject,
          ...prescriptionCheckValues,
        })
      }
      prescriptionObject={{ ...prescriptionObject, ...prescriptionCheckValues }}
      validations={{
        [HormoneValidations.Loading]: {
          isFailed:
            maxDosePrescriptionValues.isLoading ||
            minDosePrescriptionValues.isLoading ||
            getStandardizedNameQuery.isLoading,
        },
        [HormoneValidations.MissingDEA]: {
          isFailed:
            !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
        },
        [HormoneValidations.DaysOfSupply]: {
          isFailed: !maxDosePrescriptionValues.data?.data.validDaysOfSupply,
        },
        [HormoneValidations.SIGExceed140Chars]: {
          isFailed: Number(generatedSIG.length) > 140,
        },
      }}
      onBack={onBack}
      title={capitalizedString(RxDetails?.name)}
      hormoneInputs={[
        {
          containerClassName: 'flex justify-between',
          title: '',
          children: [
            {
              type: PrescriptionInputTypes.SelectorCheckbox,
              options: strengthOptions,
              onChange(s) {
                setStrength(s as number);
                setDose(0);
                setMaximumDose(0);
                renderModal?.({
                  oldValue: strength.toString(),
                  newValue: s.toString(),
                  checkPrescriptionUnit: 'mg/day',
                  changeValueUnit: TestosteroneWomenDetails.strengthUnit,
                });
              },
              label: 'Strength',
              value: strength,
              unit: TestosteroneWomenDetails.strengthUnit,
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: `${dtd.toString()} ml`,
              label: 'DTD',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading: false,
              value: TestosteroneWomenDetails.deliveryMethod,
              label: 'Delivery Method',
            },
            {
              label: 'Days of Supply',
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                !!minDosePrescriptionValues.isLoading,
              value:
                maxDosePrescriptionValues.data?.data.daysOfSupply ===
                minDosePrescriptionValues.data?.data?.daysOfSupply
                  ? `${maxDosePrescriptionValues.data?.data.daysOfSupply} Days`
                  : `${maxDosePrescriptionValues.data?.data.daysOfSupply}-${minDosePrescriptionValues.data?.data?.daysOfSupply} Days`,

              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForSupply[
                      maxDosePrescriptionValues.data?.data.validDaysOfSupply.toString()
                    ]
                  : ''
              }`,
            },
          ],
        },

        {
          title: 'Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${TestosteroneWomenDetails.dose[val]?.toFixed(2)} ${
                  TestosteroneWomenDetails.doseUnit
                }`}`,
              onChange: (d) => {
                setDose(d);
              },
              options: [
                ...TestosteroneWomenDetails.dose.slice(
                  0,
                  TestosteroneWomenDetails.dose.length - marginValue
                ),
              ],
              value: dose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!minDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Minimum ${minDosePrescriptionValues?.data?.data.unit}`,
              value:
                `${minDosePrescriptionValues?.data?.data.value} ${minDosePrescriptionValues?.data?.data.unit}` ??
                '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                minDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      minDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText: getComparisonValueForMinimumDose(),
            },
            {
              type: PrescriptionInputTypes.DoseSplitter,
              onChange: (cd) => {
                setCurrentDose({
                  morning: {
                    value: cd.morning,
                    unit: TestosteroneWomenDetails.doseUnit,
                  },
                  evening: {
                    value: cd.evening,
                    unit: TestosteroneWomenDetails.doseUnit,
                  },
                });
              },
              hormoneName: HormoneNames.TESTOSTERONE_WOMEN,
              value: TestosteroneWomenDetails.dose[dose],
              wrapperClassName: 'col-span-9 pl-4 mt-10 ',
            },
          ],
        },
        {
          title: 'Maximum Dose',
          containerClassName:
            'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${`${maxDoseOptions[val]?.toFixed(2)} ${
                  TestosteroneWomenDetails.doseUnit
                }`}`,
              onChange: (d) => {
                setMaxDosePreValue(maxDoseOptions[d]);
                setMaximumDose(d);
              },
              options: maxDoseOptions,
              value: maximumDose,
              wrapperClassName: 'col-span-9',
            },
            {
              type: PrescriptionDisplayTypes.InfoBox,
              isLoading:
                !!maxDosePrescriptionValues.isLoading ||
                getCurrentPrescriptionCheckMutation.isLoading ||
                currentPrescriptionFetchLoading,
              label: `Maximum ${maxDosePrescriptionValues?.data?.data.unit}`,
              value:
                `${maxDosePrescriptionValues?.data?.data.value} ${maxDosePrescriptionValues?.data?.data.unit}` ??
                '',
              wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
              colorClass: `${
                maxDosePrescriptionValues.data?.data.rxQuality
                  ? colorObjForCheckPrescription[
                      maxDosePrescriptionValues.data?.data.rxQuality
                    ]
                  : ''
              }`,
              helperText:
                currentActivePrescriptions?.id &&
                !!getCurrentPrescriptionCheckMutation.data?.data?.value
                  ? getDifferenceUI(
                      calculatePercentageDifference(
                        getCurrentPrescriptionCheckMutation.data?.data?.value,
                        maxDosePrescriptionValues.data?.data.value
                      ),
                      `${getCurrentPrescriptionCheckMutation.data?.data?.value} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
                    )
                  : '',
            },
          ],
        },
        {
          title: 'Refills',
          containerClassName:
            'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
          children: [
            {
              type: PrescriptionInputTypes.Slider,
              getTooltip: (val) =>
                `${TestosteroneWomenDetails.refills[val]}  refills`,
              onChange: (d) => {
                setRefills(d);
              },
              options: TestosteroneWomenDetails.refills,
              value: refills,
              wrapperClassName: 'col-span-9',
            },
          ],
        },
        {
          containerClassName: '',
          title: 'SIG',
          children: [
            {
              type: PrescriptionInputTypes.SIG,
              wrapperClassName: 'col-span-9',
              onChange: (s) => {
                setGeneratedSIG(s);
              },
              value: generatedSIG,
            },
          ],
        },
      ]}
    />
  );
}
