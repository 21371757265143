import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';

type DeleteModal = {
  state: string;
  openModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function DeleteConfirmationModal({
  onCancel,
  onConfirm,
  openModal,
  state,
}: DeleteModal) {
  return (
    <Modal
      open={openModal}
      closeModal={() => {
        //
      }}>
      <div>
        <Typography color="gray" variant="h5">
          Are you sure you want to delete the record for the state of {state}?
        </Typography>
      </div>

      <div className="my-4 mt-8 flex justify-center gap-4">
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={onConfirm}>
          Delete
        </Button>
      </div>
    </Modal>
  );
}
