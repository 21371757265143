import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { Control, FieldValues } from 'react-hook-form';

import { TextInput } from 'components/common/Input/TextInput';
import { MultiSelectDropDown } from 'components/common/MultiSelectDropDown/MultiSelectDropDown';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';

interface ProviderDetailsProps<OnboardProviderFormType extends FieldValues> {
  control: Control<OnboardProviderFormType>;
}

export function ProviderDetails<OnboardProviderFormType extends FieldValues>({
  control,
}: ProviderDetailsProps<OnboardProviderFormType>) {
  const getProviderSpecialtiesQuery = useQuery(
    [QueryKeys.ProviderSpecialties],
    UserService.getProviderSpecialties,
    {
      staleTime: Infinity,
      retry: false,
    }
  );

  return (
    <div className=" ">
      <div className="flex justify-between">
        <Typography variant="h5">Provider Details</Typography>
      </div>

      <div className="mt-3 grid grid-cols-12 gap-x-4">
        <div className="col-span-6">
          <div className="flex items-center">
            <TextInput
              name="licenseNumber"
              type="text"
              control={control}
              placeholder="License Number"
              className=" "
              fullWidth
            />
            <span className="mx-2" />
            <TextInput
              type="text"
              placeholder="NPI Number"
              className="placeholder:text-zinc-400  "
              fullWidth
              name="npi"
              control={control}
            />
          </div>
        </div>
        {/* <DropDown
          searchable
          label="Select Provider type"
          name="type"
          control={control}
          fullWidth
          options={getProviderTypesQuery?.data?.data.map((item) => ({
            title: item.name,
            value: item.id,
          }))}
        /> */}
        {/* <span className="mx-2" /> */}
        <div className="col-span-6">
          <div className="flex items-center">
            <TextInput
              type="text"
              placeholder="DEA Number"
              className="placeholder:text-zinc-400  "
              fullWidth
              name="dea"
              control={control}
            />
            <span className="mx-2" />
            <MultiSelectDropDown
              searchable
              label="Select Specialties"
              name="specialities"
              control={control}
              fullWidth
              options={getProviderSpecialtiesQuery?.data?.data.map((item) => ({
                title: item.name,
                value: item.id,
              }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
