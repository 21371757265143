import { useState } from 'react';

import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';

export const useDanazolController = () => {
  const DanazolDetails = femaleHormoneRxTypes?.danazol;
  const [strength, setStrength] = useState(6.25);
  const [maximumDose, setMaximumDose] = useState(0);

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(60);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');

  return {
    DanazolDetails,
    strength,

    setStrength,
    sig,
    setSig,
    setDTD,
    DTDValues,
    dtd,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
  };
};
