import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  fontSize: {
    fontSize: 10,
    color: '#313131',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20 15',
  },
  Header: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #a1a1a1',
    padding: 5,
    fontSize: 10,
    color: '#313131',
  },
  headerFields: {
    flexGrow: 1,
    margin: '0 5',
    fontSize: 11,
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-1px',
  },
  section: {
    flexGrow: 1,
    color: '#313131',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #a1a1a1',
    maxWidth: '100px',
  },
  mainSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #a1a1a1',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    border: '1px solid #a1a1a1',
  },
  footerSection: {
    marginTop: 2,
    flexBasis: '25%',
    fontSize: 11,
    color: '#313131',
    borderRight: '1px solid #a1a1a1',
  },
  footerSectionMen: {
    marginTop: 2,
    flexBasis: '33.333%',
    fontSize: 11,
    color: '#313131',
    borderRight: '1px solid #a1a1a1',
  },
  footerFields: {
    display: 'flex',
    flexDirection: 'column',
  },
  fontHead: {
    fontWeight: 500,
  },
  answerField: {
    borderLeft: '1px solid #a1a1a1',
    height: '25px',
    color: '#313131',
    width: '50px',
    backgroundColor: 'red',
  },
});

export const textStyle = [
  styles.fontSize,
  {
    height: '25px',
    padding: 5,
    borderBottom: '1px solid #a1a1a1',
    color: '#313131',
  },
];

export const headFont = [
  ...textStyle,
  { fontWeight: 700, borderBottom: 'none', fontSize: 12, color: '#818181' },
];
