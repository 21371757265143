import React from 'react';

import classNames from 'classnames';

import { Typography } from 'components/common/Typography/Typography';
import { QuestionnaireCategoryType } from 'types/questionnaire';

import { QuestionDetail } from './QuestionDetail';

type QuestionCategoryProps = {
  category: QuestionnaireCategoryType;
  index: number;
};

export function QuestionCategory({ category, index }: QuestionCategoryProps) {
  return (
    <div
      className={classNames(' rounded bg-background-main p-4 pt-0  ', {
        'mt-2  ': index > 0,
        'pt-4': !!category?.label,
      })}>
      {category.label && (
        <div className=" flex flex-col ">
          <Typography variant="h4" color="textMuted">
            {category.label}
          </Typography>

          <div className="mt-2 h-[4px] grow bg-background-light " />
        </div>
      )}
      <div className="grid grid-cols-12 gap-x-4 ">
        {category?.questions.map((question) => (
          <QuestionDetail question={question} />
        ))}
      </div>
    </div>
  );
}
