import React from 'react';

import { Typography } from 'components/common/Typography/Typography';

type RadioSelectorProps = {
  label?: string;
  value: string;
  onChange: (val: string) => void;
  options: string[];
};

export function RadioSelector({
  label,
  value,
  onChange,
  options,
}: RadioSelectorProps) {
  return (
    <div className="">
      <div className=" text-left">
        <Typography variant="h5" className="">
          {label}
        </Typography>
      </div>
      <div className="mt-3 flex">
        {options?.map((option) => (
          <div key={option} className="mr-8 flex items-center">
            <input
              className="h-10 w-4  cursor-pointer accent-primary-main"
              type="radio"
              name={option}
              value={option}
              checked={value === option}
              onChange={() => onChange(option)}
            />
            <label
              className="ml-2 text-md text-background-contrastText "
              htmlFor={option}>
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
