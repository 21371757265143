import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { QueryKeys } from 'constants/query-keys';
import { phoneSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { UserService } from 'services/user';

type UpdatePhoneProps = {
  modal: boolean;
  closeModal: (state: boolean) => void;
  phone: string;
};

type PhoneFormType = {
  phone: string;
};

export function UpdatePhone({ modal, closeModal, phone }: UpdatePhoneProps) {
  const queryClient = useQueryClient();
  const updatePhoneMutation = useMutationWithToast(
    UserService.updatePhoneNumber
  );

  const { handleSubmit, control, reset, errors } =
    useFormWithErrors<PhoneFormType>({
      mutation: updatePhoneMutation,
      schema: phoneSchema,
    });

  const onSubmit = handleSubmit((data) => {
    updatePhoneMutation.mutate(
      { phone: `+${data.phone}` },
      {
        onSuccess: () => {
          closeModal(false);
          reset();
          queryClient.refetchQueries([QueryKeys.UserProfile]);
        },
      }
    );
  });

  return (
    <div>
      <Modal
        title="Change Phone number"
        open={modal}
        className="w-2/5"
        closeModal={closeModal}>
        <div className="mt-4">
          <TextInput
            className="w-full"
            type="tel"
            name="phone"
            control={control}
            helperText={
              extractValidationErrors('phone', updatePhoneMutation, errors).msg
            }
            error={
              extractValidationErrors('phone', updatePhoneMutation, errors)
                .hasError
            }
            defaultValue={phone}
            placeholder="Phone"
          />

          <div>
            <div className="mt-2 mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => closeModal(false)}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updatePhoneMutation.isLoading}
                onClick={onSubmit}>
                Update Phone
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
