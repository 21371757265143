import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { RangeSlider } from 'components/common/Slider/RangeSlider';
import { Typography } from 'components/common/Typography/Typography';

export type PrescriptionSliderProps = {
  label?: ReactNode;
  value: number;
  onChange: (value: number) => void;

  options: (string | number)[];

  getTooltip: (value: number) => ReactNode;
  disable?: boolean;
};

export function PrescriptionSlider({
  label,
  value,
  onChange,

  options,

  getTooltip,
  disable,
}: PrescriptionSliderProps) {
  return (
    <div className="col-span-9 mb-2 grid grid-cols-9 gap-x-12">
      {label && (
        <div className="col-span-2 -mt-1 text-left  text-sm font-semibold xl:col-span-1  ">
          <Typography className="mr-1" variant="h5">
            {label}
          </Typography>
        </div>
      )}
      <div
        className={classNames('col-span-9 text-black ', {
          '!col-span-7 text-black xl:!col-span-8': !!label,
        })}>
        <RangeSlider
          disable={disable}
          value={value}
          onChange={onChange}
          getTooltip={getTooltip}
          options={options}
        />
      </div>
    </div>
  );
}
