import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { HormoneWrapper } from 'components/prescriptions/HormoneWrapper/HormoneWrapper';
import { DeliveryMethods } from 'constants/delivery-methods';
import { femaleHormoneRxTypes } from 'constants/hormone-details';
import { useBiEstController } from 'hooks/hormones/useBiEstController';
import {
  HormoneNames,
  PrescribedObjectTypes,
  PrescriptionKeys,
} from 'types/prescriptionTypes';

import { CustomSlider } from '../../common/Slider/Slider';

type BiestPropTypes = {
  addNewPrescription: (prescriptionObject: PrescribedObjectTypes) => void;
};

export function BiEst({ addNewPrescription }: BiestPropTypes) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const BiEstDetails = femaleHormoneRxTypes?.[rx || ''];
  const {
    mgPerMl,
    setMgPerMl,
    DTD,
    DTDInWords,
    E3E2Ratio,
    setE3E2Ratio,
    drops,
    sig,
    setSig,
    refills,
    setRefils,
    setDrops,
  } = useBiEstController();

  const prescriptionObject: PrescriptionKeys = {
    displayName: BiEstDetails.displayName,
    name: HormoneNames.BI_EST,
    mgPerMl: `${mgPerMl} mg/ml`,
    linkedHormone: 'Estradiol',
    e3Toe2: BiEstDetails.E3ToE2[E3E2Ratio],
    drops: `${drops} drop${drops > 1 ? 's' : ''}/day`,
    refills,
    dtd: DTD,
    sig,
    family: BiEstDetails?.family,
    deliveryMethod: DeliveryMethods.OrificeReducer.name,
  };

  return (
    <HormoneWrapper
      title={BiEstDetails.displayName}
      onClick={() => {
        addNewPrescription(prescriptionObject);
      }}>
      <div className="py-2   pb-4 text-background-contrastText">
        <div className="-mb-8 grid grid-cols-12 content-center gap-x-8">
          <div className="col-span-1 mt-1 h-24 text-left text-sm font-semibold  ">
            <Typography className="mr-1" variant="h5">
              Strength
            </Typography>
          </div>
          <div className="col-span-8 text-black">
            <CustomSlider
              min={BiEstDetails.mgPerMl[0]}
              max={BiEstDetails.mgPerMl.at(-1)}
              sliderValue={mgPerMl}
              toolTipValue={`${mgPerMl} mg/ml`}
              onChange={(val) => {
                setMgPerMl(Number(val));
              }}
            />
          </div>
          <div className="col-span-3">
            <Typography variant="h5" align="left">
              Delivery Method:{' '}
            </Typography>
            <Typography className="ml-1" color="primary" variant="h5">
              {DeliveryMethods.OrificeReducer.name}
            </Typography>
          </div>
        </div>

        {/* E3:E2 slider */}

        <div className="mt-4 grid grid-cols-12 gap-x-10 ">
          <div className="col-span-1 mt-6  mt-1 text-left text-sm font-semibold  ">
            <Typography variant="h5" className="mr-1">
              Ratio
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={0}
              max={15}
              toolTipValue={`E3:E2(${BiEstDetails.E3ToE2[E3E2Ratio]})`}
              sliderValue={E3E2Ratio}
              customStartRangeValue="05/95"
              customEndRangeValue="80/20"
              onChange={(val) => {
                setE3E2Ratio(Number(val));
              }}
            />
          </div>

          <div className="col-span-3 text-left  text-sm font-semibold  ">
            <div className="flex">
              <Typography variant="h5"> D.T.D: </Typography>{' '}
              <Typography variant="h5" className="ml-1" color="primary">
                {DTDInWords}
              </Typography>
            </div>
          </div>
        </div>

        {/* drops per day slider */}

        <div className="mt-8 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold  ">
            <Typography variant="h5" className="mr-1">
              Dose
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={BiEstDetails.dropsPerDay[0]}
              max={BiEstDetails.dropsPerDay.at(-1)}
              toolTipValue={`${drops} drop${drops > 1 ? 's' : ''}/day`}
              sliderValue={drops}
              onChange={(val) => {
                setDrops(Number(val));
              }}
            />
          </div>
        </div>

        {/* Refills slider */}
        <div className="mt-8 grid grid-cols-12 gap-x-10">
          <div className="col-span-1 mt-1  text-left text-sm font-semibold  ">
            <Typography variant="h5" className="mr-1">
              Refills
            </Typography>
          </div>
          <div className="col-span-8">
            <CustomSlider
              min={BiEstDetails.refills[0]}
              max={BiEstDetails.refills.at(-1)}
              toolTipValue={`${refills} Refills`}
              sliderValue={refills}
              onChange={(val) => {
                setRefils(Number(val));
              }}
            />
          </div>
        </div>
        <div className="mt-4 grid grid-cols-12">
          <div className="col-span-1 text-left">
            <Typography variant="h5" className="mt-4">
              SIG
            </Typography>
          </div>
          <div className="col-span-8">
            <div className="pr-2">
              <TextInput
                value={sig}
                onChange={setSig}
                fullWidth
                className="my-1 rounded bg-background-light py-2 px-4"
              />
            </div>
          </div>
        </div>
      </div>
    </HormoneWrapper>
  );
}
