import React, { Dispatch, SetStateAction } from 'react';

import { useQuery } from '@tanstack/react-query';

import { DropDown } from 'components/common/DropDown/DropDown';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';
import { HormoneNames } from 'types/prescriptionTypes';

type DiagnosisOptionsProps<DiagnosisType> = {
  diagnosis: DiagnosisType;
  setDiagnosis: Dispatch<SetStateAction<DiagnosisType>>;
  diagnosisHormoneList: { hormone: HormoneNames[]; thyroid: HormoneNames[] };
  error: boolean;
  setDiagnosisError: Dispatch<React.SetStateAction<boolean>>;
  patientDetails: PatientDetailsContextType;
};

export function DiagnosisOptions<DiagnosisType>({
  diagnosisHormoneList,
  error,
  diagnosis,
  setDiagnosis,
  setDiagnosisError,
  patientDetails,
}: DiagnosisOptionsProps<DiagnosisType>) {
  const getHormoneDiagnosisCodesQuery = useQuery(
    QueryKeys.DiagnosisCode.listing({
      gender: patientDetails.gender,
      type: 'hormone',
    }),
    () =>
      PatientService.getDiagnosisCodes({
        gender: patientDetails.gender,
        type: 'hormone',
      })
  );

  const getThyroidDiagnosisCodesQuery = useQuery(
    QueryKeys.DiagnosisCode.listing({
      gender: patientDetails.gender,
      type: 'thyroid',
    }),
    () =>
      PatientService.getDiagnosisCodes({
        gender: patientDetails.gender,
        type: 'thyroid',
      })
  );

  return (
    <div
      id="diagnosisOptions"
      className="flex flex-col rounded bg-background-main p-6 ">
      <Typography variant="h4">Diagnosis Code</Typography>
      {!!diagnosisHormoneList?.hormone[0] && (
        <div className="mt-6 flex flex-col gap-1">
          <Typography variant="subtitle1">
            {`Select the Diagnostic purpose for ${diagnosisHormoneList.hormone.join(
              ', '
            )} `}
          </Typography>
          <DropDown
            fullWidth
            name="hormoneCode"
            placeholder="Select Diagnostic Reason"
            options={getHormoneDiagnosisCodesQuery.data?.data.map((i) => ({
              title: `${i.code} - ${i.description}`,
              value: i.id,
            }))}
            onChange={(v) => {
              setDiagnosisError(false);
              setDiagnosis({ ...diagnosis, hormone: v.value });
            }}
          />
        </div>
      )}
      {!!diagnosisHormoneList.thyroid[0] && (
        <div className="mt-4 flex flex-col gap-1">
          <Typography variant="subtitle1">
            {`Select the Diagnostic purpose for ${diagnosisHormoneList.thyroid.join(
              ', '
            )} `}
          </Typography>
          <DropDown
            fullWidth
            name="thyroidCode"
            className="grow"
            placeholder="Select Diagnostic Reason"
            options={getThyroidDiagnosisCodesQuery.data?.data.map((i) => ({
              title: `${i.code} - ${i.description}`,
              value: i.id,
            }))}
            onChange={(v) => {
              setDiagnosisError(false);
              setDiagnosis({ ...diagnosis, thyroid: v.value });
            }}
          />
        </div>
      )}
      {error && (
        <Typography className="pl-1" color="error" variant="subtitle3">
          Please select diagnosis reasons
        </Typography>
      )}
    </div>
  );
}
