import { useState } from 'react';

export const usePregnylController = () => {
  const [mg, setMg] = useState(0);
  const [sig, setSig] = useState('Inject 0.25 - 0.5 ml as directed');
  const [DTD, setDTD] = useState(30);
  const [refills, setRefils] = useState(0);
  const mgml = 10000;

  return {
    refills,
    DTD,
    DTDInWords: `${DTD} ml`,
    mg,
    setDTD,
    setRefils,
    sig,
    setSig,
    setMg,
    mgml,
  };
};
