import React from 'react';

import { UnderConstruction } from 'components/common/UnderConstruction/UnderConstruction';

export function Dashboard() {
  return (
    <div className="">
      <UnderConstruction />

      {/* <div className="grid grid-cols-12 gap-x-14 mt-4">
        <div className="col-span-6 ">
          <span className="text-base  flex justify-between items-center mb-4">
            <Typography variant="subtitle3">Patients of Concerns</Typography>
          </span>
          <PatientsOfConcerns />
        </div>

        <div className="col-span-6 flex justify-center items-center">
          <Typography variant="subtitle1">
            Select the patient of concern to see their reported symptoms.
          </Typography>
        </div>
      </div>
      <span className="m-10" />
      <div className="grid grid-cols-12 gap-x-14 mt-4">
        <div className="col-span-6 ">
          <span className="text-base  flex justify-between items-center mb-4">
            <Typography variant="subtitle3">Patients of Titration</Typography>
          </span>
          <PatientsOfTitration />
        </div>

        <div className="col-span-6 flex justify-center items-center">
          <Typography variant="subtitle1">
            Select the patient of Titration to see their reported symptoms.
          </Typography>
        </div>
      </div> */}
    </div>
  );
}
