import errorSvg from '../../../assets/images/404.svg';

export function Page404() {
  return (
    <div className="relative flex min-h-screen w-screen flex-col items-center justify-center bg-background-dark">
      <figure className="relative -mb-[50px] -mt-[50px] h-[30rem] w-auto">
        <img
          className="h-full w-full object-contain"
          src={errorSvg}
          alt="Error Svg"
        />
      </figure>
      <p className="mb-2 text-5xl font-semibold text-background-contrastText">
        Oh no!
      </p>
      <p className="text-xl text-background-contrastText">Page not found.</p>
    </div>
  );
}
