import React, { useEffect } from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

// eslint-disable-next-line
import LogoGreen from 'assets/images/LogoGreen(OnLeft).svg';
import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { BasicInformation } from 'components/onboard/BasicInformation';
import { OfficeDetails } from 'components/onboard/OfficeDetails';
import { PasswordDetails } from 'components/onboard/PasswordDetails';
import { PracticeDetails } from 'components/onboard/PracticeDetails';
import { ProviderDetails } from 'components/onboard/ProviderDetails';
import { QueryKeys } from 'constants/query-keys';
import { providerOnboardSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';

type OnboardProviderFormType = {
  firstName: string;
  lastName: string;
  password: string;
  phone: string;

  dea: string;
  npi: string;
  officeManagerName: string;
  officeManagerEmail: string;

  licenseNumber: string;
  specialities: string[];
};

export function ProviderOnboard() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';
  const providerOnboardMutation = useMutationWithToast(
    UserService.onBoardProvider,
    {}
  );
  const getProviderPracticeDetailsQuery = useQuery(
    [QueryKeys.ProviderPracticeDetails],
    () => UserService.getProviderPracticeDetails({ token })
  );
  const navigate = useNavigate();

  const { handleSubmit, control, errors } =
    useFormWithErrors<OnboardProviderFormType>({
      mutation: providerOnboardMutation,
      schema: providerOnboardSchema,
    });

  const onSubmit = handleSubmit((data) => {
    providerOnboardMutation.mutate(
      {
        token: token ?? '',
        providerDetails: {
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          phone: `+${data.phone}`,
          details: {
            dea: data.dea.toString(),
            npi: data.npi.toString(),
            officeManagerName: data.officeManagerName,
            officeManagerEmail: data.officeManagerEmail,
          },
          license_id: data.licenseNumber,
          specialities: data.specialities?.map((s: string) => ({ id: s })),
        },
      },
      {
        onSuccess: () => {
          navigate({ pathname: '/auth/login' });
        },
      }
    );
  });

  useEffect(() => {
    const firstErrorKey = Object.keys(errors).find(
      (key) => errors[key as keyof OnboardProviderFormType]
    );

    if (firstErrorKey) {
      (
        document.querySelector(
          `input[name="${firstErrorKey}"]`
        ) as HTMLInputElement | null
      )?.focus();
    }
  }, [errors]);

  return (
    <div className="bg-background-dark   p-52 pt-12  ">
      <div className="flex justify-center">
        {' '}
        <img
          className="h-14 rounded"
          src={
            getProviderPracticeDetailsQuery.data?.data.inviteToPractice
              .logoUrl || LogoGreen
          }
          alt="logo"
        />
      </div>
      <div className="mt-10 ">
        <Typography variant="h1">
          Register as a{' '}
          <Typography className="mx-2" variant="h1" color="primary">
            {getProviderPracticeDetailsQuery?.data?.data.inviteToPractice
              .name ?? 'Brite'}
          </Typography>{' '}
          provider.
        </Typography>
        <Typography variant="h3" className="mt-2">
          Please provide us with the following information to register yourself.
        </Typography>
      </div>
      <div className="mt-10 grid gap-2">
        <BasicInformation<OnboardProviderFormType> control={control} />
        <PasswordDetails<OnboardProviderFormType> control={control} />
        {/* <PracticeDetails
          control={control}
          errors={errors}
          providerOnboardMutation={providerOnboardMutation}
        /> */}
        {/* <div className="flex justify-between">
          <MailingAddress alwaysActive control={control} />
          <span className="mx-2" />
          <PhysicalAddress alwaysActive control={control} />
        </div> */}
        <PracticeDetails
          practiceAddressDetails={
            getProviderPracticeDetailsQuery?.data?.data.inviteToPracticeAddress
          }
        />
        <OfficeDetails<OnboardProviderFormType> control={control} />
        <ProviderDetails<OnboardProviderFormType> control={control} />
        {/* <div className="grid grid-cols-12 ">
          <div className="col-span-3 flex">
            <SignatureUploadBox />
          </div>
        </div> */}
        <div className="flex justify-end">
          <Button
            loading={providerOnboardMutation.isLoading}
            onClick={onSubmit}>
            Register <ArrowLongRightIcon className="ml-8 h-5 w-5 text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
}
