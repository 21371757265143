import React from 'react';

import { AddressType } from 'types/onboard';

type PracticeDetailsProps = {
  practiceAddressDetails?: AddressType;
};

export function PracticeDetails({
  practiceAddressDetails,
}: PracticeDetailsProps) {
  function getAddress() {
    if (practiceAddressDetails) {
      return `${practiceAddressDetails?.addressLine1}, ${
        practiceAddressDetails?.addressLine2
          ? `${practiceAddressDetails?.addressLine2},`
          : ''
      } ${practiceAddressDetails?.city}, ${
        practiceAddressDetails?.state?.name
      } ${practiceAddressDetails?.zipCode}, ${
        practiceAddressDetails?.country
      }. `;
    }

    return '';
  }

  return (
    <div className="grid grid-cols-12 gap-4 ">
      <div className="col-span-6">
        <div className="mt-3 mb-4  max-h-fit rounded border border-background-light py-3 px-2  text-md font-bold text-zinc-400">
          {getAddress()}
        </div>
      </div>
    </div>
  );
}
