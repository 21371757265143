import { useState } from 'react';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';
import { GetDeaLicenses, SupervisingProvider } from 'types/provider';

import { AddLicenseDeaModal } from '../AddLicenseDea/AddLicenseDeaModal';
import { DeleteConfirmationModal } from './DeleteConfirmationModal/DeleteConfirmationModal';
import { SupervisingProviderListingModal } from './SupervisingProvidersListingModal/SupervisingProviderListingModal';

interface DEALicenseListingBaseProps {
  checkoutPage?: false;
  stateID?: string;
}

interface DEALicenseListingCheckoutProps {
  checkoutPage: true;
  stateID: string;
}
type DEALicenseListingProps =
  | DEALicenseListingBaseProps
  | DEALicenseListingCheckoutProps;

export function DEALicenseListing({
  checkoutPage,
  stateID,
}: DEALicenseListingProps) {
  const getDEALicenseQuery = useQuery([QueryKeys.ProviderDEALicenses], () =>
    UserService.getLicensesAndDEA()
  );
  const deleteDEALicenseMutation = useMutationWithToast(
    UserService.deleteLicensesAndDea
  );

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [supervisingProviderData, setSupervisingProviderData] =
    useState<SupervisingProvider>();
  const [supervisingProviderState, setSupervisingProviderState] = useState('');
  const [
    showSupervisingProviderListingModal,
    setShowSupervisingProviderListingModal,
  ] = useState(false);
  const [showAddDeaLicenseModal, setShowAddDEALicenseModal] = useState(false);
  const [deaLicenseData, setDeaLiceneData] = useState<
    GetDeaLicenses | undefined
  >();

  const handleDeleteRecord = (stateId: string) => {
    setOpenDeleteConfirmationModal(false);
    deleteDEALicenseMutation.mutate(
      { stateId },
      {
        onSuccess: () => {
          getDEALicenseQuery.refetch();
        },
      }
    );
  };

  const getStateRecord = () => {
    const record = getDEALicenseQuery.data?.data.find(
      (item) => item.stateId === stateID
    );

    return record ? [record] : [];
  };

  return (
    <div className={!checkoutPage ? 'w-[60rem]' : 'w-full'}>
      <Table
        tableClassName={classNames(' !overflow-auto block ', {
          'max-h-[30rem]': getDEALicenseQuery.data?.data.length !== 0,
        })}
        recordsPerPage={getDEALicenseQuery?.data?.data.length}
        // tableClassName="!bg-background-light"
        isSticky={!checkoutPage}
        loading={getDEALicenseQuery?.isLoading}
        rowsData={
          stateID
            ? getStateRecord()
            : getDEALicenseQuery?.data?.data
            ? getDEALicenseQuery?.data?.data
            : []
        }
        columns={[
          {
            title: 'State',
            render: (rowData) => rowData.state,
            classNames: 'text-center',
          },
          {
            title: 'License',
            render: (rowData) => rowData.license,
            classNames: 'text-center',
          },
          {
            title: 'License Expiration',
            render: (rowData) => rowData.licenseExpiry || '-',
            classNames: 'text-center',
          },

          {
            title: 'DEA',
            render: (rowData) => rowData.dea || '-',
            classNames: 'text-center',
          },

          {
            title: 'DEA Expiration',
            render: (rowData) => rowData.deaExpiry || '-',
            classNames: 'text-center',
          },
          {
            title: 'Supervising Provider',
            classNames: 'text-center',
            render: (rowData) =>
              rowData.supervisingProvider ? (
                <Typography
                  variant="subtitle3"
                  color="primary"
                  className="cursor-pointer justify-center underline"
                  onClick={() => {
                    setSupervisingProviderState(rowData.state);
                    setSupervisingProviderData(rowData.supervisingProvider);
                    setShowSupervisingProviderListingModal(true);
                  }}>
                  View Details
                </Typography>
              ) : (
                <Typography variant="subtitle3" className="justify-center">
                  Not Required
                </Typography>
              ),
          },
          {
            title: '',
            render: (rowData) => (
              <div className="flex">
                <PencilIcon
                  className=" text-white-400 ml-1 h-4 cursor-pointer"
                  onClick={() => {
                    setDeaLiceneData(rowData);
                    setShowAddDEALicenseModal(true);
                  }}
                />
                <span className="mx-2" />
                {checkoutPage ? null : (
                  <TrashIcon
                    className="text-white-400 ml-1 h-4 cursor-pointer"
                    onClick={() => {
                      setDeaLiceneData(rowData);
                      setOpenDeleteConfirmationModal(true);
                    }}
                  />
                )}
              </div>
            ),
          },
        ]}
        noDataMessage="You currently have no records."
      />

      <SupervisingProviderListingModal
        supervisingProvider={supervisingProviderData}
        supervisingProviderState={supervisingProviderState}
        showSupervisingProviderListingModal={
          showSupervisingProviderListingModal
        }
        setShowSupervisingProviderListingModal={
          setShowSupervisingProviderListingModal
        }
      />

      <AddLicenseDeaModal
        isUpdate
        deaLicenseData={deaLicenseData}
        showAddModal={showAddDeaLicenseModal}
        handleClose={(state: boolean) => {
          setShowAddDEALicenseModal(state);
          setDeaLiceneData(undefined);
        }}
      />

      <DeleteConfirmationModal
        onCancel={() => setOpenDeleteConfirmationModal(false)}
        openModal={openDeleteConfirmationModal}
        onConfirm={() => handleDeleteRecord(deaLicenseData?.stateId || '')}
        state={deaLicenseData?.state || ''}
      />
    </div>
  );
}
