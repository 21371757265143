import React from 'react';

/* eslint-disable consistent-return */
import { useQuery } from '@tanstack/react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';

import { CustomLegend } from '../LegendForCharts/LegendForCharts';

type HormoneDataType = {
  name: string;
  value: number | undefined;
};

enum ChartsHormone {
  'Bi-Est' = 'Bi-Est',
  'Progesterone' = 'Progesterone',
  'Testosterone' = 'Testosterone',
}

export function CurrentHormonalStateChart() {
  const { id } = useParams();
  const patientDetails = useOutletContext<PatientDetailsContextType>();

  const getPatientCurrentHormonalStateQuery = useQuery(
    QueryKeys.PatientCurrentHormonalState.item(id as string),
    () => PatientService.getPatientCurrentHormonalState(id || ''),
    {
      enabled: !!id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const chartsHormoneData: HormoneDataType[] = [
    {
      name: ChartsHormone['Bi-Est'],
      value: undefined,
    },
    {
      name: ChartsHormone.Progesterone,
      value: undefined,
    },
    {
      name: ChartsHormone.Testosterone,
      value: undefined,
    },
  ];

  function getChartsData() {
    const data: HormoneDataType[] = [];
    chartsHormoneData.forEach((item) => {
      switch (item.name) {
        case ChartsHormone['Bi-Est']:
          if (patientDetails.gender === 'M') {
            return;
          }
          data.push({
            name: ChartsHormone['Bi-Est'],
            value: getPatientCurrentHormonalStateQuery.data?.data.estrogenScore,
          });

          return;
        case ChartsHormone.Progesterone:
          if (patientDetails.gender === 'M') {
            return;
          }
          data.push({
            name: ChartsHormone.Progesterone,
            value:
              getPatientCurrentHormonalStateQuery.data?.data.progesteroneScore,
          });

          return;
        case ChartsHormone.Testosterone:
          data.push({
            name: ChartsHormone.Testosterone,
            value:
              getPatientCurrentHormonalStateQuery.data?.data.testosteroneScore,
          });

          return;
        default:
          return {};
      }
    });

    return data;
  }

  return (
    <SkeletonContent
      containerClassName="w-full max-w-[50%]"
      layout={[
        {
          className: 'h-[32rem] w-full rounded bg-background-main py-8 px-4 ',
          children: [
            {
              className: ' h-6 w-40',
            },
          ],
        },
      ]}
      isLoading={
        getPatientCurrentHormonalStateQuery.isLoading &&
        !getPatientCurrentHormonalStateQuery.isError
      }>
      {() => (
        <div className="flex h-[32rem] w-full max-w-[50%] flex-col rounded bg-background-main py-2">
          <Typography className="p-4 pb-12" variant="h4">
            Hormonal Insufficiency and Excess Currently
          </Typography>
          {getPatientCurrentHormonalStateQuery.isError ? (
            <div className="flex  w-full grow items-center justify-center">
              <Typography className="px-12" variant="subtitle1">
                To demonstrate the current hormonal state, a patient must have
                completed the initial questionnaire.
              </Typography>
            </div>
          ) : (
            <ResponsiveContainer width="100%" height="80%">
              <BarChart
                width={500}
                height={300}
                data={getChartsData()}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}>
                <CartesianGrid stroke="#ccc" vertical={false} horizontal />
                <XAxis
                  tickMargin={14}
                  dataKey="name"
                  tick={{
                    fontSize: 12,
                    fill: '#ccc',
                  }}
                />
                <YAxis
                  tick={{ fontSize: 12, fill: '#ccc' }}
                  // axisLine={false}
                  tickLine={false}
                  ticks={[-10, -8, -6, -4, -2, 0, 2, 4, 6, 8, 10]}>
                  <Label
                    value="Excess"
                    offset={48}
                    position="insideTopRight"
                    angle={-90}
                  />{' '}
                  <Label
                    value="Insufficiency"
                    offset={25}
                    position="insideBottomLeft"
                    startOffset={100}
                    angle={-90}
                  />
                </YAxis>
                <Tooltip />
                <Legend
                  content={
                    <CustomLegend
                      customPayload={[
                        { color: '#318474', value: 'Excess' },
                        { color: '#a3ded3', value: 'Insufficiency' },
                      ]}
                      className="pt-8"
                    />
                  }
                />
                <ReferenceLine y={0} strokeWidth={4} stroke="#46bda6" />
                <Bar radius={[10, 10, 0, 0]} barSize={30} dataKey="value">
                  {getChartsData().map((entry, index) => (
                    <Cell
                      fill={Number(entry?.value) > 0 ? '#318474' : '#a3ded3'}
                      strokeWidth={index === 2 ? 4 : 1}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      )}
    </SkeletonContent>
  );
}
