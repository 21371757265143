import { useContext, useMemo, useState } from 'react';

import { EPrescribeUtilContext } from 'components/patient/e-prescribeV2/HormoneListing';
import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';
import { CurrentDoseType } from 'types/patient/prescription';
import { HormoneNames } from 'types/prescriptionTypes';

export const useProgesteroneController = () => {
  const ProgesteroneDetails = femaleHormoneRxTypes?.progesterone;
  const [strength, setStrength] = useState(0);
  const [maximumDose, setMaximumDose] = useState(0);

  const [deliveryMethod, setDeliveryMethod] = useState<string>(
    ProgesteroneDetails.deliveryMethod
  );
  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(30);
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const { sig, setSig } = useSIG('');
  const [note, setNote] = useState('');
  const [currentDose, setCurrentDose] = useState<CurrentDoseType>();
  const { switchHormone } = useContext(EPrescribeUtilContext);
  // The maximum daily dose slider should always start at 1.5ml. If the dose slider is pulled to 1.5 ml or greater, the maximum dose slider should go to 2ml.
  const marginValue = 3;

  const maxDoseOptions = useMemo(
    () => [
      ...ProgesteroneDetails.dose.slice(
        dose > 27 ? 38 : 28,
        ProgesteroneDetails.dose.length
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dose]
  );

  function handleDose(val: number, type?: string) {
    if (
      typeof ProgesteroneDetails.dose[dose + val + marginValue] === 'string' &&
      !!type
    ) {
      setOpenWarningPopup(true);
    }
    if (!type) {
      setDose(val);
      if (typeof ProgesteroneDetails.dose[val + marginValue] === 'string') {
        setOpenWarningPopup(true);
      }
      if (ProgesteroneDetails.dose[val] >= 0.4) {
        setDTD(60);
      } else {
        setDTD(30);
      }
    } else {
      if (
        typeof ProgesteroneDetails.dose[dose + val + marginValue] === 'string'
      ) {
        setOpenWarningPopup(true);
      }
      if (ProgesteroneDetails.dose[dose + val] >= 0.4) {
        setDTD(60);
      } else {
        setDTD(30);
      }
    }
  }

  const handleConfirmation = () => {
    switchHormone?.(HormoneNames.ORAL_PROGESTERONE);
    setOpenWarningPopup(false);
  };

  return {
    ProgesteroneDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,

    dose,
    setDose,
    refills,
    setRefills,
    deliveryMethod,
    setDeliveryMethod,

    handleDose,
    openWarningPopup,
    setOpenWarningPopup,

    handleConfirmation,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
    currentDose,
    setCurrentDose,
    switchHormone,
    marginValue,
    maxDoseOptions,
  };
};
