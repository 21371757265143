import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';

export function useLicenseDeaExpirationDateChecker() {
  const getDEALicenseQuery = useQuery([QueryKeys.ProviderDEALicenses], () =>
    UserService.getLicensesAndDEA()
  );

  const checkIfAllRecordsHasExpirationDate = () => {
    const record = getDEALicenseQuery.data?.data.find(
      (item) => (item.dea && !item.deaExpiry) || !item.licenseExpiry
    );

    return !record;
  };

  const checkIfSelectedStateHasDEALicenseExpiry = (stateID: string) => {
    const record = getDEALicenseQuery.data?.data.find(
      (item) =>
        item.stateId === stateID &&
        ((item.dea && !item.deaExpiry) || !item.licenseExpiry)
    );

    return !record;
  };

  return {
    checkIfAllRecordsHasExpirationDate,
    checkIfSelectedStateHasDEALicenseExpiry,
  };
}
