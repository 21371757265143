import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import toast from 'react-hot-toast';
import { useOutletContext, useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Chip } from 'components/common/Chip/Chip';
import { InfoTooltip } from 'components/common/InfoTooltip/InfoTooltip';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { DoseSplitter } from 'components/patient/e-prescribeV2/DoseSplitter';
import { ChangeDoseConfirmationModal } from 'components/patient/Order/ChangeDoseConfirmationModal';
import { PrescriptionDosageHistoryModal } from 'components/patient/PrescriptionDosageHistoryModal/PrescriptionDosageHistoryModal';
import { PrescriptionSlider } from 'components/prescriptions/PrescriptionSlider';
import { TitrationDetails } from 'components/prescriptions/tiration-details/TitrationDetails';
import { DeliveryMethodsEnum } from 'constants/delivery-methods';
import {
  femaleHormoneRxTypes,
  maleHormoneRxTypes,
} from 'constants/hormone-details-new';
import { QueryKeys } from 'constants/query-keys';
import { useDebounce } from 'hooks/useDebounce';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { GMTDate } from 'lib/general/GMT-date';
import { PatientPrescriptionService } from 'services/prescription';
import { PatientDetailsContextType } from 'types/patient';
import {
  CurrentDoseType,
  PatientPrescription,
  PrescriptionCheckType,
} from 'types/patient/prescription';
import { HormoneNames } from 'types/prescriptionTypes';

import HormoneImage from '../../../../../assets/images/britebox.png';
import CapsuleSvg from '../../../../../assets/images/capsule.svg';
import InjectionSvg from '../../../../../assets/images/injection.svg';

type PrescriptionDetailsPageProps = {
  prescription: PatientPrescription;
};

export function PrescriptionDetailsPage({
  prescription,
}: PrescriptionDetailsPageProps) {
  const queryClient = useQueryClient();
  // const navigate = useNavigate();
  const patientDetails = useOutletContext<PatientDetailsContextType>();
  const { id: patientId } = useParams();
  const [currentDose, setcurrentDose] = useState<number | undefined>(0);
  const debouncedValue = useDebounce<number | undefined>(currentDose, 1000);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [showDosageHistory, setShowDosageHistory] = useState(false);
  const [currentPrescriptionCheckValue, setCurrentPrescriptionCheckValue] =
    useState<PrescriptionCheckType>();
  const [showDoseChangeConfModal, setshowDoseChangeConfModal] = useState(false);
  const [splittedDose, setSplittedDose] = useState<{
    morning: string;
    evening: string;
  }>();

  const getPrescriptionCheckMutation = useMutationWithToast(
    (dose: string) =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: {
              ...prescription.deliveryMethodAndSig.minDailyDose,
              value: dose,
            },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      retry: false,

      onSuccess(data) {
        setCurrentPrescriptionCheckValue(() => data.data);
      },
    }
  );
  const updateDoseMutation = useMutation(
    (dose: CurrentDoseType) =>
      PatientPrescriptionService.updateDose({
        dose,
        prescriptionId: prescription.id,
        patientId: patientId ?? '',
      }),
    {
      onSuccess() {
        queryClient.refetchQueries(
          QueryKeys.PatientPrescriptions.listing({
            category: 'current',
            id: patientDetails?.id ?? '',
          })
        );
        queryClient.refetchQueries(
          QueryKeys.PrescriptionDosageHistory.listing({
            patientId: patientId ?? '',
            prescriptionId: prescription.id,
          })
        );
        toast.success('Dose updated successfully');
      },
    }
  );

  const checkPrescriptionValueCurrentDoseQuery = useQuery(
    [prescription.id, prescription.deliveryMethodAndSig.dose.value, 'current'],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: {
              ...prescription.deliveryMethodAndSig.minDailyDose,
              value: prescription.deliveryMethodAndSig.currentDose
                ? (
                    Number(
                      prescription.deliveryMethodAndSig.currentDose.morning
                        .value
                    ) +
                    Number(
                      prescription.deliveryMethodAndSig.currentDose.evening
                        .value
                    )
                  ).toFixed(2)
                : prescription.deliveryMethodAndSig.minDailyDose.value,
            },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      enabled:
        !!patientId ||
        !!prescription.id ||
        !!prescription.deliveryMethodAndSig?.currentDose,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const checkPrescriptionMaxDoseValueQuery = useQuery(
    [prescription.id, prescription.deliveryMethodAndSig.dose.value, 'max'],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      enabled:
        !!patientId ||
        !!prescription.id ||
        !!prescription.deliveryMethodAndSig.dose.value,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const checkPrescriptionMinDoseValueQuery = useQuery(
    [
      prescription.id,
      prescription.deliveryMethodAndSig?.minDailyDose?.value,
      'min',
    ],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: { ...prescription.deliveryMethodAndSig.minDailyDose },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      enabled:
        !!patientId ||
        !!prescription.id ||
        !!prescription.deliveryMethodAndSig.minDailyDose.value,
      retry: false,
      refetchOnMount: false,
      // onSuccess: (data) => {
      //   if (!prescription.deliveryMethodAndSig.currentDose) {
      //     setCurrentPrescriptionCheckValue(data.data);
      //   }
      // },
    }
  );

  const getPrescriptionDosageHistoryQuery = useQuery(
    QueryKeys.PrescriptionDosageHistory.listing({
      patientId,
      prescriptionId: prescription.id,
    }),
    () =>
      PatientPrescriptionService.getDoseHistory({
        patientId: patientId ?? '',
        prescriptionId: prescription.id,
      }),
    {
      enabled: !!patientId && !!prescription.id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess(data) {
        if (data.data.length > 0) {
          if (!isSameDay(new GMTDate(data?.data[0].updatedAt), new Date())) {
            getPrescriptionCheckHistoryValueMutation.mutateAsync({
              dose: (
                data.data[0].dose.morning.value +
                data.data[0].dose.evening.value
              ).toFixed(2),
              id: data.data[0].id,
            });

            return;
          }

          getPrescriptionCheckHistoryValueMutation.mutateAsync({
            dose: (
              data.data[1].dose.morning.value + data.data[1].dose.evening.value
            ).toFixed(2),
            id: data.data[0].id,
          });
        }
      },
    }
  );

  const getPrescriptionCheckHistoryValueMutation = useMutation(
    ({ dose, id }: { dose: string; id: string }) => {
      // eslint-disable-next-line no-console
      console.log(id);

      return PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: {
              ...prescription.deliveryMethodAndSig.dose,
              value: dose,
            },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      });
    }
  );

  const getDoseRange = useCallback(() => {
    if (patientDetails.gender === 'F') {
      const hormone: {
        dose: string[];
        doseUnit: string;
      } = {
        ...femaleHormoneRxTypes?.[
          prescription.prescriptionToMedication.name
            .replaceAll(' ', '-')
            .toLocaleLowerCase()
        ],
      };

      if (
        HormoneNames.TESTO_PER_DHEA.toLowerCase() ===
          prescription.prescriptionToMedication.name.toLocaleLowerCase() &&
        !hormone.dose.includes('0.1')
      ) {
        hormone.dose = [
          '0.1',
          '0.15',
          ...hormone.dose.map((d) => d.toString()),
        ];
      } else if (hormone?.dose) {
        hormone.dose = hormone?.dose
          ? [...hormone.dose.map((d) => d.toString())]
          : [];
      }

      const doseRange = hormone?.dose.slice(
        hormone?.dose.indexOf(
          prescription.deliveryMethodAndSig?.minDailyDose?.value.toString()
        ),
        Number(
          hormone?.dose.indexOf(
            prescription.deliveryMethodAndSig?.dose?.value.toString() ?? 0
          )
        ) + 1
      );

      return doseRange;
    }
    if (patientDetails.gender === 'M') {
      const hormone: {
        dose: string[];
        doseUnit: string;
      } =
        maleHormoneRxTypes?.[
          prescription.prescriptionToMedication.name.toLocaleLowerCase()
        ];
      const doseRange = hormone?.dose.slice(
        hormone?.dose.indexOf(
          prescription.deliveryMethodAndSig?.minDailyDose?.value ?? 0
        ),
        Number(
          hormone?.dose.indexOf(
            prescription.deliveryMethodAndSig?.dose?.value ?? 0
          )
        ) + 1
      );

      return doseRange;
    }

    return [];
  }, [
    patientDetails.gender,
    prescription.deliveryMethodAndSig?.dose?.value,
    prescription.deliveryMethodAndSig?.minDailyDose?.value,
    prescription.prescriptionToMedication.name,
  ]);

  function splitDoseForBiest(dose: number) {
    if (dose % 2 === 0) {
      // If the dose is even, split it evenly
      return {
        morning: dose / 2,
        evening: dose / 2,
      };
    }
    // If the dose is odd, the larger portion goes to the evening
    const morning = Math.floor(dose * 10) / 10 / 2;
    const evening = dose - morning;

    return {
      morning,
      evening,
    };
  }

  function splitDose(type: string, doseInString: string) {
    const dose = Number(doseInString);
    const dosage = splitDoseForBiest(dose);
    switch (prescription?.prescriptionToMedication.name) {
      case HormoneNames.BI_EST:
        return type === 'Morning'
          ? Number(dosage.morning).toFixed(2)
          : Number(dosage.evening).toFixed(2);

      case HormoneNames.PROGESTERONE:
        return type === 'Morning' ? 0 : dose.toFixed(2);

      case HormoneNames.TESTOSTERONE_WOMEN:
      case HormoneNames.TESTO_PER_DHEA:
      case HormoneNames.DHEA:
        return type === 'Morning' ? dose : 0;

      default:
        return (dose / 2).toFixed(1);
    }
  }

  const calculatePercentageDifference = useCallback(
    (originalValue = '', currentValue = '') => {
      let difference = 0;
      let percentageDifference = 0;
      const isTestoDhea = !!(
        HormoneNames.TESTO_PER_DHEA.toLowerCase() ===
        prescription.prescriptionToMedication.name.toLocaleLowerCase()
      );
      if (isTestoDhea) {
        difference =
          Number(`${currentValue}`.split('/')?.[1]) -
          Number(`${originalValue}`.split('/')?.[1]);
        percentageDifference =
          (difference / Number(`${originalValue}`.split('/')?.[1])) * 100;
      } else {
        difference = Number(currentValue) - Number(originalValue);
        percentageDifference = (difference / Number(originalValue)) * 100;
      }

      if (Number.isNaN(percentageDifference)) {
        return {
          UI: <div />,
          difference: 0,
          currentValue,
          originalValue,
        };
      }

      if (
        percentageDifference === 0 ||
        (Number(originalValue) === 0 && Number(currentValue) === 0)
      ) {
        return {
          UI: (
            <div className="mt-1 flex justify-center gap-1 text-base text-background-contrastText">
              No Change
            </div>
          ),
          difference: 0,
          currentValue,
          originalValue,
        };
      }

      if (percentageDifference < 0) {
        return {
          UI: (
            <div className="mt-1 flex justify-center gap-1 text-base text-background-contrastText">
              <span className=" flex items-center pr-1 text-error-main">
                <ArrowDownIcon className="h-4 w-4 font-extrabold " />{' '}
                {percentageDifference.toFixed(0).replace('-', '')}%
              </span>
              decrease from {originalValue}{' '}
              {checkPrescriptionMaxDoseValueQuery.data?.data.unit}
            </div>
          ),
          difference: percentageDifference,
          currentValue,
          originalValue,
        };
      }

      return {
        UI: (
          <div className="mt-1 flex justify-center gap-1 text-base text-background-contrastText">
            <span className=" flex items-center pr-1 text-primary-main">
              <ArrowUpIcon className="h-4 w-4 font-extrabold " />{' '}
              {percentageDifference.toFixed(0)}%
            </span>
            increase from {originalValue}{' '}
            {checkPrescriptionMaxDoseValueQuery.data?.data.unit}
          </div>
        ),
        difference: percentageDifference,
        currentValue,
        originalValue,
      };
    },
    [
      checkPrescriptionMaxDoseValueQuery.data?.data.unit,
      prescription.prescriptionToMedication.name,
    ]
  );

  const getPercentageDifference = useMemo(() => {
    if (!getPrescriptionDosageHistoryQuery?.data?.data.length) {
      return calculatePercentageDifference(
        checkPrescriptionMinDoseValueQuery.data?.data.value ?? '',

        currentPrescriptionCheckValue?.value ??
          checkPrescriptionValueCurrentDoseQuery.data?.data.value
      );
    }
    // compare current and min dose
    if (
      isSameDay(
        new GMTDate(getPrescriptionDosageHistoryQuery?.data?.data[0].updatedAt),
        new Date()
      )
    ) {
      return calculatePercentageDifference(
        checkPrescriptionMinDoseValueQuery.data?.data.value ?? '',

        currentPrescriptionCheckValue?.value ??
          checkPrescriptionValueCurrentDoseQuery.data?.data.value
      );
    }

    // compare last saved dose and current dose

    return calculatePercentageDifference(
      getPrescriptionCheckHistoryValueMutation.data?.data.value ?? '',

      currentPrescriptionCheckValue?.value ??
        checkPrescriptionValueCurrentDoseQuery.data?.data.value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getPrescriptionDosageHistoryQuery?.data?.data,
    checkPrescriptionValueCurrentDoseQuery.data?.data.value,
    getPrescriptionCheckHistoryValueMutation.data?.data,
    currentPrescriptionCheckValue?.value,
    checkPrescriptionMinDoseValueQuery.data?.data?.value,
  ]);

  const handleUpdateDose = (d: number) => {
    setcurrentDose(d);
    getPrescriptionCheckMutation.mutate(getDoseRange()[d]);
    updateDoseMutation.mutate({
      morning: {
        value:
          splittedDose?.morning ??
          splitDose('Morning', getDoseRange()[d] ?? 0).toString(),
        unit: prescription.deliveryMethodAndSig.dose.unit,
      },
      evening: {
        value:
          splittedDose?.evening ??
          splitDose('Evening', getDoseRange()[d] ?? 0).toString(),
        unit: prescription.deliveryMethodAndSig.dose.unit,
      },
    });
    setshowDoseChangeConfModal(false);
  };

  // function getPatientDoseFormattedLable() {
  //   const doseUnit =
  //     checkPrescriptionMaxDoseValueQuery.data?.data.unit.split('/');

  //   return `(${doseUnit?.[0]})/${doseUnit?.[1]}` ?? '';
  // }

  function getPatientDoseFormattedValue(
    doseValue?: string,
    prescriptionCheckValue?: string
  ) {
    return doseValue ? (
      <div className="flex flex-col">
        <span>
          {doseValue} {prescription.deliveryMethodAndSig.dose.unit}
        </span>{' '}
        {isTopicalHormone && (
          <span>
            {prescriptionCheckValue}{' '}
            {checkPrescriptionMaxDoseValueQuery.data?.data.unit}
          </span>
        )}
      </div>
    ) : (
      '-'
    );
  }

  function showAdjustDoseUI() {
    return prescription.category === 'current';
  }

  function showSlider() {
    if (!prescription) return false;

    const { deliveryMethodAndSig, titrationType, category } = prescription;

    const hasMinDailyDose = !!deliveryMethodAndSig?.minDailyDose?.value;
    const doseDiffersFromMin =
      deliveryMethodAndSig?.dose?.value !==
      deliveryMethodAndSig?.minDailyDose?.value;
    const isCurrentCategory = category === 'current';
    const isNotTitration = !titrationType;

    // Show slider if all conditions are met
    return (
      hasMinDailyDose &&
      doseDiffersFromMin &&
      isNotTitration &&
      isCurrentCategory &&
      isTopicalHormone
    );
  }

  function getHormoneImage(deliveryMethod: DeliveryMethodsEnum) {
    switch (deliveryMethod.toLowerCase()) {
      case DeliveryMethodsEnum.OralSyringe.toLowerCase():
        return <img className="h-80" src={HormoneImage} alt="hormone bottle" />;

      case DeliveryMethodsEnum.Capsules.toLowerCase():
      case DeliveryMethodsEnum.Tablets.toLowerCase():
        return (
          <img
            style={{ background: '#FFFFFF' }}
            className="h-80 !w-96 p-20"
            src={CapsuleSvg}
            alt="capsule/tablet"
          />
        );

      case DeliveryMethodsEnum.Vial.toLowerCase():
      case DeliveryMethodsEnum.Syringe.toLowerCase():
        return (
          <img
            style={{ background: '#FFFFFF' }}
            className="h-80 !w-96 p-20"
            src={InjectionSvg}
            alt="capsule/tablet"
          />
        );

      default:
        return '';
    }
  }

  const isTopicalHormone = useMemo(
    () =>
      !!prescription.standardRepresentation.includes('Topical') ||
      !!prescription.deliveryMethodAndSig.sig.includes('topically'),
    [prescription.standardRepresentation, prescription.deliveryMethodAndSig.sig]
  );

  const setSliderWithCurrentActiveDose = useCallback(() => {
    const currentDoseIndex = getDoseRange()?.findIndex(
      (n) =>
        Number(n) ===
        Number(
          (
            Number(
              prescription.deliveryMethodAndSig.currentDose?.morning?.value
            ) +
            Number(
              prescription.deliveryMethodAndSig.currentDose?.evening?.value
            )
          ).toFixed(2)
        )
    );

    setcurrentDose(currentDoseIndex >= 0 ? currentDoseIndex : 0);

    return currentDoseIndex >= 0 ? currentDoseIndex : 0;
  }, [getDoseRange, prescription.deliveryMethodAndSig.currentDose]);

  useEffect(() => {
    if (prescription.deliveryMethodAndSig.currentDose) {
      setSliderWithCurrentActiveDose();
    }
  }, [
    prescription.deliveryMethodAndSig.currentDose,
    checkPrescriptionMaxDoseValueQuery.isLoading,
    getDoseRange,
    setSliderWithCurrentActiveDose,
  ]);

  useEffect(() => {
    if (allowUpdate) {
      getPrescriptionCheckMutation.mutate(getDoseRange()[Number(currentDose)]);
      setshowDoseChangeConfModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <SkeletonContent
      isLoading={checkPrescriptionMaxDoseValueQuery.isLoading}
      layout={[
        {
          className: 'grid gap-4 ',
          children: [
            {
              className:
                'grid grid-cols-[12] items-center gap-x-10 gap-4 rounded-md bg-background-main p-6',
              children: [
                {
                  className: 'flex flex-col gap-6',
                  children: [
                    { className: 'w-32 h-6 mt-2' },
                    {
                      className: ' flex  gap-20',
                      children: [
                        { className: 'w-96 !h-88 ' },
                        {
                          className: 'flex flex-col gap-8',
                          children: [
                            { className: 'w-96 h-6 mt-6' },

                            {
                              className: ' flex gap-6 items-center  ',
                              children: [
                                {
                                  className: 'grid gap-y-8',
                                  children: new Array(3).fill(0).map(() => ({
                                    className: 'h-4 w-[250px] ',
                                  })),
                                },
                                { className: 'w-1 h-32 ' },
                                {
                                  className: 'grid gap-y-8',
                                  children: new Array(3).fill(0).map(() => ({
                                    className: 'h-4 w-[250px] ',
                                  })),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },

                {
                  className: 'mt-4 grid row-start-2 gap-6',
                  children: [
                    {
                      className: 'flex-col gap-4',
                      children: [{ className: 'h-1 w-full' }],
                    },
                    {
                      className: 'flex justify-between',
                      children: new Array(6).fill(0).map(() => ({
                        className: 'flex flex-col gap-2 ',
                        children: [
                          { className: 'h-4 w-36' },
                          { className: 'h-4 w-36' },
                        ],
                      })),
                    },
                    {
                      className: 'flex-col gap-4',
                      children: [{ className: 'h-1 w-full' }],
                    },
                  ],
                },

                {
                  className: 'mt-4 grid  row-start-4 gap-4 col-span-1 w-full',
                  children: new Array(1)
                    .fill(0)
                    .map(() => ({ className: 'h-96 w-full ' })),
                },
              ],
            },
          ],
        },
      ]}>
      {() => (
        <div className="flex !w-full ">
          <div className="flex !w-full   flex-col gap-4 rounded bg-background-main p-8">
            <div className="mt-2">
              <Typography variant="h4">
                Prescription Id :{' '}
                <span className="px-1 text-primary-main">
                  {prescription?.id}
                </span>
                {prescription?.titrationType ? (
                  <Chip className="mx-1" label={prescription?.titrationType} />
                ) : (
                  ''
                )}
              </Typography>
            </div>
            <div className={classNames('mt-2 flex  gap-20')}>
              {' '}
              <div className="w-96">
                {getHormoneImage(
                  prescription.deliveryMethodAndSig
                    .deliveryMethod as DeliveryMethodsEnum
                )}
              </div>
              <div className=" flex flex-col gap-4">
                <Typography color="textPrimary" className="" variant="h4">
                  {prescription?.standardRepresentation}{' '}
                </Typography>

                <div>
                  <Typography
                    variant="subtitle2"
                    color="textMuted"
                    className="wrap  !flex !items-start">
                    SIG{' '}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    className="wrap  !flex !items-start">
                    {prescription?.deliveryMethodAndSig.sig}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="subtitle2"
                    color="textMuted"
                    className="wrap  !flex !items-start">
                    DTD
                    <InfoTooltip
                      id="dtd"
                      label="DTD is the dispense quantity"
                    />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    className="wrap  !flex !items-start">
                    {prescription?.dtd.value
                      ? `${prescription?.dtd.value} ${prescription?.dtd.unit}`
                      : 'NA'}{' '}
                    {}
                  </Typography>
                </div>
                <div className="flex gap-16">
                  <div className="flex flex-col gap-6">
                    {' '}
                    <div className="min-h-[2rem]">
                      <Typography
                        variant="subtitle2"
                        color="textMuted"
                        className="wrap  !flex !items-start">
                        Days of Supply (Most)
                        <InfoTooltip
                          id="MaxDaysOfSupply"
                          label="This is the maximum number of days this prescription should last. It is derived by dividing DTD by minimum dose."
                        />
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className="wrap  !flex !items-start">
                        {`${checkPrescriptionMinDoseValueQuery.data?.data.daysOfSupply} Days` ??
                          '-'}
                      </Typography>
                    </div>
                    <div className="min-h-[2rem]">
                      <Typography
                        variant="subtitle2"
                        color="textMuted"
                        className="wrap  !flex !items-start">
                        Min Dose
                        <InfoTooltip
                          id="MinimumDosage"
                          label="The minimum dose prescribed by the provider while creating this prescription."
                        />
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className="wrap  !flex !items-start">
                        {getPatientDoseFormattedValue(
                          prescription.deliveryMethodAndSig?.minDailyDose
                            ?.value ??
                            prescription.deliveryMethodAndSig?.dose?.value,
                          checkPrescriptionMinDoseValueQuery.data?.data.value
                        )}
                      </Typography>
                    </div>
                    <div className="min-h-[2rem]">
                      <Typography
                        variant="subtitle2"
                        color="textMuted"
                        className="wrap  !flex !items-start">
                        Prescribed Refills{' '}
                        <InfoTooltip
                          id="PrescribedRefills"
                          label="The number of refills prescribed while creating this prescription."
                        />
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className="wrap  !flex !items-start">
                        {prescription.refills}
                      </Typography>
                    </div>
                  </div>
                  <span className=" h-full w-[2px] bg-background-contrastText " />
                  <div className="flex flex-col gap-6">
                    {' '}
                    <div className="min-h-[2rem]">
                      <Typography
                        variant="subtitle2"
                        color="textMuted"
                        className="wrap  !flex !items-start">
                        Days of Supply (Least)
                        <InfoTooltip
                          id="MinDaysOfSupply"
                          label="This is the min number of days this prescription should last. It is derived by dividing DTD by maximum dose."
                        />
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className="wrap  !flex !items-start">
                        {`${checkPrescriptionMaxDoseValueQuery.data?.data.daysOfSupply} Days` ??
                          '-'}
                      </Typography>
                    </div>
                    <div className="min-h-[2rem]">
                      <Typography
                        variant="subtitle2"
                        color="textMuted"
                        className="wrap  !flex !items-start">
                        Max dose
                        <InfoTooltip
                          id="MaximumDosage"
                          label="The maximum dose prescribed by the provider while creating this prescription."
                        />
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className="wrap  !flex !items-start">
                        {getPatientDoseFormattedValue(
                          prescription.deliveryMethodAndSig?.dose?.value,
                          checkPrescriptionMaxDoseValueQuery.data?.data.value
                        )}
                      </Typography>
                    </div>
                    <div className="min-h-[2rem]">
                      <Typography
                        variant="subtitle2"
                        color="textMuted"
                        className="wrap  !flex !items-start">
                        Consumed Refills{' '}
                        <InfoTooltip
                          id="ConsumedRefills"
                          label="The number of refills the patient has already ordered and/or consumed."
                        />
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className="wrap  !flex !items-start">
                        {prescription.consumedRefills}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-between gap-4 border-y p-4 ">
              <div>
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Application Start Date{' '}
                  <InfoTooltip
                    id="ApplicationStartDate"
                    label="The date when the patient started applying the prescription."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription?.startDate
                    ? new GMTDate(
                        prescription?.startDate
                      ).getReadableDateInGMT()
                    : '-'}
                </Typography>
              </div>{' '}
              <div>
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Application End Date{' '}
                  <InfoTooltip
                    id="ApplicationEndDate"
                    label="The date when the prescription was moved to Previous Rx(s) from Active Rx(s)."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription?.endDate
                    ? new GMTDate(prescription?.endDate).getReadableDateInGMT()
                    : '-'}
                </Typography>
              </div>{' '}
              <div>
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Prescribed Date{' '}
                  <InfoTooltip
                    id="PrescribedDate"
                    label="The date when this prescription was issued by the provider."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription?.createdAt
                    ? new GMTDate(
                        prescription?.createdAt
                      ).getReadableDateInGMT()
                    : '-'}
                </Typography>
              </div>{' '}
              <div>
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Prescription Expiration Date{' '}
                  <InfoTooltip
                    id="PrescriptionExpirationDate"
                    label="The controlled substance prescriptions will expire in 120 days and the non controlled prescription will expire in 365 days from the prescribed date. Once the prescription has expired the patient won't be able to order remaining refills."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription?.expirationDate
                    ? new GMTDate(
                        prescription.expirationDate
                      ).getReadableDateInGMT()
                    : '-'}
                </Typography>
              </div>{' '}
              <div>
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Last Delivered Date{' '}
                  <InfoTooltip
                    id="LastDeliveredDate"
                    label="The date when the prescription or refill was last delivered to the patient."
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription?.lastDeliveredDate
                    ? new GMTDate(
                        prescription?.lastDeliveredDate
                      ).getReadableDateInGMT()
                    : '-'}
                </Typography>
              </div>{' '}
              <div>
                <Typography
                  variant="subtitle2"
                  color="textMuted"
                  className="wrap  !flex !items-start">
                  Next Order Date{' '}
                  <InfoTooltip
                    id="NextOrderDate"
                    label="The date from which the patient will be able to order a refill for this prescription. It is derived as -
Date = [ last delivered date + (days of supply - 7 days) ]"
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className="wrap  !flex !items-start">
                  {prescription?.nextOrderDate
                    ? new GMTDate(
                        prescription.nextOrderDate
                      ).getReadableDateInGMT()
                    : '-'}
                </Typography>
              </div>
            </div>
            <div
              className={classNames(' non hidden w-full justify-end', {
                '!flex': prescription.category !== 'current',
              })}>
              <Button
                onClick={() => setShowDosageHistory(true)}
                className={classNames(
                  ' !text-background-contrastText underline'
                )}
                variant="text">
                Dosage History
              </Button>
            </div>
            {}
            {showAdjustDoseUI() && (
              <>
                {' '}
                <div className="mt-6 flex flex-col gap-4">
                  <div className="flex flex-col gap-4">
                    <Typography variant="h4" color="textPrimary">
                      Current Dose
                      <InfoTooltip
                        fullWidth
                        className=""
                        label={
                          <span className="flex flex-col">
                            Providers can adjust dosages of currently active
                            prescriptions only. The dose adjustment feature
                            won&apos;t work -
                            <span>
                              If the prescription was prescribed in titration.
                            </span>{' '}
                            <span>
                              If the minimum dose and maximum dose of the
                              prescription is exactly the same.
                            </span>{' '}
                            <span>
                              {' '}
                              If the prescription is not a topical prescription.
                            </span>
                          </span>
                        }
                        id="AdjustDosages"
                      />
                    </Typography>

                    {prescription?.titrationType && (
                      <TitrationDetails
                        loading={checkPrescriptionMaxDoseValueQuery.isLoading}
                        titrationData={[
                          {
                            name: prescription.prescriptionToMedication.name,
                            medicationId:
                              prescription.prescriptionToMedication.id,
                            titration: prescription.titrations.map((d) => ({
                              day: d?.day,
                              morning: d?.morningDose?.value,
                              evening: d?.eveningDose?.value,
                            })),
                          },
                        ]}
                      />
                    )}

                    <div
                      className={classNames('flex flex-col gap-1', {
                        '!hidden': !!prescription?.titrationType,
                      })}>
                      {(prescription.deliveryMethodAndSig.currentDose ||
                        prescription.deliveryMethodAndSig?.minDailyDose
                          ?.value ||
                        prescription.deliveryMethodAndSig.dose?.value) && (
                        // eslint-disable-next-line react/jsx-indent
                        <Typography variant="subtitle1" color="textMuted">
                          {`This patient is currently taking ${
                            getDoseRange()?.[currentDose as number] ?? ''
                          } ${prescription.deliveryMethodAndSig.dose.unit}.`}
                        </Typography>
                      )}
                      {showSlider() && (
                        <Typography variant="subtitle1" color="textMuted">
                          {`When you created this prescription the min and max dose was
                    set to ${prescription.deliveryMethodAndSig?.minDailyDose?.value}${prescription.deliveryMethodAndSig.dose.unit} & ${prescription.deliveryMethodAndSig?.dose.value}${prescription.deliveryMethodAndSig.dose.unit} respectively. Now you can adjust the
                    prescription between the same range.`}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames('mt-2 flex items-center', {
                      '!hidden': !!prescription?.titrationType,
                    })}>
                    <div
                      className={classNames('  grid grow grid-cols-10 px-2')}>
                      {showSlider() ? (
                        <PrescriptionSlider
                          label=""
                          value={currentDose as number}
                          onChange={(v) => {
                            setAllowUpdate(true);
                            setcurrentDose(v);
                          }}
                          getTooltip={(value) =>
                            `${getDoseRange()[value]} ${
                              prescription.deliveryMethodAndSig.dose.unit
                            }`
                          }
                          options={getDoseRange()}
                        />
                      ) : (
                        <div
                          className={classNames('visible flex gap-20', {
                            '!hidden':
                              !isTopicalHormone && !prescription?.titrationType,
                          })}>
                          <div>
                            <Typography
                              className=" whitespace-nowrap"
                              variant="h5"
                              align="left">
                              Morning Dose
                            </Typography>
                            <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                              {splitDose(
                                'Morning',
                                getDoseRange()?.[currentDose ?? 0]
                              )}
                            </div>
                          </div>{' '}
                          <div>
                            <Typography
                              className=" whitespace-nowrap"
                              variant="h5"
                              align="left">
                              Evening Dose
                            </Typography>{' '}
                            <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                              {splitDose(
                                'Evening',
                                getDoseRange()?.[currentDose ?? 0]
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className={classNames('w-60', {
                        hidden:
                          !isTopicalHormone && !prescription?.titrationType,
                      })}>
                      <Typography
                        className=" whitespace-nowrap"
                        variant="h5"
                        align="left">
                        Daily{' '}
                        {checkPrescriptionMaxDoseValueQuery?.data?.data.unit}
                      </Typography>
                      {getPrescriptionCheckMutation.isLoading ? (
                        <div className=" mt-1 h-[2rem] min-w-[10rem] animate-pulse rounded bg-background-light" />
                      ) : (
                        <div className="mt-1 min-w-[10rem] rounded bg-[#00D9AF] !py-2 !px-4 text-center  text-sm font-semibold text-background-light">
                          {currentPrescriptionCheckValue?.value
                            ? `${currentPrescriptionCheckValue?.value} ${currentPrescriptionCheckValue?.unit}`
                            : `${checkPrescriptionValueCurrentDoseQuery.data?.data.value} ${checkPrescriptionValueCurrentDoseQuery.data?.data.unit}`}
                        </div>
                      )}
                      <div className="mt-1 flex min-h-[1.5rem] justify-center whitespace-nowrap">
                        {checkPrescriptionValueCurrentDoseQuery.isLoading ||
                        getPrescriptionCheckHistoryValueMutation.isLoading ||
                        getPrescriptionCheckMutation.isLoading ? (
                          <div className=" mt-2 h-[1rem] w-[80%] animate-pulse rounded bg-background-light" />
                        ) : (
                          getPercentageDifference.UI
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    'mt-4 flex items-center justify-between ',
                    {
                      '!hidden': !!prescription?.titrationType,
                    }
                  )}>
                  <div
                    className={classNames('flex hidden gap-20', {
                      '!flex': showSlider(),
                    })}>
                    {/* <div>
                      <Typography
                        className=" whitespace-nowrap"
                        variant="h5"
                        align="left">
                        Morning Dose
                      </Typography>
                      <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                        {splitDose('Morning', getDoseRange()[currentDose ?? 0])}
                      </div>
                    </div>{' '}
                    <div>
                      <Typography
                        className=" whitespace-nowrap"
                        variant="h5"
                        align="left">
                        Evening Dose
                      </Typography>{' '}
                      <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                        {splitDose('Evening', getDoseRange()[currentDose ?? 0])}
                      </div>
                    </div> */}
                    <DoseSplitter
                      value={getDoseRange()?.[currentDose as number]}
                      onChange={(d) => {
                        setSplittedDose(d);
                      }}
                      hormoneName={prescription?.prescriptionToMedication.name}
                    />
                  </div>
                  <Button
                    onClick={() => setShowDosageHistory(true)}
                    className={classNames(
                      'visible !text-background-contrastText underline',
                      {
                        '!invisible':
                          prescription.deliveryMethodAndSig.dose.value ===
                            prescription.deliveryMethodAndSig?.minDailyDose
                              ?.value || !isTopicalHormone,
                      }
                    )}
                    variant="text">
                    Dosage History
                  </Button>
                </div>
              </>
            )}
          </div>

          <PrescriptionDosageHistoryModal
            title={`${prescription.prescriptionToMedication.name} Dosage Adjustment History`}
            onClose={() => {
              setShowDosageHistory(false);
            }}
            prescriptionId={prescription.id}
            isOpen={showDosageHistory}
            prescription={{
              ...prescription,
              currentDoseIndex: currentDose ?? 0,
            }}
          />
          <ChangeDoseConfirmationModal
            prescriptionName={prescription.standardRepresentation}
            open={showDoseChangeConfModal}
            prescriptionDifference={getPercentageDifference}
            unit={checkPrescriptionMaxDoseValueQuery.data?.data.unit ?? ''}
            loading={
              (getPrescriptionCheckMutation.isLoading ||
                getPrescriptionCheckHistoryValueMutation.isLoading) &&
              allowUpdate
            }
            onCancel={() => {
              setAllowUpdate(false);
              getPrescriptionCheckMutation.mutate(
                getDoseRange()[setSliderWithCurrentActiveDose()]
              );

              setSliderWithCurrentActiveDose();
              setshowDoseChangeConfModal(false);
            }}
            onConfirm={() => {
              handleUpdateDose(debouncedValue as number);
            }}
          />
        </div>
      )}
    </SkeletonContent>
  );
}
