import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useNavigate, useSearchParams } from 'react-router-dom';

// eslint-disable-next-line
import Logo from 'assets/images/LOGO.png';
import { Button } from 'components/common/Button/Button';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useProfile } from 'hooks/useProfile';
import { UserService } from 'services/user';

type StatusProperties = {
  logo: ReactElement;
  title: string;
  message: ReactElement | string;
};

type StatusTypes = 'loading' | 'verified' | 'error';

type Status = {
  [Key: string]: StatusProperties;
};

export function VerifyEmail() {
  const [active, setActive] = useState<StatusTypes>('loading');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const verifyEmailMutation = useMutationWithToast(UserService.verifyEmail);
  const { isLoggedIn, userProfileQuery, profile } = useProfile();

  const verifyEmail = useCallback(() => {
    verifyEmailMutation.mutate(
      { token: token ?? '' },
      {
        onSuccess: () => {
          setActive('verified');
        },
        onError: () => {
          setActive('error');
        },
      }
    );
  }, [token, verifyEmailMutation]);

  useEffect(() => {
    if (userProfileQuery.isLoading) return;
    if (userProfileQuery.isSuccess && token) {
      verifyEmail();

      return;
    }
    setActive('error');
    // eslint-disable-next-line
  }, [userProfileQuery.isLoading]);

  const status: Status = {
    loading: {
      logo: <LoadingSpinner className="mt-2 text-primary-main" size={12} />,
      title: 'Verifying Email',
      message: <>We are verifying your email. Thank you for your patience</>,
    },
    verified: {
      logo: <CheckBadgeIcon className="mt-2 text-primary-main" height={62} />,
      title: 'Email Verified!',
      message: (
        <div className="flex flex-col items-center">
          Thank you for Verification.
          <Button
            onClick={() => navigate({ pathname: '/auth/login' })}
            className="mt-2">
            Login
          </Button>
        </div>
      ),
    },
    error: {
      logo: (
        <ExclamationCircleIcon className="mt-2 text-error-main" height={52} />
      ),
      title: 'Verification failed!',
      message: !isLoggedIn ? (
        <div className="flex flex-col">
          You must me logged in order to verify your email.
          <br />
          <Button
            onClick={() => navigate({ pathname: '/auth/login' })}
            className="mx-auto mt-2">
            Login
          </Button>
        </div>
      ) : (
        <>
          Something went wrong. We are unable to verify your email
          <br />
          Please try again later.
        </>
      ),
    },
  };

  return (
    <div className="flex h-screen w-screen justify-center bg-background-dark  ">
      <div className="shadow-black-900 mt-40 h-64 w-2/5 rounded border border-background-contrastText bg-background-main shadow-md  ">
        <div className="relative flex flex-col items-center justify-center">
          <div className="mt-4 flex min-h-[88px] flex-col items-center  ">
            <img
              className="w-20 rounded"
              src={profile?.practice.logoUrl}
              alt="logo"
            />
            <Typography className="mt-1" variant="subtitle1">
              {profile?.practice.name}
            </Typography>
          </div>
          {/* <Typography className="mt-10" color="textMuted" variant="h1">
            Brite Live
          </Typography> */}
          <div className=" flex flex-col items-center">
            {status[active].logo}

            <Typography className="mt-3" color="textMuted" variant="subtitle3">
              {status[active].title}
            </Typography>
            <Typography
              className="mt-3"
              color="textMuted"
              align="center"
              variant="subtitle3">
              {status[active].message}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
