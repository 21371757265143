import React, { useCallback, useEffect, useState } from 'react';

import { useFloating } from '@floating-ui/react-dom';
import { Portal } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { TextInput } from 'components/common/Input/TextInput';
import { ThemeName } from 'theme/types';

interface SIGDropdownProps {
  options?: string[];
  theme?: ThemeName;
  value: string;
  onChange: (val: string, type: string) => void;
  helperText?: string;
  error?: boolean;
}

export function SIGDropdown({
  value,
  options,
  theme = ThemeName.Dark,
  onChange,
  helperText,
  error,
}: SIGDropdownProps) {
  const closeMenu = useCallback((event: MouseEvent) => {
    if ((event?.target as SVGElement).id === 'DropDownbutton') {
      return;
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('click', (e) => closeMenu(e));

    return () => document.removeEventListener('click', closeMenu);
  }, [closeMenu]);
  const [open, setOpen] = useState(false);
  const { x, y, strategy, refs } = useFloating();

  return (
    <div>
      {/*  eslint-disable-next-line */}
      <div
        id="DropDownbutton"
        className="relative cursor-pointer"
        ref={refs.setReference}
        onClick={() => setOpen(!open)}>
        <TextInput
          id="DropDownbutton"
          onChange={(e) => onChange(e, 'Entered')}
          value={value}
          fullWidth
          placeholder="Please select or enter sig"
          className=""
          disableHelperText
        />
        <div className=" absolute inset-y-0 right-4  z-20 ml-2 flex  cursor-pointer items-center text-background-contrastText">
          <ChevronDownIcon
            id="DropDownbutton"
            height={20}
            className={`${open && 'rotate-180'}`}
          />
        </div>
      </div>
      {open && (
        <Portal>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: (y ?? 0) + 20,
              left: x ?? 0,
              width: refs.reference.current?.getBoundingClientRect().width,
            }}
            className={classNames(
              theme,
              ' -mt-4  max-h-48  overflow-y-auto rounded-b bg-background-light   '
            )}>
            <ul className={classNames(' ')}>
              {options?.map((option: string) => (
                // eslint-disable-next-line
                <li
                  onClick={() => {
                    onChange(option, 'Selected');
                  }}
                  key={option}
                  className={`cursor-pointer p-2 pl-6  text-base text-background-contrastText hover:rounded-sm hover:bg-primary-main hover:text-primary-contrastText
            ${
              option.toLowerCase() === value?.toLowerCase() &&
              ' border-l-4 border-primary-main pl-3 '
            }, 
           `}>
                  {option}
                </li>
              ))}
            </ul>
          </div>
        </Portal>
      )}
      <p
        style={{ minHeight: '1rem' }}
        className={classNames(
          'm-1 text-left text-sm text-background-contrastText ',
          {
            '!text-error-main': error,
          }
        )}>
        {helperText}
      </p>
    </div>
  );
}
