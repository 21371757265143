import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { GMTDate } from 'lib/general/GMT-date';
import { PatientService } from 'services/patient';
import { GetPatientsHormonalEffectResponse } from 'services/patient/types';
import {
  CustomizedAxisTickProps,
  PatientDetailsContextType,
} from 'types/patient';

import { CustomLegend } from '../LegendForCharts/LegendForCharts';

interface CustomTooltipProps extends TooltipProps<number, string> {
  data?: GetPatientsHormonalEffectResponse[];
}

enum HormoneNameType {
  Progesterone = 'Progesterone',
  Testosterone = 'Testosterone',
  'Bi-Est' = 'Bi-Est',
}

function CustomTooltip({ active, payload, label, data }: CustomTooltipProps) {
  const hormoneData = data?.find(
    (d) =>
      new GMTDate(
        new Date(d.createdAt).toISOString()
      ).getReadableDateInGMT() === label
  );

  function getHormoneTooltipValue(
    dataKey: HormoneNameType,
    hormoneScore: GetPatientsHormonalEffectResponse
  ) {
    switch (dataKey) {
      case HormoneNameType['Bi-Est']:
        return hormoneScore.estrogenScore;

      case HormoneNameType.Progesterone:
        return hormoneScore.progesteroneScore;

      case HormoneNameType.Testosterone:
        return hormoneScore.testosteroneScore;

      default:
        return 0;
    }
  }

  if (active && payload && payload.length && hormoneData) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          opacity: 0.9,
        }}>
        <p className="label">{`${label}`}</p>
        {payload.map((p) => (
          <p
            style={{
              color: p.color,
            }}>
            {`${p.dataKey}: ${getHormoneTooltipValue(
              p.dataKey as HormoneNameType,
              hormoneData
            )}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
}

function CustomizedAxisTick({ x, y, payload }: CustomizedAxisTickProps) {
  return (
    <Text
      x={x}
      y={y}
      dy={20}
      dx={30}
      width={90}
      textAnchor="end"
      angle={0}
      fontSize={12}
      fill="#ccc"
      breakAll={false}
      style={{
        whiteSpace: 'nowrap',
      }}>
      {payload?.value}
    </Text>
  );
}

export function HormonalEffectDataCharts() {
  const { id } = useParams();
  const patientDetails = useOutletContext<PatientDetailsContextType>();

  const getPatientHormonalEffectQuery = useQuery(
    QueryKeys.PatientHormonalEffect.item(id as string),
    () => PatientService.getPatientHormonalEffect(id || ''),
    {
      enabled: !!id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  function mutateIfSameScoreForProgesterone(index: number) {
    if (
      getPatientHormonalEffectQuery.data?.data[index]?.progesteroneScore ===
      getPatientHormonalEffectQuery.data?.data[index]?.estrogenScore
    ) {
      return (
        Number(
          getPatientHormonalEffectQuery.data?.data[index]?.progesteroneScore
        ) + 0.1
      );
    }

    return getPatientHormonalEffectQuery.data?.data[index]?.progesteroneScore;
  }

  function mutateIfSameScoreForTestosterone(index: number) {
    if (
      getPatientHormonalEffectQuery.data?.data[index]?.testosteroneScore ===
      getPatientHormonalEffectQuery.data?.data[index]?.estrogenScore
    ) {
      if (
        getPatientHormonalEffectQuery.data?.data[index]?.testosteroneScore ===
        getPatientHormonalEffectQuery.data?.data[index]?.progesteroneScore
      ) {
        return (
          Number(
            getPatientHormonalEffectQuery.data?.data[index]?.testosteroneScore
          ) + 0.2
        );
      }

      return (
        Number(
          getPatientHormonalEffectQuery.data?.data[index]?.testosteroneScore
        ) + 0.1
      );
    }

    return getPatientHormonalEffectQuery.data?.data[index]?.testosteroneScore;
  }

  function getChartsData() {
    const timeLineLength = getPatientHormonalEffectQuery.data?.data.length
      ? Number(getPatientHormonalEffectQuery.data?.data.length) + 1
      : 2;

    return [...Array(timeLineLength)].map((item, index) => ({
      'Bi-Est': getPatientHormonalEffectQuery.data?.data[index]?.estrogenScore,
      Progesterone: mutateIfSameScoreForProgesterone(index),
      Testosterone: mutateIfSameScoreForTestosterone(index),
      date: getPatientHormonalEffectQuery.data?.data[index]?.createdAt
        ? new GMTDate(
            new Date(
              getPatientHormonalEffectQuery.data?.data[index]?.createdAt
            ).toISOString()
          ).getReadableDateInGMT()
        : undefined,
    }));
  }

  return (
    <SkeletonContent
      containerClassName="w-full max-w-[50%]"
      layout={[
        {
          className: 'h-[32rem]  w-full rounded bg-background-main py-8 px-4 ',
          children: [
            {
              className: ' h-6 w-40',
            },
          ],
        },
      ]}
      isLoading={
        getPatientHormonalEffectQuery.isLoading &&
        !getPatientHormonalEffectQuery.isError
      }>
      {() => (
        <div className="flex h-[32rem] w-full max-w-[50%] flex-col rounded bg-background-main py-2">
          <Typography className="p-4 pb-12" variant="h4">
            Hormonal Insufficiency and Excess Over Time
          </Typography>
          {getPatientHormonalEffectQuery.isError ? (
            <div className="flex  w-full grow items-center justify-center">
              <Typography className="px-12" variant="subtitle1">
                To demonstrate the hormonal effects over time, a patient must
                have completed the initial questionnaire and filled out at least
                one follow-up questionnaire.
              </Typography>
            </div>
          ) : (
            <>
              {' '}
              <ResponsiveContainer width="98%" height="80%">
                <LineChart
                  width={500}
                  height={300}
                  data={getChartsData()}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 10,
                    bottom: 5,
                  }}>
                  <CartesianGrid horizontal vertical={false} />
                  <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                  <YAxis
                    ticks={[-10, -8, -6, -4, -2, 0, 2, 4, 6, 8, 10]}
                    tick={{ fontSize: 12, fill: '#ccc' }}
                    // axisLine={false}
                    tickLine={false}>
                    <Label
                      value="Excess"
                      offset={48}
                      position="insideTopRight"
                      angle={-90}
                    />{' '}
                    <Label
                      value="Insufficiency"
                      offset={25}
                      position="insideBottomLeft"
                      startOffset={100}
                      angle={-90}
                    />
                  </YAxis>
                  <Tooltip
                    content={
                      <CustomTooltip
                        data={getPatientHormonalEffectQuery.data?.data}
                      />
                    }
                  />
                  <Legend className="pt-8" content={<CustomLegend />} />
                  {patientDetails?.gender === 'F' && (
                    <Line
                      radius={20}
                      strokeWidth={4}
                      dot={false}
                      type="monotone"
                      dataKey={HormoneNameType['Bi-Est']}
                      stroke="#5030e5"
                    />
                  )}
                  {patientDetails?.gender === 'F' && (
                    <Line
                      strokeWidth={4}
                      dot={false}
                      type="monotone"
                      dataKey={HormoneNameType.Progesterone}
                      stroke="#f22941"
                    />
                  )}

                  <Line
                    strokeWidth={4}
                    dot={false}
                    type="monotone"
                    dataKey={HormoneNameType.Testosterone}
                    stroke="#46bda6"
                  />
                </LineChart>
              </ResponsiveContainer>{' '}
            </>
          )}
        </div>
      )}
    </SkeletonContent>
  );
}
