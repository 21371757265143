import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export type PaginationProps = {
  page: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  recordsPerPage: number;
};

export function Pagination({
  page = 1,
  totalRecords,
  onPageChange,
  recordsPerPage,
}: PaginationProps) {
  return (
    <nav
      className="mr-2 flex justify-between text-background-contrastText"
      aria-label="Table navigation">
      <span className="text-sm font-normal " />
      <span className="flex items-center space-x-2  ">
        <button
          type="button"
          onClick={() => {
            if (page > 1) onPageChange(page - 1);
          }}
          className={classNames(
            'grid h-6 w-6 cursor-pointer place-items-center rounded-md bg-background-light text-white hover:bg-primary-light active:scale-75',
            {
              ' pointer-events-none opacity-50 ': page <= 1,
            }
          )}>
          <ChevronLeftIcon height={15} />
        </button>
        <p className="mx-2 text-base">
          Page {page} of {Math.ceil(totalRecords / recordsPerPage)}
        </p>{' '}
        <button
          type="button"
          onClick={() => {
            if (page < totalRecords / recordsPerPage) {
              onPageChange(page + 1);
            }
          }}
          className={classNames(
            'grid h-6 w-6 cursor-pointer place-items-center rounded-md bg-background-light text-white hover:bg-primary-light active:scale-75',
            {
              ' pointer-events-none opacity-50 ':
                page >= Math.ceil(totalRecords / recordsPerPage),
            }
          )}>
          <ChevronRightIcon height={15} />
        </button>
      </span>
    </nav>
  );
}
