import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useDebounce } from 'hooks/useDebounce';
import { transformMedication } from 'pages/app/patients/[id]/prescriptions/prescribe/common';
import { PatientPrescriptionService } from 'services/prescription';
import { PrescribedObjectType } from 'types/prescriptionTypes';

type HormoneStandardizedNameProp = {
  prescription: PrescribedObjectType;
};

export function useHormoneStandardizedName({
  prescription,
}: HormoneStandardizedNameProp) {
  const { id: patientId } = useParams();
  const debouncedValue = useDebounce<PrescribedObjectType>(
    prescription as PrescribedObjectType,
    500
  );

  const getStandardizedNameQuery = useQuery(
    [prescription.id, debouncedValue, 'standardizedName'],
    () =>
      PatientPrescriptionService.getStandardizedName({
        patientId: patientId ?? '',
        medication: transformMedication(debouncedValue as PrescribedObjectType),
      }),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return { getStandardizedNameQuery };
}
