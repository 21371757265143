import React from 'react';

import { ArrowDownTrayIcon, PrinterIcon } from '@heroicons/react/24/solid';
import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import printJS from 'print-js';

import { Typography } from 'components/common/Typography/Typography';
import { Gender } from 'types/patient';
import { SummaryQuestionType } from 'types/questionnaire';

import { FemalePDF } from './FemalePDF';
import { MalePDF } from './MalePDF';

type QuestionnairePDFProps = {
  filteredSummaryQuestions: SummaryQuestionType;
  generalInfo: { [key: string]: string };
  gender: string;
};

export function QuestionnairePDF({
  filteredSummaryQuestions,
  generalInfo,
  gender,
}: QuestionnairePDFProps) {
  const pdf =
    gender === Gender.Female ? (
      <FemalePDF
        filteredSummaryQuestions={filteredSummaryQuestions}
        generalInfo={generalInfo}
        gender={gender}
      />
    ) : (
      <MalePDF
        filteredSummaryQuestions={filteredSummaryQuestions}
        generalInfo={generalInfo}
        gender={gender}
      />
    );

  return (
    <div className=" mt-1 flex flex-col items-center  ">
      <div className="flex h-16 w-full items-center justify-between bg-[#323639] px-6 pr-10">
        <div className="  flex items-center   ">
          <Typography color="white" variant="subtitle2">
            {' '}
            {`${generalInfo?.name} Summary`}
          </Typography>
        </div>
        <div className="flex gap-5">
          <PDFDownloadLink
            document={pdf}
            fileName={`${generalInfo?.name} Summary`}>
            {() => (
              <ArrowDownTrayIcon className="w-10 cursor-pointer rounded-full  p-[10px] text-white hover:bg-background-light   " />
            )}
          </PDFDownloadLink>
          <BlobProvider document={pdf}>
            {(props) => (
              <PrinterIcon
                onClick={() => {
                  printJS(props.url as string);
                }}
                className=" w-10 cursor-pointer rounded-full  p-[10px] text-white hover:bg-background-light    "
              />
            )}
          </BlobProvider>
        </div>
      </div>
      <PDFViewer showToolbar={false} style={{ height: '100vh', width: '100%' }}>
        {pdf}
      </PDFViewer>
    </div>
  );
}
