/* eslint-disable camelcase */
import { hiddenProviderTitles } from 'constants/provider-titles';
import { APIService } from 'services/api-service';
import {
  DeaLicense,
  GetDeaLicenses,
  GetProviderPracticesAddressesResponse,
  ProviderDEA,
  ProviderLicence,
} from 'types/provider';

import { UserServiceNS } from './types';

export class UserService extends APIService {
  static USER_PROFILE = `${this.API_PREFIX_URL}/profile/`;
  static getUserProfile = async () =>
    this.get<UserServiceNS.GetUserProfileResponse>(UserService.USER_PROFILE, {
      withAuthToken: true,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });

  static UPDATE_USER_PASSWORD = `${this.API_PREFIX_URL}/profile/password`;
  static updateUserPassword = ({
    oldPassword,
    password,
  }: UserServiceNS.UpdateUserPasswordBody) =>
    this.put(
      UserService.UPDATE_USER_PASSWORD,
      {
        oldPassword,
        newPassword: password,
      },
      {
        withAuthToken: true,
      }
    );

  static PROFILE_DETAILS = `${this.API_PREFIX_URL}/profile/details`;
  static getProfileDetails = () =>
    this.get<UserServiceNS.GetUserDetailsResponse>(
      UserService.PROFILE_DETAILS,

      {
        withAuthToken: true,
      }
    );

  static UPDATE_PHONE = `${this.API_PREFIX_URL}/profile/phone`;
  static updatePhoneNumber = ({ phone }: UserServiceNS.UpdatePhoneBody) =>
    this.put(
      UserService.UPDATE_PHONE,
      {
        phone,
      },
      {
        withAuthToken: true,
      }
    );

  static UPDATE_NAME = `${this.API_PREFIX_URL}/profile/`;
  static updateName = ({
    firstName,
    lastName,
    middleName,
  }: UserServiceNS.UpdateNameBody) => {
    const url = new URL(UserService.UPDATE_NAME);

    return this.put(
      url.toString(),
      {
        firstName,
        lastName,
        middleName,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static UPDATE_EMAIL = `${this.API_PREFIX_URL}/profile/email`;
  static updateEmail = ({ email }: UserServiceNS.UpdateEmailBody) =>
    this.put(
      UserService.UPDATE_EMAIL,
      {
        email,
      },
      {
        withAuthToken: true,
      }
    );

  static VERIFY_EMAIL = `${this.API_PREFIX_URL}/profile/verify-email`;
  static verifyEmail = ({ token }: UserServiceNS.VerifyEmailBody) =>
    this.put(`${UserService.VERIFY_EMAIL}?token=${token}`, undefined, {
      withAuthToken: true,
    });

  static UPDATE_PROFILE_PICTURE = `${this.API_PREFIX_URL}/profile/picture`;
  static updateProfilePicture = ({
    picture,
  }: UserServiceNS.UpdateProfilePictureBody) => {
    const formData = new FormData();
    formData.append('picture', picture);

    return this.put(UserService.UPDATE_PROFILE_PICTURE, formData, {
      withAuthToken: true,
      formData: true,
    });
  };

  static deleteProfilePicture = () =>
    this.delete(UserService.UPDATE_PROFILE_PICTURE, {
      withAuthToken: true,
    });

  static UPDATE_ADDRESS = `${this.API_PREFIX_URL}/profile/provider/`;
  static updateAddress = ({
    addressType,
    addressLine1: address_line_1,
    addressLine2: address_line_2,
    city,
    country,
    state,
    zipCode: zip_code,
  }: UserServiceNS.UpdateAddressArgs) =>
    this.put(
      `${UserService.UPDATE_ADDRESS}${addressType}`,
      {
        address_line_1,
        address_line_2,
        city,
        country,
        state,
        zip_code,
      },
      {
        withAuthToken: true,
      }
    );

  static GET_PROVIDER_SPECIALTIES = `${this.API_PREFIX_URL}/provider/specialties`;
  static getProviderSpecialties = async () =>
    this.get<UserServiceNS.GetProviderSpecialtiesResponse[]>(
      `${UserService.GET_PROVIDER_SPECIALTIES}`,
      {
        withAuthToken: false,
      }
    );

  static GET_PROVIDER_TYPES = `${this.API_PREFIX_URL}/provider/provider-types`;
  static getProviderTypes = async () =>
    this.get<UserServiceNS.GetProviderSpecialtiesResponse[]>(
      `${UserService.GET_PROVIDER_TYPES}`,
      {
        withAuthToken: false,
      }
    );

  static ADD_PROFILE_PICTURE = `${this.API_PREFIX_URL}/onboard/provider/profile-picture`;
  static addProfilePicture = ({
    picture,
    token,
  }: UserServiceNS.PostProfilePictureBody) => {
    const formData = new FormData();
    formData.append('picture', picture);

    return this.put(
      `${UserService.ADD_PROFILE_PICTURE}?token=${token}`,
      formData,
      {
        withAuthToken: true,
        formData: true,
      }
    );
  };

  static ADD_SIGNATURE = `${this.API_PREFIX_URL}/onboard/provider/signature`;
  static addSignature = ({
    signature,
    token,
  }: UserServiceNS.PostSignatureBody) => {
    const formData = new FormData();
    formData.append('signature', signature);

    return this.put(`${UserService.ADD_SIGNATURE}?token=${token}`, formData, {
      withAuthToken: true,
      formData: true,
    });
  };

  static ONBOARD_PROVIDER_URL = `${this.API_PREFIX_URL}/onboard/provider`;
  static onBoardProvider = async ({
    token,
    providerDetails,
  }: UserServiceNS.OnboardProviderBody) =>
    this.post(
      `${UserService.ONBOARD_PROVIDER_URL}?token=${token}`,
      { ...providerDetails },
      {
        withAuthToken: true,
      }
    );

  static GET_PROVIDER_PRACTICE_DETAILS = `${this.API_PREFIX_URL}/onboard/practice`;
  static getProviderPracticeDetails = async ({
    token,
  }: UserServiceNS.GetProviderPracticeDetailsBody) =>
    this.get<UserServiceNS.GetProviderPracticeDetailsResponse>(
      `${UserService.GET_PROVIDER_PRACTICE_DETAILS}?token=${token}`,
      {
        withAuthToken: false,
      }
    );

  static UPDATE_PROFILE_DETAILS_URL = `${this.API_PREFIX_URL}/provider/details`;
  static updateProfile = ({
    officeManagerEmail,
    officeManagerName,
    providerSuffix,
    dob,
    gender,
    offersTelemedicine,
  }: UserServiceNS.UpdateProfileBody) =>
    this.put(
      UserService.UPDATE_PROFILE_DETAILS_URL,
      {
        ...(!!officeManagerEmail && { officeManagerEmail }),
        ...(!!officeManagerName && { officeManagerName }),
        ...(!!providerSuffix && { providerSuffix }),
        ...(!!dob && { dob }),
        ...(!!gender && { gender }),
        ...(!!offersTelemedicine !== undefined && { offersTelemedicine }),
      },
      {
        withAuthToken: true,
      }
    );

  static GET_STATES = `${this.API_PREFIX_URL}/profile/states`;
  static getStates = async () =>
    this.get<UserServiceNS.GetStatesResponse[]>(UserService.GET_STATES, {
      withAuthToken: true,
    });

  static ADD_LICENSE_URL = `${this.API_PREFIX_URL}/provider/licenses`;
  static addLicense = async ({ license, stateId }: ProviderLicence) =>
    this.post(
      `${UserService.ADD_LICENSE_URL}`,
      { state_id: stateId, license },
      {
        withAuthToken: true,
      }
    );

  static ADD_DEA_URL = `${this.API_PREFIX_URL}/provider/provider_dea`;
  static addDEA = async ({ dea, stateId }: ProviderDEA) =>
    this.post(
      `${UserService.ADD_DEA_URL}`,
      { state_id: stateId, dea },
      {
        withAuthToken: true,
      }
    );

  static DELETE_LICENSE_URL = `${this.API_PREFIX_URL}/provider/licenses`;
  static deleteLicense = async (licenseId: string) =>
    this.delete(
      `${UserService.ADD_LICENSE_URL}/${licenseId}`,

      {
        withAuthToken: true,
      }
    );

  static DELETE_DEA_URL = `${this.API_PREFIX_URL}/provider/provider_dea`;
  static deleteDEA = async (deaID: string) =>
    this.delete(
      `${UserService.DELETE_DEA_URL}/${deaID}`,

      {
        withAuthToken: true,
      }
    );

  static GET_PROVIDER_PRACTICES = `${this.API_PREFIX_URL}/provider/practices`;
  static getProviderPractices = async ({
    size,
    page,
  }: {
    size: number;
    page: number;
  }) => {
    const url = new URL(UserService.GET_PROVIDER_PRACTICES);

    url.searchParams.append('size', String(size));
    url.searchParams.append('page', String(page));

    return this.get<UserServiceNS.GetProviderPracticesResponse>(
      url.toString(),
      {
        withAuthToken: true,
      }
    );
  };

  static GET_PROVIDER_PRACTICES_ADDRESSES = `${this.API_PREFIX_URL}/provider/practices`;
  static getProviderPracticeAddresses = async (id: string) =>
    this.get<GetProviderPracticesAddressesResponse[]>(
      `${UserService.GET_PROVIDER_PRACTICES_ADDRESSES}/${id}`,
      {
        withAuthToken: true,
      }
    );

  static SWITCH_PROVIDER_PRACTICE = `${this.API_PREFIX_URL}/provider/practices/switch`;
  static switchPractice = async (providerPracticeId: string) =>
    this.put(
      `${UserService.SWITCH_PROVIDER_PRACTICE}`,
      {
        providers_practice_id: providerPracticeId,
      },
      {
        withAuthToken: true,
      }
    );

  static PROVIDER_SUFFIX_URL = `${this.API_PREFIX_URL}/provider/suffixes`;
  static getProviderSuffixes = () =>
    this.get<UserServiceNS.GetProviderSuffixes>(
      UserService.PROVIDER_SUFFIX_URL,
      {
        withAuthToken: true,
      }
    ).then((res) => {
      res.data.suffixes = res.data.suffixes.filter(
        (suffix) => !hiddenProviderTitles.includes(suffix)
      );

      return res;
    });

  static SUPERVISING_DOCTOR_URL = `${this.API_PREFIX_URL}/provider/supervising-provider`;
  static addSupervisingDoctor = ({
    require,
    supervisingProvider,
  }: UserServiceNS.RequireSupervisingProviderDetailsBody) =>
    this.put(
      `${UserService.SUPERVISING_DOCTOR_URL}`,
      {
        requireSupervisingProvider: require,
        ...(!!supervisingProvider && {
          supervising_provider: {
            license_number: supervisingProvider.licenseNumber,
            licensed_prescriber_first_name: supervisingProvider.firstName,
            licensed_prescriber_last_name: supervisingProvider.lastName,
            dea: supervisingProvider.dea,
            license_state: supervisingProvider.licenseState,
            licensed_prescriber_suffix: supervisingProvider.suffix,
            ...(supervisingProvider?.middleName && {
              licensedPrescriberMiddleName: supervisingProvider?.middleName,
            }),
            ...(supervisingProvider?.npi && {
              npi: supervisingProvider?.npi,
            }),
          },
        }),
      },
      {
        withAuthToken: true,
      }
    );

  static UPDATE_SUPERVISING_PROVIDER = `${this.API_PREFIX_URL}/provider/supervising-provider/`;

  static updateSupervisingDoctor = ({
    supervisingProviderId,
    supervisingProvider,
  }: UserServiceNS.UpdateRequireSupervisingProviderDetailsBody) => {
    const url = new URL(
      `${UserService.UPDATE_SUPERVISING_PROVIDER}${supervisingProviderId}`
    );

    return this.put(
      url.toString(),
      {
        license_number: supervisingProvider?.licenseNumber,
        licensed_prescriber_first_name: supervisingProvider?.firstName,
        licensed_prescriber_last_name: supervisingProvider?.lastName,
        dea: supervisingProvider?.dea,
        license_state: supervisingProvider?.licenseState,
        licensed_prescriber_suffix: supervisingProvider?.suffix,
        ...(supervisingProvider?.middleName && {
          licensedPrescriberMiddleName: supervisingProvider?.middleName,
        }),
        ...(supervisingProvider?.npi && {
          npi: supervisingProvider?.npi,
        }),
      },
      {
        withAuthToken: true,
      }
    );
  };

  static DELETE_SUPERVISING_PROVIDER = `${this.API_PREFIX_URL}/provider/supervising-provider/`;
  static deleteSupervisingProvider = (supervisingProviderId: string) => {
    const url = new URL(
      `${UserService.DELETE_SUPERVISING_PROVIDER}${supervisingProviderId}`
    );

    return this.delete(url.toString(), {
      withAuthToken: true,
    });
  };

  static PHARMACY_MESSAGES_URL = `${this.API_PREFIX_URL}/orders/preset-messages?is_active=true`;
  static getPharmacyMessage = ({
    size,
    page,
    search,
    noteType,
  }: {
    size: number;
    page: number;
    search?: string;
    noteType: string;
  }) => {
    const url = new URL(UserService.PHARMACY_MESSAGES_URL);

    url.searchParams.append('size', String(size));
    url.searchParams.append('page', String(page));
    url.searchParams.append('note_type', String(noteType));
    if (search) {
      url.searchParams.append('search', String(search));
    }

    return this.get<UserServiceNS.GetPharmacyMessagesResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static CREATE_PRESET_MESSAGE = `${this.API_PREFIX_URL}/orders/preset-messages`;
  static createPresetMessage = async ({
    pharmacyMessage,
    noteType,
  }: {
    pharmacyMessage: string;
    noteType: string;
  }) => {
    const url = new URL(`${this.CREATE_PRESET_MESSAGE}`);

    return this.post(
      url.toString(),
      {
        message: pharmacyMessage,
        noteType,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static ADD_PROVIDER_DEA_LICENSE = `${this.API_PREFIX_URL}/provider/dea-licenses`;
  static addProviderDeaAndLicense = async ({
    license,
    stateId,
    dea,
    deaExpiry,
    licenseExpiry,
    supervisingProvider,
  }: DeaLicense) => {
    const request = {
      license,
      dea,
      deaExpiry,
      licenseExpiry,
      supervisingProvider: supervisingProvider?.dea
        ? {
            ...supervisingProvider,
            licensed_prescriber_first_name: supervisingProvider?.firstName,
            licensed_prescriber_last_name: supervisingProvider?.lastName,
            licensed_prescriber_middle_name: supervisingProvider?.middleName,
            licensed_prescriber_suffix: supervisingProvider?.suffix,
          }
        : null,
    };

    return this.post(
      `${UserService.ADD_PROVIDER_DEA_LICENSE}/${stateId}`,
      request,
      {
        withAuthToken: true,
      }
    );
  };
  static updateProviderDeaAndLicense = async ({
    license,
    stateId,
    dea,
    deaExpiry,
    licenseExpiry,
    supervisingProvider,
  }: DeaLicense) => {
    const request = {
      license,
      dea,
      deaExpiry,
      licenseExpiry,
      supervisingProvider: supervisingProvider?.dea
        ? {
            ...supervisingProvider,
            licensed_prescriber_first_name: supervisingProvider?.firstName,
            licensed_prescriber_last_name: supervisingProvider?.lastName,
            licensed_prescriber_middle_name: supervisingProvider?.middleName,
            licensed_prescriber_suffix: supervisingProvider?.suffix,
          }
        : null,
    };

    return this.put(
      `${UserService.ADD_PROVIDER_DEA_LICENSE}/${stateId}`,
      request,
      {
        withAuthToken: true,
      }
    );
  };

  static getLicensesAndDEA = () =>
    this.get<GetDeaLicenses[]>(`${UserService.ADD_PROVIDER_DEA_LICENSE}`, {
      withAuthToken: true,
    });

  static deleteLicensesAndDea = ({ stateId }: { stateId: string }) =>
    this.delete(`${UserService.ADD_PROVIDER_DEA_LICENSE}?state_id=${stateId}`, {
      withAuthToken: true,
    });
}
