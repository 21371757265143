import React, { ReactNode } from 'react';

import classNames from 'classnames';
import ReactSlider from 'react-slider';

import { Typography } from '../Typography/Typography';

type RangeSliderProps = {
  tooltipValue?: string;
  options: (string | number)[];
  value: number;
  onChange: (value: number) => void;
  getTooltip: (value: number) => ReactNode;
  disable?: boolean;
};
export function RangeSlider({
  getTooltip,
  options,
  value,
  onChange,
  disable = false,
}: RangeSliderProps) {
  return (
    <div
      className={classNames('!z-2 relative', {
        '!pointer-events-none !opacity-60 ': disable,
      })}>
      <Typography className=" absolute left-0 mt-4">{options?.[0]}</Typography>
      <ReactSlider
        withTracks
        className=""
        marks
        min={0}
        value={value}
        max={options.length - 1 ?? 0}
        onChange={onChange}
        renderThumb={(props) => (
          <div
            {...props}
            style={{ ...props.style, zIndex: 20 }}
            className="relative -mt-2 flex h-8 w-8 flex-col items-center outline-none">
            <div
              className={classNames(
                'absolute  mb-2 ml-3  -mt-8 inline-block whitespace-nowrap  rounded bg-white bg-opacity-10 px-2 py-1  text-base lowercase text-primary-contrastText backdrop-blur',
                { '!-ml-3': value === 0 }
              )}>
              {getTooltip(value)}
            </div>
            <div
              className={classNames(
                'ml-3 h-6 w-6 cursor-pointer rounded-full border-4 border-primary-light bg-primary-light shadow-lg',
                { '!-ml-3': value === 0 }
              )}
            />
          </div>
        )}
        renderTrack={(props, state) => (
          <div
            {...props}
            className={classNames('h-2.5 cursor-pointer rounded-full pl-2', {
              'bg-background-contrastText': state.index === 1,
              'z-10 bg-primary-dark': state.index === 0 || value === 0,
            })}
          />
        )}
      />
      <Typography className=" absolute right-0 mt-4">
        {options.length === 1 ? '' : options?.[options.length - 1]}
      </Typography>
    </div>
  );
}
