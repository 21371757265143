import { ReactNode } from 'react';

import { CurrentDoseType } from './patient/prescription';

export type PrescribedObjectTypes = {
  id?: string;
  mgPerMl?: string;
  drops?: string;
  displayName?: string;
  refills?: number;
  e3Toe2?: string;
  mlPerDay?: string;
  mg?: string;
  name?: string;
  sig?: string;
  dtd?: number | string;
  linkedHormone?: string;
  family?: string;
  deliveryMethod?: string;
};

export type PrescribedObjectType = {
  id?: string;
  name?: HormoneNames;
  strength?: string;
  strengthUnit?: string;
  ratio?: string;
  ratioUnit?: string;
  dose?: string;
  doseUnit?: string;
  displayName?: HormoneNames;
  refills?: number;
  sig?: string;
  dtd?: number;
  dtdUnit?: string;
  linkedHormone?: string;
  family?: string;
  deliveryMethod?: string;
  isFromTitration?: boolean;
  coveredUnderSubscription?: string;
  note?: string;
  minimumDose?: string;
  currentDose?: CurrentDoseType;
} & PrescriptionCheckValuesForReview;

export type PrescriptionCheckValuesForReview = {
  checkValues?: {
    withMinDose?: string;
    withMaxDose?: string;
    unit?: string;
  };
  daysOfSupply?: {
    withMinDose?: string;
    withMaxDose?: string;
  };
  isHighPotencyRx?: boolean;
  isLowPotencyRx?: boolean;
  standardizedName?: string;
};

export enum HormoneNames {
  BI_EST = 'Bi-Est',
  ESTRADIOL = 'Estradiol',
  PROGESTERONE = 'Progesterone',
  ORAL_PROGESTERONE = 'Oral Micronized Progesterone',
  TESTO_PER_DHEA = 'Testosterone/DHEA',
  TESTOSTERONE_WOMEN = 'Testosterone',
  DHEA = 'DHEA',
  DHEA_ORAL = 'DHEA oral',
  LEVOTHYROXINE = 'Levothyroxine (T4)',
  LIOTHYRONINE_EXT = 'Liothyronine Extended Release (LAT3)',
  T4_PER_LAT3 = 'T4/LAT3',
  SILDENAFIL = 'Sildenafil',
  TADALAFIL = 'Tadalafil',
  Danazol = 'Danazol',
  TESTOSTERONE_MEN = 'Testosterone',
  TestosteroneCyp = 'Testosterone Cyp Inj ',
  Pregnyl = 'Pregnyl (injectable)(HCG)',
}

export enum HormoneCompoundBaseName {
  Brite = 'Brite',
  BriteCompound = 'Compound: Brite',
  BriteTopical = 'Compound: Brite Topical Base',
}

type BiEstKeys = {
  name: HormoneNames.BI_EST;
  drops: string;
  displayName: string;
  dtd: number;
  refills: number;
  e3Toe2: string;
  mgPerMl: string;
  linkedHormone: string;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type EstradiolKeys = {
  name: HormoneNames.ESTRADIOL;
  displayName: string;
  drops: string;
  refills: number;
  dtd: number;
  mgPerMl: string;
  linkedHormone: string;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type ProgesteroneKeys = {
  name: HormoneNames.PROGESTERONE;
  displayName: string;
  drops?: string;
  dtd: number;
  mlPerDay?: string;
  mgPerMl: string;
  refills: number;
  linkedHormone: string;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type OralProgesteroneKeys = {
  name: HormoneNames.ORAL_PROGESTERONE;
  displayName: string;
  linkedHormone: string;
  mg: string;
  sig: string;
  dtd: number;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type TestoPerDHEAKeys = {
  name: HormoneNames.TESTO_PER_DHEA;
  displayName: string;
  mgPerMl: string;
  dtd: number;
  drops: string;
  refills: number;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type TesteroneWomenKeys = {
  name: HormoneNames.TESTOSTERONE_WOMEN;
  displayName: string;
  mgPerMl: string;
  drops: string;
  dtd: number;
  refills: number;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type DheaKeys = {
  name: HormoneNames.DHEA;
  displayName: string;
  dtd: number;
  mgPerMl: string;
  drops: string;
  refills: number;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type DheaOralKeys = {
  name: HormoneNames.DHEA_ORAL;
  displayName: string;
  sig: string;
  dtd: number;
  mg?: string;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type LevothyroxineKeys = {
  name: HormoneNames.LEVOTHYROXINE;
  displayName: string;
  mgPerMl: string;
  dtd: number;
  sig: string;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type LiothyronineExtKeys = {
  name: HormoneNames.LIOTHYRONINE_EXT;
  displayName: string;
  dtd: number;
  sig: string;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type T4PerLAT3Keys = {
  name: HormoneNames.T4_PER_LAT3;
  displayName: string;

  dtd: number;
  sig: string;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type SildenafilKeys = {
  name: HormoneNames.SILDENAFIL;
  displayName: string;

  dtd: number;
  sig: string;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type TadalafilKeys = {
  name: HormoneNames.TADALAFIL;
  displayName: string;
  sig: string;
  dtd: number;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type DanazolKeys = {
  name: HormoneNames.Danazol;
  displayName: string;
  sig: string;
  dtd: number;
  refills: number;
  mlPerDay: string;
  family?: string;
  deliveryMethod: string;
};

type TestosteroneMenKeys = {
  name: HormoneNames.TESTOSTERONE_MEN;
  displayName: string;
  mg: string;
  dtd: number;
  refills: number;
  mlPerDay: string;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type TestosteroneCypKeys = {
  name: HormoneNames.TestosteroneCyp;
  displayName: string;

  mg: string;
  refills: number;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

type PregnylKeys = {
  name: HormoneNames.Pregnyl;
  displayName: string;
  mg: string;

  refills: number;
  sig: string;
  family?: string;
  deliveryMethod: string;
};

export type PrescriptionKeys =
  | BiEstKeys
  | EstradiolKeys
  | ProgesteroneKeys
  | OralProgesteroneKeys
  | TestoPerDHEAKeys
  | TesteroneWomenKeys
  | DheaKeys
  | DheaOralKeys
  | LevothyroxineKeys
  | LiothyronineExtKeys
  | T4PerLAT3Keys
  | SildenafilKeys
  | TadalafilKeys
  | DanazolKeys
  | TestosteroneMenKeys
  | TestosteroneCypKeys
  | PregnylKeys;

export enum ReducerActionType {
  ADD_HORMONE,
  DELETE_HORMONE,
  UPDATE_HORMONE,
}

export type ReducerAction = {
  type: ReducerActionType;
  payload: {
    hormoneObject: PrescribedObjectTypes;
    index: number;
  };
};
export enum ReducerActionTypeNew {
  ADD_HORMONE,
  DELETE_HORMONE,
  UPDATE_HORMONE,
}

export type ReducerActionNew = {
  type: ReducerActionTypeNew;
  payload: {
    hormoneObject: PrescribedObjectType;
    index: number;
  };
};

export type SliderType = {
  label?: string;
  value: number;
  onChange: (value: number) => void;
  getTooltip: (value: number) => ReactNode;
  options: (number | string)[];
  disable?: boolean;
};

export type DTDType = {
  label?: string;
  value: number | string;
  onChange: (val: number) => void;
  DTDValues: number[];
  unit?: string;
};

export type SelectorCheckboxType = {
  label?: string;
  value: number | string;
  onChange: (val: number | string) => void;
  options: (number | string)[];
  unit: string;
};

export type SIGType = {
  value: string;
  onChange: (val: string) => void;
  options?: string[];
};

export type RadioType = {
  label?: string;
  value: string;
  onChange: (val: string) => void;
  options: string[];
};

export type TextType = {
  label: string;
  value: string;
};
export type SigGeneratorType = {
  value: string;
  onChange: (val: string) => void;
  doseOptions: (number | string)[];
  doseValue: number;
  maximumDoseValue: number;
  maximumDoseOnChange: (val: number) => void;
  unit?: string;
  sigUnit?: string;
};

export type InfoBoxType = {
  label: string;
  value: string;
  isLoading: boolean;
  className?: string;
  colorClass?: string;
  helperText?: ReactNode;
};

export type DoseSplitterType = {
  value: string;
  onChange: (currentDose: { morning: string; evening: string }) => void;
  hormoneName: HormoneNames;
};

export enum DoseSplittingMethods {
  MorningAndEvening = 'Morning & Evening',
  Morning = 'Just Morning',
  Evening = 'Just Evening',
}

export enum PrescriptionInputTypes {
  Slider = 'Slider',
  DTD = 'DTD',
  SIG = 'SIG',
  Radio = 'Radio',
  Text = 'Text',
  SigGenerator = 'SigGenerator',
  DoseSplitter = 'DoseSplitter',
  SelectorCheckbox = 'SelectorCheckbox',
}

export enum PrescriptionDisplayTypes {
  ElementContainer = 'ElementContainer',
  InfoBox = 'InfoBox',
}

export type PrescriptionInputObjectType = (
  | { type: 'Container' }
  | ({
      type: PrescriptionInputTypes.Slider;
    } & SliderType)
  | ({
      type: PrescriptionInputTypes.DTD;
    } & DTDType)
  | ({
      type: PrescriptionInputTypes.SelectorCheckbox;
    } & SelectorCheckboxType)
  | ({
      type: PrescriptionInputTypes.SIG;
    } & SIGType)
  | ({
      type: PrescriptionInputTypes.Radio;
    } & RadioType)
  | ({
      type: PrescriptionInputTypes.Text;
    } & TextType)
  | ({
      type: PrescriptionInputTypes.SigGenerator;
    } & SigGeneratorType)
  | ({
      type: PrescriptionDisplayTypes.InfoBox;
    } & InfoBoxType)
  | ({
      type: PrescriptionDisplayTypes.ElementContainer;
    } & HormoneDetailsUIContainerType)
  | ({
      type: PrescriptionInputTypes.DoseSplitter;
    } & DoseSplitterType)
) & {
  wrapperClassName?: string;
};

export enum HormoneValidations {
  DaysOfSupply = 'DaysOfSupply',
  Loading = 'Loading',
  MissingDEA = 'MissingDEA',
  SIGExceed140Chars = 'SIGExceed140Chars',
}

export type HormoneValidationType = {
  [key in HormoneValidations]?: {
    isFailed: boolean;
  };
};

export type RightFloatingChildProps = {
  label: string;
  value: string;
};

export type HormoneDetailsUIContainerType = {
  containerClassName?: string;
  children: PrescriptionInputObjectType[];
  title?: string;
};
