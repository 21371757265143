import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import CountUp from 'react-countup';
import { useParams } from 'react-router-dom';

import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { HarsService } from 'services/hars';

import 'react-circular-progressbar/dist/styles.css';

const getPathColor = (percentage: number) => {
  if (percentage >= 0 && percentage < 25) {
    return '#d32f2f';
  }
  if (percentage >= 25 && percentage < 50) {
    return '#F79327';
  }

  return '#359583';
};

function AnimatedCircularProgressbar({
  value,
  children,
}: {
  value: number;
  children: (v: number) => JSX.Element | null;
}) {
  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    const animationStep = 30;
    const interval = setInterval(() => {
      setAnimatedValue((prevValue) => {
        const newValue = prevValue + animationStep;
        if (newValue >= value) {
          clearInterval(interval);

          return value;
        }

        return newValue;
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [value]);

  return children(animatedValue);
}

export function HarsStats() {
  const { id: patientId } = useParams();
  const getHarsStatsQuery = useQuery(
    QueryKeys.PatientHars.item(patientId || ''),
    () => HarsService.getHarsStats(patientId || ''),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <SkeletonContent
      containerClassName="w-full max-w-[50%]"
      layout={[
        {
          className: ' mt-1 w-40 h-6 bg-background-main mb-2',
        },
        {
          className:
            'h-80  w-full rounded bg-background-main py-8 px-4 flex justify-between items-center ',
          children: [
            {
              className: ' h-60 w-60 !rounded-full !mx-12',
            },
            {
              className: ' flex flex-col !mx-12 gap-6',
              children: new Array(3)
                .fill(0)
                .map(() => ({ className: ' h-6 w-40 ' })),
            },
          ],
        },
      ]}
      isLoading={getHarsStatsQuery.isLoading}>
      {() => (
        <div className="w-full max-w-[50%]">
          <Typography variant="h4" className=" rounded-t  px-2 py-2 ">
            Hormone Application Compliance
          </Typography>
          <div className="flex h-[26rem] items-center justify-between rounded bg-background-main ">
            {!getHarsStatsQuery?.data?.data.prescriptionsHar.length ? (
              <div className="flex h-full w-full items-center justify-center">
                <Typography className="px-12" variant="subtitle1">
                  This is only for female patients who are in the titration
                  phase.
                </Typography>
              </div>
            ) : (
              <>
                <div className={classNames(' px-12')}>
                  <AnimatedCircularProgressbar
                    value={
                      getHarsStatsQuery.data?.data.overallHar
                        .overallHarPercent || 0
                    }>
                    {(animatedValue) => (
                      <CircularProgressbarWithChildren
                        strokeWidth={11}
                        className="h-[15rem] w-[15rem]"
                        background
                        value={animatedValue}
                        styles={buildStyles({
                          pathColor: getPathColor(
                            getHarsStatsQuery?.data?.data.overallHar
                              .overallHarPercent || 0
                          ),
                          trailColor: '#262626',
                          backgroundColor: '#1d1d1d',
                        })}>
                        <div className="flex flex-col items-center">
                          <Typography variant="h3">
                            <CountUp
                              end={
                                getHarsStatsQuery?.data?.data.overallHar
                                  .overallHarPercent || 0
                              }
                              duration={2}
                            />
                            %
                          </Typography>
                          <Typography variant="subtitle3">Overall</Typography>
                        </div>
                      </CircularProgressbarWithChildren>
                    )}
                  </AnimatedCircularProgressbar>
                </div>
                <div className=" flex flex-col gap-6 px-16">
                  {getHarsStatsQuery?.data?.data.prescriptionsHar.map(
                    (prescriptionHar) => (
                      <div className="flex flex-col items-center">
                        <Typography variant="subtitle1">
                          {prescriptionHar.har.overallHarPercent || 0}%
                        </Typography>{' '}
                        <Typography variant="subtitle1">
                          {prescriptionHar.prescription.medicationName}
                        </Typography>{' '}
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </SkeletonContent>
  );
}
