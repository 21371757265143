import React, { useCallback, useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useDebounce } from 'hooks/useDebounce';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { PatientService } from 'services/patient';
import { Option } from 'types/inputTypes';

type OtherMedicationsProps = {
  otherMedicationByPatient?: string;
  otherMedicationByProvider?: Option[] | null;
  patientId: string;
};

export function OtherMedications({
  otherMedicationByPatient,
  otherMedicationByProvider,
  patientId,
}: OtherMedicationsProps) {
  const queryClient = useQueryClient();
  const [medication, setMedication] = useState<Option>();
  const [medications, setMedications] = useState<Option[]>(
    otherMedicationByProvider ?? []
  );

  const [clearState, setClearState] = useState(false);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedValue = useDebounce<string>(searchValue, 300);
  const [noMedication, setNoMedication] = useState(false);

  const updateAllergiesMedicationMutation = useMutationWithToast(
    PatientService.updatePatientAllergiesMedication
  );

  const getOtherMedicationsQuery = usePaginatedQuery(
    QueryKeys.OtherMedication.listing({ search: debouncedValue }),
    ({ pageParam = 1 }) =>
      PatientService.getPatientOtherMedicationList({
        search: debouncedValue,
        page: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const updateMedication = useCallback(
    (newMedication: Option[]) =>
      updateAllergiesMedicationMutation.mutate(
        {
          id: patientId,

          patientOtherMedicationsNc: [...newMedication],
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(
              QueryKeys.PatientAllergiesMedication.item(patientId)
            );
            setSearchValue('');
            setError(false);
            setMedication(undefined);
          },
        }
      ),
    [queryClient, updateAllergiesMedicationMutation, patientId]
  );
  function deleteMedication(index: number) {
    updateMedication(medications.filter((i, indx) => indx !== index));
    setMedications(medications.filter((i, indx) => indx !== index));
    if (medications?.[0]?.value) {
      setNoMedication(true);
    }
  }

  useEffect(() => {
    if (
      otherMedicationByProvider === null ||
      otherMedicationByProvider === undefined
    ) {
      setNoMedication(false);
    } else if (!otherMedicationByProvider?.[0]?.value) {
      setNoMedication(true);
    }
  }, [otherMedicationByProvider]);

  return (
    <div className=" flex w-full  grow flex-col  rounded bg-background-main p-6 ">
      <Typography variant="h4">Other Medications</Typography>
      <div className="mt-6">
        <Typography variant="h5">
          Patient provided following as other medications.
        </Typography>
        <div data-tooltip-id="otherMedicationByPatient">
          <TextInput
            disabled
            value={otherMedicationByPatient}
            className="mt-1 pl-2"
            type="text"
            name="patientOtherMedications"
            fullWidth
            placeholder="Enter other medications"
          />
        </div>
        <Tooltip id="otherMedicationByPatient">
          {otherMedicationByPatient}
        </Tooltip>
      </div>
      <CheckBox
        onChange={(v) => {
          if (v) {
            updateMedication([]);
            setMedications([]);
          }
          setNoMedication(v);
        }}
        checked={noMedication}
        id="otherMedication"
        label="Takes No Medications"
      />
      {!noMedication && (
        <div className="mt-4">
          <Typography variant="h5">
            Please select relevant medications from the list below.
          </Typography>
          <div className="mt-1 flex items-start gap-x-2 ">
            <DropDown
              clearDropdownState={clearState}
              onChange={(v) => {
                setMedication(v);
                setClearState(false);
                setError(false);
              }}
              withInfiniteScroll
              searchable
              fullWidth
              placeholder="Select other medication"
              options={getOtherMedicationsQuery.data?.pages.map((i) => ({
                title: i?.medMedidDesc,
                value: i?.formularyDrugId,
              }))}
              onSearchChange={(text) => {
                setSearchValue(text);
              }}
              onNextPage={() => {
                getOtherMedicationsQuery.fetchNextPage();
              }}
              isOptionsLoading={getOtherMedicationsQuery.isLoading}
              isFetchingNextPage={getOtherMedicationsQuery.isFetchingNextPage}
              error={error}
              helperText={error ? 'Medication already exists' : ''}
            />

            <Button
              disabled={!medication}
              onClick={() => {
                if (medication) {
                  if (medications.some((a) => a.value === medication.value)) {
                    setError(true);

                    return;
                  }
                  updateMedication([
                    ...medications,
                    {
                      title: medication.title,

                      value: medication.value as string,
                    },
                  ]);
                  setMedications([
                    ...medications,
                    {
                      title: medication.title,

                      value: medication.value as string,
                    },
                  ]);
                  setClearState(true);
                  setMedication(undefined);
                }
              }}
              className="!py-3"
              size="large">
              ADD
            </Button>
          </div>
        </div>
      )}
      <div className="flex  flex-col overflow-auto ">
        <div className="">
          {medications?.[0]?.value && (
            <div className=" flex h-40 flex-col  gap-y-1 overflow-auto rounded-b">
              {medications?.map((a, index) => (
                <div className=" grid grid-cols-12 rounded border border-dashed  border-primary-main bg-background-main p-2 ">
                  <Typography
                    variant="subtitle2"
                    className="col-span-11 pl-5 pr-1"
                    ellipsis>
                    {a.title}
                  </Typography>{' '}
                  <TrashIcon
                    onClick={() => deleteMedication(index)}
                    id={a.value as string}
                    className="col-span-1 h-5 w-5 cursor-pointer font-extrabold text-error-main "
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
