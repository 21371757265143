import React from 'react';

import { OrderTables } from './OrderTables';

export function Orders() {
  return (
    <div className="mt-2  py-4">
      <OrderTables title="Orders in Progress" type="ongoing" />
      <div className="my-6" />
      <OrderTables title="Fulfilled Orders" type="fulfilled" />
    </div>
  );
}
