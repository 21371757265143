import React, { LegacyRef, useEffect, useRef, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { Tab } from 'types/questionnaire';

enum ArrowPosition {
  End = 'end',
  Start = 'start',
}

enum ScrollPosition {
  End = 'scrollToEnd',
  Start = 'scrollToStart',
}

type ScrollableTabBarProps = {
  tabs?: Tab[];
};
export function ScrollableTabBar({ tabs }: ScrollableTabBarProps) {
  const [activeArrow, setActiveArrow] = useState<ArrowPosition>(
    ArrowPosition.End
  );
  const [scrollable, setScrollable] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const section = searchParams.get('section') || '';
  const startRef = useRef<HTMLSpanElement>();
  const endRef = useRef<HTMLSpanElement>();
  const handleClick = (direction: string) => {
    if (direction === ScrollPosition.End) {
      endRef.current?.scrollIntoView({ behavior: 'smooth' });
      setActiveArrow(ArrowPosition.Start);
    } else {
      startRef.current?.scrollIntoView({ behavior: 'smooth' });
      setActiveArrow(ArrowPosition.End);
    }
  };

  function getScrollOptions(element: HTMLElement) {
    setScrollable(
      element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
  }

  useEffect(() => {
    if (tabs) {
      const scrollableContainer = document.getElementById(
        'scrollableContainer'
      ) as HTMLElement;
      getScrollOptions(scrollableContainer);
    }
  }, [tabs]);

  return (
    <div className=" relative flex h-[5.7rem] items-center justify-between rounded-sm bg-background-light px-4 py-4 ">
      {scrollable && (
        <ChevronLeftIcon
          onClick={() => {
            if (activeArrow === ArrowPosition.Start) {
              handleClick(ScrollPosition.Start);
            }
          }}
          className={classNames(
            '-ml-4 h-[50px]   w-[70px] cursor-pointer text-primary-contrastText ',
            {
              'opacity-20': activeArrow !== ArrowPosition.Start,
            }
          )}
        />
      )}
      <div
        id="scrollableContainer"
        className="  scrollbar-hide flex  items-center   overflow-x-auto scroll-smooth">
        {tabs?.map((tab, index) => (
          // eslint-disable-next-line
          <div
            onClick={() => {
              const params = new URLSearchParams(window.location.search);
              params.set('section', tab.link);
              setSearchParams(params, { replace: true });
            }}
            style={{ minWidth: '120px', maxWidth: '120px' }}
            className={classNames(
              'mr-6  cursor-pointer border-b-4 border-b-transparent py-1   pb-2 text-center  text-md text-background-contrastText ',
              {
                ' !border-b-primary-main ': section === tab.link,
              }
            )}>
            <span
              ref={
                index === 0
                  ? (startRef as LegacyRef<HTMLSpanElement>)
                  : (endRef as LegacyRef<HTMLSpanElement>)
              }>
              {tab.title}
            </span>
          </div>
        ))}
      </div>
      {scrollable && (
        <ChevronRightIcon
          onClick={() => {
            if (activeArrow === ArrowPosition.End) {
              handleClick(ScrollPosition.End);
            }
          }}
          className={classNames(
            ' -mr-6   h-[50px] w-[100px] cursor-pointer text-primary-contrastText ',
            {
              'opacity-20': activeArrow !== ArrowPosition.End,
            }
          )}
        />
      )}
    </div>
  );
}
