import React, { ReactNode, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { useDebounce } from 'hooks/useDebounce';
import { useProfile } from 'hooks/useProfile';
import { transformMedication } from 'pages/app/patients/[id]/prescriptions/prescribe/common';
import { PatientPrescriptionService } from 'services/prescription';
import { PatientDetailsContextType } from 'types/patient';
import { PrescriptionCheckType } from 'types/patient/prescription';
import {
  PrescribedObjectType,
  PrescriptionInputObjectType,
  PrescriptionInputTypes,
  RightFloatingChildProps,
} from 'types/prescriptionTypes';

import { DTDSelector } from './DTDSelector';
import { PrescriptionSlider } from './PrescriptionSlider';
import { PrescriptionStatusIndicator } from './PrescriptionStatusIndicator';
import { RadioSelector } from './RadioSelector';
import { SigGenerator } from './SigGenerator';
import { SIGTextInput } from './SIGTextInput';

interface HormoneDetailsProps {
  containerClassName?: string;
  title: string;
  onAdd: () => void;
  hormoneInputs: PrescriptionInputObjectType[];
  hormoneInfo: RightFloatingChildProps[];
  prescriptionObject?: PrescribedObjectType;
  statusUnit?: string;
  onPrescriptionValueChange?: (
    value: PrescriptionCheckType,
    renderChangeModal: (
      prevValue?: string,
      newValue?: string,
      unit?: string,
      label?: string
    ) => void
  ) => void;
  showStatus?: boolean;
  onPharmacyNoteChange?: (note: string) => void;
}
export function HormoneDetails({
  title,
  onAdd,
  hormoneInputs,
  hormoneInfo,
  prescriptionObject,
  statusUnit,
  onPrescriptionValueChange,
  containerClassName,
  showStatus = true,
  onPharmacyNoteChange,
}: HormoneDetailsProps) {
  const { profile } = useProfile();
  const { id, address } = useOutletContext<PatientDetailsContextType>();
  const [searchParams] = useSearchParams();

  const isHormoneControlled = useMemo(
    () => searchParams.get('controlled') === 'true',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, prescriptionObject?.id]
  );
  const hasDeaForShippingState = !!profile?.providerDetails.providerDea.find(
    (d) => d.stateId === address?.state?.id
  )?.dea;
  const [status, setStatus] = useState<PrescriptionCheckType>();
  const [sigError, setSigError] = useState(false);
  const [showChangeModal, setshowChangeModal] = useState<{
    state: boolean;
    text: ReactNode;
  }>({
    state: false,
    text: '',
  });
  // eslint-disable-next-line
  console.log(onPharmacyNoteChange);
  // const [showNoteOption, setShowNoteOption] = useState(false);
  // const getRefillQuery = useQuery(
  //   QueryKeys.PatientPrescriptionRefills.listing({
  //     id: id ?? '',
  //   }),
  //   () => PatientService.getPatientRefill(id ?? ''),
  //   { enabled: !!id }
  // );
  // const getPatientPrescriptionQuery = useQuery(
  //   QueryKeys.PatientPrescriptions.listing({
  //     category: 'current',
  //     id,
  //   }),
  //   () =>
  //     PatientService.getPatientPrescription({
  //       category: 'current',
  //       id,
  //     }),
  //   { enabled: !!id }
  // );

  const debouncedValue = useDebounce<PrescribedObjectType>(
    prescriptionObject as PrescribedObjectType,
    500
  );
  const isDebounceLoading =
    JSON.stringify(debouncedValue) !== JSON.stringify(prescriptionObject);

  function renderChangeModal(
    prevValue?: string,
    newValue?: string,
    unit?: string,
    label?: string
  ) {
    setshowChangeModal({
      state: true,
      text: label ?? (
        <>
          Note that the mg/ml has changed from{' '}
          <span className="font-bold text-primary-main">{prevValue}</span> to{' '}
          <span className="font-bold text-primary-main">{newValue}</span>.
          Please double-check that the{' '}
          <span className="font-bold text-background-contrastText ">
            {unit}
          </span>{' '}
          is correct.
        </>
      ),
    });
  }

  const checkPrescriptionMaxDoseValueQuery = useQuery(
    [debouncedValue, id],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: id,
        medication: transformMedication(
          prescriptionObject as PrescribedObjectType
        ),
      }),
    {
      enabled: !!id && !!prescriptionObject?.id,
      onSuccess: (data) => {
        onPrescriptionValueChange?.(data.data, renderChangeModal);
        setStatus(data.data);
      },
    }
  );

  const checkPrescriptionMinDoseValueQuery = useQuery(
    [
      id,
      prescriptionObject?.minimumDose,
      prescriptionObject?.strength ?? '',
      prescriptionObject?.dtd ?? '',
      prescriptionObject?.ratio ?? '',
    ],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: id,
        medication: transformMedication({
          ...prescriptionObject,
          dose: prescriptionObject?.minimumDose,
        } as PrescribedObjectType),
      }),
    {
      enabled: !!id && !!prescriptionObject?.minimumDose,
    }
  );
  const onHormoneAdd = () => {
    const sigInput = hormoneInputs.find(
      (h) =>
        h.type === PrescriptionInputTypes.SIG ||
        h.type === PrescriptionInputTypes.SigGenerator
    );
    if (
      sigInput?.type === PrescriptionInputTypes.SIG ||
      sigInput?.type === PrescriptionInputTypes.SigGenerator
    ) {
      if (!sigInput.value) {
        setSigError(true);

        return;
      }
    }
    onAdd();
  };

  // const showPharmacyNoteUI = () => {
  //   if (
  //     getPatientPrescriptionQuery.data?.data.some(
  //       (p) => p.prescriptionToMedication.id === prescriptionObject?.id
  //     )
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  // const showEligibleToOrderLabel = () => {
  //   const refillData = getRefillQuery.data?.data.refillProducts.find(
  //     (p) =>
  //       p.prescription.prescriptionToMedication.id === prescriptionObject?.id
  //   );

  //   return refillData?.eligibleToOrderAfter ?? '';
  // };

  const getPlaceOrderTooltip = () => {
    let text = '';

    if (
      status &&
      (!checkPrescriptionMaxDoseValueQuery.data?.data?.validDaysOfSupply ||
        !checkPrescriptionMaxDoseValueQuery.data?.data?.validRxQuality ||
        (checkPrescriptionMinDoseValueQuery.data?.data &&
          !checkPrescriptionMinDoseValueQuery.data?.data?.validRxQuality))
    ) {
      text = 'Prescription is outside parameters';
    }

    if (
      checkPrescriptionMaxDoseValueQuery.isFetching &&
      !!prescriptionObject?.id
    ) {
      text = 'Loading';
    }

    if (!hasDeaForShippingState && isHormoneControlled) {
      return `The DEA number is missing for the state of ${address?.state?.name}. Prescribing controlled substances without a DEA number is not permitted.`;
    }

    // if (showPharmacyNoteUI() && !prescriptionObject?.note) {
    //   text =
    //     "Please select note for pharmacy. You're trying to prescribe something before you're allowed to order it.";
    // }

    return text;
  };

  const isPlaceOrderButtonDisabled = useMemo(() => {
    if (!prescriptionObject?.id) {
      return false;
    }

    if (
      !checkPrescriptionMaxDoseValueQuery.data?.data?.validDaysOfSupply ||
      !checkPrescriptionMaxDoseValueQuery.data?.data?.validRxQuality ||
      (checkPrescriptionMinDoseValueQuery.data?.data &&
        !checkPrescriptionMinDoseValueQuery.data?.data?.validRxQuality) ||
      checkPrescriptionMaxDoseValueQuery?.isFetching
    ) {
      return true;
    }

    if (!hasDeaForShippingState && isHormoneControlled) {
      return true;
    }

    return false;
  }, [
    checkPrescriptionMaxDoseValueQuery.data?.data?.validDaysOfSupply,
    checkPrescriptionMaxDoseValueQuery.data?.data?.validRxQuality,
    checkPrescriptionMaxDoseValueQuery?.isFetching,
    checkPrescriptionMinDoseValueQuery.data?.data,
    isHormoneControlled,
    prescriptionObject?.id,
    hasDeaForShippingState,
  ]);

  function getInputs(
    item: PrescriptionInputObjectType,
    index: number
  ): ReactNode {
    const showFloatingChild = index < hormoneInfo?.length;

    const showMinStatusBox =
      !showFloatingChild &&
      hormoneInfo.length === index &&
      !!prescriptionObject?.id &&
      showStatus;
    const showStatusBox =
      !showFloatingChild &&
      hormoneInfo.length + 2 === index &&
      !!prescriptionObject?.id &&
      showStatus;

    const showSupplyBox =
      !showFloatingChild &&
      hormoneInfo.length + (showStatus ? 1 : 0) === index &&
      !!prescriptionObject?.id;
    switch (item.type) {
      case PrescriptionInputTypes.Slider:
        return (
          <div
            className={classNames(
              ' mt-14 grid h-8 grid-cols-12 items-center gap-x-8',
              {
                '!mt-0': index === 0,
              }
            )}>
            <PrescriptionSlider
              label={item.label}
              value={item.value}
              onChange={item.onChange}
              getTooltip={item.getTooltip}
              options={item.options}
              disable={item.disable}
            />

            {showFloatingChild && (
              <div className="col-span-3  text-left text-sm font-semibold">
                <Typography
                  className=" whitespace-nowrap"
                  variant="h5"
                  align="left">
                  {hormoneInfo[index].label}:
                </Typography>
                <div className="self-end">
                  <Typography variant="h5" className="ml-1" color="primary">
                    {hormoneInfo[index].value}
                  </Typography>
                </div>
              </div>
            )}

            {showMinStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={statusUnit}
                secondaryData={checkPrescriptionMinDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMinDoseValueQuery.isFetching ||
                  isDebounceLoading
                }
              />
            )}
            {showStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={`Maximum ${statusUnit}`}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isFetching ||
                  isDebounceLoading
                }
              />
            )}

            {showSupplyBox && (
              <PrescriptionStatusIndicator
                supply
                secondaryData={checkPrescriptionMinDoseValueQuery.data?.data}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isFetching ||
                  isDebounceLoading
                }
              />
            )}
          </div>
        );

      case PrescriptionInputTypes.SIG:
        return (
          <div
            className={classNames(
              'mt-10 grid grid-cols-12  items-center gap-x-8',
              {
                '!mt-0': index === 0,
              }
            )}>
            <SIGTextInput
              error={sigError}
              setError={setSigError}
              value={item.value}
              onChange={item.onChange}
              options={item.options}
            />
            {showFloatingChild && (
              <div className="col-span-3  text-left text-sm font-semibold">
                <Typography
                  className=" whitespace-nowrap"
                  variant="h5"
                  align="left">
                  {hormoneInfo[index].label}:
                </Typography>
                <div className="self-end">
                  <Typography variant="h5" className="ml-1" color="primary">
                    {hormoneInfo[index].value}
                  </Typography>
                </div>
              </div>
            )}
            {showMinStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={statusUnit}
                secondaryData={checkPrescriptionMinDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMinDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}
            {showStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={`Maximum ${statusUnit}`}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}{' '}
            {showSupplyBox && (
              <PrescriptionStatusIndicator
                supply
                secondaryData={checkPrescriptionMinDoseValueQuery.data?.data}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isFetching ||
                  isDebounceLoading
                }
              />
            )}
          </div>
        );

      case PrescriptionInputTypes.Text:
        return (
          <div
            className={classNames(
              'mt-10 mb-2 grid grid-cols-12  items-center gap-x-8',
              {
                '!mt-0': index === 0,
              }
            )}>
            <div className="col-span-2 text-left xl:col-span-1">
              <Typography variant="h5" className="">
                {item.label}
              </Typography>
            </div>
            <div className="col-span-7 xl:col-span-8">
              <Typography
                variant="h5"
                color="white"
                className="max-w-min whitespace-nowrap rounded bg-primary-dark  p-2">
                {item.value}
              </Typography>
            </div>
            {showFloatingChild && (
              <div className="col-span-3  text-left text-sm font-semibold">
                <Typography
                  className=" whitespace-nowrap"
                  variant="h5"
                  align="left">
                  {hormoneInfo[index].label}:
                </Typography>
                <div className="self-end">
                  <Typography variant="h5" className="ml-1" color="primary">
                    {hormoneInfo[index].value}
                  </Typography>
                </div>
              </div>
            )}
            {showMinStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={statusUnit}
                secondaryData={checkPrescriptionMinDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMinDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}
            {showStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={`Maximum ${statusUnit}`}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}

            {showSupplyBox && (
              <PrescriptionStatusIndicator
                supply
                secondaryData={checkPrescriptionMinDoseValueQuery.data?.data}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isFetching ||
                  isDebounceLoading
                }
              />
            )}
          </div>
        );

      case PrescriptionInputTypes.DTD:
        return (
          <div
            className={classNames(
              ' mt-6 grid grid-cols-12  items-center gap-x-8',
              {
                '!mt-0': index === 0,
              }
            )}>
            <DTDSelector
              label={item.label}
              value={item.value}
              DTDValues={item.DTDValues}
              onChange={item.onChange}
              unit={item.unit}
            />
            {showFloatingChild && (
              <div className="col-span-3   text-left text-sm font-semibold">
                <Typography
                  className=" whitespace-nowrap"
                  variant="h5"
                  align="left">
                  {hormoneInfo[index].label}:
                </Typography>
                <div className="self-end">
                  <Typography variant="h5" className="ml-1" color="primary">
                    {hormoneInfo[index].value}
                  </Typography>
                </div>
              </div>
            )}
            {showMinStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={statusUnit}
                secondaryData={checkPrescriptionMinDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMinDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}
            {showStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={`Maximum ${statusUnit}`}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}
            {showSupplyBox && (
              <PrescriptionStatusIndicator
                supply
                secondaryData={checkPrescriptionMinDoseValueQuery.data?.data}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isFetching ||
                  isDebounceLoading
                }
              />
            )}
          </div>
        );

      case PrescriptionInputTypes.Radio:
        return (
          <div
            className={classNames(
              ' mt-10 grid grid-cols-12  items-center gap-x-8',
              {
                '!mt-0': index === 0,
              }
            )}>
            <RadioSelector
              label={item.label}
              value={item.value}
              options={item.options}
              onChange={item.onChange}
            />
            {showFloatingChild && (
              <div className="col-span-3  text-left text-sm font-semibold">
                <Typography
                  className=" whitespace-nowrap"
                  variant="h5"
                  align="left">
                  {hormoneInfo[index].label}:
                </Typography>
                <div className="self-end">
                  <Typography variant="h5" className="ml-1" color="primary">
                    {hormoneInfo[index].value}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        );

      case 'Container':
        return (
          <div
            className={classNames(
              ' mt-10 grid grid-cols-12  items-center gap-x-8',
              {
                '!mt-0': index === 0,
              }
            )}>
            <div className="col-span-9" />
            {showFloatingChild && (
              <div className="col-span-3  text-left text-sm font-semibold">
                <Typography
                  className=" whitespace-nowrap"
                  variant="h5"
                  align="left">
                  {hormoneInfo[index].label}:
                </Typography>
                <div className="self-end">
                  <Typography variant="h5" className="ml-1" color="primary">
                    {hormoneInfo[index].value}
                  </Typography>
                </div>
              </div>
            )}
            {!showFloatingChild && hormoneInfo.length === index && (
              <PrescriptionStatusIndicator
                statusUnit={statusUnit}
                secondaryData={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}
            {showSupplyBox && (
              <PrescriptionStatusIndicator
                supply
                secondaryData={checkPrescriptionMinDoseValueQuery.data?.data}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isFetching ||
                  isDebounceLoading
                }
              />
            )}
          </div>
        );

      case PrescriptionInputTypes.SigGenerator:
        return (
          <div
            className={classNames(
              'mt-10 grid grid-cols-12  items-center gap-x-8',
              {
                '!mt-0': index === 0,
              }
            )}>
            <SigGenerator
              error={sigError}
              setError={setSigError}
              value={item.value}
              onChange={item.onChange}
              doseOptions={[
                ...item.doseOptions.slice(
                  item.doseValue,
                  item.doseOptions.length
                ),
              ]}
              sigUnit={item.sigUnit}
              unit={item.unit}
              doseValue={item.doseValue}
              maximumDoseValue={item.maximumDoseValue}
              maximumDoseOnChange={item.maximumDoseOnChange}
            />
            {showFloatingChild && (
              <div className="col-span-3  text-left text-sm font-semibold">
                <Typography
                  className=" whitespace-nowrap"
                  variant="h5"
                  align="left">
                  {hormoneInfo[index].label}:
                </Typography>
                <div className="self-end">
                  <Typography variant="h5" className="ml-1" color="primary">
                    {hormoneInfo[index].value}
                  </Typography>
                </div>
              </div>
            )}
            {showMinStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={statusUnit}
                secondaryData={checkPrescriptionMinDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMinDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}
            {showStatusBox && (
              <PrescriptionStatusIndicator
                statusUnit={`Maximum ${statusUnit}`}
                data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                isLoading={
                  checkPrescriptionMaxDoseValueQuery.isLoading ||
                  isDebounceLoading
                }
              />
            )}{' '}
            {showSupplyBox && (
              <div className="col-span-3  -mb-12 grid grid-cols-3   ">
                <PrescriptionStatusIndicator
                  className="mt-2"
                  supply
                  secondaryData={checkPrescriptionMinDoseValueQuery.data?.data}
                  data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                  isLoading={
                    checkPrescriptionMaxDoseValueQuery.isFetching ||
                    isDebounceLoading
                  }
                />

                <span className="mt-5" />
                <PrescriptionStatusIndicator
                  statusUnit={`Maximum ${statusUnit}`}
                  data={checkPrescriptionMaxDoseValueQuery?.data?.data}
                  isLoading={
                    checkPrescriptionMaxDoseValueQuery.isLoading ||
                    isDebounceLoading
                  }
                />
              </div>
            )}
          </div>
        );

      default:
        return '';
    }
  }

  return (
    <div className="relative flex h-full w-full  flex-col bg-background-main pt-6 pl-10 pr-6">
      <div className=" flex justify-between">
        <div>
          <Typography variant="h3" color="textPrimary">
            Prescribing
            <Typography
              variant="h3"
              color="white"
              className="mx-2 rounded-sm bg-primary-main px-2">
              {title}
            </Typography>
          </Typography>
        </div>
        {/* {!!showEligibleToOrderLabel() && (
          <Typography className="flex gap-1 rounded bg-background-light p-2">
            You are eligible to order this medication after{' '}
            {getReadableDate(showEligibleToOrderLabel())}
          </Typography>
        )} */}
      </div>
      <div className={classNames('mt-12 pb-4', containerClassName)}>
        {hormoneInputs?.map((item, index) => getInputs(item, index))}
      </div>
      {/* {showPharmacyNoteUI() && (
        <div
          className={classNames(
            'mt-6 mb-2 grid grid-cols-12  items-center gap-x-8'
          )}>
          <div className="col-span-2 text-left xl:col-span-1">
            <Typography variant="h6" className="">
              Pharmacy Note
            </Typography>
          </div>
          <div className=" col-span-7 ml-1 xl:col-span-8">
            {prescriptionObject?.note ? (
              <div className="relative">
                {' '}
                <TextInput
                  disabled
                  value={prescriptionObject.note}
                  fullWidth
                  disableHelperText
                  className="w-full text-ellipsis pr-12 "
                />
                <PencilSquareIcon
                  onClick={() => {
                    setShowNoteOption(true);
                  }}
                  className="absolute top-2 right-4 ml-1 h-6 cursor-pointer  text-zinc-400"
                />
              </div>
            ) : (
              <Button
                className=""
                variant="outlined"
                onClick={() => {
                  setShowNoteOption(true);
                }}>
                <div className="flex w-80 justify-between">
                  Select note for the pharmacy{' '}
                  <ChevronDownIcon id="DropDownbutton" height={20} />
                </div>
              </Button>
            )}
          </div>
        </div>
      )} */}

      {/* <Modal
        open={showNoteOption}
        className="w-2/3"
        title="Please select the most relevant note for pharmacy"
        closeModal={() => {
          //
        }}>
        <PharmacyNotes
          noteType="rx"
          defaultValue={prescriptionObject?.note}
          onSubmit={(note) => {
            onPharmacyNoteChange?.(note);
            setShowNoteOption(false);
          }}
          onClose={() => {
            setShowNoteOption(false);
          }}
        />
      </Modal> */}

      <div
        data-tooltip-id="add_prescription"
        className="absolute bottom-4 right-6">
        <Button
          className="!px-12"
          onClick={onHormoneAdd}
          disabled={isPlaceOrderButtonDisabled}>
          Add to cart
        </Button>
        {!!getPlaceOrderTooltip() && (
          <Tooltip className=" max-w-[40rem]" id="add_prescription">
            {getPlaceOrderTooltip()}
          </Tooltip>
        )}
      </div>
      <ConfirmationModal
        isModalOpen={showChangeModal.state}
        closeModal={() => {
          setshowChangeModal({ ...showChangeModal, state: false });
        }}>
        <div className="py-4">{showChangeModal.text}</div>
      </ConfirmationModal>
    </div>
  );
}
