import React, { useEffect, useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import {
  NavLink,
  Outlet,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';

import { QueryKeys } from 'constants/query-keys';
import { QuestionnaireService } from 'services/questionnaire';
import { PatientDetailsContextType } from 'types/patient';
import { QuestionnaireResponseType } from 'types/questionnaire';
import { getReadableDate } from 'utils/common';

const tabs = [
  {
    title: 'Initial Questionnaire',
    link: 'initial-questionnaire',
    disabled: false,
  },
  {
    title: 'Follow Up Questionnaire',
    link: 'followup-questionnaire',
    disabled: false,
  },
  // { title: 'Self Assessment', link: 'self-assessment', disabled: true },
  // {
  //   title: 'Annual Assessment',
  //   link: 'annual-assessment',
  //   disabled: true,
  // },
];

const getGender = {
  M: 'male',
  F: 'female',
};

export function Questionnaire() {
  const navigate = useNavigate();
  const [showFollowUpMenu, setShowFollowUpMenu] = useState(false);
  const [followUpId, setFollowUpId] = useState('sanket');
  const { gender, id, firstName, lastName, dob, address } =
    useOutletContext<PatientDetailsContextType>();

  // eslint-disable-next-line
  const getQuestionnaireResponsesQuery = useQuery(
    QueryKeys.PatientQuestionnaire.listing({ gender, id }),
    () =>
      QuestionnaireService.getQuestionnaireResponses({
        gender: getGender[gender],
        id,
      }),

    {
      enabled: !!id,
    }
  );
  // eslint-disable-next-line
  const getFollowUpQuestionnaireResponsesQuery = useQuery(
    QueryKeys.PatientFollowUpQuestionnaire.listing({ gender, id }),
    () =>
      QuestionnaireService.getFollowupQuestionnaireResponses({
        gender: getGender[gender],
        id,
      }),

    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (tabs[0]) {
      navigate({ pathname: tabs[0].link });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function navigateToFollowUp(i: QuestionnaireResponseType) {
    navigate({
      pathname: 'followup-questionnaire',
      search: `responseId=${i?.id as unknown as string}`,
    });
    setFollowUpId(i?.id as unknown as string);
  }

  return (
    <div className="mt-2 pb-8">
      <div className=" grid grid-cols-11 gap-2 ">
        <div className="col-span-2 flex flex-col  ">
          <NavLink
            onClick={() => {
              setShowFollowUpMenu(false);
              setFollowUpId('');
            }}
            to="initial-questionnaire"
            className={({ isActive }) =>
              classNames(
                'mb-[1px] justify-start rounded-t-sm  bg-background-light    py-3 pl-4  !text-lg text-background-contrastText hover:bg-primary-main',
                {
                  'bg-primary-main text-primary-contrastText hover:bg-primary-main':
                    !!isActive,
                }
              )
            }>
            <div className="flex flex-col">Initial Questionnaire</div>
          </NavLink>
          <div className="flex w-full flex-col items-center">
            <NavLink
              onClick={() => {
                // e.preventDefault();
                // e.stopPropagation();
                if (
                  getFollowUpQuestionnaireResponsesQuery.data?.data
                    .items?.[0] &&
                  !showFollowUpMenu
                ) {
                  navigateToFollowUp(
                    getFollowUpQuestionnaireResponsesQuery.data?.data.items?.[0]
                  );
                }
                if (showFollowUpMenu) {
                  setFollowUpId('');
                  navigate({
                    search: '',
                  });
                }
                setShowFollowUpMenu(!showFollowUpMenu);
              }}
              to={{
                pathname: 'followup-questionnaire',
                search: `responseId=${
                  showFollowUpMenu
                    ? (getFollowUpQuestionnaireResponsesQuery.data?.data
                        .items?.[0]?.id as unknown as string)
                      ? 'hide'
                      : ''
                    : (getFollowUpQuestionnaireResponsesQuery.data?.data
                        .items?.[0]?.id as unknown as string)
                }`,
              }}
              className={({ isActive }) =>
                classNames(
                  'mb-[1px]  flex w-full  justify-between  rounded-b-sm bg-background-light px-2    py-3 pl-4  !text-lg text-background-contrastText hover:bg-primary-main',
                  {
                    'bg-background-main text-primary-contrastText hover:bg-background-main ':
                      !!isActive,
                  }
                )
              }>
              Follow Up Questionnaire(s){' '}
              <ChevronDownIcon
                className={classNames(
                  'h-5 w-5 font-extrabold text-background-contrastText ',
                  {
                    '!rotate-180': showFollowUpMenu,
                  }
                )}
              />
            </NavLink>
            <div
              className={classNames(
                'flex h-0 w-full flex-col  overflow-auto transition-all duration-300  ',
                {
                  '!h-[30rem]': showFollowUpMenu,
                }
              )}>
              {getFollowUpQuestionnaireResponsesQuery.data?.data.items.map(
                (i) => (
                  // eslint-disable-next-line
                  <div
                    onClick={(e) => {
                      if (followUpId === (i?.id as unknown as string)) {
                        return;
                      }
                      e.preventDefault();
                      e.stopPropagation();
                      navigateToFollowUp(i);
                    }}
                    className={classNames(
                      'mb-[1px]  flex w-full  cursor-pointer justify-center  rounded-b-sm bg-background-light px-2    py-3 pr-4  !text-lg text-background-contrastText hover:bg-primary-main',
                      {
                        'bg-primary-main text-primary-contrastText  ':
                          followUpId === (i?.id as unknown as string),
                      }
                    )}>
                    {getReadableDate(i?.createdAt as unknown as string)}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="col-span-9 grid  ">
          <Outlet
            context={{
              id,
              gender,
              firstName,
              lastName,
              dob,
              address,
            }}
          />
        </div>
      </div>
    </div>
  );
}
