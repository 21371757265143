import { useState } from 'react';

import { maleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';

export const useTestosteroneCypeController = () => {
  const TestosteroneCypeDetails =
    maleHormoneRxTypes?.['testosterone-cyp-inj-200mg/ml'];
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(10);
  const { sig, setSig } = useSIG(
    '0.25 ml twice weekly by subcutaneous injection.'
  );
  const [note, setNote] = useState('');

  const [dose] = useState('0.25');
  const doseUnit = 'ml';
  const dtdUnit = 'ml';

  return {
    TestosteroneCypeDetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    refills,
    setRefills,
    dose,
    doseUnit,
    dtdUnit,
    note,
    setNote,
  };
};
