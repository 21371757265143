import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Gender, PatientDetailsContextType } from 'types/patient';
import { PrescribedObjectType } from 'types/prescriptionTypes';

import { BiEst } from '../hormones/BiEst';
import { Danazol } from '../hormones/Danazol';
import { DHEA } from '../hormones/DHEA';
import { DHEAOral } from '../hormones/DHEAOral';
import { Levothyroxine } from '../hormones/Levothyronine';
import { LiothyronineExt } from '../hormones/LiothyronineExt';
import { OralProgesterone } from '../hormones/OralProgesterone';
import { Pregnyl } from '../hormones/Pregnyl';
import { Progesterone } from '../hormones/Progesterone';
import { Sildenafil } from '../hormones/Sildenafil';
import { T4PerLAT3 } from '../hormones/T4PerLAT3';
import { Tadalafil } from '../hormones/Tadalafil';
import { TestoDHEA } from '../hormones/TestoDHEA';
import { TestosteroneCyp } from '../hormones/TestosteroneCyp';
import { TestosteroneMen } from '../hormones/TestosteroneMen';
import { TestosteroneWomen } from '../hormones/TestosteroneWomen';

type PrescriptionDetailsType = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function PrescriptionDetailsNew({
  addNewPrescription,
}: PrescriptionDetailsType) {
  const [searchParams] = useSearchParams();
  const rx = searchParams.get('rx');
  const { gender } = useOutletContext<PatientDetailsContextType>();
  // eslint-disable-next-line
  const femaleRXComponents: any = {
    'bi-est': <BiEst addNewPrescription={addNewPrescription} />,
    // estradiol: <Estradiol addNewPrescription={addNewPrescription} />,
    progesterone: <Progesterone addNewPrescription={addNewPrescription} />,
    'oral-micronized-progesterone': (
      <OralProgesterone addNewPrescription={addNewPrescription} />
    ),
    'testosterone/dhea': <TestoDHEA addNewPrescription={addNewPrescription} />,
    testosterone: <TestosteroneWomen addNewPrescription={addNewPrescription} />,
    dhea: <DHEA addNewPrescription={addNewPrescription} />,
    'dhea-oral': <DHEAOral addNewPrescription={addNewPrescription} />,
    'levothyroxine-(t4)': (
      <Levothyroxine addNewPrescription={addNewPrescription} />
    ),
    'liothyronine-extended-release-(lat3)': (
      <LiothyronineExt addNewPrescription={addNewPrescription} />
    ),
    't4/lat3': <T4PerLAT3 addNewPrescription={addNewPrescription} />,
    sildenafil: <Sildenafil addNewPrescription={addNewPrescription} />,
    tadalafil: <Tadalafil addNewPrescription={addNewPrescription} />,
    danazol: <Danazol addNewPrescription={addNewPrescription} />,
  };
  // eslint-disable-next-line
  const maleRXComponents: any = {
    testosterone: <TestosteroneMen addNewPrescription={addNewPrescription} />,

    'levothyroxine-(t4)': (
      <Levothyroxine addNewPrescription={addNewPrescription} />
    ),
    'liothyronine-extended-release-(lat3)': (
      <LiothyronineExt addNewPrescription={addNewPrescription} />
    ),
    't4/lat3': <T4PerLAT3 addNewPrescription={addNewPrescription} />,
    sildenafil: <Sildenafil addNewPrescription={addNewPrescription} />,
    tadalafil: <Tadalafil addNewPrescription={addNewPrescription} />,
    danazol: <Danazol addNewPrescription={addNewPrescription} />,
    'testosterone-cyp-inj-200mg/ml': (
      <TestosteroneCyp addNewPrescription={addNewPrescription} />
    ),
    'pregnyl-(injectable)(hcg)': (
      <Pregnyl addNewPrescription={addNewPrescription} />
    ),
  };
  function getRxHormoneComponent(rxHormone: string) {
    if (gender === Gender.Male) {
      return maleRXComponents[`${rxHormone}`];
    }

    return femaleRXComponents[`${rxHormone}`];
  }

  return getRxHormoneComponent(rx || '');
}
