import { useState } from 'react';

export const useBiEstController = () => {
  const [mgPerMl, setMgPerMl] = useState(10);
  const [deliveryMethod, setDeliveryMethod] = useState();
  const [sig, setSig] = useState(
    'Take 1-3 drops two times per day as directed'
  );
  const [drops, setDrops] = useState(1);
  const [refills, setRefils] = useState(0);

  const [E3E2Ratio, setE3E2Ratio] = useState(0);
  const DTD = 30;
  const dropsPerML = 20.4;

  const getCourseDuration = () => {
    const totalDrops = dropsPerML * DTD;
    const eachBottleDuration = totalDrops / 30 / drops;
    const refillsDuration = eachBottleDuration * (refills + 1);

    return {
      eachBottleDuration: eachBottleDuration.toFixed(1),
      durationWithRefills: refillsDuration.toFixed(1),
    };
  };

  return {
    mgPerMl,
    getCourseDuration,
    deliveryMethod,
    sig,
    setSig,
    E3E2Ratio,
    drops,
    refills,
    DTD,
    DTDInWords: `${DTD} ml`,
    setDeliveryMethod,
    setMgPerMl,
    setRefils,
    setDrops,
    setE3E2Ratio,
  };
};
