import React from 'react';

import { DisplaySlider } from 'components/common/Slider/DisplaySlider';
import { Typography } from 'components/common/Typography/Typography';
import { QuestionObject, QuestionTypes } from 'types/questionnaire';
import { formatPhoneNumber } from 'utils/common';

type QuestionDetailProps = {
  question: QuestionObject;
};

export function QuestionDetail({ question }: QuestionDetailProps) {
  function getQuestion(_question: QuestionObject) {
    switch (_question.type) {
      default:
        return (
          <Typography variant="subtitle2" color="textMuted">
            {_question.question.label}
          </Typography>
        );
    }
  }

  function getAnswer(_question: QuestionObject) {
    switch (_question.type) {
      case QuestionTypes.NUMBER_INPUT:
      case QuestionTypes.TEXT_BOX:
      case QuestionTypes.TEXT_INPUT:
        return (
          <Typography variant="subtitle2">
            {_question.answer?.text ?? 'NA'}
          </Typography>
        );

      case QuestionTypes.PHONE_NUMBER_INPUT:
        return (
          <Typography variant="subtitle2">
            {formatPhoneNumber(_question.answer?.text) || 'NA'}
          </Typography>
        );
      case QuestionTypes.RADIO_OPTIONS:
        return (
          <Typography variant="subtitle2">
            {_question.answer?.selectedOption ?? 'NA'}
          </Typography>
        );

      case QuestionTypes.SLIDER:
        return (
          <div className="mt-10 mb-4 px-4">
            <DisplaySlider
              min={_question.minValue}
              max={_question.maxValue}
              value={
                _question.answer?.text ? String(_question.answer?.text) : 'NA'
              }
            />
          </div>
        );

      case QuestionTypes.MULTI_INPUTS:
        return (
          <div className="pl-4 text-md">
            {_question.options.map((option) => (
              <div className="grid grid-cols-12">
                <Typography variant="subtitle2" className="col-span-3">
                  {`${option.label} `}{' '}
                </Typography>
                <Typography variant="subtitle2" className="col-span-4">
                  {`${option.answer?.text ?? 'NA'}`}
                </Typography>
              </div>
            ))}
          </div>
        );
      default:
        return 'NA';
    }
  }

  return (
    <div className="col-span-6 mt-2 flex flex-col justify-between py-2">
      <div>
        {getQuestion(question)}
        {getAnswer(question)}
      </div>
      <div className="my-1 mr-[20%] border-b-2 border-background-light" />
    </div>
  );
}
