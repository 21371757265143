import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';

export const useProfile = () => {
  const userProfileQuery = useQuery(
    [QueryKeys.UserProfile],
    UserService.getUserProfile,
    {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: true,
    }
  );

  return useMemo(
    () => ({
      userProfileQuery,
      isLoggedIn: !!userProfileQuery.data?.data.id,
      profile: userProfileQuery?.data?.data,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userProfileQuery]
  );
};
