import {
  Fragment,
  useState,
  useEffect,
  ReactNode,
  PropsWithChildren,
} from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { Button, Color, Variant } from 'components/common/Button/Button';

import { Typography } from '../Typography/Typography';

type ConfirmationModalProps = {
  isModalOpen: boolean;
  closeModal: (close: boolean) => void;
  handleConfirm?: (confirm: boolean) => void;
  title?: string;
  semiBoldTitle?: boolean;
  infoContent?: ReactNode;
  confirmText?: string;
  infoButtonCancelText?: string;
  wider?: boolean;
  confirmLoading?: boolean;
  onCancel?: () => void;
  buttonColor?: {
    confirmBtn?: Color;
    cancelBtn?: Color;
  };
  buttonVariant?: {
    confirmBtn?: Variant;
    cancelBtn?: Variant;
  };
  classes?: {
    title?: string;
    confirmBtn?: string;
    cancelBtn?: string;
    modal?: string;
  };
};

export function ConfirmationModal({
  isModalOpen,
  closeModal,
  handleConfirm,
  confirmText,
  confirmLoading,
  title,
  infoButtonCancelText,
  classes,
  infoContent,
  children,
  semiBoldTitle = false,
  wider,
  buttonVariant,
  onCancel,
  buttonColor,
}: PropsWithChildren<ConfirmationModalProps>) {
  const [isOpen, setIsOpen] = useState(isModalOpen);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="light relative z-50"
        onClose={() => closeModal(true)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Dialog.Overlay className="fixed inset-0 bg-background-main bg-opacity-20 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel
                className={classNames(
                  ' w-2/5 transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all ',
                  {
                    '!w-7/12': !!wider,
                  },
                  classes?.modal
                )}>
                <Dialog.Title>
                  <Typography
                    className={classNames(classes?.title)}
                    variant={semiBoldTitle ? 'subtitle1' : 'h3'}>
                    {title}
                  </Typography>
                </Dialog.Title>
                {children ?? (
                  <div className="mt-2">
                    <Typography variant="subtitle2">{infoContent}</Typography>
                  </div>
                )}

                <div
                  className={classNames('mt-6  flex', {
                    'justify-end': confirmText,
                    'justify-center': !confirmText,
                  })}>
                  <Button
                    variant={buttonVariant?.cancelBtn || 'contained'}
                    color={buttonColor?.cancelBtn || 'secondary'}
                    className={classNames(classes?.cancelBtn)}
                    onClick={() => onCancel?.() ?? closeModal(true)}>
                    {infoButtonCancelText || 'Close'}
                  </Button>{' '}
                  <span className="mx-2" />
                  {confirmText && (
                    <Button
                      variant={buttonVariant?.confirmBtn || 'contained'}
                      color={buttonColor?.confirmBtn || 'primary'}
                      className={classNames(classes?.confirmBtn)}
                      onClick={() => handleConfirm?.(true)}
                      loading={confirmLoading}>
                      {confirmText || 'Confirm'}
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
