import React, { useMemo } from 'react';

import { Gender, HormoneSelectionProps } from 'types/patient';

import { BiEst } from './Hormones/BiEst';
import { Danazol } from './Hormones/Danazol';
import { DHEA } from './Hormones/DHEA';
import { DHEAOral } from './Hormones/DHEAOral';
import { Levothyroxine } from './Hormones/Levothyroxine';
import { LiothyronineExt } from './Hormones/LiothyronineExt';
import { OralProgesterone } from './Hormones/OralProgesterone';
import { Pregnyl } from './Hormones/Pregnyl';
import { Progesterone } from './Hormones/Progesterone';
import { Sildenafil } from './Hormones/Sildenafil';
import { T4PerLAT3 } from './Hormones/T4PerLAT3';
import { Tadalafil } from './Hormones/Tadalafil';
import { TestoDHEA } from './Hormones/TestoDHEA';
import { TestosteroneCyp } from './Hormones/TestosteroneCyp';
import { TestosteroneMen } from './Hormones/TestosteroneMen';
import { TestosteroneWomen } from './Hormones/TestosteroneWomen';

export function HormoneSelection(props: HormoneSelectionProps) {
  //
  const RxHormoneComponent = useMemo(() => {
    if (props.gender === Gender.Male) {
      switch (props.RxDetails?.name) {
        case 'testosterone':
          return <TestosteroneMen {...props} />;
        case 'levothyroxine-(t4)':
          return <Levothyroxine {...props} />;
        case 'liothyronine-extended-release-(lat3)':
          return <LiothyronineExt {...props} />;
        case 't4/lat3':
          return <T4PerLAT3 {...props} />;
        case 'sildenafil':
          return <Sildenafil {...props} />;
        case 'tadalafil':
          return <Tadalafil {...props} />;
        case 'danazol':
          return <Danazol {...props} />;
        case 'testosterone-cyp-inj-200mg/ml':
          return <TestosteroneCyp {...props} />;
        case 'pregnyl-(injectable)(hcg)':
          return <Pregnyl {...props} />;
        default:
          return <>No hormone UI found in Male</>; // or some fallback component
      }
    } else {
      switch (props.RxDetails?.name) {
        case 'bi-est':
          return <BiEst {...props} />;
        // case 'estradiol':
        //   return <Estradiol addNewPrescription={addNewPrescription} />;
        case 'progesterone':
          return <Progesterone {...props} />;
        case 'oral-micronized-progesterone':
          return <OralProgesterone {...props} />;
        case 'testosterone/dhea':
          return <TestoDHEA {...props} />;
        case 'testosterone':
          return <TestosteroneWomen {...props} />;
        case 'dhea':
          return <DHEA {...props} />;
        case 'dhea-oral':
          return <DHEAOral {...props} />;
        case 'levothyroxine-(t4)':
          return <Levothyroxine {...props} />;
        case 'liothyronine-extended-release-(lat3)':
          return <LiothyronineExt {...props} />;
        case 't4/lat3':
          return <T4PerLAT3 {...props} />;
        case 'sildenafil':
          return <Sildenafil {...props} />;
        case 'tadalafil':
          return <Tadalafil {...props} />;
        case 'danazol':
          return <Danazol {...props} />;
        default:
          return <>No hormone UI found in Female</>; // or some fallback component
      }
    }
  }, [props]);

  return RxHormoneComponent;
}
