import React from 'react';

type DisplaySliderProps = {
  min?: string | number;
  max?: string | number;
  value?: string | number;
};

export function DisplaySlider({ min, max, value }: DisplaySliderProps) {
  return (
    <div className="text-md text-background-contrastText">
      <div className="h-2.5 w-full rounded-full bg-background-contrastText ">
        <div
          className="relative flex w-0"
          style={{
            width: `${
              Number(value) > 0 ? (Number(value) / Number(max)) * 100 : 0
            }%`,
          }}>
          <div
            className="h-2.5 rounded-full bg-primary-light"
            style={{ width: '100%' }}
          />
          <div className="absolute -right-2 -top-6 mb-4 text-md font-bold">
            {value}
          </div>
        </div>
      </div>
      <div className="mb-1 flex justify-between">
        <span className="text-md font-medium  ">{min}</span>
        <span className="text-md font-medium  ">{max}</span>
      </div>
    </div>
  );
}
