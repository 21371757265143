import React, { useCallback, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Note } from 'components/patient/notes/Note';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';

export function PatientNotes() {
  const queryClient = useQueryClient();
  const [note, setNote] = useState<string>('');
  const { id } = useOutletContext<PatientDetailsContextType>();
  const addNoteMutation = useMutationWithToast(PatientService.addPatientNote);

  const getNotesQuery = usePaginatedQuery(
    QueryKeys.PatientNotes.item(id as string),
    ({ pageParam = 1 }) =>
      PatientService.getPatientNotes({ id, page: pageParam }),
    { enabled: !!id }
  );

  const addNote = () => {
    addNoteMutation.mutate(
      { id, note },
      {
        onSuccess: () => {
          setNote('');
          queryClient.refetchQueries(QueryKeys.PatientNotes.item(id));
        },
      }
    );
  };

  const onNotesScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = event.currentTarget;
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        getNotesQuery.fetchNextPage();
      }
    },
    [getNotesQuery]
  );

  return (
    <div className="mt-2 grid min-h-[25rem]  grid-cols-12 rounded-b-md bg-background-main ">
      <div className="col-span-6  p-8 ">
        <TextInput
          disabled={addNoteMutation?.isLoading}
          value={note}
          onChange={(v) => setNote(v)}
          className="!placeholder:text-md resize-none border border-background-contrastText !text-md  "
          placeholder="Add notes related to this patient"
          multiline
          rows={18}
          fullWidth
          disableHelperText
        />
        <div className="mt-2 flex justify-end ">
          <Button
            loading={addNoteMutation?.isLoading}
            onClick={addNote}
            disabled={!note}
            className="px-16 py-2 ">
            Add
          </Button>
        </div>
      </div>
      <div
        className=" col-span-6 m-8 h-[25rem] overflow-auto"
        onScroll={onNotesScroll}>
        {getNotesQuery?.data?.pages?.map((item) => (
          <Note
            className="mb-6 rounded-md bg-background-light p-3"
            key={item.id}
            text={item.note}
            date={item.createdAt}
          />
        ))}
        {getNotesQuery.isFetchingNextPage || getNotesQuery.isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner className="text-primary-main" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
