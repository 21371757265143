import React from 'react';

import { Control, FieldValues } from 'react-hook-form';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';

interface PasswordDetailsProps<OnboardProviderFormType extends FieldValues> {
  control: Control<OnboardProviderFormType>;
}
export function PasswordDetails<OnboardProviderFormType extends FieldValues>({
  control,
}: PasswordDetailsProps<OnboardProviderFormType>) {
  return (
    <div className="grid grid-cols-12 gap-x-4 ">
      <div className=" col-span-12 flex justify-between">
        <Typography variant="h5">Password</Typography>
      </div>
      <div className=" col-span-6 mt-3 flex gap-x-4">
        <TextInput
          type="password"
          name="password"
          control={control}
          placeholder="Password"
          fullWidth
        />

        <TextInput
          type="password"
          name="confirmPassword"
          control={control}
          placeholder="Confirm Password"
          fullWidth
        />
      </div>
    </div>
  );
}
