export enum QuestionTypes {
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_BOX = 'TEXT_BOX',
  NUMBER_INPUT = 'NUMBER_INPUT',
  PHONE_NUMBER_INPUT = 'PHONE_NUMBER_INPUT',
  CATEGORY_LABEL = 'CATEGORY_LABEL',
  MULTI_INPUTS = 'MULTI_INPUTS',
  RADIO_OPTIONS = 'RADIO_OPTIONS',
  SLIDER = 'SLIDER',
}

export type SectionKeys =
  | 'generalHealthHistoryPart1'
  | 'generalHealthHistoryPart2'
  | 'initialSelfAssessment'
  | 'currentHealthOverview'
  | 'femaleHealthHistoryPart1'
  | 'femaleHealthHistoryPart2'
  | 'hormonalSymptoms'
  | 'generalSymptomsPart1'
  | 'generalSymptomsPart2'
  | 'generalSymptomsPart3'
  | 'generalHealthHistory'
  | 'currentHealthOverviewPart1'
  | 'currentHealthOverviewPart2'
  | 'hormoneRelatedSymptomsPart1'
  | 'hormoneRelatedSymptomsPart2'
  | 'generalSymptomsPart4';

type Question = {
  id: string;
  label: string;
  parentQuestionId?: number;
  parentQuestionResponse?: string;
  condition?: string;
};

interface Inputs {
  placeholder?: string;
  noOfLines?: number;
  steps?: number;
}

export type TextBasedAnswer = {
  text: string;
};

export type OptionBasedAnswer = {
  selectedOption: string;
};

interface RadioOptions {
  label: string;
  type: keyof typeof QuestionTypes;
  inputs: Inputs;
}

interface MultiInputsOptions extends RadioOptions {
  answer?: TextBasedAnswer;
}
export type QuestionObject =
  | {
      section: {
        label: string;
        apiKey: SectionKeys;
        otherInformation: string;
        answer?: TextBasedAnswer;
      };
      category?: { label: string };
      inputs: Inputs;
      question: Question;
    } & (
      | {
          type: QuestionTypes.RADIO_OPTIONS;
          answer?: OptionBasedAnswer;
          options: RadioOptions[];
        }
      | {
          type: QuestionTypes.MULTI_INPUTS;
          options: MultiInputsOptions[];
        }
      | {
          type:
            | QuestionTypes.NUMBER_INPUT
            | QuestionTypes.PHONE_NUMBER_INPUT
            | QuestionTypes.TEXT_BOX
            | QuestionTypes.TEXT_INPUT;

          answer?: TextBasedAnswer;
        }
      | {
          type: QuestionTypes.SLIDER;
          answer?: TextBasedAnswer;
          minValue: number;
          maxValue: number;
        }
    );

export type QuestionnaireResponseType = {
  [s in SectionKeys as string]: QuestionObject[];
};
export type Tab = {
  title: string;
  link: string;
};
export type QuestionnaireCategoryType = {
  label: string;
  questions: QuestionObject[];
};

export type SummaryQuestionType = {
  [key: string]: QuestionObject[];
};

export type SummaryQuestionStructureType = {
  [key: string]: { label: string; id: string }[];
};
