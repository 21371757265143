import { ColorType } from 'types/patient/e-prescribe';

export const colorObjForCheckPrescription: ColorType = {
  Good: 'bg-[#359583]',
  Warning: 'bg-[#ccc500]',
  Bad: 'bg-[#EC6761]',
};

export const colorObjForSupply: ColorType = {
  true: 'bg-[#359583]',
  false: 'bg-[#EC6761]',
};
