import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';

import { SIGDropdown } from './SIGDropdown';

export type SIGTextInputProps = {
  value: string;
  onChange: (val: string) => void;
  options?: string[];
  error?: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  showLabel?: boolean;
  multiline?: boolean;
  helperText?: string;
};
export function SIGTextInput({
  value,
  onChange,
  options,
  error,
  setError,
  showLabel = true,
  multiline = false,
  helperText,
}: SIGTextInputProps) {
  const [showSigAlterPopup, setShowSigAlterPopup] = useState({
    state: false,
    shown: false,
  });
  useEffect(() => {
    if (error) {
      if (value) {
        setError(false);
      }
    }
  }, [value, setError, error]);

  const onChangeHandler = (val: string, type?: string) => {
    if (showSigAlterPopup?.shown || type === 'Selected') {
      onChange(val);
    } else {
      setShowSigAlterPopup({
        state: true,
        shown: true,
      });
    }
  };

  return (
    <>
      {showLabel && (
        <div className="col-span-2 text-left xl:col-span-1">
          <Typography variant="h5" className="mb-6">
            SIG
          </Typography>
        </div>
      )}
      <div className="col-span-7 xl:col-span-8">
        {options ? (
          <SIGDropdown
            value={value}
            onChange={onChangeHandler}
            options={options}
            error={error}
            helperText={error ? 'SIG is required' : ''}
          />
        ) : (
          <TextInput
            multiline={multiline ? true : undefined}
            rows={4}
            value={value}
            onChange={onChangeHandler}
            fullWidth
            className="pl-6 pt-6"
            error={error}
            helperText={error ? helperText : ''}
            placeholder="Please enter sig"
          />
        )}
      </div>
      <Modal
        closeModal={() => {
          //
        }}
        open={showSigAlterPopup.state}>
        <div
          className={classNames(
            ' m-2 flex  w-full  flex-col items-center overflow-y-hidden '
          )}>
          Altering the SIG is likely to lead to prescription rejection. Please
          communicate specific instructions with your patient directly.
          <Button
            onClick={() => {
              setShowSigAlterPopup({
                state: false,
                shown: true,
              });
            }}
            className="mt-4 w-20">
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}
