import React from 'react';

import { usePregnylController } from 'hooks/hormones-new/usePregnylController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type PregnylProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function Pregnyl({ addNewPrescription }: PregnylProps) {
  const {
    PregnylDetails,

    sig,
    setSig,

    refills,
    setRefills,
    dose,
    dtd,
    doseUnit,
    dtdUnit,
    setDTD,
    note,
    setNote,
  } = usePregnylController();
  const prescriptionObject: PrescribedObjectType = {
    displayName: PregnylDetails.displayName,
    name: HormoneNames.Pregnyl,
    strength: PregnylDetails.strength,
    strengthUnit: PregnylDetails.strengthUnit,

    refills,
    dose,
    dtd,

    sig,
    doseUnit,
    dtdUnit,
    deliveryMethod: PregnylDetails.deliveryMethod,
    family: PregnylDetails.family,
    note,
  };

  return (
    <HormoneDetails
      showStatus={false}
      prescriptionObject={prescriptionObject}
      title={PregnylDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: PregnylDetails.refills,
          getTooltip: (val) => `${PregnylDetails.refills[val]} refills`,
        },

        {
          type: PrescriptionInputTypes.SIG,
          value: sig,
          onChange(val) {
            setSig(val);
          },
        },
        {
          type: PrescriptionInputTypes.DTD,
          value: dtd,
          onChange(val) {
            setDTD(val);
          },
          DTDValues: [10],
          unit: dtdUnit,
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: PregnylDetails.deliveryMethod,
        },
        {
          label: 'Strength',
          value: `${PregnylDetails.strength} ${PregnylDetails.strengthUnit}`,
        },
        {
          label: 'Dose',
          value: `${dose} ${doseUnit}`,
        },
      ]}
    />
  );
}
