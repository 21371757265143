import { Fragment, useEffect, useState } from 'react';

import { Popover, Transition } from '@headlessui/react';
import {
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AiOutlineCopy } from 'react-icons/ai';
import QRCode from 'react-qr-code';
import { NavLink } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { SwitchPracticeModal } from 'components/practice/SwitchPracticeModal/SwitchPracticeModal';
import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { removeAccessAndRefreshTokens } from 'lib/general/token';
import { UserService } from 'services/user';
import { copyToClipboard } from 'utils/common';

import { Avatar } from '../Avatar/Avatar';

export function AvatarDropdown() {
  const { profile } = useProfile();
  const queryClient = useQueryClient();
  const [showPracticeSwitchModal, setShowPracticeSwitchModal] = useState(false);
  const [inviteCode, setInviteCode] = useState<string>();
  const [inviteLink, setInviteLink] = useState<string>();
  const getProviderPracticeAddressesMutation = useMutation(
    QueryKeys.ProviderPracticeAddresses.listing({
      id: profile?.id,
    }),
    (id: string) => UserService.getProviderPracticeAddresses(id)
  );
  const handleLogout = () => {
    removeAccessAndRefreshTokens();
    queryClient.refetchQueries([QueryKeys.UserProfile]);
    window.location.replace(
      `${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout`
    );
  };

  const navLinks = [
    {
      name: 'Profile',
      href: '/app/user/profile',
      icon: UserCircleIcon,
    },
    // {
    //   name: 'Setting',
    //   href: '/user/setting',
    //   icon: Cog6ToothIcon,
    // },
  ];
  useEffect(() => {
    if (profile?.practice?.id) {
      getProviderPracticeAddressesMutation.mutate(profile?.practice.id ?? '', {
        onSuccess: (data) => {
          const providerUniqueId = data.data.find(
            (i) => i.address.id === profile.practiceAddress.id
          );

          setInviteCode(providerUniqueId?.providerCode || '');
          setInviteLink(providerUniqueId?.providerDeeplink || '');
        },
      });
    }
    // eslint-disable-next-line
  }, [profile]);

  const onImageDownload = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg as Node);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${profile?.firstName}${profile?.lastName}QRCode`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div className="AvatarDropdown z-40 outline-none">
      <Popover className="relative">
        {({ close }) => (
          <>
            <Popover.Button className="inline-flex items-center space-x-3 rounded-md !outline-none    ">
              <Avatar
                imgUrl={profile?.pictureUrl}
                userName={profile?.firstName}
                sizeClass="w-8 h-8"
              />
              <h1 className="font-bold text-background-contrastText ">
                {`${profile?.firstName} ${profile?.lastName}`}
              </h1>
              <ChevronDownIcon className="h-5 w-5 font-extrabold text-background-contrastText " />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute top-10 z-10  mt-3 w-screen max-w-[300px] sm:-right-5 sm:px-0 lg:-right-8 ">
                <div className="overflow-visible rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 ">
                  <div className="relative grid gap-6 bg-background-main bg-opacity-95 p-6  backdrop-blur">
                    <div className="flex flex-col gap-y-4 ">
                      {' '}
                      {/* <Avatar
                        containerClassName="self-center"
                        imgUrl={profile?.practice.logoUrl ?? undefined}
                        userName={profile?.practice?.name}
                        sizeClass="h-20 w-20"
                      /> */}
                      <div className="flex flex-col gap-y-2 ">
                        <Typography color="textPrimary" variant="h5">
                          Your current Practice & Location
                        </Typography>
                        <div className="flex flex-col gap-y-2 rounded bg-background-light p-2 ">
                          <Typography color="white" variant="h5">
                            {profile?.practice?.name}
                          </Typography>

                          <Typography
                            color="white"
                            className="italic"
                            variant="subtitle2">
                            {`${`${
                              profile?.practiceAddress?.addressLine1
                            }, ${`${
                              profile?.practiceAddress?.addressLine2
                                ? `${profile?.practiceAddress?.addressLine2},`
                                : ''
                            }`} ${profile?.practiceAddress?.city}, ${
                              profile?.practiceAddress?.state?.name
                            } ${profile?.practiceAddress?.zipCode}, ${
                              profile?.practiceAddress?.country
                            }.`}`}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex flex-col  ">
                        <Typography color="textPrimary" variant="h5">
                          QR code
                        </Typography>
                        <div className="mt-2 flex flex-col items-center gap-2 rounded bg-background-light p-4">
                          <QRCode
                            id="QRCode"
                            bgColor="#262626"
                            fgColor="#ffffff"
                            // fgColor="#359583"
                            size={256}
                            style={{
                              height: 'auto',

                              width: '70%',
                            }}
                            value={inviteLink ?? ''}
                          />
                          <Button
                            onClick={() => onImageDownload()}
                            size="small"
                            variant="outlined"
                            className="w-full ">
                            Download
                            {/* <ArrowDownTrayIcon className="h-5 w-5 font-extrabold text-primary-light " /> */}
                          </Button>
                          <div className=" grid grid-cols-12">
                            <Typography
                              data-tooltip-id="qrLink"
                              ellipsis
                              className="  col-span-10  rounded  bg-background-light p-2"
                              color="white"
                              variant="h5">
                              {inviteLink ?? ''}
                            </Typography>
                            <Tooltip id="qrLink">{inviteLink ?? ''}</Tooltip>
                            <button
                              className="ml-2"
                              type="button"
                              onClick={() => {
                                copyToClipboard(inviteLink ?? '');
                              }}>
                              <AiOutlineCopy className="text-2xl text-background-contrastText active:scale-90" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-y-2 ">
                        <Typography color="textPrimary" variant="h5">
                          Your 6 digit unique code for this Practice & Location
                        </Typography>
                        <div className="flex">
                          <Typography
                            className="rounded bg-background-light p-2"
                            color="white"
                            variant="h5">
                            {inviteCode}
                          </Typography>
                          <button
                            className="ml-2"
                            type="button"
                            onClick={() => {
                              copyToClipboard(inviteCode ?? '');
                            }}>
                            <AiOutlineCopy className="text-2xl text-background-contrastText active:scale-90" />
                          </button>
                        </div>

                        <Typography color="white" variant="subtitle3">
                          To share the app with your new patients, either invite
                          them from the patient&apos;s tab or share the number
                          above. This number is unique both to you and this
                          practice&apos;s location.
                        </Typography>
                      </div>
                      <Button
                        onClick={() => {
                          setShowPracticeSwitchModal(true);
                        }}>
                        Switch
                      </Button>
                    </div>

                    <span className="   h-[2px] bg-background-light " />
                    {navLinks.map((item) => (
                      <NavLink
                        key={item.href}
                        to={item.href}
                        className="-m-3 flex items-center rounded p-2 transition duration-150 ease-in-out hover:bg-background-light focus:outline-none  focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 active:bg-background-main dark:text-white"
                        onClick={() => {
                          close();
                        }}>
                        <div className="flex flex-shrink-0 items-center justify-center text-background-contrastText">
                          <item.icon
                            aria-hidden="true"
                            className="h-5 w-5 text-background-contrastText"
                          />
                        </div>
                        <div className="ml-4 text-background-contrastText">
                          <p className=" font-medium">{item.name}</p>
                        </div>
                      </NavLink>
                    ))}
                    <NavLink
                      to="#"
                      className="-m-3 flex items-center rounded p-2 transition duration-150 ease-in-out hover:bg-background-light focus:outline-none  focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 active:bg-background-main dark:text-white"
                      onClick={() => {
                        handleLogout();
                        close();
                      }}>
                      <div className="flex flex-shrink-0 items-center justify-center  ">
                        <ArrowLeftOnRectangleIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-background-contrastText"
                        />
                      </div>
                      <div className="ml-4 ">
                        <p className=" font-medium text-background-contrastText">
                          Logout
                        </p>
                      </div>
                    </NavLink>
                  </div>
                  {/* <hr className="h-[1px] border-t border-secondary-300 dark:border-secondary-700" /> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <SwitchPracticeModal
        open={showPracticeSwitchModal}
        onClose={() => {
          setShowPracticeSwitchModal(false);
        }}
      />
    </div>
  );
}
