import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useBiEstController } from 'hooks/hormones-new/useBiEstController';
import {
  HormoneNames,
  PrescribedObjectType,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';

import { HormoneDetails } from '../HormoneDetails';

type BiEstProps = {
  addNewPrescription: (prescribedObject: PrescribedObjectType) => void;
};

export function BiEst({ addNewPrescription }: BiEstProps) {
  const [searchParams] = useSearchParams();

  const medicationId = searchParams.get('id');
  const {
    BiEstDetails,

    sig,
    setSig,
    dtd,
    strength,
    setStrength,
    ratio,
    setRatio,
    dose,
    setDose,
    refills,
    setRefills,
    maximumDose,
    setMaximumDose,
    setDTD,
    note,
    setNote,
  } = useBiEstController();

  const prescriptionObject: PrescribedObjectType = {
    id: medicationId || undefined,
    displayName: BiEstDetails.displayName,
    name: HormoneNames.BI_EST,
    strength: `${strength}`,
    strengthUnit: BiEstDetails.strengthUnit,
    ratio: BiEstDetails.ratio[ratio],
    ratioUnit: BiEstDetails.ratioUnit,
    dose: BiEstDetails.dose[dose + maximumDose],
    minimumDose: BiEstDetails.dose[dose],
    doseUnit: BiEstDetails.doseUnit,
    refills,
    dtd,
    sig,
    deliveryMethod: BiEstDetails.deliveryMethod,
    family: BiEstDetails.family,
    note,
  };

  return (
    <HormoneDetails
      containerClassName="mt-4"
      onPrescriptionValueChange={(p, renderModal) => {
        if (BiEstDetails.ratio[ratio] === '80:20') {
          if (Number(p.value) > 4.05) {
            if (strength !== 60) {
              renderModal(strength.toString(), '60', 'mgeeq/day');
              setDose(4);
            }
            setStrength(60);
          } else {
            setStrength(30);
            if (strength !== 30) {
              renderModal(strength.toString(), '30', 'mgeeq/day');
            }
          }
        }
      }}
      prescriptionObject={prescriptionObject}
      statusUnit={BiEstDetails.statusUnit}
      title={BiEstDetails.displayName}
      onAdd={() => {
        addNewPrescription(prescriptionObject);
      }}
      onPharmacyNoteChange={(t) => {
        setNote(t);
      }}
      hormoneInputs={[
        {
          type: PrescriptionInputTypes.Text,
          label: 'Strength',
          value: `${strength} ${BiEstDetails.strengthUnit}`,
        },

        {
          type: PrescriptionInputTypes.Slider,
          label: 'Ratio',
          value: ratio,
          onChange(value) {
            setRatio(value);
            if (BiEstDetails.ratio[value] !== '80:20') {
              setStrength(30);
            }
          },
          options: BiEstDetails.ratio,
          // eslint-disable-next-line react/no-unstable-nested-components
          getTooltip: (val) => (
            <span className="uppercase">
              {`${BiEstDetails.ratioUnit}(${BiEstDetails.ratio[val]})`}
            </span>
          ),
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Dose',
          value: dose,
          onChange(value) {
            setDose(value);
          },
          options: BiEstDetails.dose,
          getTooltip: (val) =>
            `${`${BiEstDetails.dose[val]} ${BiEstDetails.doseUnit}`}`,
        },
        {
          type: PrescriptionInputTypes.Slider,
          label: 'Refills',
          value: refills,
          onChange(value) {
            setRefills(value);
          },
          options: BiEstDetails.refills,
          getTooltip: (val) => `${BiEstDetails.refills[val]}  refills`,
        },

        {
          type: PrescriptionInputTypes.SigGenerator,
          value: sig,
          onChange(val) {
            setSig(val);
          },
          doseOptions: BiEstDetails.dose,
          doseValue: dose,
          maximumDoseValue: maximumDose,
          maximumDoseOnChange(val) {
            setMaximumDose(val);
            if (BiEstDetails.dose[val + dose] > 0.15) {
              setDTD(30);
            } else {
              setDTD(15);
            }
          },
        },
      ]}
      hormoneInfo={[
        {
          label: 'Delivery Method',

          value: BiEstDetails.deliveryMethod,
        },
        {
          label: 'D.T.D',
          value: `${dtd.toString()} ml`,
        },
      ]}
    />
  );
}
