import React, { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Overlay } from 'components/common/Overlay/Overlay';
import { SearchInput } from 'components/common/SearchInput/SearchInput';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useSearchParamsPagination } from 'hooks/useSearchParamsPagination';
import { PatientService } from 'services/patient';
import { PatientNS } from 'services/patient/types';

const gender = {
  M: 'Male',
  F: 'Female',
  blank: '',
};

export function InProgressPatients() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);

  const [archivePatient, setArchivePatient] = useState({
    state: false,
    id: '',
    hasSubscription: false,
  });
  const [search, setSearch] = useState<string>('');
  const [showBiggerImage, setShowBiggerImage] = useState({
    state: false,
    data: '',
  });

  const { page, setPage } = useSearchParamsPagination({
    key: 'inprogress_pt_page',
  });

  const activatePatientMutation = useMutationWithToast(
    PatientService.switchUserActive
  );

  const getPatientsQuery = useQuery(
    QueryKeys.Patients.listing({
      page,
      search,
      size: recordsPerPage,
      isTodoCompleted: 'false',
      archived: 'no',
    }),
    () =>
      PatientService.getPatientsV2({
        page,
        search,
        isTodoCompleted: false,
        archived: false,
        size: recordsPerPage,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleRowClick = (item: PatientNS.PatientInfo) => {
    navigate(`../${item?.id}/profile-completion`);
  };

  function refetchAndClose() {
    queryClient.refetchQueries(
      QueryKeys.Patients.listing({
        page,
        search,
        archived: 'no',
        isTodoCompleted: 'false',
      })
    );
    setArchivePatient({ state: false, id: '', hasSubscription: false });
  }

  const handleArchivePatient = () => {
    activatePatientMutation.mutate(
      { id: archivePatient.id, isActive: false },
      {
        onSuccess() {
          refetchAndClose();
        },
      }
    );
  };

  useEffect(() => {
    if (!search) return;
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="col-span-6 ">
      <span className="mb-2 flex  items-center justify-end text-base ">
        <SearchInput
          onSearch={(value) => {
            setSearch(value);
          }}
        />
      </span>

      <Table
        loading={getPatientsQuery?.isLoading || getPatientsQuery.isRefetching}
        rowsData={getPatientsQuery?.data?.data?.items || []}
        columns={[
          {
            title: 'ID',
            render: (rowData) => rowData?.id,
          },
          {
            title: 'Name',
            render: (rowData) => (
              <div className=" flex items-center gap-2">
                <Avatar
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowBiggerImage({
                      state: !!rowData?.pictureUrl,
                      data: rowData?.pictureUrl,
                    });
                  }}
                  // data-tooltip-id={rowData?.id}
                  sizeClass="h-8 w-8 "
                  imgUrl={rowData?.pictureUrl}
                />

                <Typography>{rowData?.name}</Typography>
              </div>
            ),
          },
          {
            title: 'Gender',
            render: (rowData) => gender?.[rowData?.patientDetails?.gender],
          },
          {
            title: '',
            render: (rowData) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setArchivePatient({
                    state: true,
                    id: rowData?.id,
                    hasSubscription: rowData.patientCompletedVisits > 0,
                  });
                }}
                variant="outlined"
                size="small"
                className="">
                Archive
              </Button>
            ),
            classNames: 'text-center  ',
            skeletonClassNames: 'flex justify-center',
          },
        ]}
        onRowClick={handleRowClick}
        noDataMessage={
          search
            ? 'No patient match this search criteria'
            : 'You currently have no patients in progress.'
        }
        pagination
        onPageChange={setPage}
        page={page}
        totalRecords={getPatientsQuery?.data?.data?.total || 0}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        isPageSizeSelectionAvailable
      />
      <Overlay
        onClose={() => {
          setShowBiggerImage({
            state: false,
            data: '',
          });
        }}
        className="!flex !items-center !justify-center"
        open={showBiggerImage.state}>
        {' '}
        <Avatar sizeClass="h-96 w-96 " imgUrl={showBiggerImage.data} />
      </Overlay>
      <ConfirmationModal
        confirmLoading={activatePatientMutation.isLoading}
        handleConfirm={handleArchivePatient}
        onCancel={() => {
          setArchivePatient({ state: false, id: '', hasSubscription: false });
        }}
        confirmText="Archive"
        semiBoldTitle
        isModalOpen={archivePatient.state}
        title="Are you sure you want to archive this patient? Archiving will move this patient from the active patient list to the archived patient list. Please note that if this patient has an active subscription, archiving them will result in the cancellation of their subscription."
        closeModal={() => {
          //
        }}
      />
    </div>
  );
}
