import { APIService } from 'services/api-service';

import { BillingNS } from './types';

export class BillingService extends APIService {
  static BILLING_PLANS_URL = `${this.API_PREFIX_URL}/billing/plans/v1`;

  static getBillingPlans = async () =>
    this.get<BillingNS.SubscriptionPlans>(`${this.BILLING_PLANS_URL}`, {
      withAuthToken: true,
    });

  static CANCEL_SUBSCRIPTION_URL = `${this.API_PREFIX_URL}/billing/subscription/cancel`;

  static cancelSubscription = async (patientId: string) => {
    const url = new URL(this.CANCEL_SUBSCRIPTION_URL);
    url.searchParams.append('patient_id', patientId);

    return this.post(
      url.toString(),
      {},
      {
        withAuthToken: true,
      }
    );
  };
}
