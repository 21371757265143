import { useEffect, useState } from 'react';

import { femaleHormoneRxTypes } from 'constants/hormone-details-new';
import { useDTD } from 'hooks/hormones/useDTD';
import { useSIG } from 'hooks/hormones/useSIG';
import { CurrentDoseType } from 'types/patient/prescription';

export const useTestoDHEAControllerController = () => {
  const TestoDHEADetails = femaleHormoneRxTypes?.['testosterone/dhea'];
  const [strength, setStrength] = useState('8/160');
  const [maximumDose, setMaximumDose] = useState(0);

  const [dose, setDose] = useState(0);
  const [refills, setRefills] = useState(0);
  const { dtd, setDTD, DTDValues } = useDTD(18);
  const { sig, setSig } = useSIG('');
  const [doseValues, setDoseValues] = useState([...TestoDHEADetails.dose]);
  const [note, setNote] = useState('');
  const [currentDose, setCurrentDose] = useState<CurrentDoseType>();

  const notIncludedDoseInLowerStrength = [0.1, 0.15];

  useEffect(() => {
    if (strength === '8/160') {
      // eslint-disable-next-line

      if (doseValues.includes(0.1)) {
        setDoseValues((pre) => {
          if (pre.includes(0.1)) {
            return pre.slice(2, doseValues.length);
          }

          return pre;
        });
        setDose(0);
      }
    } else {
      setDoseValues((pre) => {
        if (pre.includes(0.1)) {
          return pre;
        }

        pre.splice(0, 0, ...notIncludedDoseInLowerStrength);

        return pre;
      });
      setDose(2);
    }

    // eslint-disable-next-line
  }, [strength]);

  useEffect(() => {
    if (doseValues[dose + maximumDose] > 0.65) {
      setDTD(60);

      return;
    }
    if (doseValues[dose + maximumDose] > 0.3) {
      setDTD(30);
    } else {
      setDTD(18);
    }
  }, [maximumDose, dose, doseValues, setDTD]);

  return {
    TestoDHEADetails,

    sig,
    setSig,
    setDTD,
    DTDValues,

    dtd,
    strength,
    setStrength,

    dose,
    setDose,
    refills,
    setRefills,
    doseValues,
    maximumDose,
    setMaximumDose,
    note,
    setNote,
    setDoseValues,
    notIncludedDoseInLowerStrength,
    currentDose,
    setCurrentDose,
  };
};
