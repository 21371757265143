import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';

import { useProfile } from 'hooks/useProfile';

interface CheckThirdPartyCookieProps {
  onCookieStatusResolved: (isEnabled: boolean) => void;
}

export function CheckThirdPartyCookie({
  onCookieStatusResolved,
}: CheckThirdPartyCookieProps) {
  const userProfile = useProfile();

  useEffect(() => {
    window.addEventListener('message', (e) => {
      /*
       * The `checkingCookie` flag is to confirm if the message sent is from the cookie checking script.
       * The `cookieEnabled` is another flag which tells if the third party cookies are enabled or not.
       * First party cookies can be directly checked for, but to check if third party cookies are enabled then
       * this requires to be done on a third-party script.
       */
      if (e?.data?.checkingCookie) {
        if (!e.data?.cookieEnabled) {
          const sentryCookiesDisabledError = new Error(
            `Cookies are disabled for ${userProfile.profile?.firstName} ${userProfile.profile?.lastName} provider`,
            {}
          );
          Sentry.captureException(sentryCookiesDisabledError, {
            extra: {
              type: 'Data',
              value: {
                errors: ['Cookies are disabled'],
                providerID: userProfile.profile?.id,
              },
            },
          });
        }
        onCookieStatusResolved(!!e.data?.cookieEnabled);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <iframe
      height="0"
      width="0"
      title="checkCookie"
      src="https://storage.googleapis.com/brite-public-assets/static-assets/check-cookie.html"
    />
  );
}
