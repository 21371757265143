import React from 'react';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { emailSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';

type UpdateEmailProps = {
  modal: boolean;
  closeModal: (state: boolean) => void;
  email: string;
};
type EmailFormType = {
  email: string;
};
export function UpdateEmail({ modal, closeModal, email }: UpdateEmailProps) {
  const updateEmailMutation = useMutationWithToast(UserService.updateEmail);

  const { handleSubmit, control, reset } = useFormWithErrors<EmailFormType>({
    mutation: updateEmailMutation,
    schema: emailSchema,
  });

  const onSubmit = handleSubmit((data) => {
    updateEmailMutation.mutate(
      { email: data.email },
      {
        onSuccess: () => {
          closeModal(false);
          reset();
        },
      }
    );
  });

  return (
    <div>
      <Modal
        title="Change Email "
        open={modal}
        className="w-2/5"
        closeModal={closeModal}>
        <div className="mt-4">
          <TextInput
            className="pl-2 "
            type="text"
            name="email"
            control={control}
            placeholder="Enter email"
            defaultValue={email}
          />

          <div>
            <div className="mt-2 mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => closeModal(false)}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updateEmailMutation.isLoading}
                onClick={onSubmit}>
                Update Email
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
