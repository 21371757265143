export enum OrderStatus {
  PaymentConfirmed = 'payment_confirmed',
  PendingPayment = 'pending_payment',
  PartialCompleted = 'partial_payment',
  OrderConfirmed = 'order_confirmed',
  InProgress = 'in_progress',
  OutForDelivery = 'out_for_delivery',
  ReadyForPickup = 'ready_for_pickup',
  Returned = 'returned',
  Completed = 'completed',
  Error = 'error',
  Canceled = 'canceled',
  Shipped = 'shipped',
}

export const OrderStatusLabel: { [x: string]: string } = {
  [OrderStatus.PaymentConfirmed]: 'Awaiting Order Submission',
  [OrderStatus.PendingPayment]: 'Awaiting Payment',
  [OrderStatus.PartialCompleted]: 'Awaiting Payment',
  [OrderStatus.OrderConfirmed]: 'Prescription(s) Successfully Submitted',
  [OrderStatus.InProgress]: 'In Progress',
  [OrderStatus.OutForDelivery]: 'Out for Delivery',
  [OrderStatus.Completed]: 'Completed',
  [OrderStatus.Error]: 'Error',
  [OrderStatus.Canceled]: 'Canceled',
  [OrderStatus.Shipped]: 'Shipped',
  [OrderStatus.ReadyForPickup]: 'Ready for pickup',
  [OrderStatus.Returned]: 'Returned',
};

export const OrderStatusHoverText: { [x: string]: string } = {
  [OrderStatus.PaymentConfirmed]:
    'Waiting for the Brite admin to submit the order to Curexa',
  [OrderStatus.PendingPayment]:
    'These prescriptions will be hold back until the payment is received from patient.',
  [OrderStatus.PartialCompleted]:
    'These prescriptions will be hold back until the payment is received from patient.',
  [OrderStatus.OrderConfirmed]: 'Prescriptions are sent to the pharmacist.',
  [OrderStatus.InProgress]:
    'Our pharmacist are working on preparing your prescriptions.',
  [OrderStatus.OutForDelivery]: 'Prescriptions will be delivered by today EOD.',
  [OrderStatus.Completed]: 'Prescriptions are delivered.',
  [OrderStatus.Error]: 'Error',
  [OrderStatus.Canceled]: 'Canceled',
  [OrderStatus.Shipped]: 'Prescriptions are shipped.',
};
